import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const WalletSvg: React.FC<Props> = ({ className = '', color = '#686A7B', ...props }) => {
  return (
    <StyledSvg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M6.13756 4.09766C5.42715 4.09766 4.74585 4.4363 4.24352 5.0391C3.74119 5.64189 3.45898 6.45946 3.45898 7.31194V7.9548H18.459V7.31194C18.459 6.45946 18.1768 5.64189 17.6744 5.0391C17.1721 4.4363 16.4908 4.09766 15.7804 4.09766H6.13756ZM18.459 9.24051H3.45898V13.7405C3.45898 14.593 3.74119 15.4106 4.24352 16.0134C4.74585 16.6162 5.42715 16.9548 6.13756 16.9548H15.7804C16.4908 16.9548 17.1721 16.6162 17.6744 16.0134C18.1768 15.4106 18.459 14.593 18.459 13.7405V9.24051ZM13.6376 13.0977H15.7804C15.9225 13.0977 16.0588 13.1654 16.1592 13.2859C16.2597 13.4065 16.3161 13.57 16.3161 13.7405C16.3161 13.911 16.2597 14.0745 16.1592 14.1951C16.0588 14.3156 15.9225 14.3834 15.7804 14.3834H13.6376C13.4955 14.3834 13.3592 14.3156 13.2587 14.1951C13.1583 14.0745 13.1018 13.911 13.1018 13.7405C13.1018 13.57 13.1583 13.4065 13.2587 13.2859C13.3592 13.1654 13.4955 13.0977 13.6376 13.0977Z"
        fill={color}
      />
    </StyledSvg>

  )
}

const StyledSvg = styled.svg`

`
