import styled, { css } from 'styled-components'
import { applyStyles, SIZES } from 'context/layoutContext'
import { COLORS, FONTS } from 'ui-kit/constants'
import { SvgIcon } from '../svg';


export const SItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const SItem = styled.div`
  display: flex;
  background: ${COLORS.dark600};
  border-radius: 4px;
  color: ${COLORS.dark80};
  flex-direction: column;
`

export const SQuestion = styled.div`
  ${FONTS.a1};
  cursor: pointer;
  padding: 16px 12px;
  transition: 0.3s;
  flex: 1;

  &:hover, &.active {
    color: ${COLORS.white};
  }
`

export const SHeader = styled.div`
  display: flex;
`

export const SArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`

export const SDropdownArrow = styled(SvgIcon)`
  margin-left: 8px;
  color: ${COLORS.dark200};
  transition: 0.2s;

  &.rotated {
    transform: rotate(-180deg);
    color: white;
  }
`

export const SAnswer = styled.div`
  ${FONTS.a1};
  line-height: 150%;
  padding: 0 12px 16px;
`
