import React from 'react'

import { ModalType } from 'types/enums'
import { Button } from 'ui-kit'
import { useTranslation } from 'next-i18next'
import { useAppContext } from 'context/AppContext'

import { StyledLoginLink, StyledWrapperButton } from './styles'
import CheckboxAgreement from './CheckboxAgreement'

export function SubmitFooter ({ sending }: { sending: boolean }) {
  const { t } = useTranslation()
  const context = useAppContext()

  return (
    <StyledWrapperButton>
      <Button type='submit'
        spinner={sending}
      >
        {t('registration_button')}
      </Button>

      <CheckboxAgreement />

      <StyledLoginLink>
        {`${t('registration_has_account')} `}

        <span onClick={() => context.showModal(ModalType.login)}>
          {t('registration_login')}
        </span>
      </StyledLoginLink>
    </StyledWrapperButton>
  )
}

