import styled from 'styled-components'
import { Button } from 'ui-kit'

export const StyledTitle = styled.div`
  font-weight: 700;
  font-size: 22px;
  line-height: 100%;
  color: #FFD747;
  text-align: center;
  line-height: 1.5;

  @media screen and (max-width: $xs-land) {
    font-size: 16px;
  }
`
export const StyledImg = styled.img`
  margin-top: 20px;
  width: 197px;
  height: 225px;

  @media screen and (max-width: $xs-land) {
    width: 163px;
    height: 190px;
  }
`

export const StyledText = styled.div`
  margin-top: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #FFFFFF;
  max-width: 427px;

  a {
    color: #2C50FF;
  }

  @media screen and (max-width: $xs-land) {
    margin-top: 14px;
    font-size: 10px;
  }
`

export const StyledFooter = styled.div`
  margin-top: 40px;
  display: flex;
  gap: 12px;
`
export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;

  @media screen and (max-width: 700px) {
    padding: 100px 20px;
  }
`

export const StyledButton = styled(Button)`
  min-width: 130px;
  padding: 0 24px;
`
