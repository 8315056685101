import { FC } from 'react'

import classNames from 'classnames'
import { useAppContext } from 'context/AppContext'

import { ModalClose } from '../ModalClose/ModalClose'

import classes from './HeaderDesktop.module.scss'

interface Props {
  title?: string
  showId?: boolean
  showBack?: boolean
  onBackClick?: () => void
}

export const HeaderDesktop: FC<Props> = (props) => {
  const appContext = useAppContext()

  return (
    <div className={classNames(classes.root)}>
      <div className={classes.left}>
        {(props.showBack || appContext.lastProfileModal?.type) && (
          <div className={classes.back}
            onClick={props.onBackClick}
          >
            <img src="/img/icons/back.svg"
              alt=""
            />
          </div>
        )}

        <div className={classes.title}>
          {props.title}
        </div>
      </div>

      <div className={classes.right}>
        {props.showId && appContext.user && (
          <div className={classes.id}>
ID
            {appContext.user?.id}
          </div>
        )}

        <ModalClose />
      </div>
    </div>
  )
}
