import React from 'react'

import styled from 'styled-components'
import Spinner from 'components/ui/Spinner'
import classNames from 'classnames'

import { COLORS, FONTS } from './constants'

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  className?: string,
  children: React.ReactNode,
  disabled?: boolean
  spinner?: boolean,
  color?: 'blue' | 'green' | 'dark' | 'gray' | 'transparent-outlined',
  size?: 'medium' | 'small'
  mode?: 'transparent'
}

export const Button: React.FC<Props> = ({
  className = '',
  disabled,
  spinner,
  color = 'blue',
  size = 'medium',
  mode,
  children,
  ...props
}) => {
  return (
    <ButtonBox
      className={classNames(className, color, size)}
      disabled={disabled}
      mode={mode}
      {...props}
    >
      {!spinner && children}

      <SpinnerBox className={classNames({ visible: spinner })}>
        <Spinner
          size={22}
          color="#fff"
          secondaryColor="rgba(255,255,255,0.4)"
        />
      </SpinnerBox>
    </ButtonBox>
  )
}

interface IButtonProps {
  mode?: 'transparent'
}

const ButtonBox = styled.button<IButtonProps>`
  border: none;
  height: 44px;
  width: 100%;
  background: ${COLORS.btnBlue};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  ${FONTS.a1};
  color: ${COLORS.white};
  cursor: pointer;
  position: relative;
  text-wrap: nowrap;
  line-height: 1.4;

  &:hover, &:focus {
    background: ${COLORS.btnBlueFocused};
  }

  &:disabled {
    pointer-events: none;
  }

  //sizes
  &.small {
    height: 32px;
  }

  //colors
  &.green {
    background: ${COLORS.btnGreen};

    &:hover, &:focus {
      background: ${COLORS.btnGreenFocused};
    }
  }

  &.dark {
    background: ${props => props.mode === 'transparent' ? 'rgba(29, 30, 37, 0.50)' : COLORS.dark600};
    color: ${COLORS.dark200};

    &:hover, &:focus {
      background: ${COLORS.dark550};
    }
  }

  &.gray {
    background: ${COLORS.dark400};
    color: ${COLORS.dark200};

    &:hover, &:focus {
      background: ${COLORS.dark400};
      color: white;
    }
  }

  &.transparent-outlined {
    background: transparent;
    color: white;
    border: 1px solid ${COLORS.dark400};

    &:hover, &:focus {
      background: ${COLORS.dark400};
    }
  }

`

const SpinnerBox = styled.div`
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &.visible {
    visibility: visible;
  }
`
