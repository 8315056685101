import styled from "styled-components"
import { ScrollableWithShadows } from "ui-kit"
import { BREAKPOINTS, COLORS, FONTS } from "ui-kit/constants"

export const WalletPaymentSystemBox = styled(ScrollableWithShadows)`
  margin-top: 16px;
  width: calc(100% + 10px);

  @media screen and (max-width: ${BREAKPOINTS.xsMax}px) {
    width: 100%;
  }
`

export const CardsBox = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;

  @media screen and (max-width: ${BREAKPOINTS.xsMax}px) {
    grid-gap: 1.875vw;
  }
`

export const CardBox = styled.div`
  width: 100%;
  height: 100%;
  min-height: 115px;
  background: ${COLORS.dark400};
  border-radius: 8px;
  padding: 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  border: 2px solid transparent;
  position: relative;

  &.active {
    background: ${COLORS.dark550};
    border-color: #3E7AFF;
  }
`

export const CardImg = styled.img`
  margin-bottom: 5px;
  width: 100%;
  height: 48px;
  min-height: 48px;
  object-fit: contain;
`

export const CardName = styled.p`
  margin-top: auto;
  ${FONTS.a1};
  color: ${COLORS.white};
  line-height: 140%;
`

export const CardMinAmount = styled.p`
  margin-top: 5px;
  ${FONTS.p2};
  color: ${COLORS.dark80};

  ${CardBox}.active & {
    color: ${COLORS.white};
  }
`
