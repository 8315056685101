import React from "react";
import styled from "styled-components";

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string;
  color?: string;
}

export const PhoneSvg: React.FC<Props> = ({
  className = "",
  color = "#686A7B",
  ...props
}) => (
  <StyledSvg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path
      d="M14.5269 16.9999C11.471 16.9959 8.5414 15.7778 6.38054 13.6126C4.21968 11.4475 3.00396 8.51202 3 5.45004C3 4.53505 3.36276 3.65754 4.00846 3.01055C4.65417 2.36356 5.52994 2.00009 6.44311 2.00009C6.63648 1.99861 6.82953 2.01619 7.01946 2.05258C7.20308 2.07981 7.38358 2.12503 7.55838 2.18758C7.68132 2.2308 7.79087 2.30543 7.8762 2.40408C7.96153 2.50274 8.01968 2.622 8.04491 2.75007L9.07036 7.25001C9.098 7.37215 9.09467 7.49929 9.06068 7.61982C9.02668 7.74034 8.9631 7.85042 8.87575 7.94C8.77844 8.045 8.77096 8.0525 7.8503 8.53249C8.58757 10.1531 9.88113 11.4546 11.4955 12.1999C11.982 11.2699 11.9895 11.2625 12.0943 11.165C12.1837 11.0774 12.2936 11.0137 12.4139 10.9797C12.5341 10.9456 12.661 10.9423 12.7829 10.97L17.2739 11.9974C17.3977 12.0262 17.5121 12.086 17.6063 12.1713C17.7006 12.2566 17.7717 12.3645 17.8129 12.4849C17.876 12.6629 17.9236 12.8461 17.9551 13.0324C17.9852 13.2209 18.0002 13.4115 18 13.6024C17.9862 14.5135 17.6132 15.3821 16.9626 16.0186C16.3119 16.6551 15.4363 17.0079 14.5269 16.9999Z"
      fill={color}
    />
  </StyledSvg>
);

const StyledSvg = styled.svg``;
