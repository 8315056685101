import React, { useRef, useState } from 'react'

import CurrencySvg from 'components/svg/CurrencySvg/CurrencySvg'
import styled from 'styled-components'
import { BREAKPOINTS, COLORS, FONTS } from 'ui-kit/constants'
import UserUtils from 'utils/user'
import Converter from 'utils/converter'
import { ProfileModalType, SnackbarType } from 'types/enums'
import UserRepository from 'data/repositories/UserRepository'
import { useAppContext } from 'context/AppContext'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { Button, Scrollable, Tabs } from 'ui-kit'
import { useDetectOutsideClick } from 'hooks/useDetectOutsideClick'
import { CryptoSvg } from 'components/svg/CryptoSvg'
import { FiatSvg } from 'components/svg/FiatSvg'
import GiftSvg from 'components/svg/GiftSvg'
import { WalletPlusSvg } from 'components/svg/WalletPlusSvg'
import { useRouter } from 'next/router'
import { Routes } from 'types/routes'
import { SvgIcon } from 'ui-kit/svg'
import Link from "next/link"

export const BalancesDropdown = ({ className = '' }) => {
  const appContext = useAppContext()
  const { t } = useTranslation()
  const router = useRouter()

  const mainAccount = UserUtils.getMainBalanceReal(appContext.user)
  const otherAccounts = (UserUtils.getOtherBalancesReal(appContext.user))
  const accountOptions = Converter.convertUserBalanceCurrencyToOptions([mainAccount, ...otherAccounts], appContext.currencies)
  const bonusesBalances = UserUtils.getBonusBalances(appContext.user)
  const wageringBonusPercents = UserUtils.getWageringBonusesPercents(appContext.user)

  const fiatCurrencies = accountOptions.filter((option) => !appContext.currencies.find(c => c.iso === option.value)?.flags?.isCrypto)
  const cryptoCurrencies = accountOptions.filter((option) => !!appContext.currencies.find(c => c.iso === option.value)?.flags?.isCrypto)

  const dropdownRef = useRef(null)

  const [opened, toggleDropdown] = useDetectOutsideClick(dropdownRef, false)
  const [activeTab, setActiveTab] = useState('fiat')

  const tabs = [
    {
      content: (
        <>
          <StyledFiatSvg color={activeTab === 'fiat' ? 'white' : COLORS.dark200} />

          {' '}

          {t('balances_dropdown_fiat')}
        </>
      ),
      value: 'fiat',
    },
    {
      content: (
        <>
          <StyledCryptoSvg color={activeTab === 'crypto' ? 'white' : COLORS.dark200} />

          {' '}

          {t('balances_dropdown_crypto')}
        </>
      ),
      value: 'crypto',
    },
    {
      content: (
        <>
          <StyledGiftSvg color={activeTab === 'bonus' ? 'white' : COLORS.dark200} />

          {' '}

          {t('balances_dropdown_bonus')}
        </>
      ),
      value: 'bonus',
    },
  ]

  const handleOptionClick = async (currency?: string) => {
    if (!currency) {
      appContext.showModalProfile(ProfileModalType.profile)

      return
    }

    try {
      await UserRepository.changeDefaultCurrency(currency)

      appContext.updateUserFromCookies()
      appContext.onChangeMainCurrency(currency)
    } catch (error) {
      appContext.showSnackbar(error, SnackbarType.error)
    }
  }

  const handleTabChange = (tab: string) => {
    setActiveTab(tab)
  }

  const handleWalletClick = () => {
    if (activeTab === 'bonus') {
      router.push('/bonuses-dashboard')
    } else {
      appContext.showModal(ProfileModalType.wallet)
    }
  }

  const handleFreeSpinsClick = () => {
    appContext.showModalProfile(ProfileModalType.freeSpinsGames)
  }

  const handleLotteryClick = () => {
    router.push(Routes.lottery)
  }

  const options = activeTab === 'fiat' ? fiatCurrencies : activeTab === 'crypto' ? cryptoCurrencies : []

  if (!!appContext.user.flags.isHideBalance) {
    return (
      <BalancesDropdownBox className={className}>
        <DepositBtn color="green"
          onClick={handleWalletClick}
        >
          <WalletImg src="/img/icons/wallet.svg" />

          <StyledWalletPlusSvg color="white" />

          <span>
            {t('profile_deposit')}
          </span>
        </DepositBtn>
      </BalancesDropdownBox>
    )
  }

  return (
    <BalancesDropdownBox className={className}
      ref={dropdownRef}
    >
      <DropdownToggle onClick={() => toggleDropdown(!opened)}>
        <StyledCurrency
          color
          currencyIso={mainAccount?.currency}
        />

        {mainAccount?.value}

        <StyledArrowSvg type="dropdown-arrow"
          className={classNames({ rotated: !!opened })}
        />
      </DropdownToggle>

      <DepositBtn color="green"
        onClick={handleWalletClick}
      >
        <WalletImg src="/img/icons/wallet.svg" />

        <StyledWalletPlusSvg color="white" />

        <span>
          {t('profile_deposit')}
        </span>
      </DepositBtn>

      {
        opened && (
          <DropdownBox>
            <StyledTabs
              tabs={tabs}
              activeTab={activeTab}
              onChange={handleTabChange}
            />

            {
              (activeTab === 'fiat' || activeTab === 'crypto') && (
                <OptionsBox>
                  {
                    options.map(option => (
                      <Option key={option.value}
                        onClick={() => handleOptionClick(option.label)}
                      >
                        <StyledCurrency color
                          currencyIso={option?.value}
                        />

                        <CurrencyIso>
                          {option.displayName}
                        </CurrencyIso>

                        <AmountsBox>
                          <MainAmount>
                            {option.balance}

                            {' '}

                            {option.displayIso}
                          </MainAmount>

                          <AdditionalAmount>
                            {option.calculatedBalance}

                            {' '}

                            {option.mainCurrency}
                          </AdditionalAmount>
                        </AmountsBox>
                      </Option>
                    ))
                  }

                  {
                    !options.length && (
                      <EmptyText>
                        {t('search_empty')}
                      </EmptyText>
                    )

                  }
                </OptionsBox>
              )
            }

            {
              activeTab === 'bonus' && (
                <OptionsBox>
                  {bonusesBalances.map(bonus => (
                    <WagerBonus key={bonus.currency}>
                      <Link href={Routes.bonusesDashboard}>
                        <WagerBonusTitleRow>
                          <BonusImg src="/img/bonuses/casino.png" />

                          {t('balances_dropdown_casino_bonus')}

                          <SInfoIcon type={'info'} />
                        </WagerBonusTitleRow>
                      </Link>

                      <WagerBonusWagerRow>
                        <WagerBonusWagerRowLeft>
                          {t('balances_dropdown_wager')}
                        </WagerBonusWagerRowLeft>

                        <WagerBonusWagerRowRight>
                          <WagerBonusAmount>
                            {bonus.value}

                            {' '}

                            {bonus.currency}
                          </WagerBonusAmount>

                          <WagerBonusAdditionalAmount>
                            {bonus.calculated}

                            {' '}

                            {bonus.mainCurrency}
                          </WagerBonusAdditionalAmount>
                        </WagerBonusWagerRowRight>
                      </WagerBonusWagerRow>

                      <WageredProgressBox>
                        <ProgressInner style={{ width: (wageringBonusPercents[bonus.currency.toLowerCase()] || 0) + '%' }} />

                        <ProgressText>
                          {wageringBonusPercents[bonus.currency.toLowerCase()] || 0}
                          %
                        </ProgressText>
                      </WageredProgressBox>
                    </WagerBonus>
                  ))
                  }

                  <BonusesItemsList>
                    <BonusItem onClick={handleFreeSpinsClick}
                      className={classNames({ disabled: (appContext.user.extraBalances.freespinAmount ?? 0) === 0 })}
                    >
                      <BonusImg src="/img/bonuses/fs.png" />

                      <BonusName>
                        {t('bonus_fs')}
                      </BonusName>

                      <BonusAmount>
                        {appContext.user.extraBalances.freespinAmount ?? 0}
                      </BonusAmount>

                      <ArrowRightSvg
                        type="dropdown-arrow"
                      />
                    </BonusItem>

                    <BonusItem onClick={handleLotteryClick}>
                      <BonusImg src="/img/bonuses/lottery.png" />

                      <BonusName>
                        {t('profile_accounts_bonus_lottery')}
                      </BonusName>

                      <BonusAmount>
                        {appContext.user.extraBalances.lotteryTickets ?? 0}
                      </BonusAmount>

                      <ArrowRightSvg
                        type="dropdown-arrow"
                      />
                    </BonusItem>
                  </BonusesItemsList>
                </OptionsBox>
              )}

            <StyledButton color="green"
              onClick={handleWalletClick}
            >
              <WalletImg src="/img/icons/wallet.svg" />

              {activeTab === 'bonus' && t('bonus_dashboard_title')}

              {activeTab !== 'bonus' && t('header_deposit')}
            </StyledButton>
          </DropdownBox>
        )
      }

    </BalancesDropdownBox>
  )
}

const BalancesDropdownBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 44px;

  @media (max-width: ${BREAKPOINTS.xsMax - 1}px) {
    height: 36px;
  }
`

const DropdownToggle = styled.div`
  height: 100%;
  padding: 0 16px;
  display: flex;
  align-items: center;
  ${FONTS.a1};
  line-height: 1;
  background: ${COLORS.dark400};
  border-radius: 4px;
  cursor: pointer;
  user-select: none;

  @media (max-width: ${BREAKPOINTS.xsMax - 1}px) {
    padding: 0 12px;
  }
`

const StyledCurrency = styled(CurrencySvg)`
  margin-right: 11px;
  width: 20px;
  height: 20px;
  max-width: 20px;
  max-height: 20px;
  min-width: 20px;
  min-height: 20px;

  img {
    max-width: 20px;
    max-height: 20px;
    width: 20px;
    height: 20px;
  }
`

const StyledArrowSvg = styled(SvgIcon)`
  margin-left: 11px;
  width: 20px;
  height: 20px;
  color: ${COLORS.dark200};

  &.rotated {
    transform: rotate(180deg);
    color: white;
  }
`

const DropdownBox = styled.div`
  width: 370px;
  background: ${COLORS.dark600};
  border-radius: 0px 0px 12px 12px;
  position: absolute;
  top: 54px;
  left: 50%;
  transform: translateX(-50%);
  padding: 16px 24px;

  @media (max-width: ${BREAKPOINTS.xsMax - 1}px) {
    width: 100%;
    position: fixed;
    overflow: auto;
    top: 56px;
    left: 0;
    z-index: 10000;
    transform: unset;
  }

  @media (max-height: 599px) and (orientation: landscape) {
    width: 400px;
    position: fixed;
    overflow: auto;
    top: 56px;
    right: 0;
    left: unset;
    z-index: 10000;
    transform: unset;
  }
`

const StyledTabs = styled(Tabs)`
  min-height: 36px;

  .tab {
    padding: 0 17px;
    flex: 1;
  }
`

const OptionsBox = styled(Scrollable)`
  margin-top: 16px;
  overflow: auto;
  width: 100%;
  max-height: min(320px, calc(100vh - 245px));
  overscroll-behavior: contain;

  @media (min-width: ${BREAKPOINTS.xsMax}px) {
    position: relative;
    width: calc(100% + 10px);
    overflow-y: auto;
    scrollbar-gutter: stable;
  }
`

const Option = styled.div`
  display: flex;
  align-items: center;
  height: 46px;
  width: 100%;
  padding: 0 16px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: ${COLORS.dark550};
  }

  &.active {
    background: ${COLORS.dark550};
  }
`

const CurrencyIso = styled.span`
  ${FONTS.p1};
`

const AmountsBox = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const MainAmount = styled.p`
  ${FONTS.a1};
`

const AdditionalAmount = styled.p`
  font-weight: 500;
  font-size: 10px;
  line-height: 120%;
  color: ${COLORS.dark200};
`

const StyledButton = styled(Button)`
  margin-top: 16px;
  width: 100%;
`

const WalletImg = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 6px;

  @media (max-width: ${BREAKPOINTS.xsMax}px) {
    display: none;
  }
`

const StyledCryptoSvg = styled(CryptoSvg)`
  min-width: 20px;
  margin-right: 6px;
`

const StyledFiatSvg = styled(FiatSvg)`
  min-width: 20px;
  margin-right: 6px;
`

const DepositBtn = styled(Button)`
  margin-left: -4px;
  padding: 0 27px;
  height: 100%;

  @media (max-width: ${BREAKPOINTS.xsMax - 1}px) {
    height: 36px;
    width: 38px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: none;
    }
  }
`

const StyledWalletPlusSvg = styled(WalletPlusSvg)`
  display: none;
  width: 24px;
  height: 24px;

  @media (max-width: ${BREAKPOINTS.xsMax}px) {
    display: block;
  }
`

const EmptyText = styled.p`
  ${FONTS.p1};
  color: white;
  height: 46px;
  width: 100%;
  padding: 0 16px;
  display: flex;
  align-items: center;
`

const StyledGiftSvg = styled(GiftSvg)`
  min-width: 20px;
  margin-right: 6px;
`

const BonusesItemsList = styled.div`
  background: ${COLORS.dark550};
  border-radius: 4px;
`

const BonusItem = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  width: 100%;
  padding: 0 13px 0 20px;
  background: ${COLORS.dark550};
  cursor: pointer;

  &:hover {
    background: ${COLORS.dark400};
  }

  &:first-of-type {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-of-type {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &.disabled {
    pointer-events: none;
  }
`

const BonusImg = styled.img`
  margin-right: 6px;
  width: 20px;
  height: 20px;
`

const BonusName = styled.p`
  ${FONTS.p1};
  color: white;
`

const BonusAmount = styled.p`
  margin-left: auto;
  ${FONTS.a1};
  color: white;
`

const ArrowRightSvg = styled(SvgIcon)`
  margin-left: 8px;
  width: 20px;
  height: 20px;
  transform: rotate(270deg);
  color: ${COLORS.dark200};
`

const WagerBonus = styled.div`
  margin-bottom: 24px;
  background: ${COLORS.dark550};
  border-radius: 4px;
  padding: 17px 20px;
  width: 100%;
`

const WagerBonusTitleRow = styled.a`
  ${FONTS.h4};
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const WagerBonusWagerRow = styled.div`
  margin-top: 9px;
  display: flex;
  justify-content: space-between;
`

const WagerBonusWagerRowLeft = styled.div`
  color: ${COLORS.dark80};
  ${FONTS.p1};
`

const WagerBonusWagerRowRight = styled.div`

`

const WagerBonusAmount = styled.p`
  ${FONTS.a1};
  line-height: 120%;
  color: white;
`

const WagerBonusAdditionalAmount = styled.p`
  font-size: 10px;
  font-weight: 600;
  color: ${COLORS.dark200};
  text-align: right;
`

const WageredProgressBox = styled.div`
  margin-top: 13px;
  width: 100%;
  background: ${COLORS.dark400};
  border-radius: 100px;
  padding: 2px;
  position: relative;
  height: 20px;
`

const ProgressInner = styled.div`
  background: linear-gradient(270deg, #17AA25 -6.01%, #76DC26 114.87%);
  border-radius: 100px;
  border-radius: 100px;
  height: 100%;
`

const ProgressText = styled.span`
  ${FONTS.a2};
  color: ${COLORS.dark80};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const SInfoIcon = styled(SvgIcon)`
  margin-left: auto;
  width: 16px;
  height: 16px;
`
