import styled from 'styled-components'
import { COLORS } from 'ui-kit/constants'



export const SPagination = styled.div`
    margin-top: 24px;
    margin-bottom: 24px;
    display: grid;
    grid-gap: 8px;
    grid-template-columns: repeat(auto-fit, minmax(0, 32px));

    @media (max-width: 769px) {
        display: grid;
    }
`


export const SArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  transform: rotate(-90deg);
  opacity: 0.3;

  &.left {
    transform: rotate(90deg);
  }
`


export const SButton = styled.button`
    height: 32px;
    min-width: 32px;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 4px;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: ${COLORS.dark200};

    ${SArrow} {
      opacity: 0.3
    }

    &:hover {
      background: ${COLORS.blue};
      color: ${COLORS.white};

      ${SArrow} {
        opacity: 1
      }
    }

  &.active {
    background: ${COLORS.blue};
    color: ${COLORS.white};
  }
`
