import { useEffect } from 'react'

export const useResizeViewPort = () => {
  const setAppHeight = () => {
    document.documentElement.style.setProperty(
      '--app-height',
      `${window.innerHeight}px`,
    )
  }

  useEffect(() => {
    if (!window) return

    setAppHeight()

    window.addEventListener('resize', setAppHeight)

    return () => {
      window.removeEventListener('resize', setAppHeight)
    }
  }, [])
}
