export const CURRENCY_ISO = {
  USDT: 'USDT',
  USDT_TRC20: 'USDT TRC20',
}

export const PAYMENT_SYSTEM_NAME = {
  TRC20: 'TRC20',
}

export const CONTACTS = {
  email: 'mailto:support@richy.casino',
  facebook: 'https://facebook.com/richybetgames',
  youtube: 'https://www.youtube.com/channel/UCO50cIEX2SN5xoch8e5Z7eg',
  instagram: 'https://instagram.com/richy.games',
  twitter: 'https://twitter.com/richy_casino',
  telegram: 'https://t.me/richycasino',
  linkedIn: '#',
}

export const LINKS = {
  partners: 'https://richy.partners',
  responsiblegambling: 'https://www.responsiblegambling.org',
  begambleaware: 'https://www.begambleaware.org',
  thegamblerbay: 'https://www.thegamblerbay.com',
  onlineCasinoGroups: 'https://www.onlinecasinogroups.com/curacao-casinos/',
  license: '/license.jpg',
}

//'US', 'GB'
export const RESTRICTED_COUNTRIES = []
