import React, { useState } from 'react'

import ReactModal from 'react-modal'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import { RegistrationType } from 'types/enums'
import { COLORS } from 'ui-kit/constants'
import { useAppContext } from 'context/AppContext'

import {
  Content, Frame, Right, Root, StyledEmailIcon,
  StyledPhoneIcon, StyledCloseIcon, Overlay, StyledTabs,
  StyledTab, StyledTabLabel
} from './styles'
import styles from './index.module.scss'
import FastRegistration from './Forms/FastRegistration'
import PhoneRegistration from './Forms/PhoneRegistration'
import { Banner } from './Banner'

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
}

export const ModalRegistration: React.FC<Props> = ({
  isOpen,
  onRequestClose,
}) => {
  const [activeTab, setActiveTab] = useState<RegistrationType>(
    RegistrationType.email
  )
  const [minFormHeight, setMinHeight] = useState<number>(0)
  const [isOverflowAutoDisabled, toggleIsOverflowAutoDisabled] = useState(false)
  const { t } = useTranslation()
  const context = useAppContext()

  const settings = {
    className: styles.modalContent,
    overlayClassName: classNames(styles.modalOverlay),
  }

  // const tabs = [
  //   {
  //     content: (
  //       <StyledTab>
  //         <StyledEmailIcon color={activeTab === RegistrationType.email ? 'white' : COLORS.dark200} />

  //         <StyledTabLabel>
  //           {t('registration_tab_fast')}
  //         </StyledTabLabel>
  //       </StyledTab>
  //     ),
  //     value: RegistrationType.email,
  //   },
  //   {
  //     content: (
  //       <StyledTab>
  //         <StyledPhoneIcon color={activeTab === RegistrationType.phone ? 'white' : COLORS.dark200} />

  //         <StyledTabLabel>
  //           {t('registration_tab_phone')}
  //         </StyledTabLabel>
  //       </StyledTab>
  //     ),
  //     value: RegistrationType.phone,
  //   }
  // ]

  const handleInputFocus = () => {
    toggleIsOverflowAutoDisabled(true)
  }

  const handleInputBlur = () => {
    toggleIsOverflowAutoDisabled(false)
  }

  return (
    <ReactModal {...settings}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick
    >
      <Frame>
        <Root>
          <Banner
            onRequestClose={onRequestClose}
          />

          <Right className={classNames({ 'without-overflow': isOverflowAutoDisabled })}>
            <StyledCloseIcon type="close"
              onClick={() => onRequestClose()}
            />

            {/* <StyledTabs
              tabs={tabs}
              activeTab={activeTab}
              onChange={setActiveTab}
            /> */}

            <Content>
              {activeTab === RegistrationType.email && (
                <FastRegistration setMinHeight={setMinHeight}
                  onInputFocus={handleInputFocus}
                  onInputBlur={handleInputBlur}
                />
              )}

              {activeTab === RegistrationType.phone && (
                <PhoneRegistration minHeight={minFormHeight}
                  onInputFocus={handleInputFocus}
                  onInputBlur={handleInputBlur}
                />
              )}
            </Content>
          </Right>
        </Root>

        <Overlay onClick={() => onRequestClose()} />
      </Frame>
    </ReactModal>
  )
}
