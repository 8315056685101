import React, { useEffect } from 'react'

import { useTranslation } from 'next-i18next'
import Formatter from 'utils/formatter'
import { useTimer } from 'react-timer-hook'
import styled from 'styled-components'
import { COLORS, FONTS } from 'ui-kit/constants'
import { useLoyaltyContext } from 'context/loyaltyContext'
import { PrivelegyIdent } from 'data/interfaces/ILoyalty'
import { pluralize } from "numeralize-ru"

export const WalletDepositBanner = ({ className = '' }) => {
  const { t } = useTranslation()
  const loyaltyContext = useLoyaltyContext()

  const data = loyaltyContext.depositPageBonus
  const relativeAmountBonus = data?.privileges?.[PrivelegyIdent.relativeAmount] && data.privileges?.[PrivelegyIdent.relativeAmount][data.privileges?.[PrivelegyIdent.relativeAmount].length - 1]

  const handleExpire = () => {
    loyaltyContext.refetchBonuses()
  }

  useEffect(() => {
    if (data?.validTill && data.validTill - (new Date()).getTime() < 0) {
      loyaltyContext.refetchBonuses()
    }
  }, [data?.validTill])

  if (!data?.validTill || data.validTill - (new Date()).getTime() < 0) {
    return null
  }

  const expiredAt = new Date(data?.validTill)

  return (
    <BannerBox className={className}>
      <GiftImg src="/img/bonuses/deposit-banner-gift.png" />

      <GiftBottomImg src="/img/bonuses/deposit-banner-gift-bottom.png" />

      <Left>
        <BonusTitle>
          {t('profile_accounts_bonus_title')}

          {' '}

          {!!relativeAmountBonus && relativeAmountBonus.percent + '%'}
        </BonusTitle>

        <BonusUpTo>
          {t('bonus_up_to')}

          {' '}

          {Formatter.formatNumber(relativeAmountBonus?.maxAmount)}

          {' '}

          {relativeAmountBonus?.currency || 'USD'}
        </BonusUpTo>
      </Left>

      <Right>
        <Only>
          Only
          {' '}

          {data.activeHours}

          {' '}

          {pluralize(data.activeHours, t('timer_hours_1'), t('timer_hours_2'), t('timer_hours_5')).toLowerCase()}
        </Only>

        {!!data.validTill && (
          <Timer
            expiredAt={expiredAt}
            onExpire={handleExpire}
          />
        )}
      </Right>
    </BannerBox>
  )
}

const BannerBox = styled.div`
  margin-bottom: 16px;
  width: 100%;
  height: 82px;
  background: linear-gradient(277.12deg, #A8FF38 -0.47%, #2AA23D 27.95%, #272832 79.78%);
  border-radius: 4px;
  padding: 16px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Left = styled.div`
  position: relative;
`

const Right = styled.div`
  position: relative;
`

const BonusTitle = styled.p`
  font-weight: 900;
  font-size: 24px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #FFD747;
`

const BonusUpTo = styled.p`
  font-weight: 900;
  font-size: 16px;
  color: #FFFFFF;
  text-transform: uppercase;
`

const Only = styled.p`
  font-weight: 900;
  font-size: 12px;
  line-height: 140%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  color: #FFFFFF;
`

const GiftImg = styled.img`
  position: absolute;
  top: 9px;
  right: 89px;
  width: 40px;
  height: 40px;
  object-fit: contain;
`

const GiftBottomImg = styled.img`
  position: absolute;
  bottom: 0px;
  right: 2px;
  width: 45px;
`

const splitTimerValue = (value: number) =>
  value >= 10 ? [Math.floor(value / 10), value % 10] : [0, value]


const Timer = ({ expiredAt, onExpire }: { expiredAt: Date, onExpire: () => void }) => {
  const { seconds, minutes } = useTimer({
    expiryTimestamp: expiredAt,
    onExpire: onExpire
  })

  return (
    <TimerBox>
      {splitTimerValue(minutes).map((number, index) => (
        <TimerValueBox
          key={index}
        >
          {number}
        </TimerValueBox>
      ))}

      <TimerDivider>
        <TimerDividerItem />

        <TimerDividerItem />
      </TimerDivider>

      {splitTimerValue(seconds).map((number, index) => (
        <TimerValueBox
          key={index}
        >
          {number}
        </TimerValueBox>
      ))}

    </TimerBox>
  )
}

const TimerBox = styled.div`
  display: flex;
  align-items: center;
`

const TimerValueBox = styled.div`
  width: 24px;
  height: 24px;
  background: #FFFFFF;
  border-radius: 4px;
  border: 2px solid ${COLORS.green};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.dark550};
  ${FONTS.a0};

  & ~ & {
    margin-left: 2px;
  }
`

const TimerDivider = styled.div`
  margin: 0 2px 0 4px;
`

const TimerDividerItem = styled.div`
  width: 4px;
  height: 4px;
  background: ${COLORS.white};
  border-radius: 50%;

  & ~ & {
    margin-top: 4px;
  }
`
