import React from "react";
import styled from "styled-components";

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string;
  color?: string;
}

export const EmailSvg: React.FC<Props> = ({
  className = "",
  color = "#fff",
  ...props
}) => (
  <StyledSvg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path
      d="M15.75 4H5.25C4.65326 4 4.08097 4.25399 3.65901 4.70608C3.23705 5.15818 3 5.77135 3 6.41071V14.4464C3 15.0858 3.23705 15.699 3.65901 16.1511C4.08097 16.6032 4.65326 16.8571 5.25 16.8571H15.75C16.3467 16.8571 16.919 16.6032 17.341 16.1511C17.7629 15.699 18 15.0858 18 14.4464V6.41071C18 5.77135 17.7629 5.15818 17.341 4.70608C16.919 4.25399 16.3467 4 15.75 4ZM15.75 5.60714L10.875 9.19911C10.761 9.26964 10.6317 9.30677 10.5 9.30677C10.3683 9.30677 10.239 9.26964 10.125 9.19911L5.25 5.60714H15.75Z"
      fill={color}
    />
  </StyledSvg>
);

const StyledSvg = styled.svg``;
