import React, { useMemo, useState } from 'react'

import styled from 'styled-components'
import classNames from 'classnames'
import { FieldConfig, useField } from 'formik'
import { useTranslation } from 'next-i18next'
import { EyeNew } from 'components/svg/EyeNew'

import { COLORS, FONTS } from './constants'
import { EnumTypeInput } from './enum'

type Props = React.InputHTMLAttributes<HTMLInputElement> &
  FieldConfig & {
    className?: string;
    label?: string;
    error?: string;
  };

export const PasswordInput: React.FC<Props> = React.forwardRef<
  HTMLInputElement,
  Props
>(({ className = '', label, ...props }, ref) => {
  const [type, setType] = useState<EnumTypeInput>(EnumTypeInput.password)
  const { t, i18n } = useTranslation()
  const [field, meta] = useField(props)
  const error = useMemo(() => meta?.error || props?.error || '', [meta, props])

  const changeTypeInput = () => {
    const invertType =
      type === EnumTypeInput.password
        ? EnumTypeInput.text
        : EnumTypeInput.password

    setType(invertType)
  }

  return (
    <InputGroup className={className}>
      {!!label && (
        <Label>
          {label}
        </Label>
      )}

      <InputBox className={classNames('input-box', { error: !!error })}>
        <StyledInput
          {...field}
          {...props}
          className='input'
          type={type}
          ref={ref}
        />

        {<Icon onClick={changeTypeInput}
          type={type}
        />}
      </InputBox>

      {(!!meta.error || !!props.error) && (
        <ErrorBox>
          {i18n.exists(error) ? t(error) : error}
        </ErrorBox>
      )}
    </InputGroup>
  )
})

const InputGroup = styled.div``

const InputBox = styled.div`
  width: 100%;
  height: 44px;
  background: ${COLORS.dark550};
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 16px;
  border: 2px solid transparent;

  &:focus-within {
    border-color: ${COLORS.blue};
  }

  &.error {
    border-color: ${COLORS.red};
  }
`

const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  background: transparent;
  ${FONTS.p1};
  color: ${COLORS.white};
  flex: 1;
  padding: 0;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    color: white;
    -webkit-text-fill-color: white;
    -webkit-box-shadow: 0 0 0 30px #272832 inset;
  }

  &::placeholder {
    ${FONTS.p1};
    color: ${COLORS.dark200};
  }
`

const Label = styled.p`
  margin-bottom: 4px;
  ${FONTS.p1};
  color: ${COLORS.dark200};
`

const ErrorBox = styled.p`
  margin-top: 8px;
  font-size: 12px;
  color: ${COLORS.red};
`

const Icon = styled(EyeNew)`
  cursor: pointer;
`
