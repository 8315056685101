import { BettingHistoryIcon } from './BettingHistoryIcon'
import { GiftIcon } from './GiftIcon'
import { ProfileIcon } from './ProfileIcon'
import { ReferralIcon } from './ReferralIcon'
import { SettingsIcon } from './SettingsIcon'
import { SignoutIcon } from './SignoutIcon'
import { SupportIcon } from './SupportIcon'
import { TransactionHistoryIcon } from './TransactionHistoryIcon'
import { WalletIcon } from './WalletIcon'
import { WithdrawalIcon } from './WithdrawalIcon'
import { DropdownArrowIcon } from './ArrowIcon'
import { MenuIcon } from './MenuIcon'
import { UserAddIcon } from './UserAddIcon'
import { CloseIcon } from './CloseIcon'
import { BackArrowIcon } from './BackArrow'
import { StarEmptyIcon } from './StarEmpty'
import { StarFullIcon } from './StarFull'
import { HomeIcon } from './HomeIcon'
import { CasinoIcon } from './CasinoIcon'
import { BallIcon } from './BallIcon'
import { CategoriesIcon } from './CategoriesIcon'
import { SearchIcon } from './SearchIcon'
import { ItemsSearch } from './ItemsSearch'
import { TicketIcon } from './TicketIcon'
import { FreeSpinsIcon } from './FreeSpinsIcon'
import { FreeBetIcon } from './FreeBetIcon'
import { TimeIcon } from './TimeIcon'
import { BackIcon } from "./BackIcon"
import { MenuAccountIcon } from "./MenuAccountIcon"
import { SecurityIcon } from "./SecurityIcon"
import { ConfidentialityIcon } from "./ConfidentialityIcon"
import { InfoIcon } from "./InfoIcon"
import { CheckIcon } from './CheckIcon'
import { DepositIcon } from "./DepositIcon"
import { WithdrawIcon } from "./WithdrawIcon"
import { DotsIcon } from "./DotsIcon"
import { PlusIcon } from "./PlusIcon"

export type TIconsType =
  'profile'
  | 'wallet'
  | 'transaction-history'
  | 'betting-history'
  | 'withdrawal'
  | 'gift'
  | 'referral'
  | 'support'
  | 'signout'
  | 'settings'
  | 'dropdown-arrow'
  | 'menu'
  | 'user-add'
  | 'close'
  | 'back-arrow'
  | 'star-empty'
  | 'star-full'
  | 'home'
  | 'casino'
  | 'ball'
  | 'categories'
  | 'search'
  | 'items-search'
  | 'ticket'
  | 'free-spins'
  | 'free-bet'
  | 'time'
  | 'info'
  | 'check'
  | 'back'
  | 'menu-account'
  | 'security'
  | 'confidentiality'
  | 'deposit'
  | 'withdraw'
  | 'dots'
  | 'plus'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string,
  type: TIconsType
}

export const SvgIcon: React.FC<Props> = ({ type, ...props }) => {
  switch(type) {
  case 'profile':
    return <ProfileIcon {...props} />
  case 'wallet':
    return <WalletIcon {...props} />
  case 'transaction-history':
    return <TransactionHistoryIcon {...props} />
  case 'betting-history':
    return <BettingHistoryIcon {...props} />
  case 'withdrawal':
    return <WithdrawalIcon {...props} />
  case 'gift':
    return <GiftIcon {...props} />
  case 'referral':
    return <ReferralIcon {...props} />
  case 'support':
    return <SupportIcon {...props} />
  case 'signout':
    return <SignoutIcon {...props} />
  case 'settings':
    return <SettingsIcon {...props} />
  case 'dropdown-arrow':
    return <DropdownArrowIcon {...props} />
  case 'menu':
    return <MenuIcon {...props} />
  case 'user-add':
    return <UserAddIcon {...props} />
  case 'close':
    return <CloseIcon {...props} />
  case 'back':
    return <BackIcon {...props} />
  case 'menu-account':
    return <MenuAccountIcon {...props} />
  case 'security':
    return <SecurityIcon {...props} />
  case 'confidentiality':
    return <ConfidentialityIcon {...props} />
  case 'back-arrow':
    return <BackArrowIcon {...props} />
  case 'star-empty':
    return <StarEmptyIcon {...props} />
  case 'star-full':
    return <StarFullIcon {...props} />
  case 'home':
    return <HomeIcon {...props} />
  case 'casino':
    return <CasinoIcon {...props} />
  case 'ball':
    return <BallIcon {...props} />
  case 'categories':
    return <CategoriesIcon {...props} />
  case 'search':
    return <SearchIcon {...props} />
  case 'items-search':
    return <ItemsSearch {...props} />
  case 'ticket':
    return <TicketIcon {...props} />
  case 'free-spins':
    return <FreeSpinsIcon {...props} />
  case 'free-bet':
    return <FreeBetIcon {...props} />
  case 'time':
    return <TimeIcon {...props} />
  case 'info':
    return <InfoIcon {...props} />
  case 'check':
    return <CheckIcon {...props} />
  case 'deposit':
    return <DepositIcon {...props} />
  case 'withdraw':
    return <WithdrawIcon {...props} />
  case 'dots':
    return <DotsIcon {...props} />
  case 'plus':
    return <PlusIcon {...props} />
  default:
    return (
      <>
Icon not found
      </>
    )
  }
}
