import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const BackIcon: React.FC<Props> = ({ className = '', ...props }) => {
  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      className={className}
      {...props}
    >
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9996 7.02686C13.9996 7.51011 13.636 7.90186 13.1875 7.90186L0.813093 7.90186C0.364549 7.90186 0.00092024 7.51011 0.00092026 7.02686C0.00092028 6.54361 0.364549 6.15186 0.813093 6.15186L13.1875 6.15186C13.636 6.15186 13.9996 6.54361 13.9996 7.02686Z"
      />

      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M7.22059 0.28594C7.51768 0.622029 7.50805 1.15646 7.19907 1.47962L1.89542 7.02685L7.19907 12.5741C7.50805 12.8973 7.51768 13.4317 7.22059 13.7678C6.9235 14.1039 6.43219 14.1143 6.12322 13.7912L0.237747 7.6354C0.0855696 7.47623 -0.000433244 7.2565 -0.000433235 7.02685C-0.000433226 6.79721 0.0855696 6.57748 0.237747 6.41831L6.12322 0.262535C6.43219 -0.0606271 6.9235 -0.0501482 7.22059 0.28594Z"
      />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  path {
    fill: currentColor;
  }
`
