import { ArrowBackNewSvg } from 'components/svg/ArrowBackNewSvg'
import { Form } from 'formik'
import styled from 'styled-components'
import { Scrollable } from 'ui-kit'
import { BREAKPOINTS, COLORS, FONTS } from 'ui-kit/constants'

export const Root = styled.div`
  position: relative;
  border-radius: 18px;
  z-index: 5;
  margin: 2vh auto;
  display: flex;
  width: 752px;
  min-height: 560px;
  max-height: 850px;

  @media screen and (max-width: ${BREAKPOINTS.xsMax}px) and (orientation: portrait)  {
    margin: 0;
    width: 100% !important;
    top: 0;
    bottom: 0;
    position: fixed;
    height: calc(100%);
    max-height: unset;
    flex-direction: column;
    overflow: hidden;
    border-radius: 0;
    background: #14151B;
    padding: 0 24px 24px;
  }

  @media (max-height: 599px) and (orientation: landscape) {
    max-height: 100vh;
    min-height: 100vh;
  }

  @media screen and (max-width: 752px) and (orientation: landscape) {
    width: 630px;
  }
`

export const Frame = styled.div`
  overflow-y: scroll;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media screen and (max-width: 900px) {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  @media (max-height: 599px) and (orientation: landscape) {
    overflow: hidden;
  }
`

export const Overlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
`

export const Left = styled.div`
  width: 376px;
  background: ${COLORS.dark550};
  border-radius: 16px 0 0 16px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;

  @media screen and (max-width: ${BREAKPOINTS.xsMax}px) and (orientation: portrait)  {
    width: 100%;
    border-radius: 0;
    padding: 0;
    background: unset;
    flex-grow: unset;
  }
`

export const Right = styled.div`
  width: 376px;
  background: #1D1E25;
  border-radius: 0 16px 16px 0;
  padding: 24px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;

  @media screen and (max-width: ${BREAKPOINTS.xsMax}px) and (orientation: portrait)  {
    width: 100%;
    border-radius: 0;
    background: unset;
    padding: 0;
  }

  @media (max-height: 599px) and (orientation: landscape) {
    overflow: auto;
  }
`

export const Title = styled.p`
  ${FONTS.h1};
  color: ${COLORS.white};

  @media screen and (max-width: ${BREAKPOINTS.xsMax}px) and (orientation: portrait)  {
    display: none;
  }
`

export const RightTitleRow = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-end;
  ${FONTS.a1};
  text-align: right;
  color: ${COLORS.white};

  @media screen and (max-width: ${BREAKPOINTS.xsMax}px) and (orientation: portrait)  {
    display: none;
  }
`

export const CloseImg = styled.img`
  margin-left: 14px;
  width: 12px;
  height: 12px;
  cursor: pointer;

  @media screen and (max-width: ${BREAKPOINTS.xsMax}px) and (orientation: portrait)  {
    margin-top: -5px;
  }
`

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;

  @media (max-width: ${BREAKPOINTS.xsMax}px) {
    margin-top: 3.125vw;
  }

  @media (max-height: 599px) and (orientation: landscape) {
    overflow: auto;
  }
`

export const Fields = styled(Scrollable)`
  margin-bottom: 10px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;

  @media (max-height: 599px) and (orientation: landscape) {
    overflow-x: unset;
    overflow-y: unset;
  }
`

export const FixedBottomBox = styled.div`
  flex-shrink: 0;
`

export const MobileTitle = styled.div`
  margin-top: 20px;
  margin-bottom: 16px;
  display: none;
  align-items: center;
  width: 100%;
  ${FONTS.h1};
  line-height: 1;
  flex-shrink: 0;

  @media screen and (max-width: ${BREAKPOINTS.xsMax}px) and (orientation: portrait)  {
    display: flex;
  }
`

export const MobileTitleRight = styled.div`
  margin-left: auto;
  ${FONTS.a1};
  color: ${COLORS.white};
  height: 20px;
  display: flex;
  align-items: center;
`

export const StyledArrowBack = styled(ArrowBackNewSvg)`
  margin-right: auto;
  cursor: pointer;
`

export const StyledArrowBackMobile = styled(ArrowBackNewSvg)`
  margin-right: 11px;
  cursor: pointer;
`

