import React, { useEffect, useRef, useState } from 'react'

import styled from 'styled-components'
import classNames from 'classnames'
import { FieldConfig, FormikProvider, useField, useFormik, useFormikContext } from 'formik'
import { usePopper } from 'react-popper'
import { useTranslation } from 'next-i18next'
import { SearchSvg } from 'components/svg/SearchSvgNew'
import { UseOnclickOutside } from 'hooks/useOnclickOutside'
import { Placement } from '@popperjs/core/lib/enums'

import { COLORS, FONTS } from './constants'
import { Scrollable } from './Scrollable'
import { Input } from './Input'
import { SvgIcon } from './svg'

interface IOption { label: string, value: string, iconContent?: React.ReactNode }

type Props = React.InputHTMLAttributes<HTMLInputElement> & FieldConfig & {
  className?: string,
  label?: string,
  renderToggleContent?: (option?: IOption, opened?: boolean) => React.ReactNode,
  renderFullyReplacedToggle?: (option?: IOption, opened?: boolean, toggleDropdown: () => void) => React.ReactNode,
  placeholder?: string,
  options: IOption[],
  placement?: Placement
  withSearch?: boolean,
}

export const Select: React.FC<Props> = ({ className = '', name, label, renderToggleContent, placeholder, options, withSearch, placement = 'bottom-start', renderFullyReplacedToggle, ...props  }) => {
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement>()
  const [popperElement, setPopperElement] = useState(null)
  const [opened, toggleDropdown] = useState(false)
  const [isInputFocused, setInputFocused] = useState(false)

  const handleInputFocus = () => setInputFocused(true)
  const handleInputBlur = () => setInputFocused(false)

  const boxRef = useRef<HTMLDivElement>()

  const { t, i18n } = useTranslation()
  const { setFieldValue } = useFormikContext()
  const [field, meta] = useField({ name, placeholder, ...props })

  const formik = useFormik({
    initialValues: {
      search: '',
    },
    onSubmit: () => undefined
  })

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    strategy: 'absolute',
    placement: placement,
    modifiers: [
      {
        name: 'flip',
        enabled: false,
      },
      {
        name: 'offset',
        options: {
          offset: [0, 0],
        },
      }
    ]
  })

  useEffect(() => {
    setReferenceElement(boxRef.current)
  }, [])

  const handleChange = (value: string) => {
    if (!!props.onChange) {
      props.onChange(value)
    }

    setFieldValue(name, value)
    toggleDropdown(false)
  }

  const handleClickOutside = () => {
    toggleDropdown(false)
  }

  const refSelect = UseOnclickOutside(handleClickOutside)

  const currentOption = options.find(option => option.value === field.value)
  const currentToggleLable = currentOption?.label

  return (
    <InputGroup
      className={className}
      data-field={name}
      ref={boxRef}
    >
      {!!label && (
        <Label>
          {label}
        </Label>
      )}

      <SelectBox className='select-box'
        ref={refSelect}
      >
        {!renderFullyReplacedToggle && (
          <SelectToggleBox onClick={() => toggleDropdown(state => !state)}
            className={classNames('select-toggle-box', { 'active-top': opened && placement === 'top', 'active-bottom': opened && placement === 'bottom-start' })}
          >
            {!!renderToggleContent ? renderToggleContent(currentOption, opened) : (currentToggleLable || placeholder)}

            <StyledArrowSvg color={opened ? 'white' : '#686A7B'}
              className={classNames('select-arrow', { rotated: !!opened })}
              type='dropdown-arrow'
            />
          </SelectToggleBox>
        )
        }

        {!!renderFullyReplacedToggle &&
          renderFullyReplacedToggle(currentOption, opened, toggleDropdown)
        }

        {opened && (
          <DropdownBox
            ref={setPopperElement}
            {...attributes.popper}
            style={popperStyles.popper}
            className={classNames('select-dropdown-box', { 'bottom': placement === 'bottom-start', top: placement === 'top' })}
          >
            {withSearch && (
              <FormikProvider value={formik}>
                <StyledInput name='search'
                  suffixContent={<SearchSvg color={isInputFocused ? 'white' : '#686A7B'} />}
                  placeholder={t('catalog_list_search')}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                />
              </FormikProvider>
            )}

            <StyledScrollable>
              {
                options.filter(option => option.label.toLowerCase().startsWith(formik.values.search.toLowerCase())).map(option => (
                  <OptionRow
                    key={option.label}
                    onClick={() => handleChange(option.value)}
                  >
                    {option.iconContent}

                    <OptionLabel className={classNames('option-label', { 'with-margin': !!option.iconContent })}>
                      {option.label}
                    </OptionLabel>
                  </OptionRow>
                ))
              }
            </StyledScrollable>
          </DropdownBox>
        )}
      </SelectBox>

      {
        !!meta.error && (
          <ErrorBox>
            {i18n.exists(meta.error) ? t(meta.error) : meta.error}
          </ErrorBox>
        )
      }
    </InputGroup>
  )
}

const InputGroup = styled.div`
`

const SelectToggleBox = styled.div`
  width: 100%;
  height: 44px;
  background: ${COLORS.dark550};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  border: 2px solid transparent;
  position: relative;
  cursor: pointer;
  z-index: 2;

  &:focus-within {
    border-color: ${COLORS.blue};
  }

  &.error {
    border-color: ${COLORS.red};
  }

  &.active-top {
    background: ${COLORS.dark400};
    border-radius: 0 0 4px 4px;
  }

  &.active-bottom {
    background: ${COLORS.dark400};
    border-radius: 4px 4px 0 0;
  }
`

const SelectBox = styled.div`
  width: 100%;
  position: relative;
`

const Label = styled.p`
  margin-bottom: 4px;
  ${FONTS.p1};
  color: ${COLORS.dark200};
`

const ErrorBox = styled.p`
  margin-top: 4px;
  font-size: 12px;
  color: ${COLORS.red};
`

const DropdownBox = styled.div`
  width: 100%;
  max-height: 220px;
  height: 220px;
  padding-right: 0;
  border-radius: 4px;
  z-index: 1;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.dark550};

  &.top {
    margin-bottom: -2px;
    border-radius: 4px 4px 0 0;
    padding-bottom: 2px;
  }

  &.bottom {
    margin-top: -2px;
    border-radius: 0 0 4px 4px;
    padding-top: 2px;
  }
`

const StyledScrollable = styled(Scrollable)`
  flex: 1;
  overflow: auto;
  width: 100%;
  padding-right: 0;

  &::-webkit-scrollbar-track {
    background-color: ${COLORS.dark550};
  }
`

const OptionRow = styled.div`
  height: 44px;
  background: ${COLORS.dark550};
  display: flex;
  align-items: center;
  padding: 0 16px;
  ${FONTS.p1};
  color: ${COLORS.dark200};
  cursor: pointer;
  width: 100%;

  &:hover {
    background: ${COLORS.dark400};
    color: white;
  }
`

const StyledArrowSvg = styled(SvgIcon)`
  margin-left: 9px;
  transition: 0.2s;
  color: '686A7B';

  &.rotated {
    transform: rotate(-180deg);
    color: white;
  }
`

const OptionLabel = styled.span`
  &.with-margin {
    margin-left: 12px;
  }
`

const StyledInput = styled(Input)`
  position: sticky;
  top: 0;

  .input-box {
    padding-left: 16px;

    &.focused {
      border-radius: 4px;
    }
  }

  .input-box:not(.focused) {
    border-bottom: 2px solid ${COLORS.dark400};
    border-radius: 0;
  }
`
