import React, { useEffect } from 'react'

import App, { AppContext, AppProps } from 'next/app'
import Head from 'next/head'
import { DefaultSeo } from 'next-seo'
import { appWithTranslation, useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import nookies from 'nookies'
import { v4 as uuidv4 } from 'uuid'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-input-range/lib/css/index.css'
import 'react-date-picker/dist/DatePicker.css'
import 'react-calendar/dist/Calendar.css'
import 'react-phone-number-input/style.css'
import 'scss/globals.scss'

import nextI18NextConfig from 'next-i18next.config.js'
import { ModalList } from 'components/Modals/ModalList/ModalList'
import { BottomSheetList } from 'components/Modals/BottomSheet/BottomSheetList/BottomSheetList'
import { AuthUserFeatures } from 'components/layout/AuthUserFeatures'
import Snackbar from 'components/layout/Snackbar'
import NotificationBanner from 'components/for_pages/Common/NotificationBanner'
import ErrorBoundary from 'components/ui/ErrorBoundary'
import { AppProvider } from 'context/AppContext'
import { AuthWrapper } from 'context/auth_state'
import { FavoriteWrapper } from 'context/favorite_state'
import ReactPWAInstallProvider from 'context/pwa_state'
import { TournamentWrapper } from 'context/tournament_state'
import { CookiesType } from 'types/enums'
import { CookiesLifeTime } from 'types/constants'
import { getIsMobile, setMobileClass } from 'utils/mobile'
import { useResizeViewPort } from 'utils/hooks'
import { useIniti18n } from 'utils/i18'
import { initServiceWorker } from 'utils/serviceWorker'
import { getToken } from 'utils/auth'
import { DEFAULT_LANGUAGE } from 'types/language'
import { useAppSocket } from 'context/useSocket'
import { LayoutContextProvider } from 'context/layoutContext'
import { LoyaltyContextProvider } from 'context/loyaltyContext'

import { TAWK_SCRIPTS } from "../constants/tawkScripts"
import { NotificationsContextProvider } from "../context/notificationsContext"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  },
})

interface Props {
  isMobile: boolean,
}

const MyApp = ({ Component, pageProps }: AppProps<Props>) => {
  useEffect(() => {
    initServiceWorker()
    setMobileClass(pageProps.isMobile)
  }, [])

  useResizeViewPort()
  useIniti18n()

  return (
    <AppProvider isMobile={pageProps.isMobile}
      token={getToken()}
    >

      <NotificationsContextProvider>
        <AppComponent Component={Component}
          pageProps={pageProps}
        />
      </NotificationsContextProvider>
    </AppProvider>
  )
}

const TawkScript: React.FC<{
  locale: string
}> = React.memo(({ locale }) => {
  return (
    <script
      dangerouslySetInnerHTML={{
        __html: TAWK_SCRIPTS[locale] || TAWK_SCRIPTS.en
      }}
    />
  )
})

const AppComponent = ({ Component, pageProps }: AppProps<Props>) => {
  const { t } = useTranslation()

  useAppSocket()

  return (
    <LayoutContextProvider>
      <AuthWrapper>
        <FavoriteWrapper>
          <TournamentWrapper>
            <ReactPWAInstallProvider enableLogging>
              <Head>
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0, viewport-fit=cover"
                />

                <meta name="twitter:title"
                  content={t('seo_twitter_title')}
                />

                <meta
                  name="twitter:description"
                  content={t('seo_twitter_description')}
                />

                <meta name="keywords"
                  content=""
                />

                <meta prefix="og: http://ogp.me/ns#" />

                <meta property="og:url"
                  content="https://richy.casino/"
                />

                <meta property="og:type"
                  content="website"
                />

                <meta property="og:title"
                  content={t('new_seo_title')}
                />

                <meta property="og:description"
                  content={t('new_seo_description')}
                />

                <meta property="og:image"
                  content="https://richy.casino/img/og_img.jpg"
                />

                <meta name="twitter:card"
                  content="summary_large_image"
                />

                <meta property="twitter:domain"
                  content="richy.casino"
                />

                <meta property="twitter:url"
                  content="https://richy.casino/"
                />

                <meta name="twitter:title"
                  content={t('new_seo_title')}
                />

                <meta name="twitter:description"
                  content={t('new_seo_description')}
                />

                <meta name="twitter:image"
                  content="https://richy.casino/img/og_img.jpg"
                />
              </Head>

              <DefaultSeo
                title={t('new_seo_title')}
                defaultTitle={t('new_seo_title')}
                description={t('new_seo_description')}
              />

              <QueryClientProvider client={queryClient}>
                <LoyaltyContextProvider>
                  <Component {...pageProps} />

                  <ErrorBoundary>
                    <ModalList />
                  </ErrorBoundary>

                  <ErrorBoundary>
                    <BottomSheetList />
                  </ErrorBoundary>

                  <ErrorBoundary>
                    <AuthUserFeatures />
                  </ErrorBoundary>

                  <ErrorBoundary>
                    <NotificationBanner />
                  </ErrorBoundary>

                  <Snackbar />

                  <TawkScript locale={pageProps?._nextI18Next?.initialLocale} />
                </LoyaltyContextProvider>
              </QueryClientProvider>
            </ReactPWAInstallProvider>
          </TournamentWrapper>
        </FavoriteWrapper>
      </AuthWrapper>
    </LayoutContextProvider>
  )
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const props = await App.getInitialProps(appContext)

  const userAgent =
    appContext.ctx.req?.headers['user-agent'] || navigator.userAgent

  props.pageProps.isMobile = getIsMobile(userAgent)

  const ppDetails = Object.fromEntries(
    Object.entries({
      clickid: appContext.ctx.query.clickid,
      'webmaster-id': appContext.ctx.query.webmaster_id,
      sub1: appContext.ctx.query.sub1,
      sub2: appContext.ctx.query.sub2,
      sub3: appContext.ctx.query.sub3,
      sub4: appContext.ctx.query.sub4,
      sub5: appContext.ctx.query.sub5,
      sub6: appContext.ctx.query.sub6,
      pid: appContext.ctx.query.pid,
      promocode: appContext.ctx.query.promocode,
    }).filter(([_, v]) => !!v),
  )

  if (Object.keys(ppDetails).length > 0) {
    nookies.set(
      appContext.ctx,
      CookiesType.ppDetails,
      JSON.stringify(ppDetails),
      {
        maxAge: CookiesLifeTime.ppDetails * 60 * 60 * 24,
        path: '/',
      },
    )
  }

  if (appContext.ctx.req) {
    const cookies = nookies.get(appContext.ctx)
    const cookieSessionId = cookies[CookiesType.sessionId]

    if (!cookieSessionId) {
      const sessionId = uuidv4()

      nookies.set(appContext.ctx, CookiesType.sessionId, sessionId, {
        maxAge: CookiesLifeTime.sessionId * 60 * 60 * 24,
        path: '/',
      })
    }

    const SSRConfig = await serverSideTranslations(
      appContext.ctx.locale || DEFAULT_LANGUAGE,
      ['common']
    )

    props.pageProps = { ...props.pageProps, ...SSRConfig }
  }

  return props
}

export default appWithTranslation(MyApp, nextI18NextConfig)
