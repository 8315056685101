import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const CasinoIcon: React.FC<Props> = ({ className = '', ...props }) => {
  return (
    <StyledSvg width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path d="M4.90909 10.1111L6.36364 7.26667V6.55556H4.18182V7.26667H5.63636L4.18182 10.1111M7.81818 10.1111L9.27273 7.26667V6.55556H7.09091V7.26667H8.54545L7.09091 10.1111M10.7273 10.1111L12.1818 7.26667V6.55556H10V7.26667H11.4545L10 10.1111M16.5455 3C15.7455 3 15.0909 3.64 15.0909 4.42222C15.0909 4.92 15.3818 5.41778 15.8182 5.63111V13.6667H13.6364V12.2444C14.0727 12.2444 14.3636 11.96 14.3636 11.5333V5.13333C14.3636 4.70667 14.0727 4.42222 13.6364 4.42222H10.8727C10.2909 3.56889 9.27273 3 8.18182 3C7.09091 3 6.07273 3.56889 5.49091 4.42222H2.72727C2.29091 4.42222 2 4.70667 2 5.13333V11.5333C2 11.96 2.29091 12.2444 2.72727 12.2444V17.2222H13.6364V15.0889H15.8182C16.6182 15.0889 17.2727 14.4489 17.2727 13.6667V5.63111C17.7091 5.41778 18 4.92 18 4.42222C18 3.64 17.3455 3 16.5455 3ZM10.7273 15.0889H5.63636V13.6667H10.7273V15.0889ZM12.9091 10.8222H3.45455V5.84444H12.9091V10.8222Z" />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  path {
    fill: currentColor;
  }
`
