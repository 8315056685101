import { useRef, useState } from 'react'

import ReactModal from 'react-modal'
import classNames from 'classnames'
import { useAppContext } from 'context/AppContext'
import { useTranslation } from 'next-i18next'
import Validator from 'utils/validator'
import { Button } from 'ui-kit'
import { Formik } from 'formik'
import { LoginFormData, TwoFaLoginModalArguments } from 'types/interfaces'
import AuthRepository from 'data/repositories/AuthRepository'
import { ModalType } from 'types/enums'
import { isSafari } from 'react-device-detect'
import FormError from 'components/ui/Form/FormError'
import { COLORS } from 'ui-kit/constants'
import ReCAPTCHA from 'react-google-recaptcha'
import { runtimeConfig } from 'config/runtimeConfig'

import styles from './index.module.scss'
import {
  Frame, Root, StyledCloseIcon, StyledForm, StyledInputFields, StyledScrollable,
  StyledTitle, StyledHeader, StyledInput, StyledPassword, StyledForget,
  StyledRegistration, StyledClose, StyledWrapperBottom, Overlay,
} from './styles'

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
}

const initialValues: LoginFormData = {
  authInput: '',
  password: ''
}

export const ModalLogin: React.FC<Props> = ({
  isOpen,
  onRequestClose,
}) => {
  const context = useAppContext()
  const { t } = useTranslation()
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const recaptchaRef = useRef<any>(null)

  const settings = {
    className: styles.modalContent,
    overlayClassName: classNames(styles.modalOverlay),
  }

  const handleLogin = async (values: LoginFormData) => {
    setLoading(true)

    const scrollY = window.scrollY

    try {
      setError(null)

      const recaptchaToken = await recaptchaRef.current?.executeAsync()

      const res = await AuthRepository.login(values?.authInput, values.password, recaptchaToken)

      if (!res) {
        return
      }

      if (isSafari) {
        setTimeout(() => {
          window.scrollTo(0, scrollY)
        }, 10)
      }

      if (res.is2FaRequired) {
        context.showModal(ModalType.faLogin, { identity: values.authInput, password: values.password } as TwoFaLoginModalArguments)
        return
      }

      const accessToken = res.token

      if (!accessToken) {
        setError(t('api_error_unknown'))
        setLoading(false)
        return
      }

      context.setToken(accessToken)
      context.updateUserFromCookies()
      context.hideModal()
    } catch (e) {
      setError(e)
    } finally {
      recaptchaRef.current?.reset()
    }

    setLoading(false)
  }

  return (
    <ReactModal
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      onRequestClose={onRequestClose}
      {...settings}
    >
      <Frame>
        <Root>
          <StyledCloseIcon type='close'
            onClick={() => onRequestClose()}
          />

          <StyledHeader>
            <StyledTitle>
              {t('header_login')}
            </StyledTitle>
          </StyledHeader>

          <Formik initialValues={initialValues}
            onSubmit={handleLogin}
            validateOnChange={false}
            validateOnBlur={false}
          >
            <StyledForm>
              <StyledScrollable>
                <StyledInputFields>
                  <StyledInput name='authInput'
                    placeholder={t('login_field_identity')}
                    validate={Validator.required}
                  />

                  <StyledPassword name='password'
                    placeholder='Password'
                    validate={Validator.required}
                  />

                  <FormError error={error} />

                  <StyledForget
                    onClick={() => { context.showModal(ModalType.passwordRecovery) }}
                  >
                    {t('login_forgot')}
                  </StyledForget>
                </StyledInputFields>
              </StyledScrollable>

              <StyledWrapperBottom>
                <Button
                  type='submit'
                  spinner={loading}
                >
                  {t('login_button')}
                </Button>

                <StyledRegistration>
                  {`${t('login_no_account')} `}

                  <span onClick={() => context.showModal(ModalType.registration)}>
                    {t('login_register')}
                  </span>
                </StyledRegistration>
              </StyledWrapperBottom>

              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={runtimeConfig.RECAPTCHA_KEY}
                style={{ visibility: "hidden" }}
              />
            </StyledForm>
          </Formik>
        </Root>

        <Overlay onClick={() => onRequestClose()} />
      </Frame>
    </ReactModal>
  )
}
