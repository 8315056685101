import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const SearchIcon: React.FC<Props> = ({ className = '', ...props }) => {
  return (
    <StyledSvg width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      {...props}
    >
      <path
        style={{ fill: 'none' }}
        d='M17 16.4546L13.9306 13.3797M15.6316 9.27038C15.6316 10.8128 15.0188 12.2921 13.9282
        13.3828C12.8375 14.4734 11.3582 15.0862 9.81579 15.0862C8.27335 15.0862 6.79408 14.4734
        5.70341 13.3828C4.61273 12.2921 4 10.8128 4 9.27038C4 7.72794 4.61273 6.24867 5.70341
        5.158C6.79408 4.06732 8.27335 3.45459 9.81579 3.45459C11.3582 3.45459 12.8375 4.06732
        13.9282 5.158C15.0188 6.24867 15.6316 7.72794 15.6316 9.27038Z'
        stroke={props.color} strokeWidth='2' strokeLinecap='round'
      />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  path {
    fill: currentColor;
  }
`
