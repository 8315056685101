import request from 'utils/request'
import Converter from 'utils/converter'
import {
  IPaymentMethodField,
  IPaymentMethodFields,
} from 'data/interfaces/IPaymentFields'
import { IPaymentMethod } from '../interfaces/IPaymentMethod'

export default class PaymentMethodRepository {
  static async fetchDeposit(): Promise<IPaymentMethod[]> {
    const res = await request({
      method: 'get',
      url: '/api/finance/currency/deposit',
    })
    if (res.err) {
      return []
    }
    return res.data.data?.map((i) => Converter.objectKeysToCamelCase(i)) ?? []
  }
  static async fetchWithdraw(): Promise<IPaymentMethod[]> {
    const res = await request({
      method: 'get',
      url: '/api/finance/currency/withdraw',
    })
    if (res.err) {
      return []
    }
    return res.data.data?.map((i) => Converter.objectKeysToCamelCase(i)) ?? []
  }
  static async fetchWithdrawalFields(
    code: string,
    paymentSystemId: number,
  ): Promise<IPaymentMethodField[]> {
    const res = await request({
      method: 'get',
      url: '/api/finance/payment/withdrawal/fields',
      data: {
        code,
        type_id: paymentSystemId,
      },
    })
    if (res.err) return []

    const data = res.data?.data as IPaymentMethodFields
    const keys = Object.keys(data ?? {})
    const items = []

    for (const key of keys) {
      items.push({ ...data[key], key })
    }

    return items
  }

  static async fetchDepositFields(
    code: string,
    paymentSystemId: number,
  ): Promise<IPaymentMethodField[]> {
    const res = await request({
      method: 'get',
      url: '/api/finance/payment/deposit/fields',
      data: {
        code,
        type_id: paymentSystemId,
      },
    })
    if (res.err) return []

    const data = res.data?.data as IPaymentMethodFields
    const keys = Object.keys(data ?? {})
    const items = []

    for (const key of keys) {
      items.push({ ...data[key], key })
    }

    return items
  }

  static async fetchBonusClear() {
    return await request({
      method: 'post',
      url: '/api/finance/balance/bonus-clear',
    })
  }
}
