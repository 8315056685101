import { IUserBalanceCurrencyRaw } from "data/interfaces/IUser"
import { DEFAULT_CURRENCY } from "types/constants"

export const convertCurrencyToArray = (
  data: IUserBalanceCurrencyRaw,
  calculated: IUserBalanceCurrencyRaw,
) => {
  return Object.keys(data).map((key) => ({
    currency: key?.toUpperCase(),
    value: data[key],
    calculated: calculated[key],
    mainCurrency: DEFAULT_CURRENCY,
  }))
}
