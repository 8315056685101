import { ButtonHTMLAttributes } from 'react'

import Link from 'next/link'
import classNames from 'classnames'
import Spinner from 'components/ui/Spinner'

import classes from './index.module.scss'

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'outlined'
  size?:
    | 'extraSmall'
    | 'small'
    | 'normal'
    | 'large'
    | 'play'
    | 'superExtraSmall'
    | 'huge'
    | 'submit'
  background?:
    | 'dark700'
    | 'payGradient500'
    | 'dark500'
    | 'dark600'
    | 'blueGradient500'
    | 'blackTransparent'
    | 'white'
    | 'pink'
  fluid?: boolean
  href?: string
  target?: string
  disabled?: boolean
  spinner?: boolean
  className?: string
}

export default function Button(props: Props) {
  const btnClass = classNames(
    classes[props.background],
    classes[props.size],
    classes[props.variant],
    {
      [classes.fluid]: props.fluid,
      [classes.disabled]: props.disabled,
    },
    props.className,
  )

  return (
    <>
      {props.href ? (
        <Link href={props.href}>
          <a
            href={props.href}
            target={props.target}
            className={classNames(classes.link, btnClass, props.className)}
            // @ts-ignore
            onClick={!props.disabled ? props.onClick : undefined}
          >
            {props.children}
          </a>
        </Link>
      ) : (
        <button
          type={props.type || 'button'}
          disabled={props.disabled || props.spinner}
          className={classNames(classes.btn, btnClass, props.className)}
          onClick={!props.disabled ? props.onClick : undefined}
        >
          <span
            className={classNames(classes.text, {
              [classes.textHidden]: props.spinner,
            })}
          >
            {props.children}
          </span>

          <div
            className={classNames(classes.spinner, {
              [classes.spinnerVisible]: props.spinner,
            })}
          >
            <Spinner
              size={22}
              color="#fff"
              secondaryColor="rgba(255,255,255,0.4)"
            />
          </div>
        </button>
      )}
    </>
  )
}
