import React from 'react'

import classNames from 'classnames'
import styled from 'styled-components'
import { BREAKPOINTS, COLORS, FONTS } from 'ui-kit/constants'

interface Props {
  breadcrumbs: IBreadcrumb[],
  onClick: (bredcrumb: IBreadcrumb) => void,
  className?: string
}

export interface IBreadcrumb {
  label: string,
  type: 'method' | 'system' | 'currency' | 'default',
}

export const WalletBreadcrumbs: React.FC<Props> = ({ breadcrumbs, onClick, className = '' }) => {
  if (!breadcrumbs.length || breadcrumbs.length === 1) {
    return null
  }

  return (
    <WalletBreadcrumbsBox className={className}>
      {
        breadcrumbs.map((breadcrumb, index) => (
          <React.Fragment key={breadcrumb.label}>
            {index !== 0 && (
              <DividerImg
                src="/img/Wallet/breadcrumbs-arrow.svg"
              />
            )}

            <Breadcrumb className={classNames({
              active: index === breadcrumbs.length - 1,
              pointer: index <= breadcrumbs.length - 1
            })}
            onClick={() => index !== breadcrumbs.length - 1 ? onClick(breadcrumb) : false}
            >
              {breadcrumb.label}
            </Breadcrumb>
          </React.Fragment>
        ))
      }
    </WalletBreadcrumbsBox>
  )
}

const WalletBreadcrumbsBox = styled.div`
  margin-top: 16px;
  width: fit-content;
  display: flex;
  align-items: center;

  @media screen and (max-width: ${BREAKPOINTS.xsMax}px) {
    margin-top: 10px;
    background: ${COLORS.dark550};
    border-radius: 4px;
    padding: 6px 16px;
  }
`

const DividerImg = styled.img`
  margin: 0 6px;
  height: 11px;
  width: 5px;
  object-fit: contain;
`

const Breadcrumb = styled.span`
  ${FONTS.p1};
  line-height: 140%;
  color: ${COLORS.dark200};
  user-select: none;

  &.active {
    color: white;
  }

  &.pointer {
    cursor: pointer;

    &:hover {
      color: white;
    }
  }
`
