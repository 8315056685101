import React, { FC, useEffect, useState } from 'react'

import Button from 'components/ui/Button'
import { Form, FormikProvider, FormikValues, useFormik } from 'formik'
import Validator from 'utils/validator'
import PromoCode from 'components/for_pages/Common/Promocode'
import { PaymentOptions } from 'components/Profile/Wallet/PaymentOptions'
import PaymentsRepository from 'data/repositories/PaymentsRepository'
import FormError, { FormErrorValue } from 'components/ui/Form/FormError'
import { PaymentSeparator } from 'components/Profile/Wallet/PaymentSeparator'
import { useAppContext } from 'context/AppContext'
import { PaymentDepositAmountField } from 'components/Profile/Wallet/PaymentDepositAmountField'
import { useTranslation } from 'next-i18next'
import clsx from 'clsx'
import { PaymentSystemSelected } from 'components/Profile/Wallet/PaymentSystemSelected'
import { PaymentFormExtraFields } from 'components/Profile/Wallet/PaymentFormExtraFields'
import { IPaymentSystem } from 'data/interfaces/IPaymentSystem'
import { ICurrency } from 'data/interfaces/ICurrency'
import PaymentMethodRepository from 'data/repositories/PaymentMethodRepository'
import { IPaymentMethodField, IPaymentMethodFieldType } from 'data/interfaces/IPaymentFields'
import { BrowserUtils } from 'utils/browser'
import { IPromoCodeBonus } from 'data/interfaces/IPromocode'
import { FIRST_DEPOSIT_BONUS_MOCK } from 'types/constants'
import { Input, Button as ButtonFromUiKit } from 'ui-kit'
import styled from 'styled-components'
import { COLORS, FONTS } from 'ui-kit/constants'
import QRCode from 'react-qr-code'

import classes from './StepFormFiat.module.scss'

interface Props {
  paymentSystem: IPaymentSystem
  currency: ICurrency
  onPaymentSystemClick: () => void
  isFirstDeposit: boolean
}

export const StepFormFiat: FC<Props> = ({
  paymentSystem,
  currency,
  onPaymentSystemClick,
  isFirstDeposit
}) => {
  const { t, i18n } = useTranslation()

  const appContext = useAppContext()

  const [sending, setSending] = useState(false)
  const [error, setError] = useState<FormErrorValue>(null)
  const [fields, setFields] = useState<IPaymentMethodField[]>([])
  const [bonuses, setBonuses] = useState<IPromoCodeBonus[]>([])
  const [promocodeInputVisible, togglePromocode] = useState(false)
  const [contextToShow, setContextToShow] = useState<any>(null)

  const details = appContext.bonusBannerDetails

  const currencyIso = currency?.iso
  const currentSettings = paymentSystem.settings.find(
    (i) => i.currencyIso === currencyIso,
  )

  const initialValues = contextToShow && contextToShow.fields ?
    contextToShow.fields.reduce((r, field) => ({ ...r, [field.title]: field.value }), {})
    :
    {
      amount: currentSettings?.deposit?.minAmount || 20
    }

  useEffect(() => {
    if (!paymentSystem.systemCode) return

    PaymentMethodRepository.fetchDepositFields(
      paymentSystem.systemCode,
      paymentSystem.id,
    ).then((fields) => {
      setFields(fields)
    })
  }, [])

  useEffect(() => {
    if (isFirstDeposit) {
      setBonuses([details as IPromoCodeBonus || FIRST_DEPOSIT_BONUS_MOCK])
    }
  }, [details])

  const validateMinMax = (value: number) => {
    const num = parseFloat(`${value}`)
    const min = currentSettings?.deposit?.minAmount ?? 0
    const max = currentSettings?.deposit?.maxAmount ?? 0

    if (min && num < min) {
      return t('form_field_validation_amount_less', { number: min })
    }

    if (max && num > max) {
      return t('form_field_validation_amount_greater', { number: max })
    }

    return undefined
  }

  useEffect(() => {
    appContext.updateCurrencies()
  }, [])

  const handleSubmit = async (data: FormikValues) => {
    setError(null)
    setSending(true)

    try {
      const amount =
        typeof data.amount === 'string' ? parseFloat(data.amount) : data.amount

      const formattedData = {
        ...data
      }

      const fieldsWithSingleOptionData = fields.reduce((r, field) => {
        if (field.type === IPaymentMethodFieldType.Dropdown && Object.keys(field.options).length === 1) {
          return { ...r, [field.key]: Object.keys(field.options)[0] }
        }

        return { ...r }
      }, {})

      if (Object.keys(fieldsWithSingleOptionData)) {
        if (formattedData.extra_data) {
          formattedData.extra_data = { ...formattedData.extra_data, ...fieldsWithSingleOptionData }
        } else {
          formattedData.extra_data = fieldsWithSingleOptionData
        }
      }

      if (data.address && data.extra_data) {
        formattedData.extra_data.address = data.address
      }

      const res = await PaymentsRepository.depositFiat(
        currencyIso,
        paymentSystem.id,
        paymentSystem.systemCode,
        window.location.origin,
        amount,
        {
          ...formattedData,
          ...(paymentSystem?.isBrowserInfoRequired
            ? { browser_info: BrowserUtils.getDetailsForPayment(i18n.language) }
            : {}),
        },
      )

      if (res.qrCode) {
        setContextToShow({
          ...res.qrCode,
          type: 'qr',
          amount: res.amount
        })

        return
      }

      if (res.actionType === 'text') {
        const context = res.context

        setContextToShow({ ...context, type: 'text' })

        return
      }

      if (res.method === 'GET' || !res.method) {
        window.location.assign(res.url)
      }

      if (res.method === 'POST') {
        const form = document.getElementById('post-form') as HTMLFormElement
        form.method = 'POST'
        form.action = res.url

        Object.entries(res.formData || {}).forEach((field) => {
          const input = document.createElement('input')
          input.type = 'hidden'
          input.name = field[0]
          input.value = field[1]

          form.appendChild(input)
        })

        // console.log('form data', form)
        form.submit()
      }
    } catch (e) {
      setError(e as FormErrorValue)
    }

    setSending(false)
  }

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    enableReinitialize: true
  })

  useEffect(() => {
    if (!fields.length || !formik) {
      return
    }

    if (fields.find(field => field.key === 'email')) {
      formik.setFieldValue('extra_data.email', appContext.user.email || '', false)
    }

    if (fields.find(field => field.key === 'phone') && appContext.user.phone) {
      formik.setFieldValue('extra_data.phone', '+' + String(appContext.user.phone) || '', false)
    }
  }, [fields])

  const handleCheckDeposit: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault()
    e.stopPropagation()

    try {
      const response = await PaymentsRepository.checkDeposit(
        paymentSystem.systemCode,
        paymentSystem.id,
        contextToShow.uuid
      )

      if (response.result) {
        appContext.hideModal()
      } else {
        setContextToShow(state => ({ ...state, error: t('wallet_deposit_p2p_waiting') }))
      }
    } catch (error) {
      setContextToShow(state => ({ ...state, error: t('wallet_deposit_p2p_waiting') }))
    }
  }

  if (!contextToShow) {
    return (
      <div className={classes.root}>
        <PaymentOptions>
          {paymentSystem && (
            <PaymentSystemSelected
              paymentSystem={paymentSystem}
              onClick={onPaymentSystemClick}
            />
          )}
        </PaymentOptions>

        <PaymentSeparator />

        <FormikProvider value={formik}>
          <Form className={classes.form}>
            <PaymentFormExtraFields
              fields={fields}
              sending={sending}
              defaultCountry={appContext.countryByIp?.iso}
            />

            <PaymentDepositAmountField
              name={'amount'}
              hasOptions
              currency={currency.iso}
              currencyObject={currency}
              disabled={sending}
              validate={Validator.combine([Validator.required, validateMinMax])}
              bonus={bonuses[0]}
              placeholder={`Min: ${currentSettings?.deposit?.minAmount ?? 0} ${currentSettings?.currencyIso}`}
            />

            <div className={clsx(classes.bottom)}>
              <div
                className={classes.promo}
                onClick={() => togglePromocode(state => !state)}
              >
                <div className={classes.plus}>
                  {promocodeInputVisible ? '-' : '+'}
                </div>

                <span>
                  {t('wallet_form_promocode')}
                </span>
              </div>
            </div>

            <PromoCode
              bonuses={bonuses}
              onSetBonuses={setBonuses}
              currency={currency}
              withBonuses
              isInputVisible={promocodeInputVisible}
            />

            <FormError error={error} />

            <Button
              type="button"
              size="normal"
              spinner={sending}
              background="payGradient500"
              className={classes.wallet}
              onClick={() => formik.handleSubmit()}
            >
              <img src="/img/icons/wallet.svg"
                alt=""
              />

              {t('wallet_form_button_deposit')}
            </Button>
          </Form>
        </FormikProvider>

        <form id="post-form" />
      </div>
    )}

  if (contextToShow && contextToShow.type === 'text') {
    return (
      <FormikProvider value={formik}>
        <Form className={classes.root}>
          <DepositText>

            {t('wallet_deposit_p2p_description', { amount: contextToShow.sum + ' ' + currencyIso })}
          </DepositText>

          {
            !!contextToShow.fields && contextToShow.fields.map((field: any) => (
              <React.Fragment key={field.label}>
                <StyledInput
                  name={field.title}
                  disabled={true}
                  className={classes.input}
                  value={field.value}
                  label={field.title}
                />
              </React.Fragment>
            ))
          }

          {
            !!contextToShow.error && (
              <ErrorBox>
                {contextToShow.error}
              </ErrorBox>
            )}

          <StyledDoneButton
            className={classes.wallet}
            onClick={handleCheckDeposit}
            type="button"
          >
            {t('wallet_deposit_p2p_done')}
          </StyledDoneButton>
        </Form>
      </FormikProvider>
    )
  }

  if (contextToShow && contextToShow.type === 'qr') {
    return (
      <div className={classes.root}>
        <DepositText>

          {t('wallet_deposit_p2p_description', { amount: contextToShow.amount + ' ' + currencyIso })}

          <QrImageBox>
            <QRCode
              size={200}
              value={contextToShow.text}
            />
          </QrImageBox>
        </DepositText>

      </div>
    )
  }
}

const DepositText = styled.p`
  ${FONTS.p1};
  color: ${COLORS.white};
`

const StyledInput = styled(Input)`
  margin-top: 16px;
`

const StyledDoneButton = styled(ButtonFromUiKit)`
  margin-top: 40px;
`

const ErrorBox = styled.div`
  margin-top: 16px;
  color: ${COLORS.red};
  ${FONTS.p1};
`

const QrImageBox = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
