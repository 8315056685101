import { FC } from 'react'

import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import DropdownMenu from 'components/ui/DropdownMenu'
import Spinner from 'components/ui/Spinner'
import CurrencySvg from 'components/svg/CurrencySvg/CurrencySvg'
import { useAppContext } from 'context/AppContext'
import UserRepository from 'data/repositories/UserRepository'
import { IUserBalanceCurrency } from 'data/interfaces/IUser'
import { ProfileModalType, SnackbarType } from 'types/enums'
import Converter from 'utils/converter'

import PaymentMethodRepository from '../../../../../data/repositories/PaymentMethodRepository'

import classes from './Balance.module.scss'

export enum ActionType {
  SetMain = 'setMain',
  Deposit = 'deposit',
  Withdrawal = 'withdrawal',
  Remove = 'remove',
}

interface Action {
  type: ActionType
  label: string
  action: () => void
}

interface Props {
  balance: IUserBalanceCurrency
  actions: ActionType[]
  isMain?: boolean
  percent?: number
  withProgress?: boolean
}

export const Balance: FC<Props> = ({ balance, actions, isMain, percent, withProgress }) => {
  const { t } = useTranslation()
  const appContext = useAppContext()

  const currencyIso = Converter.convertCurrencyIsoToDisplayIso(appContext.currencies, balance.currency || '')
  const currencyName = Converter.convertCurrencyIsoToDisplayName(appContext.currencies, balance.currency || '')

  const changeDefaultCurrency = useMutation(() =>
    UserRepository.changeDefaultCurrency(balance.currency)
      .then(() => {
        appContext.updateUserFromCookies()
        appContext.onChangeMainCurrency(balance.currency)
      })
      .catch((e) => {
        appContext.showSnackbar(e, SnackbarType.error)
      }),
  )

  const fetchBonusClear = useMutation(() =>
    PaymentMethodRepository.fetchBonusClear().then(() => {
      appContext.updateUserFromCookies()
    }),
  )

  const isLoading = changeDefaultCurrency.isLoading || fetchBonusClear.isLoading

  const actionsTemplate: Action[] = [
    {
      type: ActionType.SetMain,
      label: t('profile_account_action_set_main'),
      action: changeDefaultCurrency.mutate,
    },
    {
      type: ActionType.Deposit,
      label: t('profile_account_action_deposit'),
      action: () => appContext.showModalProfile(ProfileModalType.wallet),
    },
    {
      type: ActionType.Withdrawal,
      label: t('profile_account_action_withdrawal'),
      action: () => {
        appContext.setWalletModalActiveTab('withdraw')
        appContext.showModalProfile(ProfileModalType.wallet)
      }
    },
    {
      type: ActionType.Remove,
      label: t('profile_account_action_remove'),
      action: fetchBonusClear.mutate,
    },
  ]

  const handleClick = (action: Action) => {
    action.action()
  }

  const actionsActive = actionsTemplate.filter((actionTemplate) =>
    actions.includes(actionTemplate.type),
  )

  return (
    <div
      className={classNames(classes.box, {
        [classes.isMain]: isMain,
      })}
    >
      <div className={classes.top}>
        <div className={classes.left}>
          <CurrencySvg currencyIso={balance.currency}
            color
          />

          <div>
            {currencyName}
          </div>
        </div>

        <div className={classes.right}>
          <div className={classes.numbers}>
            <div className={classes.calculated}>
              {balance.value}

              {' '}

              {currencyIso}
            </div>

            <div className={classes.main}>
              {balance.calculated}

              {' '}

              {balance.mainCurrency}
            </div>
          </div>

          {!!actionsActive.length && (
            <div>
              {isLoading ? (
                <Spinner size={24} />
              ) : (
                <DropdownMenu
                  options={actionsActive}
                  dots
                  textLeft
                  //@ts-ignore
                  onChange={handleClick}
                />
              )}
            </div>
          )}
        </div>
      </div>

      {!!withProgress && (
        <div className={classes.wagered}>
          <span className={classes['wgered-text']}>
          Wagered:
          </span>

          <div className={classes['progress-box']}>
            <div className={classes['progress-inner']}
              style={{ width: `${percent || 0}%` }}
            />

            <span className={classes['progress-text']}>
              {percent || 0}
%
            </span>
          </div>
        </div>
      )}
    </div>
  )
}
