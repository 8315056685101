import React, { useEffect, useRef, useState } from 'react'

import styled from 'styled-components'
import classNames from 'classnames'

import { Scrollable } from './Scrollable'
import { BREAKPOINTS } from './constants'

interface Props {
  className?: string,
  children: React.ReactNode
}

export const ScrollableWithShadows: React.FC<Props> = ({ className = '', children }) => {
  const [topReached, toggleTop] = useState(false)
  const [bottomReached, toggleBottom] = useState(false)

  const scrollableRef = useRef<HTMLDivElement>()

  useEffect(() => {
    if (!scrollableRef.current) {
      return
    }

    const element = scrollableRef.current

    if (element.scrollHeight > element.clientHeight) {
      toggleBottom(true)
    }
  }, [])

  const handleScroll = () => {
    const element =  scrollableRef.current

    if (!element) {
      return
    }

    const scrollTop = element.scrollTop

    if (Math.abs(element.scrollHeight - element.clientHeight - scrollTop) <= 1) {
      toggleBottom(false)
    } else {
      toggleBottom(true)
    }

    if (scrollTop <= 1) {
      toggleTop(false)
    } else {
      toggleTop(true)
    }
  }

  return (
    <ScrollableWithShadowsBox className={classNames(className, { 'top-not-reached': topReached, 'bottom-not-reached': bottomReached })}>
      <StyledScrollable
        onScroll={handleScroll}
        ref={scrollableRef}
      >
        {children}
      </StyledScrollable>
    </ScrollableWithShadowsBox>
  )
}

const ScrollableWithShadowsBox = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &.top-not-reached {
    &:after {
      content: '';
      width: 100%;
      height: 33px;
      background: linear-gradient(180deg, #272832 0%, rgba(39, 40, 50, 0) 100%);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }

    @media (max-width: ${BREAKPOINTS.xsMax}px) {
      &:after {
        background: linear-gradient(180deg, #14151B 0%, rgba(29, 30, 37, 0) 100%);
        height: 75px;
      }
    }
  }

  &.bottom-not-reached {
    &:before {
      content: '';
      width: 100%;
      height: 33px;
      background: linear-gradient(180deg, rgba(39, 40, 50, 0) 0%, #272832 100%);
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
    }

    @media (max-width: ${BREAKPOINTS.xsMax}px) {
      &:before {
        background: linear-gradient(180deg, rgba(29, 30, 37, 0) 0%, #14151B 100%);
        height: 75px;
      }
    }
  }
`

const StyledScrollable = styled(Scrollable)`
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`
