import { FieldConfig, useField } from "formik"
import styled from "styled-components"
import { AgreeSvg } from "components/svg/Agree"
import classNames from "classnames"

import { COLORS } from "./constants"

type Props = {
  error?: boolean;
};

const Checkbox: React.FC<Props & FieldConfig> = ({
  error,
  ...props
}) => {
  const [field, meta] = useField(props)

  return (
    <CheckboxWrapper className={classNames({ error: !!meta.error })}>
      <CheckboxInput type="checkbox"
        {...field}
        {...props}
        checked={field.value}
      />

      <WrapperIcon className={classNames({ checked: field.value })}>
        <CheckboxIcon />
      </WrapperIcon>
    </CheckboxWrapper>
  )
}

const CheckboxWrapper = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  background: ${COLORS.dark550};

  &.error {
    border: 2px solid ${COLORS.red};
  }
`

const CheckboxInput = styled.input`
  display: none;
`

const WrapperIcon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;

  &.checked {
    opacity: 1;
  }
`

const CheckboxIcon = styled(AgreeSvg)``

export { Checkbox }
