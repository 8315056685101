import styled from "styled-components"

import { BREAKPOINTS, COLORS, FONTS } from "../../../ui-kit/constants"
import { SvgIcon } from "../../../ui-kit/svg"
import { Button } from "../../../ui-kit"

export const SContent = styled.div`
  width: 376px;
  background: linear-gradient(180deg, #33343F 0%, rgba(29, 30, 37, 0.00) 100%);
  border-radius: 16px;
  background: ${COLORS.dark600};
  position: relative;

  @media (max-width: ${BREAKPOINTS.xsMedium}px) {
    width: 100%;
    max-width: 361px;
  }
`

export const SCloseIcon = styled(SvgIcon)`
  width: 24px;
  height: 24px;
  color: ${COLORS.dark200};
  cursor: pointer;
  position: absolute;
  top: 26px;
  right: 26px;

  &:hover {
    color: white;
  }

  @media (max-width: ${BREAKPOINTS.xsMedium}px) {
    top: 24px;
    right: 24px;
  }
`

export const SHeader = styled.div`
  height: 60px;
  background: linear-gradient(180deg, #33343F 0%, rgba(29, 30, 37, 0.00) 100%);
  border-radius: 16px 16px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 19px 26px 0 32px;
  ${FONTS.h1};

  @media (max-width: ${BREAKPOINTS.xsMedium}px) {
    padding: 19px 26px 0 24px;
  }
`

export const SButtonBox = styled.div`
  padding: 0 32px 32px;

  @media (max-width: ${BREAKPOINTS.xsMedium}px) {
    padding: 0 24px 24px;
  }
`
export const SButton = styled(Button)`
`

export const SText = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: white;
  padding: 0 32px 32px;

  @media (max-width: ${BREAKPOINTS.xsMedium}px) {
    padding: 0 24px 24px;
  }
`
