import { FC, ReactNode, createContext, useContext, useState, useEffect } from 'react'

import { FlattenSimpleInterpolation, ThemeProvider } from 'styled-components'
import useWindowSize from 'hooks/useWindowSize'
import { getContentSize } from 'utils/contentSize'

interface IState {
  loading: boolean,
  leftMenuOpened: boolean,
  searchOpened: boolean,
  newPagesMenuOpened: boolean,
  contentSize: SIZES,
  contentSizeDefault: SIZES,
  isNewPage: boolean,
  setLoading: (loading: boolean) => void,
  toggleLeftMenu: (leftMenuOpened: boolean) => void,
  toggleSearch: (searchOpened: boolean) => void,
  toggleNewPagesLeftMenu: (leftMenuOpened: boolean) => void,
  toggleNewPage: (isNewPage: boolean) => void,
}

export enum SIZES {
  MOBILE = 'mobile',
  W512 = 512,
  W688 = 688,
  W1040 = 1040
}

interface Props {
  children: ReactNode
}

const defaultValue: IState = {
  loading: false,
  leftMenuOpened: true,
  searchOpened: false,
  newPagesMenuOpened: true,
  isNewPage: false,
  contentSize: SIZES.MOBILE,
  contentSizeDefault: SIZES.MOBILE,
  setLoading: () => undefined,
  toggleLeftMenu: () => undefined,
  toggleSearch: () => undefined,
  toggleNewPagesLeftMenu: () => undefined,
  toggleNewPage: () => undefined,
}

const LayoutContext = createContext<IState>(defaultValue)

export const LayoutContextProvider: React.FC<Props> = ({ children }) => {
  const { width } = useWindowSize()
  const [isNewPage, toggleNewPage] = useState<boolean>(defaultValue.isNewPage)
  const [loading, setLoading] = useState<boolean>(defaultValue.loading)
  const [contentSize, setContentSize] = useState<SIZES>(defaultValue.contentSize)
  const [contentSizeDefault, setContentSizeDefault] = useState<SIZES>(defaultValue.contentSizeDefault)
  const [leftMenuOpened, toggleLeftMenu] = useState<boolean>(defaultValue.leftMenuOpened)
  const [newPagesMenuOpened, toggleNewPagesLeftMenu] = useState<boolean>(defaultValue.newPagesMenuOpened)
  const [searchOpened, toggleSearch] = useState(false)

  const value: IState = {
    loading,
    leftMenuOpened,
    searchOpened,
    newPagesMenuOpened,
    contentSize,
    isNewPage,
    contentSizeDefault,
    setLoading,
    toggleNewPage,
    toggleNewPagesLeftMenu,
    toggleLeftMenu,
    toggleSearch
  }

  useEffect(() => {
    const contentSize = getContentSize({ width, leftMenuOpened, isNewPage })

    if (!!width) setContentSize(contentSize.withSidebars)
    if (!!width) setContentSizeDefault(contentSize.default)
  }, [width, leftMenuOpened, isNewPage])

  useEffect(() => {
    if (isNewPage && [SIZES.W1040, SIZES.W688].includes(contentSize)) toggleNewPagesLeftMenu(leftMenuOpened)
  }, [leftMenuOpened])

  useEffect(() => {
    if (!!width && width < 960) {
      toggleNewPagesLeftMenu(false)
      toggleLeftMenu(false)
    }
  }, [])

  return (
    <LayoutContext.Provider value={value}>
      <ThemeProvider theme={{ contentSize, contentSizeDefault, leftMenuOpened, isNewPage }}>
        {children}
      </ThemeProvider>
    </LayoutContext.Provider>
  )
}

export function useLayoutContext() {
  return useContext(LayoutContext)
}

export const applyStyles = (sizes: SIZES[], contentSize: SIZES, styles: string | FlattenSimpleInterpolation) => {
  if (sizes.includes(contentSize)) {
    return styles
  }

  return ''
}


interface ISizeComponent {
  sizes: SIZES[]
}

export const SizeComponent: FC<ISizeComponent> = ({ children, sizes }) => {
  const { contentSize } = useLayoutContext()

  if (!(sizes).includes(contentSize)) return null

  return (
    <>
      {children}
    </>
  )
}
