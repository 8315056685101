import { useEffect, useState } from 'react'

import { useTranslation } from 'next-i18next'
import SwitchFilterPayments from 'components/for_pages/Common/SwitchFilterPayments'
import ProfileModalLayout from 'components/Profile/layout/ProfileModalLayout'
import ModalHeader from 'components/Profile/layout/ProfileModalHeader/ModalHeader'
import ProfileModalBody from 'components/Profile/layout/ProfileModalBody'
import ProfileModalFooter from 'components/Profile/layout/ProfileModalFooter'
import { useAppContext } from 'context/AppContext'
import { PaymentSwitchFilterKey, ProfileModalType } from 'types/enums'
import { format } from 'date-fns'
import Button from 'components/ui/Button'
import BalanceTransactionRepository from 'data/repositories/BalanceTransationRepository'
import { BalanceTransactionType, IBalanceTransaction } from 'data/interfaces/IBalanceTransaction'
import { IPagination, PaymentHistoryModalArguments } from 'types/interfaces'
import { IWithdrawHistory } from 'data/interfaces/IWithdrawHistory'
import classNames from 'classnames'
import InfiniteScroll from 'react-infinite-scroll-component'
import ContentLoader from 'components/ui/ContentLoader'
import Converter from 'utils/converter'

import styles from './index.module.scss'

interface Props {

}

interface ItemProps {
  label?: string
  card?: string
  date: string
  amount: number
  currency: string
  type: 'red' | 'green' | 'white'
}

const Item = ({ label, card, date, amount, currency, type = 'green' }: ItemProps) => {

  return (
    <div className={styles.item}>
      <div className={styles.left}>
        {label ? (
          <div className={styles.label}>
            {label}
          </div>
        )
          : (
            <>
              <div className={styles.bank}>
Банковская карта
              </div>

              <div className={styles.card}>
                {card.slice(0, 4)}

                {' '}
**** **** **

                {card.slice(14)}
              </div>
            </>
          )}
      </div>

      <div className={styles.right}>
        <div className={styles.date}>
          {format(new Date(date), 'dd MMMM yyyy | hh:mm')}
        </div>

        <div className={classNames(styles.amount, { [styles.red]: type === 'red', [styles.white]: type === 'white' })}>
          {/* {
            type === 'green' && '+'
          }

          {
            type === 'red' && '-'
          }

          {' '} */}

          {amount}

          {' '}

          {currency}
        </div>
      </div>
    </div>
  )
}
export default function PaymentHistory(props: Props) {
  const { t } = useTranslation()
  const appContext = useAppContext()
  const initialData = { data: [], total: 0 }
  const args = appContext.modalArguments as PaymentHistoryModalArguments
  const [filter, setFilter] = useState<PaymentSwitchFilterKey>(args?.filter ?? PaymentSwitchFilterKey.All)
  const [data, setData] = useState<IPagination<IBalanceTransaction>>(initialData)
  const [applicationsData, setApplicationsData] = useState<IPagination<IWithdrawHistory>>(initialData)
  const [page, setPage] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const limit = 30

  const getTypesFilter = (filter: PaymentSwitchFilterKey) => {
    switch (filter){
    case PaymentSwitchFilterKey.All:
      return [BalanceTransactionType.Initial, BalanceTransactionType.Deposit, BalanceTransactionType.Withdrawal]
    case PaymentSwitchFilterKey.Deposit:
      return [BalanceTransactionType.Deposit]
    case PaymentSwitchFilterKey.Withdrawal:
      return [BalanceTransactionType.Withdrawal]
    case PaymentSwitchFilterKey.Applications:
    }
  }
  useEffect(() => {
    if(filter === PaymentSwitchFilterKey.Applications){
      BalanceTransactionRepository.fetchWithdrawalHistory( 1, limit).then(i => {
        setApplicationsData(i)
        setLoading(false)
      })
    }else {
      BalanceTransactionRepository.fetchTransactions(getTypesFilter(filter), 1, limit).then(i => {
        setData(i)
        setLoading(false)
      })
    }
  }, [filter])
  const handleChangeFilter = (item: PaymentSwitchFilterKey) => {
    setPage(1)
    setData(initialData)
    setApplicationsData(initialData)
    setFilter(item)
  }

  const handleScrollNext = async () => {
    const newPage = page + 1
    setPage(newPage)
    setLoading(true)
    if(filter === PaymentSwitchFilterKey.Applications){
      const res = await BalanceTransactionRepository.fetchWithdrawalHistory( newPage, limit)
      setApplicationsData(data => ({ data: [...data.data, ...res.data], total: res.total }))
    }else {
      const res = await BalanceTransactionRepository.fetchTransactions(getTypesFilter(filter), newPage, limit)
      setData(data => ({ data: [...data.data, ...res.data], total: res.total }))
    }
    setLoading(false)
  }

  return (
    <ProfileModalLayout fixed>
      <ModalHeader title={t('payment_history_title')} />

      <div className={styles.wrapper}>
        <SwitchFilterPayments active={filter}
          onClick={handleChangeFilter}
        />
      </div>

      <ProfileModalBody fixed
        id={'payment-history-list'}
        className={styles.body}
      >
        {loading && data.total == 0 && (
          <ContentLoader style={'block'}
            isOpen={true}
          />
        )}

        <InfiniteScroll
          dataLength={PaymentSwitchFilterKey.Applications ? applicationsData.data.length : data.data.length}
          next={handleScrollNext}
          loader={ data.total > 0 ? (
            <ContentLoader style={'infiniteScroll'}
              isOpen={true}
            />
          ) : null}
          hasMore={PaymentSwitchFilterKey.Applications ? applicationsData.total > applicationsData.data.length : data.total > data.data.length}
          scrollThreshold={0.6}
          scrollableTarget={'payment-history-list'}
        >
          <div className={styles.list}>
            {filter === PaymentSwitchFilterKey.Applications &&  applicationsData.data.map((item, index) => (
              <Item label={t('payment_history_payout')}
                card={item.address}
                date={item.createdAt.unixtimestamp}
                amount={item.amount}
                currency={Converter.convertCurrencyIsoToDisplayIso(appContext.currencies, item.currencyIso || '')}
                key={item.id}
                type={item.status === 1 ? 'white': item.status === 3 ? 'red' : 'green'}
              />
            )
            )}

            {filter !== PaymentSwitchFilterKey.Applications &&  data.data.map((item, index) => (
              <Item label={item.providerName}
                card={item.providerDetails}
                date={item.eventDate}
                amount={item.amount}
                currency={Converter.convertCurrencyIsoToDisplayIso(appContext.currencies, item.currencyIso || '')}
                type={[BalanceTransactionType.Withdrawal].includes(item.type) ? 'red' : 'green'}
                key={item.unixtimestamp}
              />
            )
            )}

          </div>
        </InfiniteScroll>

      </ProfileModalBody>

      <ProfileModalFooter>
        <div className={styles.btn}>
          <Button size='normal'
            background='payGradient500'
            onClick={() => appContext.showModalProfile(ProfileModalType.wallet, { backTo: ProfileModalType.paymentHistory })}
          >
            {t('payment_history_deposit')}
          </Button>
        </div>
      </ProfileModalFooter>
    </ProfileModalLayout>

  )
}
