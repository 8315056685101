import { useEffect, useRef } from "react"

const UseOnclickOutside = <T extends HTMLElement>(
  onClickAway: (event: MouseEvent) => void
) => {
  const ref = useRef<T | null>(null)
  const savedCallback = useRef(onClickAway)

  useEffect(() => {
    savedCallback.current = onClickAway
  }, [onClickAway])

  useEffect(() => {
    const handleClickAway = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        savedCallback.current(event)
      }
    }

    document.addEventListener('click', handleClickAway)

    return () => {
      document.removeEventListener('click', handleClickAway)
    }
  }, [])

  return ref
}

export { UseOnclickOutside }