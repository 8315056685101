import React, { useCallback } from 'react'

import { Trans, useTranslation } from 'next-i18next'
import { useFormikContext } from 'formik'
import { useRouter } from 'next/router'
import { Checkbox } from 'ui-kit'
import Validator from 'utils/validator'
import { useAppContext } from 'context/AppContext'
import { Routes } from 'types/routes'

import { StyledLabel, StyledLink, StyledWrapperCheckBox, StyledErrorBox } from './styles'


export default function CheckboxAgreement () {
  const { errors } = useFormikContext()
  const context = useAppContext()
  const router = useRouter()
  const { t } = useTranslation()

  const handleTermLinkClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      e.preventDefault()
      router.push(Routes.userAgreement)
      context.hideModal()
    },
    [router, context]
  )

  const handleAgreementLinkClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      e.preventDefault()
      router.push(Routes.termsAndConditions)
      context.hideModal()
    },
    [router, context]
  )

  return (
    <div>
      <StyledWrapperCheckBox>
        <Checkbox name='checkBox'
          validate={Validator.required}
        />

        <StyledLabel>
          <Trans i18nKey='registration_terms_accept'>
            I agree with the User
            <StyledLink onClick={handleTermLinkClick}>
              agreement
            </StyledLink>
            ,

            <StyledLink onClick={handleAgreementLinkClick}>
              Terms and conditions
            </StyledLink>

            and confirm that I am over 18 years old
          </Trans>
        </StyledLabel>
      </StyledWrapperCheckBox>

      {!!errors?.checkBox && (
        <StyledErrorBox>
          {t(errors.checkBox)}
        </StyledErrorBox>
      )}

      <StyledWrapperCheckBox>
        <Checkbox name='checkBoxPromotion'
          validate={Validator.required}
        />

        <StyledLabel>
          {t('registration_terms_accept_promotions')}
        </StyledLabel>
      </StyledWrapperCheckBox>

      {!!errors?.checkBoxPromotion && (
        <StyledErrorBox>
          {t(errors.checkBoxPromotion)}
        </StyledErrorBox>
      )}
    </div>
  )
}
