import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const SecurityIcon: React.FC<Props> = ({ className = '', ...props }) => {
  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
      {...props}
    >
      <path d="M10 2L3.66366 4.26298C3.26567 4.40512 3 4.78211 3 5.20472V9.27273C3 13.3091 5.98667 17.0836 10 18C14.0133 17.0836 17 13.3091 17 9.27273V5.20472C17 4.78211 16.7343 4.40512 16.3363 4.26298L10 2ZM10.1111 5.72727C11.2 5.72727 12.2889 6.52727 12.2889 7.54545V8.63636C12.7556 8.63636 13.2222 9.07273 13.2222 9.58182V12.1273C13.2222 12.5636 12.7556 13 12.2111 13H7.93333C7.46667 13 7 12.5636 7 12.0545V9.50909C7 9.07273 7.46667 8.63636 7.93333 8.63636V7.54545C7.93333 6.52727 9.02222 5.72727 10.1111 5.72727ZM10.1111 6.6C9.48889 6.6 8.94444 6.96364 8.94444 7.54545V8.63636H11.2778V7.54545C11.2778 6.96364 10.7333 6.6 10.1111 6.6Z" />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  path {
    fill: currentColor;
  }
`
