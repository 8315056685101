import { FC } from 'react'

import Link from 'next/link'

import { SBreadcrumbs, SItem, SLinkItem } from './styles'

interface IItem {
  link?: string
  label: string
}

export const Item: FC<{ item: IItem }> = ({ item }) => {
  return (
    <>
      {item.link ? (
        <>
          <Link href={item.link}>
            <SLinkItem>
              {item.label}
            </SLinkItem>
          </Link>

          <img
            width={20}
            height={20}
            src='/img/icons/arrow-right.svg'
          />
        </>
      ) : (
        <SItem>
          {item.label}
        </SItem>
      )}
    </>
  )
}

export const Breadcrumbs: FC<{items: IItem[]}> = ({ items }) => {
  return (
    <SBreadcrumbs>
      {items.map(item => <Item key={item.label} item={item} />)}
    </SBreadcrumbs>
  )
}

export default Breadcrumbs

