import { useState, FC } from 'react'

import { getRandom } from 'components/for_pages/games/utils/rand'

import { SButtonGoTo } from './styles'
import { COLORS } from '../constants';


export const GradientButton: FC<{ onClick?: () => void, disabled?: boolean }> = ({ children, onClick, disabled = false }) => {
  const [isHovered, setIsHovered] = useState(false)
  const idForGradient = getRandom(0, 100000)

  return (
    <SButtonGoTo
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      disabled={disabled}
    >
      <>
        {children}

        <svg width='6'
          height='12'
          viewBox='0 0 6 12'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >

          <path
            d='M5.48571 6.50433L5.48606 6.50391C5.62068 6.34145 5.69483 6.13746 5.69605 5.92663C5.70835 5.72532
              5.65365 5.52552 5.54037 5.35849L5.53578 5.35171L5.53064 5.34532L2.0882 1.069C2.01219 0.970035 1.91669
              0.887687 1.80759 0.82706C1.6977 0.765992 1.57638 0.728279 1.45124 0.716278C1.32609 0.704278 1.19982
              0.71825 1.08033 0.757322C0.96245 0.795865 0.853678 0.858012 0.760636 0.939956C0.668602 1.01473 0.592002
              1.10674 0.535133 1.21083C0.477453 1.31641 0.441208 1.43235 0.428481 1.55199C0.415754 1.67162 0.426796
              1.79259 0.460973 1.90794C0.49511 2.02316 0.551657 2.13051 0.627367 2.22383C0.627454 2.22394
              0.627542 2.22404 0.627629 2.22415L3.60447 5.91764L0.515341 9.61357L0.51534 9.61357L0.51499
              9.61399C0.360054 9.8004 0.285515 10.0407 0.307768 10.2821C0.330022 10.5235 0.447245 10.7461
              0.633652 10.901C0.820059 11.056 1.06038 11.1305 1.30174 11.1082C1.54307 11.086 1.76569 10.9688
              1.92062 10.7824C1.92064 10.7824 1.92066 10.7824 1.92068 10.7824L5.48571 6.50433Z'
            strokeWidth='0.4'
            fill={`url(#gr_${idForGradient})`}
            stroke='url(#paint1_linear_892_89808)'
          />

          <defs>
            <linearGradient id={`gr_${idForGradient}`}
              x1='3.00082'
              y1='0.310843'
              x2='3.00083'
              y2='12.3995'
              gradientUnits='userSpaceOnUse'
            >
              <stop stopColor={disabled ? COLORS.dark200 : isHovered ? '#7000ff' : '#4785F6' } />

              <stop offset='1'
                stopColor={disabled ? COLORS.dark400 : isHovered ? '#2C50FF' : '#2C50FF'}
              />
            </linearGradient>
          </defs>
        </svg>
      </>
    </SButtonGoTo>
  )
}

