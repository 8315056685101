import { useTranslation } from 'next-i18next'
import { useAppContext } from 'context/AppContext'
import { PaymentMethodSelectedMobile } from 'components/Profile/Wallet/PaymentMethodSelectedMobile'
import { IPaymentSystem } from 'data/interfaces/IPaymentSystem'
import { runtimeConfig } from 'config/runtimeConfig'

import { PaymentMethodCard } from '../PaymentMethodCard'

interface Props {
  paymentSystem: IPaymentSystem
  onClick?: () => void
}

export const PaymentSystemSelected = (props: Props) => {
  const context = useAppContext()
  const isMobile = context.isMobile
  const { t } = useTranslation()
  return isMobile ? (
    <PaymentMethodSelectedMobile label={props.paymentSystem.name}
      icon={props.paymentSystem.imageUrl ? `${runtimeConfig.HOST}${props.paymentSystem.imageUrl}` : null}
      onClick={props.onClick}
    />
  ) : (
    <PaymentMethodCard icon={`${runtimeConfig.HOST}${props.paymentSystem.imageUrl}`}
      label={props.paymentSystem.name}
      selected
      onClick={props.onClick}
    />
  )
}

