import { FC } from 'react'

import classNames from 'classnames'

import classes from './index.module.scss'

export const PaymentOptions: FC = (props) => {
  const childrenLength = Array.isArray(props.children)
    ? props.children.length
    : 1

  return (
    <div
      className={classNames(classes.box, {
        [classes.one]: childrenLength === 1,
        [classes.two]: childrenLength === 2,
      })}
    >
      {props.children}
    </div>
  )
}
