import { ReactNode, createContext, useContext, useState } from 'react'

import { NotificationModal } from "../components/notifications/NotificationModal"

export interface INotificationModalData {
  title: string,
  content: string,
  button?: string,
  link?: string
}

interface IState {
  notificationModalData: INotificationModalData | null,
  setNotificationModalData: (data: INotificationModalData | null) => void,
}

interface Props {
  children: ReactNode
}

const defaultValue: IState = {
  notificationModalData: null,
  setNotificationModalData: () => undefined
}

const NotificationsContext = createContext<IState>(defaultValue)

export const NotificationsContextProvider: React.FC<Props> = ({ children }) => {
  const [notificationModalData, setNotificationModalData] = useState<INotificationModalData | null>(null)

  const value = {
    notificationModalData,
    setNotificationModalData
  }

  return (
    <NotificationsContext.Provider value={value}>
      {children}

      <NotificationModal />
    </NotificationsContext.Provider>
  )
}

export function useNotificationsContext() {
  return useContext(NotificationsContext)
}
