import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const ProfileIcon: React.FC<Props> = ({ className = '', ...props }) => {
  return (
    <StyledSvg width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path d="M7.5 8.125C5.42812 8.125 3.75 6.44687 3.75 4.375C3.75 2.30312 5.42812 0.625 7.5 0.625C9.57187 0.625 11.25 2.30312 11.25 4.375C11.25 6.44687 9.57187 8.125 7.5 8.125Z" />

      <path d="M1.5 14.6C1.5 11.5072 4.18629 9 7.5 9C10.8137 9 13.5 11.5072 13.5 14.6H1.5Z" />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  path {
    fill: currentColor;
  }
`
