import React, { useEffect, useRef, useState } from 'react'

import { useAppContext } from 'context/AppContext'
import AuthRepository from 'data/repositories/AuthRepository'
import { Formik } from 'formik'
import { useTranslation } from 'next-i18next'
import { ProfileModalType } from 'types/enums'
import { PasswordInput } from 'ui-kit'
import { analytics } from 'utils/analytics'
import Converter from 'utils/converter'
import Validator from 'utils/validator'
import ReCAPTCHA from 'react-google-recaptcha'
import { runtimeConfig } from 'config/runtimeConfig'
import PromoCode from 'components/for_pages/Common/Promocode'

import {
  StyledForm,
  StyledInputFields,
  StyledScrollable,
  StyledInput,
} from './styles'
import { CurrencyField } from './CurrencyField'
import { SubmitFooter } from './SubmitFooter'
import { getErrorCodeByFieldName } from './helper'

interface FastRegistrationProps {
  setMinHeight: (value: number) => void,
  onInputFocus: () => void
  onInputBlur: () => void
}

const FastRegistration: React.FC<FastRegistrationProps> = ({ setMinHeight, onInputFocus, onInputBlur }) => {
  const { t } = useTranslation()
  const context = useAppContext()
  const [errors, setErrors] = useState<string | null>(null)
  const [sending, setSending] = useState<boolean>(false)
  const FieldsBlockRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (FieldsBlockRef.current && context.isDesktop) setMinHeight(FieldsBlockRef.current.offsetHeight)
  }, [FieldsBlockRef.current])

  const recaptchaRef = React.useRef<any>(null)

  const handleSubmit = async (data: { email: any; password: any; currency: any }) => {
    setSending(true)

    try {
      setErrors(null)

      const recaptchaToken = await recaptchaRef.current?.executeAsync()

      const res = await AuthRepository.registerEmail({
        email: data.email,
        password: data.password,
        currency: data.currency,
        token: recaptchaToken
      })

      const accessToken = res.token

      if (!accessToken) {
        setErrors(t('registration_error'))
        setSending(false)
        return
      }

      context.setToken(accessToken)

      analytics.dataLayerPush({
        event: 'formSuccess',
        formName: 'registrationSuccess',
        registrationType: 'email',
      })

      await context.updateUserFromCookies()

      context.hideModal()
      context.showModal(ProfileModalType.wallet)
      context.setIsUserJustRegistered(true)
    } catch (e) {
      setErrors(e)
    } finally {
      recaptchaRef.current?.reset()
    }

    setSending(false)
  }

  const initialValues = {
    email: null,
    password: null,
    currency: Converter.convertCurrencyToOptions(context.currencies)[0]?.value,
    checkBox: true,
    checkBoxPromotion: true
  }

  return (
    <Formik initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur={false}
    >
      <StyledForm>
        <StyledScrollable ref={FieldsBlockRef}>
          <StyledInputFields>
            <StyledInput name='email'
              placeholder={t('registration_field_email')}
              error={getErrorCodeByFieldName(errors, 'email')}
              validate={Validator.combine([Validator.required, Validator.email])}
              onFocus={onInputFocus}
              onBlur={onInputBlur}
            />

            <PasswordInput name='password'
              placeholder={t('login_field_password')}
              error={getErrorCodeByFieldName(errors, 'password')}
              validate={Validator.required}
              onFocus={onInputFocus}
              onBlur={onInputBlur}
            />

            <CurrencyField name='currency' />
          </StyledInputFields>

          <PromoCode />
        </StyledScrollable>

        <SubmitFooter sending={sending} />

        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={runtimeConfig.RECAPTCHA_KEY}
          style={{ visibility: "hidden" }}
        />

      </StyledForm>
    </Formik>
  )
}

export default FastRegistration
