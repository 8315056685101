import { Form } from 'formik'
import styled from 'styled-components'
import { Input, PhoneInputWithCountry, Scrollable, Select, Tabs } from 'ui-kit'
import { COLORS } from 'ui-kit/constants'
import closeSvg from 'components/svg/Close'
import { ErrorBox } from 'components/ui/Form/FormError'

export const Root = styled.div`
  position: relative;
  display: flex;
  width: 657px;
  border-radius: 16px;
  z-index: 3;
  background: ${COLORS.dark600};
  overflow: hidden;

  @media screen and (max-width: 768px){
    display: block;
    width: 100%;
    border-radius: 0;
    height: 100%;
    max-height: 100%;
    overflow: auto;
  }
`

export const Frame = styled.div`
  overflow-y: scroll;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    position: fixed;
    display: block;
    bottom: auto;
    padding-top: 0px;
    padding-bottom: 0px;
    height: 100vh;
    min-height: -webkit-fill-available;
  }
`

export const StyledWrapperButton = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-top: 16px;

  @media screen and (max-width: 768px) {
    margin-top: 12px;
    margin-bottom: 74px;
  }
`

export const StyledWrapperCheckBox = styled.div`
  display: flex;
  gap: 8px;
  padding: 16px 0 0;
  align-items: center;
`

export const StyledLabel = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  margin-right: -10px;

  @media screen and (max-width: 768px){
    margin-right: 0;
  }
`

export const StyledLink = styled.span`
  color: ${COLORS.blue};
  text-decoration: none;
  cursor: pointer;
`

export const StyledLoginLink = styled.div`
  text-align: center;
  padding-top: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: ${COLORS.dark200};

  & span {
    color: ${COLORS.blue};
    cursor: pointer;
  }
`

export const StyledInputFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const StyledForm = styled(Form)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export const StyledScrollable = styled(Scrollable)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-right: 0;

  @media screen and (max-width: 768px){
    margin-top: 8px;
    height: 100%;
    min-height: auto;
  }
`

export const StyledInput = styled(Input)`
  font-weight: 500;
`

export const StyledPhoneInput = styled(PhoneInputWithCountry)`
  font-weight: 500;
`

export const Overlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  cursor: pointer;
`

export const StyledTabs = styled(Tabs)`
  display: flex;
  justify-content: center;
  flex-shrink: 0;
`

export const StyledTab = styled.div`
  display: flex;
  align-items: center;
`

export const StyledTabLabel = styled.span`
  padding-top: 3px;
  font-size: 14px;
  font-family: 'Gilroy';
`

export const StyledCloseIcon = styled(closeSvg)`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 12px;
  height: 12px;
  cursor: pointer;

  @media screen and (max-width: 768px){
    display: none;
  }
`

export const StyledErrorBox = styled(ErrorBox)`
  margin: 8px 0 0 0;
  text-align: left;
`

export const StyledSelect = styled(Select)`
  font-size: 14px;
  font-weight: 500;
`

export const ToggleContent = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`
