import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const TimeIcon: React.FC<Props> = ({ className = '', ...props }) => {
  return (
    <StyledSvg width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d='M10 2C8.41775 2 6.87103 2.46919 5.55544 3.34824C4.23985 4.22729 3.21447 5.47672 2.60897 6.93853C2.00347
        8.40034 1.84504 10.0089 2.15372 11.5607C2.4624 13.1126 3.22433 14.538 4.34315 15.6569C5.46197 16.7757 6.88743
        17.5376 8.43928 17.8463C9.99113 18.155 11.5997 17.9965 13.0615 17.391C14.5233 16.7855 15.7727 15.7602 16.6518
        14.4446C17.5308 13.129 18 11.5822 18 10C18 8.94942 17.7931 7.90914 17.391 6.93853C16.989 5.96793 16.3997
        5.08601 15.6569 4.34315C14.914 3.60028 14.0321 3.011 13.0615 2.60896C12.0909 2.20693 11.0506 2 10 2ZM13.2
        10.8H10C9.78783 10.8 9.58435 10.7157 9.43432 10.5657C9.28429 10.4157 9.2 10.2122 9.2 10V6.8C9.2 6.58783
        9.28429 6.38434 9.43432 6.23431C9.58435 6.08428 9.78783 6 10 6C10.2122 6 10.4157 6.08428 10.5657 6.23431C10.7157
        6.38434 10.8 6.58783 10.8 6.8V9.2H13.2C13.4122 9.2 13.6157 9.28428 13.7657 9.43431C13.9157 9.58434 14 9.78782
        14 10C14 10.2122 13.9157 10.4157 13.7657 10.5657C13.6157 10.7157 13.4122 10.8 13.2 10.8Z'
        fill={props.color}
      />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  path {
    fill: currentColor;
  }
`
