import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const TicketIcon: React.FC<Props> = ({ className = '', ...props }) => {
  return (
    <StyledSvg width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      {...props}
    >
      <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M10.2699 0.421194C9.96511 0.142174 9.56428 -0.00857803 9.15094 0.000377313C8.73761 0.00933266 8.3437
          0.177304 8.05136 0.46926L0.469943 8.04204C0.320954 8.19083 0.202768 8.36746 0.122135 8.56186C0.0415017
          8.75626 0 8.96462 0 9.17504C0 9.38546 0.0415017 9.59382 0.122135 9.78823C0.202768 9.98263 0.320954 10.1593
          0.469943 10.308L1.53107 11.3667C1.9287 11.7649 2.50051 11.6688 2.83742 11.4445C3.07985 11.2835 3.37058
          11.2114 3.66024 11.2402C3.94991 11.2691 4.22064 11.3972 4.42648 11.6027C4.63232 11.8083 4.76057 12.0787
          4.78945 12.368C4.81834 12.6573 4.74608 12.9476 4.58495 13.1898C4.36149 13.5262 4.26523 14.0984 4.66287
          14.4956L5.69993 15.5301C5.85177 15.6819 6.03259 15.8017 6.23167 15.8824C6.43075 15.9631 6.64403 16.003
          6.85885 15.9998C7.07367 15.9966 7.28566 15.9504 7.48224 15.8638C7.67882 15.7772 7.85599 15.6521 8.00323
          15.4958L15.564 7.47097C15.7096 7.3165 15.823 7.13472 15.8976 6.93615C15.9723 6.73758 16.0067 6.52618
          15.9989 6.31422C15.9911 6.10225 15.9413 5.89394 15.8523 5.70137C15.7633 5.50879 15.6368 5.33578 15.4804
          5.19238L14.4639 4.26195C14.3523 4.16253 14.219 4.0904 14.0746 4.05127C13.9302 4.01214 13.7787 4.00708
          13.632 4.03649C13.3719 4.08227 13.1255 4.2196 12.9467 4.41416C12.8348 4.53604 12.7 4.63473 12.5499
          4.70457C12.3999 4.77442 12.2375 4.81406 12.0721 4.82123C11.9067 4.82841 11.7415 4.80297 11.5859
          4.74638C11.4303 4.68979 11.2874 4.60315 11.1654 4.49141C11.0434 4.37967 10.9445 4.24501 10.8746
          4.09513C10.8047 3.94526 10.765 3.78309 10.7578 3.61789C10.7506 3.45269 10.7761 3.28769 10.8327
          3.13233C10.8894 2.97696 10.9762 2.83426 11.088 2.71237C11.268 2.5201 11.3837 2.2626 11.4078 1.99938C11.4243
          1.85085 11.406 1.70051 11.3543 1.56026C11.3026 1.42002 11.2189 1.29373 11.1098 1.1914L10.2699 0.421194Z'
          fill={props.color}
        />
      </svg>
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  path {
    fill: currentColor;
  }
`
