import { FC } from 'react'

import Table from './Table'
import Cell from './Cell'
import Head from './Head'
import Row from './Row'
import { ICell, IRow } from './types'
import Body from './Body'

const TableComponent: {
  Main: FC,
  Head: FC,
  Body: FC,
  Row: FC<IRow>,
  Cell: FC<ICell>,
} = {
  Main: Table,
  Cell,
  Head,
  Row,
  Body
}

export default TableComponent
