import React, { useEffect, useRef, useState } from 'react'

import { useTranslation } from 'next-i18next'
import { IPaymentMethod } from 'data/interfaces/IPaymentMethod'
import { IPaymentSystem } from 'data/interfaces/IPaymentSystem'
import { useAppContext } from 'context/AppContext'
import { FormikProvider, FormikValues, useFormik } from 'formik'
import Validator from 'utils/validator'
import PaymentMethodRepository from 'data/repositories/PaymentMethodRepository'
import { partition } from 'lodash'
import { IPaymentMethodField } from 'data/interfaces/IPaymentFields'
import { PaymentFormExtraFields } from 'components/Profile/Wallet/PaymentFormExtraFields'
import Formatter from 'utils/formatter'
import PaymentsRepository from 'data/repositories/PaymentsRepository'
import { BrowserUtils } from 'utils/browser'
import FormError, { FormErrorValue } from 'components/ui/Form/FormError'
import ContentLoader from 'components/ui/ContentLoader'
import { PaymentHistoryModalArguments } from 'types/interfaces'
import { PaymentSwitchFilterKey, ProfileModalType } from 'types/enums'
import styled from 'styled-components'
import { Button, Input } from 'ui-kit'
import { COLORS, FONTS } from 'ui-kit/constants'
import CurrencySvg from 'components/svg/CurrencySvg/CurrencySvg'
import { ArrowBackNewSvg } from 'components/svg/ArrowBackNewSvg'
import ReCAPTCHA from 'react-google-recaptcha'
import { runtimeConfig } from 'config/runtimeConfig'
import { SIZES, useLayoutContext } from 'context/layoutContext'

import { WalletModalNewTabs } from '../WalletModalNewTabs/WalletModalNewTabs'
import { WalletPaymentSystem } from '../WalletPayment/WalletPaymentSystem'
import { IBreadcrumb, WalletBreadcrumbs } from '../WalletBreadcrumbs/WalletBreadcrumbs'
import { WalletPaymentCurrency } from '../WalletPayment/WalletPaymentCurrency'

import { CloseImg, Fields, FixedBottomBox, Left, MobileTitle, MobileTitleRight, Right, RightTitleRow, Root, StyledArrowBack, StyledForm, Title } from './styles'

interface Props {
  onClose: () => void,
  onTabChange: (tab: string) => void
}

export const WalletNewWithdraw: React.FC<Props> = ({ onClose, onTabChange }) => {
  const { t, i18n } = useTranslation()

  const appContext = useAppContext()
  const { contentSize } = useLayoutContext()
  const paymentMethods = appContext.withdrawPaymentMethods
  const paymentMenthodsLoading = !paymentMethods.length

  const [paymentMethod, setPaymentMethod] = useState<
    IPaymentMethod | undefined
  >()
  const [paymentSystem, setPaymentSystem] = useState<
    IPaymentSystem | undefined
  >()
  const [paymentFields, setPaymentFields] = useState<IPaymentMethodField[]>([])
  const [sending, setSending] = useState(false)
  const [currency, setCurrency] = useState<any>()
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([{ label: t('header_profile_menu_wallet'), type: 'default' }])
  const [error, setError] = useState<FormErrorValue | null>(null)
  const [withdrawalInfoShowed, setWithdrawalInfoShowed] = useState<boolean>(false)
  const [fieldsLoading, toggleFieldsLoading] = useState(true)

  const recaptchaRef = useRef<any>(null)

  const [cryptoPaymentMethods, otherPaymentMethods] = partition(
    paymentMethods,
    (paymentMethod) => paymentMethod.isCrypto,
  )

  const sortedPaymentSystems = otherPaymentMethods.flatMap(method =>method.paymentSystems
    .map(system => ({ ...system, method, order: system.settings?.[0]?.order })))
    .sort((a, b) => a.order - b.order)

  const isMobile = SIZES.MOBILE === contentSize

  const handleSetDefaultPaymentSystem = () => {
    if (isMobile || !sortedPaymentSystems[0]) {
      return
    }

    handleSetPaymentSystem(sortedPaymentSystems[0])
  }

  useEffect(() => {
    if (isMobile) {
      return
    }

    if (!sortedPaymentSystems.length && cryptoPaymentMethods[0]) {
      handleSetPaymentMethod(cryptoPaymentMethods[0])

      return
    }

    if (!sortedPaymentSystems.length) {
      return
    }

    handleSetPaymentSystem(sortedPaymentSystems[0])
  }, [sortedPaymentSystems.length, cryptoPaymentMethods.length])

  useEffect(() => {
    if (!paymentSystem || !paymentSystem.systemCode || !appContext.auth) {
      return
    }

    toggleFieldsLoading(true)

    PaymentMethodRepository.fetchWithdrawalFields(
      paymentSystem.systemCode,
      paymentSystem.id,
    ).then((fields) => {
      setPaymentFields(fields)
      toggleFieldsLoading(false)
    })
  }, [paymentSystem, appContext.auth])

  useEffect(() => {
    setError(null)
    setWithdrawalInfoShowed(false)
  }, [paymentSystem, currency, paymentMethod])

  useEffect(() => {
    if (!paymentFields.length || !formik) {
      return
    }

    if (paymentFields.find(paymentFields => paymentFields.key === 'email')) {
      formik.setFieldValue('extra_data.email', appContext.user.email || '', false)
    }

    if (paymentFields.find(paymentFields => paymentFields.key === 'phone') && appContext.user.phone) {
      formik.setFieldValue('extra_data.phone', '+' + String(appContext.user.phone) || '', false)
    }
  }, [paymentFields])


  const handleSetPaymentMethod = (method: IPaymentMethod) => {
    setPaymentMethod(method)
    setBreadcrumbs(state => [...state.slice(0, 1), { label: method.title, type: 'method' }])

    formik.resetForm()
  }

  const handleSetPaymentSystem = (system: IPaymentSystem) => {
    setPaymentSystem(system)
    setBreadcrumbs(state => [...state.slice(0, 1), { label: system.name, type: 'system' }])

    formik.resetForm()
  }

  const handleCurrencySelect = (currency: any) => {
    if (isMobile) {
      setBreadcrumbs(state => [...state.slice(0, 2), { label: currency.displayName, type: 'currency' }])
    }

    setCurrency(currency)
    setPaymentSystem(currency.paymentSystem)
  }

  const handleBreadcrumbClick = (breadcrumb: IBreadcrumb) => {
    setWithdrawalInfoShowed(false)

    if (breadcrumb.type === 'default') {
      setPaymentMethod(undefined)
      setCurrency(undefined)

      if (isMobile) {
        setPaymentSystem(undefined)
      } else {
        handleSetDefaultPaymentSystem()
      }

      setBreadcrumbs(state => state.slice(0, 1))
    }

    if (breadcrumb.type === 'method') {
      setCurrency(undefined)

      if (isMobile) {
        setPaymentSystem(undefined)
      } else {
        handleSetDefaultPaymentSystem()
      }

      setBreadcrumbs(state => state.slice(0, 2))
    }
  }

  const handleWithdraw = async (data: FormikValues) => {
    setError(null)
    setSending(true)

    try {
      const recaptchaToken = await recaptchaRef.current?.executeAsync()

      if (isCryptoSelected) {
        await PaymentsRepository.withdrawCrypto(
          currency.iso,
          paymentSystem?.id,
          paymentSystem?.systemCode,
          data.amount,
          data.address,
          recaptchaToken
        )

        appContext.showModalProfile(ProfileModalType.paymentHistory, {
          filter: PaymentSwitchFilterKey.Applications,
        } as PaymentHistoryModalArguments)
      } else {
        const formattedData = {
          ...data,
          extra_data: data.extra_data || {},
          ...(paymentSystem?.isBrowserInfoRequired
            ? {
              browser_info: BrowserUtils.getDetailsForPayment(
                i18n.language,
              ),
            }
            : {}),
        }

        if (data.card_pan && formattedData.extra_data) {
          formattedData.extra_data.card_pan = data.card_pan
        }

        if (data.card_expires && formattedData.extra_data) {
          formattedData.extra_data.card_expires = data.card_expires
        }

        const res = await PaymentsRepository.withdrawFiat(
          currentSettings?.currencyIso,
          paymentSystem?.id,
          paymentSystem?.systemCode,
          `${window.location.origin}?withdrawal=1`,
          data.amount,
          data.address,
          data.cardHolderName || data.cardOwnerName,
          data.cardExpiry,
          formattedData,
          recaptchaToken
        )

        if (res.is_redirect_url_required || !res.url) {
          setWithdrawalInfoShowed(true)

          return
        }

        if (res.url) {
          window.location.href = res.url
        }
      }
    } catch (e) {
      console.log(e)
      setError(e as FormErrorValue)
    } finally {
      recaptchaRef.current?.reset()
    }

    setSending(false)
  }

  const formik = useFormik({
    initialValues: {
      amount: '20',
    },
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: handleWithdraw,
  })

  if (!appContext?.user) {
    return null
  }

  const userCurrency = appContext.currencies.find(
    (currency) => currency.iso === appContext?.user?.currencyIso,
  )

  const isCryptoSelected = !!paymentMethod?.isCrypto && !!currency

  const currencyIso = userCurrency?.iso as string
  // const currentSettings = paymentSystem?.settings?.find(
  //   (i) => i.currencyIso === currencyIso,
  // )
  const currentSettings = paymentSystem?.settings?.[0]

  const fiatMinAmount = currentSettings?.withdraw?.minAmount ?? 0
  const fiatMaxAmount = currentSettings?.withdraw?.maxAmount ?? 0

  const fiatMin = String(fiatMinAmount)
  const fiatMax = `${fiatMaxAmount} ${currentSettings?.currencyIso}`

  const cryptoMinAmount = currency?.withdraw?.minAmount ?? 0
  const cryptoMaxAmount = currency?.withdraw?.maxAmount ?? 0

  const cryptoMin = !!currency ? Formatter.formatAmount(
    (currency?.withdraw?.minAmount ?? 0),
    currencyIso,
  ) : ''
  const cryptoMax = !!currency ? Formatter.formatAmount(
    (currency?.withdraw?.maxAmount ?? 0),
    currencyIso,
  ) : ''

  const checkOnlyMobileCondition = (condition: boolean) => {
    if (!isMobile) {
      return true
    }

    return condition
  }

  const handleBackClick = () => {
    if (breadcrumbs.length <= 1) {
      return
    }

    const breadcrumb = breadcrumbs[breadcrumbs.length - 2]

    handleBreadcrumbClick(breadcrumb)
  }

  const isChoosingStep = ((!paymentSystem) || (!!paymentMethod && paymentMethod.isCrypto)) && !currency && !withdrawalInfoShowed

  return (
    <Root>
      <MobileTitle>
        {breadcrumbs.length > 1 && (
          <StyledArrowBackMobile color={COLORS.dark200}
            onClick={handleBackClick}
          />
        )
        }

        <span onClick={handleBackClick}>
          {t('header_profile_menu_withdrawal')}
        </span>

        <MobileTitleRight onClick={onClose}>
              ID
          {' '}

          {appContext?.user?.id}

          <CloseImg src="/img/Wallet/close.svg" />
        </MobileTitleRight>
      </MobileTitle>

      <Left>
        <Title>
          {t('header_profile_menu_withdrawal')}
        </Title>

        {!paymentMethod?.isCrypto && (isMobile ? breadcrumbs.length < 2: true) && (
          <WalletModalNewTabs
            activeTab='withdraw'
            onTabChange={onTabChange}
          />
        )}

        {(!!paymentMethod?.isCrypto || isMobile) && (
          <WalletBreadcrumbs
            breadcrumbs={breadcrumbs}
            onClick={handleBreadcrumbClick}
          />
        )}

        {!paymentMenthodsLoading && checkOnlyMobileCondition(isChoosingStep) && (
          <>
            {
              (!paymentMethod || !paymentMethod.isCrypto) && (
                <WalletPaymentSystem
                  onMethodSelect={handleSetPaymentMethod}
                  onSystemSelect={handleSetPaymentSystem}
                  cryptoPaymentMethods={cryptoPaymentMethods}
                  sortedPaymentSystems={sortedPaymentSystems}
                  selectedMethodName={paymentSystem?.name || ''}
                  currencyIso={currencyIso}
                  isWithdraw
                />
              )}

            {
              !!paymentMethod && paymentMethod.isCrypto && (
                <WalletPaymentCurrency
                  paymentMethod={paymentMethod}
                  onCurrencySelect={handleCurrencySelect}
                  selected={currency}
                  isWithdraw
                />
              )}
          </>
        )}

        {!!paymentMenthodsLoading && (
          <ContentLoader style={'block'}
            isOpen={true}
          />
        )}
      </Left>


      {
        checkOnlyMobileCondition(!isChoosingStep) && (
          <Right>
            <RightTitleRow>
              {
                (!!paymentMethod?.isCrypto || isMobile) && (
                  <StyledArrowBack color={COLORS.dark200}
                    onClick={handleBackClick}
                  />
                )
              }

              ID
              {' '}

              {appContext?.user?.id}

              <CloseImg src="/img/Wallet/close.svg"
                onClick={onClose}
              />
            </RightTitleRow>

            {!!fieldsLoading && (
              <ContentLoader style={'block'}
                isOpen={true}
              />
            )}

            {
              !!withdrawalInfoShowed && (
                <WithdrawInProcess>
                  {t('withdraw_in_process')}
                </WithdrawInProcess>

              )
            }

            {
              !withdrawalInfoShowed && !fieldsLoading && (
                <FormikProvider value={formik}>
                  <StyledForm>
                    <Fields>
                      <LimitText>
                        {t('withdraw_form_limit')}

                        {' '}

                        {isCryptoSelected ? cryptoMin : fiatMin}

-
                        {isCryptoSelected ? cryptoMax : fiatMax}
                      </LimitText>


                      <StyledInput
                        mask={Array.from({ length: 20 }, () => /^[0-9.]$/)}
                        prefixContent={(
                          <StyledCurrency color
                            currencyIso={isCryptoSelected ? currency.iso : currentSettings?.currencyIso}
                            size={20}
                          />
                        )}
                        name={'amount'}
                        disabled={sending}
                        validate={Validator.combine([Validator.required,
                          Validator.minMax(Number(isCryptoSelected ? cryptoMinAmount : fiatMinAmount),
                            Number(isCryptoSelected ? cryptoMaxAmount : fiatMaxAmount), t)])}
                        placeholder={t('withdraw_form_sum')}
                      />

                      <StyledExtraFields
                        fields={paymentFields}
                        sending={sending}
                        defaultCountry={appContext.countryByIp?.iso}
                        labelOnPlaceholder
                      />

                      {(paymentMethod?.isCrypto || paymentSystem?.isWalletRequired) && (
                        <StyledInput
                          name={'address'}
                          disabled={sending}
                          validate={Validator.required}
                          placeholder={!paymentMethod?.isCrypto ? t('withdraw_form_address') : t('deposit_modal_address')}
                          label={!paymentMethod?.isCrypto ? t('withdraw_form_address') : t('deposit_modal_address')}
                        />
                      )}

                      {!paymentMethod?.isCrypto && paymentSystem?.isCardDataRequired && (
                        <>
                          <StyledInput
                            name={'cardOwnerName'}
                            disabled={sending}
                            validate={Validator.required}
                            placeholder={t('withdraw_form_card_owner')}
                            label={t('withdraw_form_card_owner')}
                          />

                          <StyledInput
                            name={'cardExpiry'}
                            disabled={sending}
                            validate={Validator.required}
                            placeholder={t('withdraw_form_card_expiry') + ' 01/25'}
                            label={t('withdraw_form_card_expiry')}
                            mask="99/99"
                          />
                        </>
                      )}
                    </Fields>

                    <FormError error={error} />

                    <FixedBottomBox>
                      <Button
                        spinner={sending}
                        onClick={() => formik.handleSubmit()}
                        color="green"
                        type="button"
                      >
                        <ButtonImg src="/img/icons/wallet.svg"
                          alt=""
                        />

                        {t('profile_account_action_withdrawal')}
                      </Button>
                    </FixedBottomBox>
                  </StyledForm>
                </FormikProvider>
              )
            }
          </Right>
        )}

      <form id="post-form" />

      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={runtimeConfig.RECAPTCHA_KEY}
        style={{ visibility: "hidden" }}
      />
    </Root>
  )
}


const StyledInput = styled(Input)`
  margin-top: 16px;
`

const ButtonImg = styled.img`
  margin-right: 4px;
`

const LimitText = styled.p`
  margin-bottom: 16px;
  ${FONTS.a1};
  color: ${COLORS.dark200};
`

const StyledCurrency = styled(CurrencySvg)`
  margin-right: 8px;
  width: 20px;
  height: 20px;
  max-width: 20px;
  max-height: 20px;
  min-width: 20px;
  min-height: 20px;

  img {
    max-width: 20px;
    max-height: 20px;
    width: 20px;
    height: 20px;
  }
`

const StyledExtraFields = styled(PaymentFormExtraFields)`
  margin-bottom: 16px;
`

const WithdrawInProcess = styled.div`
  margin-top: 10px;
  width: 100%;
  ${FONTS.a0};
  color: ${COLORS.white};
`

export const StyledArrowBackMobile = styled(ArrowBackNewSvg)`
  margin-right: 11px;
  cursor: pointer;
`
