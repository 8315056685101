import classNames from 'classnames'
import { useTranslation } from 'next-i18next'

import styles from './index.module.scss'

interface Props {
  error?: any,
  touched?: boolean,
  className?: string,
  showErrorOnlyIfTouched?: boolean
}

export default function ErrorInput({ showErrorOnlyIfTouched = true, ...props }: Props) {
  const { t, i18n } = useTranslation()
  const { error, touched } = props
  if((showErrorOnlyIfTouched ? touched : true) && !!error) {
    return (
      <div className={classNames(styles.root, props.className)}>
        <img src='/img/icons/error.svg'
          alt=''
        />

        {i18n.exists(error) ? t(error) : error}
      </div>
    )
  }else{
    return (<></>)
  }
}
