import { ReactElement } from 'react'

import { FieldConfig, useField, useFormikContext } from 'formik'
import ErrorInput from 'components/ui/Inputs/components/ErrorInput'
import { CustomCheckbox } from 'components/ui/CustomCheckbox'
import cx from 'classnames'

import styles from './index.module.scss'
interface Props {
  label?: string | ReactElement
  disabled?: boolean
  biggerFont?: boolean
  size?: 'small' | 'normal' | 'large'
  className?: string
}

export const CheckBox = (props: Props & FieldConfig) => {
  const [field, meta] = useField(props)
  const { setFieldValue } = useFormikContext()
  const hasError = !!meta.error && meta.touched
  return (
    <div className={cx(styles.root, props.className, {
      [styles.large]: props.size === 'large'
    })}
    >
      <CustomCheckbox
        checked={field.value}
        disabled={props.disabled}
        label={props.label}
        onChange={(val) => setFieldValue(props.name, val)}
        color='#272832'
        biggerFont={props.biggerFont}
      />

      <ErrorInput {...meta} />
    </div>
  )
}
