export const SHADOWS = {
  blue: '/img/shadows/light-blue.png',
  red: '/img/shadows/light-red.png',
  yellow: '/img/shadows/light-yellow.png',
  violet: '/img/shadows/light-violet.png',
  white: '/img/shadows/light-white.png',
  green: '/img/shadows/light-green.png',
} as Record<string, string>

export const breakpoints = {
  sm: 425,
  md: 768,
  lg: 1024,
  xlg: 1280,
  xxlg: 1440,
}
