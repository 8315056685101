import { createContext } from 'react'

const noop = () => {}

export const table = createContext({
  cols: [],
  setCols: noop
})

export const head = createContext({
  orderBy: null,
  order: null,
  sort: noop
})

export const row = createContext({
  hover: null
})

export const select = createContext(null)
