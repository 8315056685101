import styled, { css } from 'styled-components'
import { COLORS, FONTS, BREAKPOINTS } from 'ui-kit/constants'
import { applyStyles, SIZES } from 'context/layoutContext'

export const SGameContent = styled.div`
  opacity: 0;
  position: relative;
  z-index: 2;
  transition: 0.3s;
  padding: 20px 8px;
  text-align: center;
  display: none;
  flex-direction: column;
  align-items: center;
  background: rgba(10, 10, 16, 0.8);
  backdrop-filter: blur(5px);
  width: 100%;
  height: 100%;

  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    padding: 12px 8px;
  }
`

export const SGameProvider = styled.div`
  ${FONTS.p2};
  max-width: 100%;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    margin-bottom: 0px;
  }
`

export const SGameName = styled.div`
  ${FONTS.h4};
  margin-bottom: 8px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    margin-bottom: 2px;
  }
`

export const SBtnWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`

export const SPlayBtn = styled.div`
  border-radius: 50%;
  background: ${COLORS.green};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${({ theme }) => `
    ${applyStyles([SIZES.MOBILE, SIZES.W512], theme.contentSize, css`
      height: 35px;
      width: 35px;
      margin-bottom: 4px;
    `)}

    ${applyStyles([SIZES.W688, SIZES.W1040], theme.contentSize, css`
      height: 70px;
      width: 70px;
      margin-bottom: 12px;
    `)}
  `};
`

export const SDemoLink = styled.div`
  text-decoration: underline;
  cursor: pointer;

  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 12px;
  }

  &:hover {
    text-decoration: none;
  }
`

export const SPictureWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.2s;
`

interface GameCardProps {
  severalRowsInMobile?: boolean;
  withoutMargin?: boolean;
}

export const SGameCard = styled.div<GameCardProps>`
  transition: 0.2s;
  overflow: hidden;
  border-radius: 8px;
  position: relative;
  background: ${COLORS.dark550};

  ${({ theme, severalRowsInMobile, withoutMargin }) => `
    ${applyStyles([SIZES.MOBILE], theme.contentSize, `
      height: calc(((100vw - 40px) / 3) * 1.125);
      width: calc((100vw - 40px) / 3);
      margin: ${withoutMargin ? '0' : '0 8px;'}
      margin-bottom: ${severalRowsInMobile ? '8px' : 0};
    `)}

    ${applyStyles([SIZES.W512], theme.contentSize, css`
      height: 165px;
      width: 147px;
      margin: ${withoutMargin ? '0' : '0 8px;'}
    `)}

    ${applyStyles([SIZES.W688, SIZES.W1040], theme.contentSize, css`
      height: 180px;
      width: 160px;
      margin: ${withoutMargin ? '0' : '0 8px;'}
    `)}
  `};

  &:hover ${SGameContent} {
    opacity: 1;
    display: flex;
  }

  &:hover ${SPictureWrapper} {
    opacity: 1;
    transform: scale(1.1);
  }
`


export const SFavoriteButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  border: none;
  outline: none;
  background: none;
  width: 16px;
  height: 16px;
  padding: 0;
  cursor: pointer;
`
