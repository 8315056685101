import { FC } from 'react'

import { useAppContext } from 'context/AppContext'

export const HiddenXs: FC = (props) => {
  const appContext = useAppContext()

  if (appContext.isMobile) return null

  return (
    <>
      {props.children}
    </>
  )
}

export default HiddenXs
