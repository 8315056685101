import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const SignoutIcon: React.FC<Props> = ({ className = '', ...props }) => {
  return (
    <StyledSvg width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path d="M5.9375 2H14.0625C15.4438 2 16.5 3.04 16.5 4.4V9.2H9.5125L11.3812 7.36C11.7062 7.04 11.7062 6.56 11.3812 6.24C11.0562 5.92 10.5688 5.92 10.2438 6.24L6.99375 9.44C6.66875 9.76 6.66875 10.24 6.99375 10.56L10.2438 13.76C10.5688 14.08 11.0562 14.08 11.3812 13.76C11.7062 13.44 11.7062 12.96 11.3812 12.64L9.5125 10.8H16.5V15.6C16.5 16.96 15.4438 18 14.0625 18H5.9375C4.55625 18 3.5 16.96 3.5 15.6V4.4C3.5 3.04 4.55625 2 5.9375 2Z"
        fill="#686A7B"
      />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  path {
    fill: currentColor;
  }
`
