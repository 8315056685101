import { FC, useEffect, useState } from 'react'

import { Form, FormikProvider, useFormik } from 'formik'
import { useTranslation } from 'next-i18next'
import clsx from 'clsx'
import Button from 'components/ui/Button'
import FormError from 'components/ui/Form/FormError'
import PromoCode from 'components/for_pages/Common/Promocode'
import { PaymentOptions } from 'components/Profile/Wallet/PaymentOptions'
import { CryptoWalletActions } from 'components/Profile/Wallet/CryptoWalletActions'
import { PaymentSeparator } from 'components/Profile/Wallet/PaymentSeparator'
import { PaymentDepositAmountField } from 'components/Profile/Wallet/PaymentDepositAmountField'
import { PaymentCurrencySelected } from 'components/Profile/Wallet/PaymentCurrencySelected'
import { PaymentMethodSelected } from 'components/Profile/Wallet/PaymentMethodSelected'
import { useAppContext } from 'context/AppContext'
import PaymentsRepository from 'data/repositories/PaymentsRepository'
import { ICurrency } from 'data/interfaces/ICurrency'
import { IPaymentSystem } from 'data/interfaces/IPaymentSystem'
import { IPaymentMethod } from 'data/interfaces/IPaymentMethod'
import Formatter from 'utils/formatter'
import Validator from 'utils/validator'
import BalanceTransactionRepository from 'data/repositories/BalanceTransationRepository'
import { BalanceTransactionType } from 'data/interfaces/IBalanceTransaction'
import { FIRST_DEPOSIT_BONUS_MOCK } from 'types/constants'
import { IPromoCodeBonus } from 'data/interfaces/IPromocode'
import { IDepositResponse } from 'data/interfaces/IPaymentDeposit'

import classes from './StepForm.module.scss'

interface Props {
  paymentMethod: IPaymentMethod
  paymentSystem: IPaymentSystem
  currency: ICurrency
  onPaymentMethodClick: () => void
  onCurrencyClick: () => void,
  onSubmit: (deposit: IDepositResponse) => void
  isFirstDeposit: boolean
}

export const StepForm: FC<Props> = ({
  paymentMethod,
  paymentSystem,
  currency,
  onPaymentMethodClick,
  onCurrencyClick,
  onSubmit,
  isFirstDeposit
}) => {
  const context = useAppContext()

  const { t } = useTranslation()

  const details = context.bonusBannerDetails

  const [sending, setSending] = useState(false)
  const [error, setError] = useState(null)
  const [bonuses, setBonuses] = useState<IPromoCodeBonus[]>([])
  const [promocodeInputVisible, togglePromocode] = useState(false)

  const currencyIso = currency?.iso
  const currentSettings = paymentSystem.settings.find(
    (i) => i.currencyIso === currencyIso,
  )


  const rate = currency?.toUsd

  useEffect(() => {
    context.updateCurrencies()
  }, [])

  useEffect(() => {
    if (isFirstDeposit) {
      setBonuses([details as IPromoCodeBonus || FIRST_DEPOSIT_BONUS_MOCK])
    }
  }, [])


  const handleSubmit = async (data) => {
    if (!data.amount || Number(data.amount) <= 0) {
      return
    }

    setError(null)
    setSending(true)

    try {
      const response = await PaymentsRepository.depositCrypto(
        currency.iso,
        paymentSystem.id,
        paymentSystem.systemCode,
        data.amount * (rate ?? 1),
      )

      onSubmit({ ...response, amount: data.amount / (rate ?? 1) })
    } catch (e) {
      setError(e)
    }

    setSending(false)
  }

  const formik = useFormik({
    initialValues: {
      amount: Math.ceil(Formatter.formatAmount(
        (currentSettings?.deposit?.minAmount ?? 0) * (rate || 1),
        currency?.iso,
      )),
    },
    onSubmit: handleSubmit,
  })

  return (
    <div className={classes.root}>
      <PaymentOptions>
        <PaymentMethodSelected
          method={paymentMethod}
          paymentSystem={paymentSystem}
          onClick={onPaymentMethodClick}
        />

        {currency && (
          <PaymentCurrencySelected
            currency={currency}
            onClick={onCurrencyClick}
          />
        )}
      </PaymentOptions>

      {/* <PaymentSeparator /> */}

      {/* <CryptoWalletActions /> */}

      <PaymentSeparator />

      <FormikProvider value={formik}>
        <Form className={classes.form}>
          <PaymentDepositAmountField
            name={'amount'}
            hasOptions
            optionCurrency={'$'}
            currency={'$'}
            disabled={sending}
            validate={Validator.required}
            bonus={bonuses[0]}
            placeholder={`Min: $${Math.ceil(Formatter.formatAmount(
              (currentSettings?.deposit?.minAmount ?? 0) * (rate || 1),
              currency?.iso,
            ))}`}
          />

          <div className={clsx(classes.bottom)}>
            <div
              className={classes.promo}
              onClick={() => togglePromocode(state => !state)}
            >
              <div className={classes.plus}>
                {promocodeInputVisible ? '-' : '+'}
              </div>

              <span>
                {t('wallet_form_promocode')}
              </span>
            </div>

            {rate && (
              <div className={classes.rate}>
                {formik.values.amount && (
                  <div>
                    {formik.values.amount ?? 0}

                    {' '}
                    USDT ≈

                    {' '}

                    {Formatter.formatAmount(
                      (formik.values.amount ?? 0) / rate,
                      currency?.iso,
                    )}

                    {' '}

                    {currency?.displayIso}
                  </div>
                )}

                <div>
                  1 USDT ≈
                  {' '}

                  {Formatter.formatAmount(rate, currency?.iso)}

                  {' '}

                  {currency?.displayIso}
                </div>
              </div>
            )}
          </div>

          <PromoCode
            bonuses={bonuses}
            onSetBonuses={setBonuses}
            currency={currency}
            bonusConvertationDisabled
            withBonuses
            isInputVisible={promocodeInputVisible}
          />

          <FormError error={error} />

          <Button
            type="button"
            size="normal"
            spinner={sending}
            background="payGradient500"
            className={classes.wallet}
            onClick={() => formik.handleSubmit()}
            disabled={!formik.values.amount || Number(formik.values.amount) <= 0}
          >
            <img src="/img/icons/wallet.svg"
              alt=""
            />

            {t('wallet_form_button_deposit')}
          </Button>
        </Form>
      </FormikProvider>
    </div>
  )
}
