import { FC } from 'react'

import { useTranslation } from 'next-i18next'
import partition from 'lodash/partition'
import { PaymentMethodList } from 'components/Profile/Wallet/PaymentMethodList'
import { PaymentMethodCryptoCard } from 'components/Profile/Wallet/PaymentMethodCryptoCard'
import { PaymentMethodCard } from 'components/Profile/Wallet/PaymentMethodCard'
import { IPaymentMethod } from 'data/interfaces/IPaymentMethod'
import { IPaymentSystem } from 'data/interfaces/IPaymentSystem'
import { runtimeConfig } from 'config/runtimeConfig'
import ContentLoader from 'components/ui/ContentLoader'

import styles from './StepMethod.module.scss'

interface Props {
  paymentMethods: IPaymentMethod[]
  loading: boolean
  onSelect: (
    paymentMethod: IPaymentMethod,
    paymentSystem?: IPaymentSystem,
  ) => void
}

export const StepMethod: FC<Props> = ({ paymentMethods, onSelect, loading }) => {
  const { t } = useTranslation()

  const [cryptoPaymentMethods, otherPaymentMethods] = partition(
    paymentMethods,
    (paymentMethod) => paymentMethod.isCrypto,
  )

  const sortedPaymentSystems = otherPaymentMethods.flatMap(method =>method.paymentSystems
    .map(system => ({ ...system, method, order: system.settings?.[0]?.order })))
    .sort((a, b) => a.order - b.order)

  return (
    <div className={styles.box}>
      <div className={styles.label}>
        {t('wallet_payment_method_choose')}
      </div>

      {
        !!loading && (
          <ContentLoader style={'block'}
            isOpen
          />
        )
      }

      {
        !loading && (
          <PaymentMethodList>
            <>
              {cryptoPaymentMethods.map((paymentMethod) => (
                <PaymentMethodCryptoCard
                  key={paymentMethod.title}
                  method={paymentMethod}
                  onClick={() => onSelect(paymentMethod)}
                />
              ))}

              {sortedPaymentSystems.map((paymentSystem) => (
                <PaymentMethodCard
                  key={paymentSystem.id}
                  label={paymentSystem.name}
                  icon={
                    paymentSystem.imageUrl
                      ? `${runtimeConfig.HOST}${paymentSystem.imageUrl}`
                      : undefined
                  }
                  onClick={() => onSelect(paymentSystem.method, paymentSystem)}
                />
              ))}
            </>
          </PaymentMethodList>
        )}
    </div>
  )
}
