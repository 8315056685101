import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { useField } from 'formik'
import { IField } from 'types/interfaces'
import { ICurrency } from 'data/interfaces/ICurrency'
import Formatter from 'utils/formatter'
import { MIN_BONUS_DEPOSIT_AMOUNT_USD } from 'types/constants'
import { Input } from 'ui-kit'
import styled from 'styled-components'
import CurrencySvg from 'components/svg/CurrencySvg/CurrencySvg'
import { COLORS, FONTS } from 'ui-kit/constants'
import { IBonus, PrivelegyIdent, PrivelegySubIdent, PropertyIdent } from 'data/interfaces/ILoyalty'

import { getBonusMaxAmount, getBonusMinAmount } from "../../../../utils/loyaltyUtils"

interface Props extends IField {
  isOpen?: boolean
  hasOptions?: boolean
  currency: string
  currencyObject?: ICurrency
  optionCurrency?: string,
  bonus?: IBonus,
  min?: string,
  max?: string,
  bonusConvertationDisabled?: boolean
}

export const PaymentDepositAmountField = (props: Props) => {
  const { t } = useTranslation()
  const [field, _, helpers] = useField(props)
  const bonusConvertationDisabled = props.bonusConvertationDisabled

  const { currencyObject, bonus } = props

  const relativeAmountBonus = props.bonus ? props?.bonus?.privileges?.[PrivelegyIdent.relativeAmount] : {}
  const fixedAmountBonus = props.bonus ? props?.bonus?.privileges?.[PrivelegyIdent.fixedAmount] : {}
  const minDepositAmount = getBonusMinAmount(bonus?.properties?.[PropertyIdent.minDeposit], currencyObject)

  const formattedOptions = currencyObject?.depositAmounts ? currencyObject?.depositAmounts.map(amount => {
    if (relativeAmountBonus && Array.isArray(relativeAmountBonus)) {
      const amountInUsd = bonusConvertationDisabled ? Number(amount) : Number(Formatter.formatAmount(Number(amount) * currencyObject?.toUsd, currencyObject?.iso)) || 0

      const bonusWithCurrentOptionRange = relativeAmountBonus.find(bonus => bonus?.[PrivelegySubIdent?.range]?.minAmount <= amountInUsd && amountInUsd < bonus?.[PrivelegySubIdent?.range]?.maxAmount)

      if (bonusWithCurrentOptionRange) {
        return {
          label: amount,
          value: amount,
          percent: bonusWithCurrentOptionRange.percent
        }
      }
    }

    return {
      label: amount,
      value: amount
    }
  }) : []


  const calculateBonusAmount = () => {
    const value = Number(field.value)

    if (relativeAmountBonus && !Array.isArray(relativeAmountBonus)) {
      let minAmount, maxAmount
      const multiplier = Number(relativeAmountBonus.percent) / 100
      const formattedMaxAmount = getBonusMaxAmount(relativeAmountBonus, currencyObject)

      if (!currencyObject || bonusConvertationDisabled || formattedMaxAmount.inCurrentCurrency) {
        minAmount = minDepositAmount.value
        maxAmount = formattedMaxAmount.value
      } else {
        minAmount = Number(Formatter.formatAmount(minDepositAmount.value / currencyObject?.toUsd, currencyObject?.iso))
        maxAmount = Number(Formatter.formatAmount((Number(formattedMaxAmount.value)) / currencyObject?.toUsd, currencyObject?.iso))
      }

      if (value * multiplier > maxAmount) {
        return maxAmount
      }

      if (value < minAmount) {
        return 0
      }

      return value * multiplier
    }

    if (relativeAmountBonus && Array.isArray(relativeAmountBonus)) {
      const valueInUsd = bonusConvertationDisabled ? value : Number(Formatter.formatAmount((value) * currencyObject?.toUsd, currencyObject?.iso)) || 0

      const currentBonus = relativeAmountBonus.find((bonus, index) => {
        return (valueInUsd < bonus[PrivelegySubIdent.range].maxAmount && valueInUsd >= bonus[PrivelegySubIdent.range].minAmount) ||
          index === relativeAmountBonus.length - 1 && valueInUsd > bonus[PrivelegySubIdent.range].maxAmount
      })

      if (!currentBonus) {
        return 0
      }

      const formattedCurrentBonusMaxAmount =  getBonusMaxAmount(currentBonus, currencyObject)
      let minAmount, maxAmount
      const multiplier = Number(currentBonus.percent) / 100

      const formattedLastItemInRangeAmount = getBonusMaxAmount(relativeAmountBonus[relativeAmountBonus.length - 1], currencyObject)

      if (formattedLastItemInRangeAmount.inCurrentCurrency && value * multiplier > formattedLastItemInRangeAmount.value) {
        return formattedLastItemInRangeAmount.value
      }

      if (valueInUsd * multiplier > relativeAmountBonus[relativeAmountBonus.length - 1].maxAmount) {
        return Number(Formatter.formatAmount((Number(relativeAmountBonus[relativeAmountBonus.length - 1].maxAmount)) / currencyObject?.toUsd, currencyObject?.iso))
      }

      if (!currencyObject) {
        minAmount = minDepositAmount.value
        maxAmount = Number(currentBonus.maxAmount)
      } else {
        minAmount = minDepositAmount.inCurrentCurrency ?
          minDepositAmount.value :
          Number(Formatter.formatAmount(minDepositAmount.value / currencyObject?.toUsd, currencyObject?.iso))
        maxAmount = formattedCurrentBonusMaxAmount.inCurrentCurrency ?
          formattedCurrentBonusMaxAmount.value :
          Number(Formatter.formatAmount((Number(currentBonus.maxAmount)) / currencyObject?.toUsd, currencyObject?.iso))
      }

      if (value * multiplier > maxAmount) {
        return maxAmount
      }

      if (value < minAmount) {
        return 0
      }


      return value * multiplier
    }

    if (fixedAmountBonus) {
      return fixedAmountBonus.amount
    }
  }

  const calculatedBonusAmount = calculateBonusAmount()

  return (
    <PaymentDepositAmountFieldBox>
      {
        !!props.min && !!props.max && (
          <LimitText>
            {t('deposit_modal_min')}
            :

            {' '}

            {props.min}

            {' '}

            |
            {' '}

            {t('deposit_modal_max')}
            :

            {' '}

            {props.max}
          </LimitText>
        )}

      <StyledInput
        mask={Array.from({ length: 20 }, () => /^[0-9.]$/)}
        prefixContent={(
          <StyledCurrency color
            currencyIso={props.currency === '$' ? 'usd' : props.currency}
            size={20}
          />
        )}
        {...props}
        placeholder={t('withdraw_form_sum')}
        suffixContent={!!bonus ? (
          <Suffix className={classNames({ red: calculatedBonusAmount === 0 })}>
            <span>

            +
              {Math.round(calculatedBonusAmount)}

              {' '}

              {props.currency}
            </span>

            <GiftImg src={calculatedBonusAmount === 0 ? "/img/bonuses/gift-red.svg" : "/img/bonuses/gift-green.svg"} />
          </Suffix>
        ) : undefined}
      />

      {props.hasOptions && (
        <OptionsBox>
          {formattedOptions.map((item, index) => (
            <Option className={classNames({ active: item.value === field.value })}
              key={index}
              onClick={() => helpers.setValue(item.value)}
            >
              {item.label}

              {!!item.percent && (
                <OptionPercent>
                  {item.percent}
                  %
                </OptionPercent>
              )}
            </Option>
          )
          )}
        </OptionsBox>
      )}
    </PaymentDepositAmountFieldBox>
  )
}

const PaymentDepositAmountFieldBox = styled.div`
  margin-bottom: 16px;
`

const StyledInput = styled(Input)`
  margin-top: 16px;
`

const LimitText = styled.p`
  margin-bottom: 16px;
  ${FONTS.a1};
  color: ${COLORS.dark200};
`

const StyledCurrency = styled(CurrencySvg)`
  margin-right: 8px;
  width: 20px;
  height: 20px;
  max-width: 20px;
  max-height: 20px;
  min-width: 20px;
  min-height: 20px;

  img {
    max-width: 20px;
    max-height: 20px;
    width: 20px;
    height: 20px;
  }
`

const OptionsBox = styled.div`
  margin-top: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`

const Option = styled.div`
  height: 32px;
  padding: 0 17px;
  display: flex;
  align-items: center;
  ${FONTS.a1};
  color: ${COLORS.dark200};
  background: ${COLORS.dark550};
  border-radius: 4px;
  cursor: pointer;
  flex: 1;
  justify-content: center;
  position: relative;

  &:hover {
    background: ${COLORS.dark400};
  }

  &.active {
    background: #3E7AFF;
    color: white;
  }
`

const OptionPercent = styled.div`
  border-radius: 0px 0px 0px 6.341px;
  background: #FFD747;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 10px;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  color: #14151B;
`

const Suffix = styled.div`
  height: calc(100% + 4px);
  width: 170px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  right: -2px;
  top: -2px;
  padding-right: 16px;
  border: double 2px transparent;
  border-radius: 4px;
  background-image: linear-gradient(${COLORS.dark550} 100%, #293227 100%),
  linear-gradient(90deg, transparent 0%, ${COLORS.dark550} 45%, #21B026 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  font-weight: 700;
  font-size: 12px;
  color: #A8FF38;

  ${StyledInput} input:focus ~ & {
    background-image: unset;
  }

  ${StyledInput} > div.error & {
    background-image: unset;
  }

  span {
    background: linear-gradient(270deg, #17AA25 -6.01%, #76DC26 114.87%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
  }

  &.red {
    color: ${COLORS.red};
    background-image: linear-gradient(${COLORS.dark550} 100%, #293227 100%),
    linear-gradient(90deg, transparent 0%, ${COLORS.dark550} 45%, ${COLORS.redLight} 100%);
    background-origin: border-box;
    background-clip: padding-box, border-box;

    ${StyledInput} input:focus ~ & {
      background-image: unset;
    }

    span {
      background: linear-gradient(270deg, ${COLORS.redLight} -6.01%, ${COLORS.red} 114.87%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
`

const GiftImg = styled.img`
  width: 20px;
  height: 20px;
`
