import {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
  FC,
} from 'react'

import {
  BonusDepositShowMode,
  CookiesType,
  LocalStorageTypes,
  ModalType,
  ProfileModalType,
  SnackbarType,
} from 'types/enums'
import ReactModal from 'react-modal'
import UserRepository from 'data/repositories/UserRepository'
import Cookies from 'js-cookie'
import { ICurrency } from 'data/interfaces/ICurrency'
import InfoRepository from 'data/repositories/InfoRepository'
import IUser from 'data/interfaces/IUser'
import {
  IBonusBannerDetails,
  IModalProfileStackItem,
  SnackbarData,
} from 'types/interfaces'
import { CookiesLifeTime, Timers } from 'types/constants'
import PromoCodeRepository from 'data/repositories/PromoCodeRepository'
import UserUtils from 'utils/user'
import { IBanner } from 'data/interfaces/IBanner'
import BannerRepository from 'data/repositories/BannerRepository'
import { addHours } from 'date-fns'
import { runtimeConfig } from 'config/runtimeConfig'
import { IPaymentMethod } from 'data/interfaces/IPaymentMethod'
import { ICountry } from 'data/interfaces/ICountry'
import { useTranslation } from 'next-i18next'
import { getIsMobile } from 'utils/mobile'
import { Subject } from 'rxjs'
import { Routes } from 'types/routes'
import { useRouter } from 'next/router'
import { RESTRICTED_COUNTRIES } from 'utils'
import { ISocialService } from 'data/interfaces/ISocialService'
import SocialServiceRepository from 'data/repositories/SocialServiceRepository'
import { analytics } from 'utils/analytics'
import { IGame } from 'data/interfaces/IGame'
import GameListRepository from 'data/repositories/GameListRepository'
import PaymentMethodRepository from 'data/repositories/PaymentMethodRepository'
import _ from 'lodash'

import ContentLoader from '../components/ui/ContentLoader'


interface IState {
  isMobile: boolean
  isDesktop: boolean
  auth: boolean
  token: string
  initialLoaded: boolean
  modalArguments?: any
  countryByIp: ICountry | null
  modal: ModalType | ProfileModalType | null
  lastProfileModal?: IModalProfileStackItem
  showModal: (type: ModalType | ProfileModalType, data?: any) => void
  showModalProfile: (
    type: ProfileModalType,
    data?: any,
    skipStack?: boolean,
  ) => void
  goBackModalProfile: () => void
  hideModal: () => void
  bottomSheet: ModalType | ProfileModalType | null
  showBottomSheet: (type: ModalType | ProfileModalType, data?: any) => void
  hideBottomSheet: () => void
  setToken: (token) => void
  logout: () => void
  updateUserFromCookies: () => void
  user: IUser
  updateUser: (data: Partial<IUser>) => void
  isUserJustRegistered: boolean
  showBonus: boolean
  bonusShowMode: BonusDepositShowMode | null,
  availableSocials: ISocialService[],
  top20Games: IGame[],
  withdrawPaymentMethods: IPaymentMethod[],
  depositPaymentMethods: IPaymentMethod[],
  setBonusShowMode: (show: BonusDepositShowMode) => void
  bonusBannerDetails: IBonusBannerDetails | null
  fetchDefaultCurrency: () => Promise<ICurrency>
  updateCurrencies: () => void
  updateCurrency: (currency: ICurrency) => void
  currencies: ICurrency[]
  defaultCurrency: ICurrency | null
  paymentMethodsDeposit: IPaymentMethod[]
  paymentMethodsWithdraw: IPaymentMethod[]
  banners?: IBanner[]
  snackbar: SnackbarData | null
  showSnackbar: (text: string, type: SnackbarType) => void
  openSupport: () => void
  changeLanguage: (code: string) => void
  onChangeMainCurrency: (code: string) => void
  authUpdateState$: Subject<boolean>
  langChangedState$: Subject<string>
  mainCurrencyChangedState$: Subject<string>
  setIsUserJustRegistered: (isUserJustRegistered: boolean) => void
  walletModalActiveTab: 'deposit' | 'withdraw'
  setWalletModalActiveTab: (tab: 'deposit' | 'withdraw') => void
}

const authUpdateState$ = new Subject<boolean>()
const langChangedState$ = new Subject<string>()
const mainCurrencyChangedState$ = new Subject<string>()
const defaultValue: IState = {
  countryByIp: null,
  modalArguments: null,
  initialLoaded: false,
  isMobile: false,
  isDesktop: true,
  modal: null,
  bottomSheet: null,
  auth: false,
  user: null,
  isUserJustRegistered: false,
  lastProfileModal: null,
  token: null,
  banners: [],
  showModal: (type, data) => null,
  showModalProfile: (type, data, skipStack) => null,
  goBackModalProfile: () => null,
  hideModal: () => null,
  showBottomSheet: (type, data) => null,
  hideBottomSheet: () => null,
  setToken: (token) => null,
  logout: () => null,
  updateUserFromCookies: () => null,
  updateCurrencies: () => null,
  updateCurrency: () => null,
  fetchDefaultCurrency: async () => null,
  showBonus: false,
  bonusShowMode: null,
  availableSocials: [],
  setBonusShowMode: (show) => null,
  bonusBannerDetails: null,
  currencies: [],
  defaultCurrency: null,
  paymentMethodsDeposit: [],
  paymentMethodsWithdraw: [],
  snackbar: null,
  showSnackbar: (text, type) => null,
  openSupport: () => null,
  changeLanguage: (code: string) => null,
  onChangeMainCurrency: (currencyIso: string) => null,
  authUpdateState$,
  langChangedState$,
  mainCurrencyChangedState$,
}

const ModalsBottomSheet = [
  ProfileModalType.withdraw,
  ProfileModalType.walletNew,
  ProfileModalType.exchange,
  ProfileModalType.buyCrypto,
  ModalType.login,
  ModalType.registration,
  ModalType.registrationPhone,
  ModalType.registrationSuccess,
  ModalType.passwordRecovery,
  ModalType.passwordReset,
  ModalType.faLogin,
  ModalType.fortune,
  ModalType.profileBurger,
  ModalType.bonus,
  ModalType.astroPay,
  ModalType.restrictedCountry
]

const AppContext = createContext<IState>(defaultValue)

interface Props {
  isMobile: boolean
  token?: string
}

export const AppProvider: FC<Props> = (props) => {
  const [modal, setModal] = useState<ModalType | ProfileModalType | null>(null)
  const [bottomSheet, setBottomSheet] = useState<
    ModalType | ProfileModalType | null
  >(null)
  const [modalArguments, setModalArguments] = useState<
    ModalType | ProfileModalType | null
  >(null)
  const { t, i18n } = useTranslation()
  const router = useRouter()
  const langInitdRef = useRef(false)
  const modalRef = useRef<ModalType | ProfileModalType | null>(null)
  const bottomSheetRef = useRef<ModalType | ProfileModalType | null>(null)
  const [user, setUser] = useState<IUser | null>(null)
  const [auth, setAuth] = useState<boolean | null>(null)
  const [showBonus, setShowBonus] = useState<boolean>(false)
  const [bonusShowMode, setBonusShowMode] =
    useState<BonusDepositShowMode | null>(null)
  const [availableSocials, setAvailableSocials] = useState<ISocialService[]>([])
  const [bonusBannerDetails, setBonusBannerDetails] =
    useState<IBonusBannerDetails>(null)
  const [currencies, setCurrencies] = useState<ICurrency[]>([])
  const [defaultCurrency, setDefaultCurrency] = useState<ICurrency | null>(null)
  const [paymentMethodsDeposit, setPaymentMethodsDeposit] = useState<
    IPaymentMethod[]
  >([])
  const [paymentMethodsWithdraw, setPaymentMethodsWithdraw] = useState<
    IPaymentMethod[]
  >([])
  const [banners, setBanners] = useState<IBanner[]>([])
  const [snackbar, setSnackbar] = useState<SnackbarData | null>(null)
  const [modalProfileStack, setModalProfileStack] = useState<
    IModalProfileStackItem[]
  >([])
  const [countryByIp, setCountryByIp] = useState<ICountry | null>(null)
  const [userLoaded, setUserLoaded] = useState<boolean>(false)
  const [isUserJustRegistered, setIsUserJustRegistered] = useState(false)
  const [infoLoaded, setInfoLoaded] = useState<boolean>(false)
  const [top20Games, setTop20Games] = useState<IGame[]>([])
  const [withdrawPaymentMethods, setWithdrawPaymentMethods] = useState<IPaymentMethod[]>([])
  const [depositPaymentMethods, setDepositPaymentMethods] = useState<IPaymentMethod[]>([])
  const [walletModalActiveTab, setWalletModalActiveTab] = useState<'deposit' | 'withdraw'>('deposit')

  useEffect(() => {
    modalRef.current = modal
  }, [modal])

  useEffect(() => {
    bottomSheetRef.current = bottomSheet
  }, [bottomSheet])

  const value: IState = {
    ...defaultValue,
    isMobile: props.isMobile,
    isDesktop: !props.isMobile,
    auth,
    modal,
    bottomSheet,
    modalArguments,
    lastProfileModal:
      modalProfileStack.length > 0
        ? modalProfileStack[modalProfileStack.length - 1]
        : null,
    currencies,
    user,
    isUserJustRegistered,
    snackbar,
    token: props.token,
    banners,
    defaultCurrency,
    paymentMethodsDeposit,
    paymentMethodsWithdraw,
    countryByIp,
    availableSocials,
    top20Games,
    initialLoaded: userLoaded && infoLoaded,
    withdrawPaymentMethods,
    depositPaymentMethods,
    showModal: (type, props: any) => {
      showModal(type, props)
    },
    showModalProfile: (type, args: any, skipStack?: boolean) => {
      showModal(type, args)

      if (
        modal &&
        !(props.isMobile && ModalsBottomSheet.includes(type)) &&
        Object.values(ProfileModalType).includes(modal as ProfileModalType) &&
        !skipStack
      ) {
        setModalProfileStack((stack) => [
          ...stack,
          {
            type: modal as ProfileModalType,
            args,
          } as IModalProfileStackItem,
        ])
      }
    },
    goBackModalProfile: () => {
      if (modalProfileStack.length > 0) {
        const last = modalProfileStack[modalProfileStack.length - 1]
        setModalProfileStack(
          [...modalProfileStack].slice(0, modalProfileStack.length - 1),
        )
        showModal(last.type, last.args)
      } else {
        hideModal()
      }
    },
    hideModal: () => {
      hideModal()
    },
    showBottomSheet: (type, props: any) => {
      showBottomSheet(type, props)
    },
    hideBottomSheet: () => {
      hideBottomSheet()
    },
    setToken: (token: string) => {
      Cookies.set(CookiesType.accessToken, token, {
        expires: CookiesLifeTime.accessToken,
      })
      setAuth(true)
      authUpdateState$.next(true)
    },
    logout: () => {
      Cookies.remove(CookiesType.accessToken)
      setAuth(false)
      setUser(null)

      authUpdateState$.next(false)
    },
    async updateUserFromCookies() {
      return updateUserDetails()
    },
    updateUser: (data) => {
      setUser(state => _.merge({ ...state }, data))
    },
    showBonus,
    bonusShowMode,
    bonusBannerDetails,
    setBonusShowMode(mode) {
      let newMode = mode

      if (router.pathname === Routes.sport) {
        if (
          mode === BonusDepositShowMode.Spoiler &&
          bonusShowMode === BonusDepositShowMode.Gift
        ) {
          showModal(ModalType.bonus)
        } else if (
          mode === BonusDepositShowMode.Spoiler &&
          bonusShowMode === BonusDepositShowMode.Modal
        ) {
          newMode = BonusDepositShowMode.Gift
        } else if (mode === BonusDepositShowMode.Spoiler) {
          newMode = BonusDepositShowMode.Gift
        }
      }
      setBonusShowMode(newMode)
      Cookies.set(CookiesType.bonusDepositShowMode, newMode, {
        expires: CookiesLifeTime.bonusDepositShowMode,
      })
    },

    showSnackbar: (text, type: SnackbarType) => {
      setSnackbar({ text, type })
      setTimeout(() => {
        setSnackbar(null)
      }, 2000)
    },
    fetchDefaultCurrency: async (): Promise<ICurrency> => {
      if (defaultCurrency) {
        return defaultCurrency
      }
      const res = await InfoRepository.getCurrencyByCountry()
      setDefaultCurrency(res)
      return res
    },
    updateCurrencies: () => {
      InfoRepository.getCurrencies().then((i) => setCurrencies(i))
    },
    updateCurrency: (currency: Partial<ICurrency>) => {
      const existedCurrencyIndex = currencies.findIndex(c => c.iso === currency.iso)

      setCurrencies([...currencies.slice(0, existedCurrencyIndex), { ...currencies[existedCurrencyIndex], ...currency }, ...currencies.slice(existedCurrencyIndex + 1)])
    },
    openSupport: () => {
      if (window.Tawk_API) {
        window.Tawk_API.toggle()

        hideModal()
      }
    },

    changeLanguage: (code: string) => {
      Cookies.set(CookiesType.NEXT_LOCALE, code, {
        expires: CookiesLifeTime.NEXT_LOCALE,
      })
    },
    onChangeMainCurrency: (currencyIso: string) => {
      mainCurrencyChangedState$.next(currencyIso)
    },

    setIsUserJustRegistered,
    walletModalActiveTab,
    setWalletModalActiveTab
  }

  useEffect(() => {
    if (props.token) {
      setAuth(true)
    } else {
      setAuth(false)
    }
  }, [props.token])

  useEffect(() => {
    if (!Cookies.get(CookiesType.firstVisitAt)) {
      Cookies.set(CookiesType.firstVisitAt, new Date().toISOString(), {
        expires: CookiesLifeTime.firstVisitAt,
      })
    }

    if (Cookies.get(CookiesType.bonusDepositShowMode)) {
      setBonusShowMode(
        Cookies.get(CookiesType.bonusDepositShowMode) as BonusDepositShowMode,
      )
    }
  }, [])

  useEffect(() => {
    if (countryByIp?.iso && RESTRICTED_COUNTRIES.includes(countryByIp.iso.toUpperCase())) {
      showModal(ModalType.restrictedCountry)
    }
  }, [countryByIp])

  useEffect(() => {
    if (userLoaded || infoLoaded) {
      const globalLoader = document.getElementById('global-page-loader')
      if (!globalLoader) return

      setTimeout(() => {
        globalLoader.style.opacity = '0'
        setTimeout(() => (globalLoader.style.display = 'none'), 500)
      }, 500)
    }
  }, [userLoaded, infoLoaded])

  useEffect(() => {
    if (!langInitdRef.current) {
      langInitdRef.current = true

      return
    }
    BannerRepository.fetchBanners()
      .then((i) => setBanners(i))
      .catch(() => {})
  }, [i18n.language])

  useEffect(() => {
    const authorized = Cookies.get(CookiesType.accessToken)

    if (!authorized) {
      localStorage.removeItem(LocalStorageTypes.registeredWithSocials)

      return
    }

    const socialType = localStorage.getItem(LocalStorageTypes.registeredWithSocials)

    if (!socialType || !['facebook', 'google', 'instragram', 'telegram'].includes(socialType)) {
      return
    }

    analytics.dataLayerPush({
      event: 'formSuccess',
      formName: 'registrationSuccess',
      registrationType:'social',
      socialType
    })

    localStorage.removeItem(LocalStorageTypes.registeredWithSocials)
  }, [])

  useEffect(() => {
    const promises = []

    if (props.token) {
      promises.push(
        updateUserDetails().catch(() => {
          setAuth(false)
        }),
      )
    } else {
      setUserLoaded(true)
    }

    promises.push(
      InfoRepository.getCurrencies()
        .then((i) => setCurrencies(i))
        .catch(() => {}),
    )

    promises.push(
      InfoRepository.getCountryByIp()
        .then((i) => setCountryByIp(i))
        .catch(() => {}),
    )

    promises.push(
      BannerRepository.fetchBanners()
        .then((i) => setBanners(i))
        .catch(() => {}),
    )

    Promise.all(promises).then((i) => setTimeout(() => setInfoLoaded(true), 1))
  }, [])

  useEffect( () => {
    SocialServiceRepository.fetchServices().then(socials => setAvailableSocials(socials ?? []))
  }, [])

  useEffect(() => {
    const fetchTopGames = async () => {
      const response = await GameListRepository.fetchPopularGames(1, 20, false, props.isMobile)

      setTop20Games(response.data)
    }

    fetchTopGames()
  }, [])

  useEffect(() => {
    if (!auth) {
      return
    }

    const fetchDepositPaymentMethods = async () => {
      const response = await PaymentMethodRepository.fetchDeposit()

      setDepositPaymentMethods(response)
    }

    const fetchWithdrawPaymentMethods = async () => {
      const response = await PaymentMethodRepository.fetchWithdraw()

      setWithdrawPaymentMethods(response)
    }

    fetchDepositPaymentMethods()
    fetchWithdrawPaymentMethods()
  }, [auth, user?.currencyIso])

  useEffect(() => {
    if (router.query.deposit === '1' && auth) {
      showModal(ProfileModalType.wallet)
    }

    if (router.query.deposit === '1' && !auth) {
      showModal(ModalType.login)
    }
  }, [auth])

  useEffect(() => {
    if (user?.id) {
      try {
        window.OneSignal.push(function() {
          window.OneSignal.setExternalUserId(user.id)
        })
      } catch (error) {
        console.error(error)
      }
    }
  }, [user])

  const showModal = (type: ModalType | ProfileModalType, args?: any) => {
    let isRestrictedCountryModalOpened = false

    if (props.isMobile && ModalsBottomSheet.includes(type)) {
      showBottomSheet(type, args)

      return
    }

    ReactModal.setAppElement('body')
    setModalArguments(args)
    setModal(state => {
      if (state === ModalType.restrictedCountry) {
        isRestrictedCountryModalOpened = true

        return state
      }

      return type
    })

    if (!isRestrictedCountryModalOpened) {
      return
    }

    if (bottomSheet) {
      hideBottomSheet()
    }
  }

  const hideModal = () => {
    if (bottomSheet) {
      hideBottomSheet()
      return
    }
    setModal(null)
    setModalProfileStack([])
  }

  const showBottomSheet = (type: ModalType | ProfileModalType, props?: any) => {
    let isRestrictedCountryModalOpened = false

    ReactModal.setAppElement('body')

    setBottomSheet(state => {
      if (state === ModalType.restrictedCountry) {
        isRestrictedCountryModalOpened = true

        return state
      }

      return type
    })

    if (isRestrictedCountryModalOpened) {
      return
    }

    setModalArguments(props)
  }

  const hideBottomSheet = () => {
    setBottomSheet(null)
  }

  const updateUserDetails = async () => {
    try {
      const res = await UserRepository.getUser()
      console.log('user: ', res)
      setUser(res)
    } catch (e) {}
    setUserLoaded(true)
  }

  return (
    <AppContext.Provider value={value}>
      {props.children}
    </AppContext.Provider>
  )
}

export const useAppContext = () => {
  return useContext(AppContext)
}
