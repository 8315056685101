import React, { useRef, useState } from 'react'

import { CopySvg } from 'components/svg/CopySvg'
import styled from 'styled-components'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'

import { COLORS } from './constants'

interface Props {
  value: string,
  className?: string
}

export const CopyIcon: React.FC<Props> = ({ className = '', value }) => {
  const { t } = useTranslation()

  const [messageVisible, toggleCopiedMessage] = useState(false)

  const timerRef = useRef<number>(null)

  const handleClick = () => {
    navigator.clipboard.writeText(value)

    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    toggleCopiedMessage(true)

    timerRef.current = setTimeout(() => {
      toggleCopiedMessage(false)
    }, 3000)
  }

  return (
    <CopyIconBox className={className}>
      <StyledIcon onClick={handleClick} />

      <CopiedText className={classNames({ hidden: !messageVisible })}>
        {t('text_copied')}
      </CopiedText>
    </CopyIconBox>
  )
}

const CopyIconBox = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: relative;
`

const StyledIcon = styled(CopySvg)`
  opacity: 0.4;

  &:hover {
    opacity: 1;
  }
`

const CopiedText = styled.p`
  position: absolute;
  bottom: -4px;
  right: 0px;
  font-size: 12px;
  transform: translateY(100%);
  opacity: 1;
  color: ${COLORS.dark200};
  transition: opacity 0.3s ease-in-out;

  &.hidden {
    opacity: 0;
  }
`
