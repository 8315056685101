import React from 'react'

import { IPaymentMethod } from 'data/interfaces/IPaymentMethod'
import { useAppContext } from 'context/AppContext'
import { IPaymentSystem } from 'data/interfaces/IPaymentSystem'
import CurrencySvg from 'components/svg/CurrencySvg/CurrencySvg'
import classNames from 'classnames'
import styled from 'styled-components'
import { COLORS } from 'ui-kit/constants'
import { useTranslation } from 'next-i18next'

import { CardBox, CardImg, CardMinAmount, CardName, CardsBox, WalletPaymentSystemBox } from './styles'

interface Props {
  onMethodSelect: (method: IPaymentMethod) => void,
  onSystemSelect: (system: IPaymentSystem) => void,
  cryptoPaymentMethods: IPaymentMethod[],
  sortedPaymentSystems: IPaymentSystem[],
  selectedMethodName: string,
  currencyIso: string,
  isWithdraw?: boolean
}

export const WalletPaymentSystem: React.FC<Props> = ({ onMethodSelect, onSystemSelect, cryptoPaymentMethods, sortedPaymentSystems, selectedMethodName, currencyIso, isWithdraw }) => {
  const { t } = useTranslation()

  return (
    <WalletPaymentSystemBox>
      <CardsBox>
        {sortedPaymentSystems.map((paymentMethod) => {
          const min = `${paymentMethod?.settings[0]?.[isWithdraw ? 'withdraw' : 'deposit']?.minAmount ?? 0} ${currencyIso}`

          return (
            <CardBox
              className={classNames({ active: selectedMethodName === paymentMethod.name })}
              key={paymentMethod.id}
              onClick={() => onSystemSelect(paymentMethod)}
            >
              <CardImg src={paymentMethod.imageUrl} />

              <CardName>
                {paymentMethod.name}
              </CardName>

              <CardMinAmount>
                {t('deposit_modal_min')}
:

                {' '}

                {min}
              </CardMinAmount>
            </CardBox>
          )}
        )}


        {cryptoPaymentMethods.map((paymentMethod) => (
          <CryptoCard key={paymentMethod.title}
            title={paymentMethod.title}
            paymentSystems={paymentMethod.paymentSystems}
            onClick={() => onMethodSelect(paymentMethod)}
            isWithdraw={isWithdraw}
          />
        ))}
      </CardsBox>
    </WalletPaymentSystemBox>
  )
}

interface CryptoCardProps {
  onClick: () => void,
  paymentSystems: IPaymentSystem[],
  title: string,
  isWithdraw?: boolean
}

export const CryptoCard: React.FC<CryptoCardProps> = ({ onClick, title, paymentSystems, isWithdraw }) => {
  const appContext = useAppContext()
  const { t } = useTranslation()

  const currenciesIso = paymentSystems
    .map((paymentSystem) =>
      paymentSystem.settings.map((settings) => settings.currencyIso),
    )
    .flat()

  const currencies = appContext.currencies.filter((currency) =>
    currenciesIso.includes(currency.iso),
  )

  const minAmounts = paymentSystems
    .map((paymentSystem) =>
      paymentSystem.settings.map((setting) => {
        const currencyFromList = appContext.currencies.find(c => c.iso === setting.currencyIso)

        const fieldName = isWithdraw ? 'withdraw' : 'deposit'

        if (!currencyFromList || !setting[fieldName]?.minAmount) {
          return false
        }

        return setting[fieldName]?.minAmount * currencyFromList?.toUsd
      }),
    )
    .flat()
    .filter(Boolean).sort()

  return (
    <CardBox
      key={title}
      onClick={onClick}
    >
      <CryptoImagesBox>
        {currencies.slice(0, 3).map(currency => (
          <CryptoImageBox
            key={currency.iso}
          >
            <StyledCurencySvg
              currencyIso={currency.iso}
              color
            />
          </CryptoImageBox>
        ))}

        <CryptoImageBox>
          {currencies.length - 3}
+
        </CryptoImageBox>
      </CryptoImagesBox>

      <CardName>
        {title}
      </CardName>

      <CardMinAmount>
        {t('deposit_modal_min')}
:

        {' '}

        $
        {Math.ceil(Number(minAmounts[0]))}
      </CardMinAmount>
    </CardBox>
  )
}

const CryptoImagesBox = styled.div`
  margin-bottom: 4px;
  width: 48px;
  height: 48px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 6px;
`

const CryptoImageBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.dark200};
  border-radius: 3px;
  font-weight: 500;
  font-size: 8px;
  line-height: 9px;
  color: #9EA3B1;
`

const StyledCurencySvg = styled(CurrencySvg)`
  width: 13px;
  height: 13px;
  min-width: 13px;
  min-height: 13px;
`
