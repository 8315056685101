import { FC } from 'react'

import { useRouter } from 'next/router'
import { Button } from 'ui-kit'
import ProfileModalLayout from 'components/Profile/layout/ProfileModalLayout'
import ProfileModalBody from 'components/Profile/layout/ProfileModalBody'
import BottomSheetLayout from 'components/layout/BottomSheetLayout'
import BottomSheetBody from 'components/layout/BottomSheetBody'
import { useTranslation } from 'next-i18next'
import { Routes } from 'types/routes'
import { useAppContext } from 'context/AppContext'

import {
  StyledTitle,
  StyledImg,
  StyledText,
  StyledFooter,
  StyledContent,
  StyledButton
} from './styles'


interface Props {
  isBottomSheet?: boolean
}

export const GameInterruptModal: FC<Props> = ({ isBottomSheet }) => {
  const appContext = useAppContext()
  const { t } = useTranslation()
  const router = useRouter()

  const goToHomePage = () => {
    router.push(Routes.home)
    appContext.hideModal()
  }

  const reloadPageWithoutUrlParams = () => {
    router.push(router?.asPath)
    appContext.hideModal()
  }

  const component = (
    <StyledContent>
      <StyledTitle>
        {t('game_interrupt_modal_title')}
      </StyledTitle>

      <StyledText>
        {t('game_interrupt_modal_description')}
      </StyledText>

      <StyledImg src='/img/restricted-country-modal/logo.png' />

      <StyledFooter>
        <StyledButton onClick={goToHomePage}>
          {t('game_interrupt_modal_go_home')}
        </StyledButton>

        <StyledButton onClick={reloadPageWithoutUrlParams}>
          {t('game_interrupt_modal_reload_button')}
        </StyledButton>
      </StyledFooter>
    </StyledContent>
  )

  if (isBottomSheet) {
    return (
      <BottomSheetLayout>
        <BottomSheetBody>
          {component}
        </BottomSheetBody>
      </BottomSheetLayout>
    )
  }

  return (
    <ProfileModalLayout fixed>
      <ProfileModalBody fixed>
        {component}
      </ProfileModalBody>
    </ProfileModalLayout>
  )
}

export default GameInterruptModal

