import styled, { css } from 'styled-components'
import { COLORS } from 'ui-kit/constants'
import { applyStyles, SIZES } from 'context/layoutContext'


export const SBreadcrumbs = styled.div`
  display: flex;
  align-items: center;
  padding: 0;

  ${({ theme }) => `
    ${applyStyles([SIZES.MOBILE], theme.contentSize, css`
      padding: 0 12px;
      grid-gap: 8px;
    `)}
  `};
`

export const SItem = styled.div`
  display: flex;
  color: ${COLORS.white};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  padding: 6px 0;
`

export const SLinkItem = styled.div`
  color: ${COLORS.dark200};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  cursor: pointer;
  transition: 0.2s;
  padding: 6px 0;

  &:hover {
    color: ${COLORS.white};
  }
`
