import { runtimeConfig } from 'config/runtimeConfig'

export class Routes {
  static get home() {
    return '/'
  }

  static get bonuses() {
    return '/bonuses'
  }

  static get freeBitcoin() {
    return '/freebitcoin'
  }

  static get lottery() {
    return '/lottery'
  }

  static get wheelOfFortune() {
    return '/wheelOfFortune'
  }

  static get referral() {
    return '/referral'
  }

  static get catalog() {
    return '/catalog'
  }

  static tournamentPage(key?: number): string {
    return `/tournaments/${key}`
  }

  static tournamentRoundPage(key?: number): string {
    return `/tournaments/round/${key}`
  }

  static catalogCategory(key?: number): string {
    return `/catalog/category/${key}`
  }

  static game(id: number, isDemo?: boolean, isFreeSpins?: boolean): string {
    return `/game/${id}${isDemo ? '/demo' : ''}${isFreeSpins ? '?fs=1' : ''}`
  }

  static get catalogAll() {
    return '/catalog/category/all'
  }

  static get catalogLive() {
    return '/catalog/category/live'
  }

  static get catalogTop() {
    return '/catalog/category/top'
  }

  static get catalogFavorite() {
    return '/catalog/category/favorite'
  }

  static get catalogLast() {
    return '/catalog/category/last'
  }

  static catalogProvider(providerId: string | number): string {
    return `/catalog/providers/${providerId}`
  }

  static get richyGames() {
    return '/catalog/category/richy'
  }

  static get aviator() {
    return '/game/36830'
  }

  static get aviatorMobile() {
    return '/game/36831'
  }

  static get poker() {
    return `/game/${runtimeConfig.GAME_POKER_ID}`
  }

  static get chess() {
    return `/game/${runtimeConfig.GAME_CHESS_ID}`
  }

  static get tournaments() {
    return '/tournaments'
  }

  static get leaderBoard() {
    return '/#leader-board'
  }

  static get sport() {
    return '/sports'
  }

  static get userAgreement() {
    return '/user_agreements'
  }

  static get termsAndConditions() {
    return '/general_terms_and_conditions'
  }

  static get search() {
    return '/search'
  }

  static get bonusesDashboard() {
    return '/bonuses-dashboard'
  }

  static get loyaltyProgram() {
    return '/loyalty-program'
  }

  static get loyaltyProgramLevels() {
    return '/loyalty-program/levels'
  }
}
