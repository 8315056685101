import { useTranslation } from 'next-i18next'
import { pluralize } from 'numeralize-ru'
import registrationBannerImg from 'public/img/registration/banner.png'
import Formatter from 'utils/formatter'
import { IBonusBannerDetails } from 'types/interfaces'
import { useLoyaltyContext } from 'context/loyaltyContext'
import { PrivelegyIdent } from 'data/interfaces/ILoyalty'
import { useQuery } from "@tanstack/react-query"

import { getBonusMaxAmount } from "../../../../utils/loyaltyUtils"
import { useAppContext } from "../../../../context/AppContext"

import {
  BannerWrapper, BannerSlide, StyledTitle, StyledPercent, StyledText, StyledAmountWrap, StyledAmount, StyledSpins,
  StyledLottery, StyledBlur, StyledCloseWrap, StyledCloseIcon
} from './styles'

interface Props {
  details: IBonusBannerDetails | null
  onRequestClose: () => void
}

export const Banner: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const loyaltyContext = useLoyaltyContext()
  const appContext = useAppContext()

  const data = loyaltyContext.registrationPageBonus

  const relativeAmountBonus = data?.privileges?.[PrivelegyIdent.relativeAmount] && data.privileges?.[PrivelegyIdent.relativeAmount][data.privileges?.[PrivelegyIdent.relativeAmount].length - 1]
  const freeSpinsBonus = data?.privileges?.[PrivelegyIdent.freeSpins] && data?.privileges?.[PrivelegyIdent.freeSpins][data?.privileges?.[PrivelegyIdent.freeSpins].length - 1]
  const lotteryTicketsBonus = data?.privileges?.[PrivelegyIdent.lotteryTickets] && data?.privileges?.[PrivelegyIdent.lotteryTickets][data?.privileges?.[PrivelegyIdent.lotteryTickets].length - 1]

  const { data: defaultCurrency } = useQuery(['fetchDefaultCurrency'], () => appContext.fetchDefaultCurrency())

  const userCurrency = appContext.auth ? appContext.currencies.find(
    (currency) => currency.iso === appContext?.user?.currencyIso,
  ) : defaultCurrency
  const maxBonusAmount = getBonusMaxAmount(relativeAmountBonus, userCurrency)

  return (
    <BannerWrapper url={registrationBannerImg}>
      <StyledCloseWrap onClick={() => props.onRequestClose()}>
        <StyledCloseIcon type='close' />
      </StyledCloseWrap>

      <BannerSlide>
        <StyledTitle>
          {t('bonus_title')}
        </StyledTitle>

        <StyledPercent>
          {!!relativeAmountBonus && relativeAmountBonus.percent + '%'}
        </StyledPercent>

        <StyledAmountWrap>
          <StyledText>
            {t('bonus_up_to')}
          </StyledText>

          <StyledAmount>
            {maxBonusAmount.inCurrentCurrency ? `${Formatter.formatNumber(maxBonusAmount.value)} ${userCurrency?.iso}` : `${maxBonusAmount.value} USD`}
          </StyledAmount>
        </StyledAmountWrap>

        {freeSpinsBonus?.count > 0 && (
          <StyledSpins>
            <span>
              {`+${Formatter.formatNumber(freeSpinsBonus.count)} `}
            </span>

            {t('bonus_fs')}
          </StyledSpins>
        )}

        <StyledLottery>
          <span>
            {!!lotteryTicketsBonus && Formatter.formatNumber(lotteryTicketsBonus.count)}
          </span>

          {' '}

          {!!lotteryTicketsBonus && pluralize(lotteryTicketsBonus.count, t('bonus_lottery_1'), t('bonus_lottery_2'), t('bonus_lottery_5'))}
        </StyledLottery>

        <StyledBlur />
      </BannerSlide>
    </BannerWrapper>
  )
}

