
import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const FiatSvg: React.FC<Props> = ({ className = '', color = '#686A7B', ...props }) => {
  return (
    <StyledSvg width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path d="M10.5044 18.0023C6.08612 18.0023 2.50439 14.4207 2.50439 10.0024C2.50439 5.58427 6.08612 2.00262 10.5044 2.00262C14.9226 2.00262 18.5044 5.58427 18.5044 10.0024C18.5044 14.4207 14.9226 18.0023 10.5044 18.0023ZM8.50437 11.6024C8.06255 11.6024 7.70439 11.9606 7.70439 12.4024C7.70439 12.8442 8.06255 13.2024 8.50437 13.2024H9.7044V14.0023C9.7044 14.4442 10.0626 14.8023 10.5044 14.8023C10.9462 14.8023 11.3044 14.4442 11.3044 14.0023V13.2024H12.1044C13.209 13.2024 14.1044 12.3069 14.1044 11.2024C14.1044 10.0979 13.209 9.20245 12.1044 9.20245H8.9044C8.68348 9.20245 8.50439 9.02342 8.50439 8.80246C8.50439 8.58159 8.68348 8.40247 8.9044 8.40247H12.5044C12.9462 8.40247 13.3044 8.04431 13.3044 7.6025C13.3044 7.16069 12.9462 6.80253 12.5044 6.80253H11.3044V6.00256C11.3044 5.56073 10.9462 5.20256 10.5044 5.20256C10.0626 5.20256 9.7044 5.56073 9.7044 6.00256V6.80253H8.9044C7.79982 6.80253 6.90439 7.69794 6.90439 8.80246C6.90439 9.907 7.79982 10.8024 8.9044 10.8024H12.1044C12.3253 10.8024 12.5044 10.9815 12.5044 11.2024C12.5044 11.4234 12.3253 11.6024 12.1044 11.6024H8.50437Z"
        fill={color}
      />
    </StyledSvg>

  )
}

const StyledSvg = styled.svg`

`
