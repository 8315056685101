import { FC } from 'react'

import { useTranslation } from 'next-i18next'
import partition from 'lodash/partition'
import { PaymentMethodList } from 'components/Profile/Wallet/PaymentMethodList'
import { PaymentMethodCryptoCard } from 'components/Profile/Wallet/PaymentMethodCryptoCard'
import { PaymentMethodCard } from 'components/Profile/Wallet/PaymentMethodCard'
import { IPaymentMethod } from 'data/interfaces/IPaymentMethod'
import { IPaymentSystem } from 'data/interfaces/IPaymentSystem'
import { runtimeConfig } from 'config/runtimeConfig'

import classes from './StepMethod.module.scss'

interface Props {
  paymentMethods: IPaymentMethod[]
  onSelect: (
    paymentMethod: IPaymentMethod,
    paymentSystem?: IPaymentSystem,
  ) => void
}

export const StepMethod: FC<Props> = ({ paymentMethods, onSelect }) => {
  const { t } = useTranslation()

  const [cryptoPaymentMethods, otherPaymentMethods] = partition(
    paymentMethods,
    (paymentMethod) => paymentMethod.isCrypto,
  )

  return (
    <div className={classes.box}>
      <div className={classes.label}>
        {t('withdraw_payment_method_choose')}
      </div>

      <PaymentMethodList>
        <>
          {cryptoPaymentMethods.map((paymentMethod) => (
            <PaymentMethodCryptoCard
              key={paymentMethod.title}
              method={paymentMethod}
              onClick={() => onSelect(paymentMethod)}
            />
          ))}

          {otherPaymentMethods.map((paymentMethod) =>
            paymentMethod.paymentSystems.map((paymentSystem) => (
              <PaymentMethodCard
                key={paymentSystem.id}
                label={paymentSystem.name}
                icon={
                  paymentSystem.imageUrl
                    ? `${runtimeConfig.HOST}${paymentSystem.imageUrl}`
                    : undefined
                }
                onClick={() => onSelect(paymentMethod, paymentSystem)}
              />
            )),
          )}
        </>
      </PaymentMethodList>
    </div>
  )
}
