import React, { FC, useContext, useEffect, useMemo, useRef, useState } from 'react'

import classNames from 'classnames'

import { table, head } from './utils'
import { SHead } from './styles'


const Head:FC = ({ children }) => {
  const ref = useRef(null),
    { ready, cols, setCols, mode } = useContext(table),
    gridTemplateColumns = useMemo(() => cols.map(item => item.size).join(' '), [cols]),
    [{ orderBy, order }, sort] = useState({ orderBy: null, order: null }),
    value = {
      orderBy,
      order,
      sort: (orderBy, order) => { sort({ orderBy, order }) }
    }

  useEffect(() => {
    const cols = [...ref.current.childNodes].map($node => ({
      size: $node.dataset.size,
      align: $node.dataset.align
    }))

    setCols(cols)
  }, [children])

  return (
    <SHead
      ref={ref}
      className={classNames( {
        ['simple']: mode === 'simple',
        ['hidden']: !ready
      })}
      style={{ gridTemplateColumns }}
    >
      <head.Provider value={value}>
        {children}
      </head.Provider>
    </SHead>
  )
}

Head.displayName = 'Table.Head'

export default Head
