import React from 'react'

import { useTranslation } from 'next-i18next'
import { IDepositCryptoResponse } from 'data/interfaces/IPaymentDeposit'
import QRCode from 'react-qr-code'
import { ICurrency } from 'data/interfaces/ICurrency'
import Formatter from 'utils/formatter'
import { CopyIcon, Input } from 'ui-kit'
import styled from 'styled-components'
import { Form, FormikProvider, useFormik } from 'formik'
import CurrencySvg from 'components/svg/CurrencySvg/CurrencySvg'
import { BREAKPOINTS, COLORS, FONTS } from 'ui-kit/constants'

interface Props {
  response: IDepositCryptoResponse
  currency: ICurrency
}

export const WalletCryptoDepositInfo: React.FC<Props> = ({ response, currency }) => {
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      amount: Formatter.formatAmount(response.amount, currency?.iso),
      wallet: response.wallet
    },
    enableReinitialize: true,
    onSubmit: () => undefined
  })

  return (

    <FormikProvider value={formik}>
      <StyledForm>
        <StyledInput
          disabled
          name="amount"
          label={t('wallet_qr_sum')}
          value={Formatter.formatAmount(response.amount, currency?.iso)}
          prefixContent={(
            <StyledCurrency color
              currencyIso={response.currencyIso}
              size={20}
            />
          )}
          suffixContent={
            (<StyledCopyIcon value={Formatter.formatAmount(response.amount, currency?.iso)} />)
          }
        />

        <StyledInput
          disabled
          name="wallet"
          label={response.currencyIso?.toUpperCase() + ' ' + t('wallet_qr_wallet')}
          value={response.wallet}
          suffixContent={
            (<StyledCopyIcon value={response.wallet} />)
          }
        />

        {response.wallet && (
          <QrCodeBox>
            <QrCodeWrapper>
              <QRCode size={136}
                value={response.wallet}
              />
            </QrCodeWrapper>
          </QrCodeBox>
        )}

        <ImportantText>
          <span>
            {t('wallet_qr_attention')}
          </span>

          {t('wallet_qr_attention_text')}
        </ImportantText>
      </StyledForm>
    </FormikProvider>
  )
}

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 1;

  @media (max-width: ${BREAKPOINTS.xsMax}px) {
    margin-top: 3.125vw;
  }
`

const StyledInput = styled(Input)`
  & ~ & {
    margin-top: 16px;
  }
`

const StyledCurrency = styled(CurrencySvg)`
  margin-right: 8px;
  width: 20px;
  height: 20px;
  max-width: 20px;
  max-height: 20px;
  min-width: 20px;
  min-height: 20px;

  img {
    max-width: 20px;
    max-height: 20px;
    width: 20px;
    height: 20px;
  }
`

const QrCodeBox = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
  width: 100%;
`

const QrCodeWrapper = styled.div`
  background: ${COLORS.dark550};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 164px;
  height: 164px;
`

const ImportantText = styled.p`
  margin-top: auto;
  ${FONTS.p2};
  color: white;

  span {
    margin-right: 5px;
    color: #A8FF38;
  }
`

const StyledCopyIcon = styled(CopyIcon)`
  margin-left: 7px;
`
