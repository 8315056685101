import { SItems } from './styles'
import Item from './Item'


export const FAQ = ({ items }) => {
  return (
    <SItems>
      {items.map(( item ) => <Item item={item} key={item.question} />)}
    </SItems>
  )
}

