import { FC, useEffect, useState } from 'react'

import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'next-i18next'
import { useField } from 'formik'
import { ProfileSettingsSelectField } from 'components/ui/Inputs/ProfileSettingsSelectField'
import InfoRepository from 'data/repositories/InfoRepository'
import { IField, IOption } from 'types/interfaces'

interface Props extends IField {
  countryIso: string
  searchPlaceholder?: string
}

export const ProfileSettingsCitySelectField: FC<Props> = (props) => {
  const { t } = useTranslation()

  const [options, setOptions] = useState<IOption<string>[]>([])
  const [field, _, helpers] = useField(props)

  const getCities = useMutation(
    () => InfoRepository.getCities(props.countryIso),
    {
      onSuccess: (response) => {
        setOptions(
          response.map((city) => ({ value: city.id, label: city.name })),
        )

        if (!response.find((city) => city.id === field.value)) {
          helpers.setValue('')
        }
      },
    },
  )

  useEffect(() => {
    if (props.countryIso) {
      getCities.mutate()
    } else {
      setOptions([])
    }
  }, [props.countryIso])

  return (
    <ProfileSettingsSelectField
      search
      {...props}
      options={options}
      searchPlaceholder={
        options.length
          ? t('settings_field_city_search')
          : t('settings_field_city_not_found')
      }
    />
  )
}
