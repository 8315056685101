import React from "react";
import { COLORS } from "ui-kit/constants";
import { EnumTypeInput } from "ui-kit/enum";

interface Props {
  color?: string;
  className?: string;
  onClick?: (e) => void;
  type?: EnumTypeInput;
}

export const EyeNew = (props: Props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={props.onClick}
    className={props.className}
  >
    <path
      d="M15.7503 9.70529C15.3504 9.04452 13.1513 5.72876 9.4153 5.83591C5.96042 5.91925 3.96122 8.81236 3.41769 9.70529C3.36285 9.79579 3.33398 9.89844 3.33398 10.0029C3.33398 10.1074 3.36285 10.2101 3.41769 10.3006C3.81128 10.9494 5.91669 14.17 9.59648 14.17H9.75267C13.2075 14.0866 15.213 11.1935 15.7503 10.3006C15.8051 10.2101 15.834 10.1074 15.834 10.0029C15.834 9.89844 15.8051 9.79579 15.7503 9.70529ZM9.72143 12.9794C7.02875 13.0389 5.2732 10.8423 4.72342 10.0029C5.34817 9.04452 6.97877 7.08602 9.47778 7.02649C12.158 6.96101 13.9198 9.16358 14.4758 10.0029C13.8323 10.9614 12.2204 12.9199 9.72143 12.9794Z"
      fill={props.type === EnumTypeInput.text ? COLORS.white : COLORS.dark200}
    />
    <path
      d="M9.58507 7.91919C9.15259 7.91919 8.72983 8.04139 8.37024 8.27032C8.01065 8.49926 7.73039 8.82466 7.56489 9.20538C7.39938 9.58609 7.35608 10.005 7.44045 10.4092C7.52483 10.8133 7.73308 11.1846 8.03889 11.476C8.34469 11.7674 8.73431 11.9658 9.15848 12.0462C9.58264 12.1266 10.0223 12.0853 10.4219 11.9276C10.8214 11.7699 11.1629 11.5029 11.4032 11.1602C11.6435 10.8176 11.7717 10.4148 11.7717 10.0027C11.7717 9.45012 11.5413 8.92017 11.1313 8.52944C10.7212 8.1387 10.165 7.91919 9.58507 7.91919ZM9.58507 10.8956C9.39972 10.8956 9.21854 10.8433 9.06443 10.7451C8.91032 10.647 8.79021 10.5076 8.71928 10.3444C8.64835 10.1813 8.62979 10.0017 8.66595 9.8285C8.70211 9.65529 8.79136 9.49618 8.92242 9.3713C9.05348 9.24642 9.22046 9.16138 9.40224 9.12693C9.58403 9.09247 9.77246 9.11015 9.94369 9.17774C10.1149 9.24532 10.2613 9.35977 10.3643 9.50661C10.4672 9.65346 10.5222 9.8261 10.5222 10.0027C10.5222 10.2395 10.4235 10.4666 10.2477 10.6341C10.072 10.8016 9.83361 10.8956 9.58507 10.8956Z"
      fill={props.type === EnumTypeInput.text ? COLORS.white : COLORS.dark200}
    />
  </svg>
);
