import { ReactElement } from 'react'

import classNames from 'classnames'
import { useAppContext } from 'context/AppContext'

import styles from './index.module.scss'

interface Props {
  fixed?: boolean
  children?: ReactElement | ReactElement[]
  className?: string
}

export default function ProfileModalFooter(props: Props) {
  const appContext = useAppContext()

  return (
    <div className={classNames(styles.root, { [styles.fixed]: props.fixed }, props.className)}>
      {props.children}
    </div>
  )
}
