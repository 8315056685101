import { useTranslation } from "next-i18next";
import ReactModal from "react-modal";

import styles from "./index.module.scss";
import classNames from "classnames";
import Close from "components/svg/Close";

interface Props {
  title?: string;
  variant?: "success" | "fail";
  isOpen: boolean;
  onRequestClose: () => void;
}

export default function PaymentModal(props: Props) {
  const { t } = useTranslation();
  const settings = {
    className: styles.modalContent,
    overlayClassName: classNames(styles.modalOverlay),
  };

  return (
    <ReactModal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      shouldCloseOnOverlayClick
      {...settings}
    >
      <div className={styles.wrapper}>
        <div className={styles.root}>
          <div>
            {"success" === props.variant ? (
              <>
                <div className={styles.image}>
                  <img src="/img/payment-modal/payment-success.png" />
                </div>
                <div className={styles.title}>
                  {props.title && t(props.title)}
                </div>
              </>
            ) : (
              <>
                <div className={styles.image}>
                  <img src="/img/payment-modal/payment-fail.png" />
                </div>
                <div className={styles.title}>
                  {props.title && t(props.title)}
                </div>
              </>
            )}
          </div>
          <div className={styles.close} onClick={() => props.onRequestClose()}>
            <Close color="#6B6C77" />
          </div>
        </div>
        <div
          className={styles.overlay}
          onClick={() => props.onRequestClose()}
        />
      </div>
    </ReactModal>
  );
}
