import { useState } from 'react'

import { useField, useFormikContext } from 'formik'
import cx from 'classnames'
import ErrorInput from 'components/ui/Inputs/components/ErrorInput'
import { IField } from 'types/interfaces'
import AutosizeInput from 'react-input-autosize'
import { Input } from 'ui-kit'
import CurrencySvg from 'components/svg/CurrencySvg/CurrencySvg'
import styled from 'styled-components'

import InputField from '../InputField'

export interface Props extends IField{
  className?: string
  inputClassName?: string
  inputWrapperClassName?: string
  currency?: string
}


export default function AmountCurrencyField(props: Props) {
  const [field] = useField(props as any)

  return (
    <Input
      {...field}
      mask={Array.from({ length: 20 }, () => /^[0-9.]$/)}
      prefixContent={(
        <StyledCurrency color
          currencyIso={props.currency}
          size={20}
        />
      )}
    />
  )
}

const StyledCurrency = styled(CurrencySvg)`
  margin-right: 8px;
  width: 20px;
  height: 20px;
  max-width: 20px;
  max-height: 20px;
  min-width: 20px;
  min-height: 20px;

  img {
    max-width: 20px;
    max-height: 20px;
    width: 20px;
    height: 20px;
  }
`
