import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const WalletIcon: React.FC<Props> = ({ className = '', ...props }) => {
  return (
    <StyledSvg width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path d="M16.125 5.56184H4.40625V5.08525L14.7188 4.24646V5.08525H16.125V3.65548C16.125 2.60699 15.2897 1.87113 14.2697 2.01888L4.85625 3.38574C3.83531 3.53443 3 4.51335 3 5.56184V15.0936C3 15.5992 3.19754 16.0841 3.54917 16.4416C3.90081 16.7992 4.37772 17 4.875 17H16.125C16.6223 17 17.0992 16.7992 17.4508 16.4416C17.8025 16.0841 18 15.5992 18 15.0936V7.4682C18 6.96261 17.8025 6.47772 17.4508 6.1202C17.0992 5.76269 16.6223 5.56184 16.125 5.56184ZM14.7188 12.2398C14.534 12.2398 14.3511 12.2027 14.1805 12.1308C14.0098 12.0588 13.8548 11.9534 13.7242 11.8206C13.5936 11.6877 13.49 11.53 13.4194 11.3565C13.3488 11.1829 13.3124 10.9969 13.3125 10.8091C13.3126 10.6213 13.349 10.4353 13.4198 10.2618C13.4905 10.0883 13.5942 9.93067 13.7248 9.7979C13.8555 9.66513 14.0106 9.55984 14.1813 9.48802C14.352 9.4162 14.535 9.37927 14.7197 9.37933C15.0928 9.37945 15.4505 9.53026 15.7143 9.79857C15.978 10.0669 16.1261 10.4307 16.1259 10.8101C16.1258 11.1894 15.9775 11.5531 15.7136 11.8212C15.4497 12.0894 15.0918 12.2399 14.7188 12.2398Z" />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  path {
    fill: currentColor;
  }
`
