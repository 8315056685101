import { useEffect, useState } from 'react'

import classNames from 'classnames'
import HiddenXs from 'components/ui/HiddenXS'
import ProfileModalLayout from 'components/Profile/layout/ProfileModalLayout'
import ModalHeader from 'components/Profile/layout/ProfileModalHeader/ModalHeader'
import { useTranslation } from 'next-i18next'
import ProfileModalBody from 'components/Profile/layout/ProfileModalBody'
import ProfileModalFooter from 'components/Profile/layout/ProfileModalFooter'
import BalanceTransactionRepository from 'data/repositories/BalanceTransationRepository'
import { format } from 'date-fns'
import Button from 'components/ui/Button'
import { IPagination } from 'types/interfaces'
import { IBetHistoryItem } from 'data/interfaces/IBetHistoryItem'
import ContentLoader from 'components/ui/ContentLoader'
import InfiniteScroll from 'react-infinite-scroll-component'
import Image from 'next/image'
import Formatter from 'utils/formatter'
import { useAppContext } from 'context/AppContext'
import { ProfileModalType } from 'types/enums'
import Converter from 'utils/converter'

import styles from './index.module.scss'

const Item = ({ item }: {item: IBetHistoryItem}) => {
  const mainCurrency = item.money.currency && Object.keys(item.money.currency).length > 0 ? Object.keys(item.money.currency)[0] : null
  const convertedCurrency = item.money.userCurrency && Object.keys(item.money.userCurrency).length > 0 ? Object.keys(item.money.userCurrency)[0] : null
  const mainAmount = mainCurrency ? item.money.currency[mainCurrency] : null
  const convertedAmount = convertedCurrency ? item.money.userCurrency[convertedCurrency] : null

  const appContext = useAppContext()

  return (
    <div className={styles.item}>
      <div className={styles.left}>
        <div className={styles.game}>
          <HiddenXs>
            <div className={styles.icon}>
              {(item.imageIconSmallUrl || item.imageIconPreviewUrlSquare)  && (
                <Image src={item.imageIconSmallUrl || item.imageIconPreviewUrlSquare}
                  width={64}
                  height={64}
                />
              )}

            </div>
          </HiddenXs>

          <div className={styles.info}>
            <div className={styles.label}>
              {item.name}
            </div>

            <div className={styles.id}>
              {item.sessionId}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.right}>
        <div className={styles.date}>
          {format(new Date(item.time), 'dd MMMM yyyy · hh:mm')}
        </div>

        <div className={classNames(styles.usdt, { [styles.plus]: mainAmount > 0 })}>
          {mainCurrency && Formatter.formatAmount(mainAmount, mainCurrency)}

          {' '}

          <span>
            {Converter.convertCurrencyIsoToDisplayIso(appContext.currencies, mainCurrency?.toUpperCase() || '')}
          </span>
        </div>

        {convertedCurrency && (
          <div className={classNames(styles.btc, { [styles.plus]: convertedAmount > 0 })}>
            {Formatter.formatAmount(convertedAmount, convertedCurrency)}

            {' '}

            {convertedCurrency?.toUpperCase()}
          </div>
        )}
      </div>
    </div>
  )
}

interface Props {

}

interface ItemProps {
  label?: string
  usdt?: string
  btc: string
  date: string
  icon: string
  id: string
}

export default function BetsHistory(props: Props) {
  const { t } = useTranslation()

  const appContext = useAppContext()

  const initialData = { data: [], total: 0 }
  const [data, setData] = useState<IPagination<IBetHistoryItem>>(initialData)
  const [page, setPage] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const limit = 30

  useEffect(() => {
    BalanceTransactionRepository.fetchBetHistory(1, limit).then(i => {
      setData(i)
      setLoading(false)
    })
  }, [])

  const handleScrollNext = async () => {
    const newPage = page + 1
    setPage(newPage)
    setLoading(true)

    const res = await BalanceTransactionRepository.fetchBetHistory(newPage, limit)
    setData(data => ({ data: [...data.data, ...res.data], total: res.total }))

    setLoading(false)
  }

  return (
    <ProfileModalLayout fixed>
      <ModalHeader title={t('bets_history_title')} />

      <ProfileModalBody fixed
        id={'bet-history-list'}
        className={styles.body}
      >
        {loading && data.total == 0 && (
          <ContentLoader style={'block'}
            isOpen={true}
          />
        )}

        <InfiniteScroll
          dataLength={data.data.length}
          next={handleScrollNext}
          loader={ data.total > 0 ? (
            <ContentLoader style={'infiniteScroll'}
              isOpen={true}
            />
          ) : null}
          hasMore={data.total > data.data.length}
          scrollThreshold={0.6}
          scrollableTarget={'bet-history-list'}
        >
          <div className={styles.list}>
            {data.data.map((item, index) => (
              <Item key={index}
                item={item}
              />
            )
            )}

          </div>
        </InfiniteScroll>

      </ProfileModalBody>

      <ProfileModalFooter>
        <div className={styles.btn}>
          <Button size='normal'
            background='payGradient500'
            onClick={() => appContext.showModalProfile(ProfileModalType.wallet, { backTo: ProfileModalType.betsHistory })}
          >
            {t('bets_history_deposit')}
          </Button>
        </div>
      </ProfileModalFooter>

    </ProfileModalLayout>
  )
}
