import { useEffect } from 'react'

import { Centrifuge } from 'centrifuge'
import Converter from 'utils/converter'
import { convertCurrencyToArray } from 'utils/currencies'
import { runtimeConfig } from 'config/runtimeConfig'

import UserRepository from "../data/repositories/UserRepository"

import { useAppContext } from './AppContext'
import { useNotificationsContext } from "./notificationsContext"

export const useAppSocket = () => {
  const context = useAppContext()
  const notificationsContext = useNotificationsContext()

  const token = context.user?.socketToken
  const userId = context.user?.id

  useEffect(() => {
    if (!userId) {
      return
    }

    const getToken = async () => {
      const response = await UserRepository.getUser()

      return response?.socketToken as string
    }

    const centrifuge = new Centrifuge(runtimeConfig.SOCKET_HOST + '/connection/websocket', { token, getToken })

    const balancesChannel = centrifuge.newSubscription(`update_balance#${userId}`)
    const currenciesChannel = centrifuge.newSubscription(`currency_update`)
    const popupNotificationsChannel = centrifuge.newSubscription(`popup#${userId}`)

    balancesChannel.on('publication', function({ data }) {
      const bonus = convertCurrencyToArray(
        data.currencies.bonus,
        data.calculatedToDefaultCurrency.bonus,
      )

      const real = convertCurrencyToArray(
        data.currencies.real,
        data.calculatedToDefaultCurrency.real,
      )

      const formattedData = Converter.objectKeysToCamelCase(data)

      formattedData.currencies.bonus = bonus
      formattedData.currencies.real = real

      context.updateUser({ balance: formattedData })
    })

    currenciesChannel.on('publication', function({ data }) {
      const formattedData = Converter.objectKeysToCamelCase(data)

      context.updateCurrency(formattedData)
    })

    popupNotificationsChannel.on('publication', function({ data }) {
      notificationsContext.setNotificationModalData(data)
    })

    balancesChannel.subscribe()
    currenciesChannel.subscribe()
    popupNotificationsChannel.subscribe()

    centrifuge.connect()

    return () => {
      centrifuge.disconnect()
    }
  }, [userId])
}
