export enum PrivelegyIdent {
  fixedAmount = 'fixed-sum',
  relativeAmount = 'relative-sum',
  freeSpins = 'free-spins',
  lotteryTickets = 'lottery-tickets'
}

export enum PrivelegySubIdent {
  range = 'range-sum'
}

export interface IPrivelegySub {
  ident: PrivelegySubIdent,
  config: {
    [key: string]: any
  },
}

export enum PropertyIdent {
  showIn = 'show-in',
  showHours = 'show-hours',
  dependsOn = 'depends-on',
  minDeposit = 'min-deposit'
}

export type IBonus = Partial<{
  privileges: Partial<Record<PrivelegyIdent, any>>,
  properties: Partial<Record<PropertyIdent, any>>,
  validTill: number,
  activeHours: number,
  name: string
}>

export interface IPrivelege {
  id: number,
  ident: PrivelegyIdent,
  data: {
    [key: string]: any
  },
  name: string,
  isEnabled: boolean,
  privilegeSubs: IPrivelegySub[]
}

export interface IProperty {
  id: number,
  ident: PropertyIdent,
  data: {
    [key: string]: any
  },
  name: string,
  isEnabled: boolean
}

export interface ILoyaltyBonus {
  id: string,
  isEventCheck: boolean,
  name: string,
  checkData?: {
    activeHours?: number,
    validAt?: number
  },
  properties: IProperty[]
  privileges: IPrivelege[]
}

export interface IPromocodeResponse {
  keyword: string,
  promocode: {
    id: number,
    name: string
  },
  userUuid: string
}

export interface ICurrentLevel {
  levelId?: number,
  levelName?: string,
  points?: number
}
