import React from 'react'

import styled from 'styled-components'
import classNames from 'classnames'

import { COLORS, FONTS } from './constants'

interface Tab {
  content?: React.ReactNode,
  value: string
}

type Props = {
  className?: string,
  tabs: Tab[],
  activeTab: string,
  onChange: (value: string) => void
}

export const Tabs: React.FC<Props> = ({ className = '', tabs, activeTab, onChange }) => {
  return (
    <TabsBox
      className={className}
    >
      {tabs.map(tab => (
        <TabBox key={tab.value}
          className={classNames('tab', { active: activeTab === tab.value })}
          onClick={() => onChange(tab.value)}
        >
          {tab.content}
        </TabBox>
      ))}
    </TabsBox>
  )
}

const TabsBox = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

export const TabBox = styled.div`
  display: flex;
  min-height: 32px;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  cursor: pointer;
  color: ${COLORS.dark200};
  ${FONTS.a1};
  white-space: nowrap;

  &:hover {
    color: white;
  }

  &:focus {
    color: #9596B4;
  }

  &.active {
    background: ${COLORS.btnBlue};
    border-radius: 40px;
    color: ${COLORS.white};
  }
`

const TabImg = styled.img``


