import React, { FC, useContext, useEffect, useState } from 'react'

import classNames from 'classnames'
import { SCell, SData } from 'ui-kit/Table/styles'

import { head } from './utils'
import { ICell } from './types'

const noop = () => {}

const Cell:FC<ICell> = ({
  size = '1fr',
  alignItems = 'vtop',
  isCustom = false,
  order: initOrder,
  align = 'left',
  sortable = false,
  overflow = false,
  children,
  onClick = noop
}) => {
  const { orderBy, order, sort } = useContext(head),
    [id] = useState(Math.random())

  const handleSort = event => {
    const nextOrder = id !== orderBy || order !== 'desc' ? 'desc' : 'asc'

    sort(id, nextOrder)
    onClick(nextOrder, event)
  }

  useEffect(() => {
    initOrder && sort(id, initOrder)
  }, [initOrder])

  return (
    <SCell
      className={classNames(align, alignItems, {
        active: sortable || onClick !== noop
      })}
      data-align={align}
      data-size={size}
      onClick={sortable ? handleSort : onClick}
    >
      {isCustom ?
        children :
        (
          <SData className={classNames(overflow ? 'overflow' : '')} >
            {children}
          </SData>
        )
      }

      {sortable && (
        <div
          className={classNames( {
            ['asc']: id === orderBy && order === 'asc',
            ['visible']: id === orderBy
          })}
        />
      )}
    </SCell>
  )
}

export default Cell
