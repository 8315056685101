import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const PlusIcon: React.FC<Props> = ({ className = '', ...props }) => {
  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      className={className}
      {...props}
    >
      <circle cx="7.5"
        cy="7.5"
        r="7.5"
      />

      <rect x="6.375"
        y="3"
        width="2.25"
        height="9"
        rx="1.125"
        fill="#272832"
      />

      <rect x="12"
        y="6.375"
        width="2.25"
        height="9"
        rx="1.125"
        transform="rotate(90 12 6.375)"
        fill="#272832"
      />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  circle {
    fill: currentColor;
  }
`
