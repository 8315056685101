import styled from "styled-components"

import { COLORS, FONTS } from "../constants"

export const SRadioButtonsBox = styled.div`
  display: flex;
  gap: 0 12px;
  flex-wrap: wrap;
`

export const SOption = styled.div`
  flex: 1;
  height: 44px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  cursor: pointer;
  border-radius: 4px;
  background: ${COLORS.dark550};
`

export const SOptionText = styled.span`
  margin-left: 8px;
  ${FONTS.p1};
  color: ${COLORS.dark80};
`

export const SLabel = styled.p`
  width: 100%;
  margin-bottom: 4px;
  ${FONTS.p1};
  color: ${COLORS.dark200};
`
