import styled from 'styled-components'
import { COLORS, FONTS } from 'ui-kit/constants'

export const SButtonGoTo = styled.button<{ disabled?: boolean }>`
  background: none;
  border: none;
  outline: none !important;
  cursor: pointer;
  ${FONTS.a0};
  color: ${({ disabled }) => disabled ? COLORS.dark200 : COLORS.blue};
  -webkit-background-clip: text;
  background-image: linear-gradient(270deg, #2C50FF 0%, #4785F6 100%);
  transition: 0.3s;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 12px;

  &:hover {
    background-image: linear-gradient(270deg, #7000ff 0%, #1a6bff 100%);
  }
`
