import { FC } from 'react'

import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import Avatar from 'components/ui/Avatar'
import Button from 'components/ui/Button'
import { Button as ButtonFromUiKit } from 'ui-kit'
import ProfileModalLayout from 'components/Profile/layout/ProfileModalLayout'
import ModalHeader from 'components/Profile/layout/ProfileModalHeader/ModalHeader'
import ProfileModalBody from 'components/Profile/layout/ProfileModalBody'
import { useAppContext } from 'context/AppContext'
import { ProfileModalType } from 'types/enums'
import Converter from 'utils/converter'
import UserUtils from 'utils/user'
import styled from 'styled-components'

import { MenuItem } from './components/MenuItem'
import { Bonus } from './components/Bonus'
import { ActionType, Balance } from './components/Balance/Balance'
import AddNewAccount from './components/AddNewAccount'
import classes from './ProfileModal.module.scss'

export const ProfileModal: FC = () => {
  const { t } = useTranslation()
  const appContext = useAppContext()

  const handleLogout = () => {
    appContext.hideModal()
    appContext.logout()
  }

  const handleFreeSpinsClick = () => {
    appContext.showModalProfile(ProfileModalType.freeSpinsGames)
  }

  const mainBalance = UserUtils.getMainBalanceReal(appContext.user)
  const otherBalance = UserUtils.getOtherBalancesReal(appContext.user)
  const bonusBalance = UserUtils.getBonusBalances(appContext.user)
  const wageringBonusPercents = UserUtils.getWageringBonusesPercents(appContext.user)

  const currenciesToAdd = appContext.currencies.filter(
    (currency) =>
      !appContext.user.balance.currencies.real.find(
        (userCurrency) => userCurrency.currency === currency.iso,
      ),
  )

  const handleOpenChat = () => {
    appContext.openSupport()
  }

  return (
    <ProfileModalLayout fixed>
      <ModalHeader
        title={!appContext.isMobile ? t('profile_title') : undefined}
      />

      <ProfileModalBody fixed>
        <div className={classes.top_box}>
          <div className={classes.user_info_box}>
            <Avatar avatar="/img/Avatar/avatar.png" />

            <div className={classes.info}>
              <div className={classes.user_name}>
                {UserUtils.formatUserName(appContext.user)}
              </div>

              <div className={classes.id}>
ID
                {appContext.user.id}
              </div>
            </div>
          </div>

          <Button className={classes.logout_btn}
            onClick={handleLogout}
          >
            <img src="/img/icons/logout.svg" />

            <span className={classes.label}>
              {t('profile_logout')}
            </span>
          </Button>
        </div>

        <div className={classes.balances_grid}>
          <div className={classes.balances_list}>
            <div className={classNames(classes.field, classes.main)}>
              <div className={classes.title}>
                {t('profile_accounts_main_title')}
              </div>

              {mainBalance && (
                <Balance
                  balance={mainBalance}
                  actions={[ActionType.Deposit, ActionType.Withdrawal]}
                  isMain
                />
              )}
            </div>

            {!otherBalance.length && (
              <AddNewAccount
                options={Converter.convertCurrencyToOptions(currenciesToAdd)}
              />
            )}

            <div className={classes.actions_box}>
              <Button
                size="normal"
                background="dark600"
                onClick={() => {
                  appContext.setWalletModalActiveTab('withdraw')
                  appContext.showModalProfile(ProfileModalType.wallet)
                }
                }
              >
                {t('profile_exchange')}
              </Button>

              <Button
                size="normal"
                background="payGradient500"
                className={classes.wallet}
                onClick={() =>
                  appContext.showModalProfile(ProfileModalType.wallet)
                }
              >
                <img src="/img/icons/wallet.svg"
                  alt=""
                />

                {t('profile_deposit')}
              </Button>
            </div>

            {!!otherBalance.length && (
              <div className={classes.field}>
                <div className={classes.title}>
                  {t('profile_accounts_additional_title')}
                </div>

                <div className={classes.list}>
                  {otherBalance.map((balance) => (
                    <Balance
                      key={balance.currency}
                      balance={balance}
                      actions={[
                        ActionType.SetMain,
                        ActionType.Deposit,
                        ActionType.Withdrawal,
                      ]}
                    />
                  ))}
                </div>
              </div>
            )}

            {!!otherBalance.length && !!currenciesToAdd.length && (
              <AddNewAccount
                options={Converter.convertCurrencyToOptions(currenciesToAdd)}
              />
            )}

            {!!bonusBalance.length && (
              <div className={classes.field}>
                <div className={classes.title}>
                  {t('profile_accounts_bonus_title')}
                </div>

                <div className={classes.list}>
                  {bonusBalance.map((balance) => (
                    <Balance
                      key={balance.currency}
                      balance={balance}
                      actions={[ActionType.Remove]}
                      percent={wageringBonusPercents[balance.currency.toLowerCase()]}
                      withProgress
                    />
                  ))}
                </div>
              </div>
            )}
          </div>

          <div className={classes.bonuses_list}>
            <div className={classes.field}>
              <div className={classes.title}>
                {t('profile_accounts_bonus')}
              </div>

              <div className={classes.list}>
                <Bonus
                  color="#587DFF"
                  label={t('profile_accounts_bonus_lottery')}
                  amount={appContext.user.extraBalances.lotteryTickets ?? 0}
                  icon="/img/Profile/icons/ticket.svg"
                />

                <Bonus
                  color="#F81AAC"
                  label={t('profile_accounts_bonus_free_spin')}
                  amount={appContext.user.extraBalances.freespinAmount ?? 0}
                  icon="/img/Profile/icons/spin.svg"
                  onClick={appContext.user.extraBalances.freespinAmount ? handleFreeSpinsClick : undefined}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={classes.menu}>
          <MenuItem
            icon="/img/Profile/icons/wallet.svg"
            label={t('profile_menu_wallet')}
            isActive
            onClick={() => appContext.showModalProfile(ProfileModalType.wallet)}
          />

          <MenuItem
            icon="/img/Profile/icons/clock.svg"
            label={t('profile_menu_bets_history')}
            onClick={() =>
              appContext.showModalProfile(ProfileModalType.betsHistory)
            }
          />

          <MenuItem
            icon="/img/Profile/icons/favorite.svg"
            label={t('profile_menu_favorite')}
            onClick={() =>
              appContext.showModalProfile(ProfileModalType.favorite)
            }
          />

          <MenuItem
            icon="/img/Profile/icons/support.svg"
            label={t('profile_menu_support')}
            onClick={appContext.openSupport}
          />

          <MenuItem
            icon="/img/Profile/icons/wallet2.svg"
            label={t('profile_menu_payments_history')}
            onClick={() =>
              appContext.showModalProfile(ProfileModalType.paymentHistory)
            }
          />

          <MenuItem
            icon="/img/Profile/icons/settings.svg"
            label={t('profile_menu_settings')}
            onClick={() =>
              appContext.showModalProfile(ProfileModalType.settings)
            }
          />
        </div>


        <StyledButton onClick={handleOpenChat}>
          {t('footer_menu_self_exclusion')}
        </StyledButton>
      </ProfileModalBody>

    </ProfileModalLayout>
  )
}

const StyledButton = styled(ButtonFromUiKit)`
  margin: 30px auto 0;
  width: 240px;
`
