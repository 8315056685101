import { Dispatch, FC, SetStateAction, useCallback } from 'react'

import { SvgIcon } from 'ui-kit/svg'
import { COLORS } from 'ui-kit/constants'

import { getPagination, getCurrentPages } from './utils'
import Button from './Button'
import { SButton, SPagination, SArrow } from './styles'


export interface IPaginationData {
  currentPage: number
  pageCount: number
  perPage: number
  totalCount: number
}
export interface IPagination {
  filter: {
    page?: number,
  }
  data: IPaginationData
  setFilter:  Dispatch<SetStateAction<unknown>>,
  className?: string
}

const Pagination: FC<IPagination> = ({ className = '', filter, data = {}, setFilter }) => {
  const { totalCount, perPage } = data
  const pageCount = Math.ceil(totalCount / perPage)

  const pagination = getPagination(1, pageCount)

  if (!pageCount || pagination.length === 0 || pageCount === 1) return null

  const page = Number(filter.page) || 1
  const paginationData = getCurrentPages(pagination, page)
  const { currentPages, isLastPage, isFirstPage, visibleLeftDots, visibleRightDots } = paginationData

  const changePage = useCallback(
    (newPage) => () => {
      setFilter({ ...filter, page: newPage })
    },
    [filter, setFilter]
  )

  return (
    <SPagination className={className}>
      {page !== 1 && (
        <Button toPage={changePage(page - 1)}>
          <SArrow className='left'>
            <SvgIcon color={COLORS.white}
              type='dropdown-arrow'
            />
          </SArrow>
        </Button>
      )}

      {pageCount > 5 && isFirstPage && (
        <>
          <Button toPage={changePage(1)}>
            1
          </Button>

          {visibleLeftDots && (
            <SButton>
              ...
            </SButton>
          )}
        </>
      )}

      {currentPages?.map((i) => (
        <Button key={i}
          toPage={changePage(i)}
          active={i === page}
        >
          {i}
        </Button>
      ))}

      {pageCount > 5 && isLastPage && (
        <>
          {visibleRightDots && (
            <SButton>
              ...
            </SButton>
          )}

          <Button toPage={changePage(pageCount)}>
            {pageCount}
          </Button>
        </>
      )}

      {page !== pageCount && (
        <Button toPage={changePage(page + 1)}>
          <SArrow>
            <SvgIcon color={COLORS.white}
              type='dropdown-arrow'
            />
          </SArrow>
        </Button>
      )}
    </SPagination>
  )
}

export default Pagination
