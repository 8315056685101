import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const WithdrawalIcon: React.FC<Props> = ({ className = '', ...props }) => {
  return (
    <StyledSvg width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M4.40625 5.56184H16.125C16.6223 5.56184 17.0992 5.76269 17.4508 6.1202C17.8025 6.47772 18 6.9626 18 7.4682V15.0936C18 15.5992 17.8025 16.0841 17.4508 16.4416C17.0992 16.7992 16.6223 17 16.125 17H15.4003C15.7837 16.2499 16 15.4002 16 14.5C16 11.4624 13.5376 9 10.5 9C7.46243 9 5 11.4624 5 14.5C5 15.4002 5.21628 16.2499 5.59971 17H4.875C4.37772 17 3.90081 16.7992 3.54917 16.4416C3.19754 16.0841 3 15.5992 3 15.0936V5.56184C3 4.51335 3.83531 3.53443 4.85625 3.38574L14.2697 2.01888C15.2897 1.87113 16.125 2.60699 16.125 3.65548V5.08525H14.7188V4.24646L4.40625 5.08525V5.56184Z"
      />

      <path d="M10.5 10L13.0251 12.6552C13.2911 12.935 13.2911 13.3743 13.0251 13.654C12.7394 13.9544 12.2605 13.9544 11.9748 13.6542L11.2071 12.8472V16.2929C11.2071 16.6834 10.8905 17 10.5 17C10.1095 17 9.7929 16.6834 9.7929 16.2929V12.8457L9.02501 13.6537C8.73945 13.9542 8.26047 13.9543 7.97482 13.6539C7.70881 13.3742 7.70881 12.935 7.97482 12.6553L10.5 10Z" />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  path {
    fill: currentColor;
  }
`
