const AgreeSvg = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 4.85714L5.19355 10L11 2"
      stroke="url(#paint0_linear_4077_386712)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4077_386712"
        x1="11.6013"
        y1="6"
        x2="-0.487342"
        y2="6"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#17AA25" />
        <stop offset="1" stop-color="#76DC26" />
      </linearGradient>
    </defs>
  </svg>
);

export { AgreeSvg };
