import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const DepositSvg: React.FC<Props> = ({ className = '', color = '#686A7B', ...props }) => {
  return (
    <StyledSvg width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path d="M16.584 5.6595H4.86523V5.18291L15.1777 4.34411V5.18291H16.584V3.75314C16.584 2.70464 15.7487 1.96879 14.7287 2.11653L5.31523 3.48339C4.2943 3.63209 3.45898 4.611 3.45898 5.6595V15.1913C3.45898 15.6969 3.65653 16.1818 4.00816 16.5393C4.35979 16.8968 4.8367 17.0977 5.33398 17.0977H16.584C17.0813 17.0977 17.5582 16.8968 17.9098 16.5393C18.2614 16.1818 18.459 15.6969 18.459 15.1913V7.56586C18.459 7.06026 18.2614 6.57537 17.9098 6.21786C17.5582 5.86035 17.0813 5.6595 16.584 5.6595ZM15.1777 12.3375C14.993 12.3374 14.8101 12.3004 14.6394 12.2284C14.4688 12.1565 14.3138 12.0511 14.1832 11.9182C14.0526 11.7854 13.949 11.6277 13.8784 11.4541C13.8077 11.2806 13.7714 11.0946 13.7715 10.9068C13.7715 10.7189 13.808 10.533 13.8787 10.3595C13.9495 10.186 14.0532 10.0283 14.1838 9.89556C14.3145 9.76279 14.4696 9.65749 14.6403 9.58567C14.811 9.51385 14.9939 9.47692 15.1787 9.47698C15.5518 9.47711 15.9095 9.62792 16.1732 9.89623C16.437 10.1645 16.585 10.5284 16.5849 10.9077C16.5848 11.287 16.4365 11.6508 16.1726 11.9189C15.9087 12.187 15.5508 12.3376 15.1777 12.3375Z"
        fill={color}
      />
    </StyledSvg>

  )
}

const StyledSvg = styled.svg`

`
