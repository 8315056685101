
import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const CryptoSvg: React.FC<Props> = ({ className = '', color = '#686A7B', ...props }) => {
  return (
    <StyledSvg width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path d="M10.0044 18.0023C5.58612 18.0023 2.00439 14.4207 2.00439 10.0024C2.00439 5.58427 5.58612 2.00262 10.0044 2.00262C14.4226 2.00262 18.0044 5.58427 18.0044 10.0024C18.0044 14.4207 14.4226 18.0023 10.0044 18.0023ZM9.2044 13.2024V14.0023C9.2044 14.4442 9.56258 14.8023 10.0044 14.8023C10.4462 14.8023 10.8044 14.4442 10.8044 14.0023V13.2024H11.6044C12.709 13.2024 13.6044 12.3069 13.6044 11.2024C13.6044 10.7522 13.4556 10.3368 13.2046 10.0024C13.4556 9.6682 13.6044 9.25269 13.6044 8.80246C13.6044 7.69794 12.709 6.80253 11.6044 6.80253H10.8044V6.00256C10.8044 5.56073 10.4462 5.20256 10.0044 5.20256C9.56258 5.20256 9.2044 5.56073 9.2044 6.00256V6.80253H6.80439V13.2024H9.2044ZM8.4044 10.8024H11.6044C11.8253 10.8024 12.0044 10.9815 12.0044 11.2024C12.0044 11.4234 11.8253 11.6024 11.6044 11.6024H8.4044V10.8024ZM8.4044 8.40247H11.6044C11.8253 8.40247 12.0044 8.58159 12.0044 8.80246C12.0044 9.02342 11.8253 9.20245 11.6044 9.20245H8.4044V8.40247Z"
        fill={color}
      />
    </StyledSvg>

  )
}

const StyledSvg = styled.svg`

`
