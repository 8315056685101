import { Form } from 'formik'
import styled from 'styled-components'
import { Input, PhoneInputWithCountry, Scrollable, Tabs } from 'ui-kit'
import { BREAKPOINTS, COLORS } from 'ui-kit/constants'
import { EmailSvg } from 'components/svg/EmailSvg'
import { PhoneSvg } from 'components/svg/PhoneSvg'
import closeSvg from 'components/svg/Close'
import { SvgIcon } from 'ui-kit/svg'

export const Root = styled.div`
  position: relative;
  display: flex;
  border-radius: 16px;
  z-index: 3;
  background: ${COLORS.dark600};
  overflow: hidden;

  @media screen and (max-width: 768px){
    display: block;
    width: 100%;
    border-radius: 0;
    height: 100%;
    max-height: 100%;
    overflow: scroll;
  }
`

export const Right = styled.div`
  width: 376px;
  padding: 32px;
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  @media screen and (max-width: 768px){
    width: 100%;
    box-sizing: border-box;
    padding: 0 24px 40px;
    height: calc(100vh - 314px);
    max-height: calc(100vh - 314px);
    overflow-y: auto;
    position: relative;

    &.without-overflow {
      overflow-y: unset;
      height: unset;
      max-height: unset;
      padding-bottom: 300px;
    }
  }
`

export const Frame = styled.div`
  overflow-y: scroll;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    position: fixed;
    display: block;
    bottom: auto;
    padding-top: 0px;
    padding-bottom: 0px;
    height: 100vh;
    min-height: -webkit-fill-available;
  }
`

export const Content = styled.div`
  padding: 16px 0 0;
  flex-grow: 1;
  display: flex;

  @media screen and (max-width: 768px){
    padding-top: 0;
  }
`

export const StyledWrapperButton = styled.div`
  font-size: 14px;
  font-weight: 500;

  @media screen and (max-width: 768px) {
    margin-top: 12px;
  }
`

export const StyledWrapperCheckBox = styled.div`
  display: flex;
  gap: 8px;
  padding: 16px 0 0;
`

export const StyledLabel = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  margin-right: -10px;

  @media screen and (max-width: 768px){
    margin-right: 0;
  }
`

export const StyledLink = styled.span`
  color: ${COLORS.blue};
  text-decoration: none;
  cursor: pointer;
`

export const StyledLoginLink = styled.div`
  text-align: center;
  padding-top: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: ${COLORS.dark200};

  & span {
    color: ${COLORS.blue};
    cursor: pointer;
  }
`

export const StyledInputFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const StyledForm = styled(Form)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export const StyledScrollable = styled(Scrollable)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 251px;

  @media screen and (max-width: 768px){
    height: 100%;
    min-height: auto;
  }
`

export const StyledInput = styled(Input)`
  font-size: 14px;
  font-weight: 500;

  @media (min-width: 1441px) {
    font-size: 16px;
  }
`

export const StyledPhoneInput = styled(PhoneInputWithCountry)`
  font-size: 14px;
  font-weight: 500;

  @media (min-width: 1441px) {
    font-size: 16px;
  }
`

export const Overlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  cursor: pointer;
`

export const StyledTabs = styled(Tabs)`
  display: flex;
  justify-content: center;
  flex-shrink: 0;

  @media screen and (max-width: 768px){
    padding: 16px 0 12px;
    margin-bottom: 4px;
    background: ${COLORS.dark600};
    z-index: 3;
    position: sticky;
    box-shadow: 0 0 5px 4px ${COLORS.dark600};
    top: 0;
  }
`

export const StyledTab = styled.div`
  display: flex;
  align-items: center;
`

export const StyledTabLabel = styled.span`
  padding-top: 3px;
  font-size: 14px;
  font-family: 'Gilroy';
`

export const StyledEmailIcon = styled(EmailSvg)`
  display: flex;
  align-items: center;
  margin-right: 4px;
  font-weight: bold;
  margin-left: -4px;
`

export const StyledPhoneIcon = styled(PhoneSvg)`
  display: flex;
  align-items: center;
  margin-right: 4px;
  margin-left: -8px;
`
export const StyledCloseIcon = styled(SvgIcon)`
  position: absolute;
  right: 12px;
  top: 16px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: ${COLORS.dark200};

  @media (max-width: ${BREAKPOINTS.xsMax}px) {
    display: none;
  }
`
