import { FC } from "react"

import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { useAppContext } from "context/AppContext"
import { ModalType } from "types/enums"
import VisibleXs from "components/ui/VisibleXS"
import { Routes } from "types/routes"
import { BallSvg } from "components/svg/BallSvg"
import GiftSvg from "components/svg/GiftSvg"
import { CasinoSvg } from "components/svg/CasinoSvg"
import MenuSvg from "components/svg/MenuSvg"
import { VipSvg } from "components/svg/VipSvg"
import { ChatSvg } from "components/svg/ChatSvg"
import { useLayoutContext } from "context/layoutContext"

import { StyledMobileFooter, StyledRoot } from "./styles"
import { Item, ItemProps } from "./MobileFooterItem"

export enum ActionType {
    Main = 'main',
    Sport = 'sport',
    Casino = 'casino',
    Menu = 'menu',
    Bonuses = 'bonuses'
}

export const MobileFooter: FC = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const context = useAppContext()
  const { leftMenuOpened, toggleLeftMenu } = useLayoutContext()

  const isMenuOpen =
    context.modal === ModalType.profileBurger ||
    context.bottomSheet === ModalType.profileBurger

  const items: Array<ItemProps> = [
    // {
    //   type: ActionType.Sport,
    //   label: "VIP",
    //   icon: <VipSvg />,
    //   link: Routes.sport,
    // },
    {
      type: ActionType.Bonuses,
      label: t('menu_bonuses'),
      icon: <GiftSvg />,
      link: Routes.bonuses,
    },
    {
      type: ActionType.Casino,
      label: t('tabbar_casino'),
      icon: <CasinoSvg />,
      link: Routes.catalog,
    },
    {
      type: ActionType.Sport,
      label: t('tabbar_sport'),
      icon: <BallSvg />,
      link: Routes.sport,
    },
    // {
    //   type: ActionType.Sport,
    //   label: 'Chat',
    //   icon: <ChatSvg />,
    //   link: Routes.sport,
    // },
    {
      type: ActionType.Menu,
      label: t('tabbar_menu'),
      icon: isMenuOpen ? <MenuSvg /> : <MenuSvg />,
    },
  ]

  const handleItemClick = (item: ItemProps) => {
    if (isMenuOpen) {
      context.hideModal()
    }

    switch (item.type) {
    case ActionType.Sport: {
      router.push(item.link!)
      break
    }

    case ActionType.Bonuses: {
      router.push(item.link!)
      break
    }

    case ActionType.Casino: {
      router.push(item.link!)
      break
    }

    case ActionType.Main: {
      router.push('/')
      break
    }

    case ActionType.Menu: {
      toggleLeftMenu(!leftMenuOpened)

      break
    }
    }
  }

  return (
    <StyledMobileFooter>
      {items.map((item) => (
        <Item
          key={item.type}
          {...item}
          onClick={() => handleItemClick(item)}
        />
      ))}
    </StyledMobileFooter>
  )
}
