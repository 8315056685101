export enum BalanceTransactionType{
  Initial = 0,
  Deposit = '2',
  Withdrawal = '1',
}

export enum BalanceTransactionStatus {
  Pending = 1,
  Completed = 2,
  Canceled = 3
}

export  interface IBalanceTransaction {
  eventDate: string
  createdAt: number
  unixtimestamp: string
  amount: number,
  currencyIso: string,
  type: BalanceTransactionType,
  typeName: string,
  comment: string,
  providerId: number,
  providerName: string,
  providerDetails: string,
  status: BalanceTransactionStatus,
  paymentType: {
    name: string,
    imageUrl?: string
  }
}
