import styled from 'styled-components'
import { COLORS, FONTS } from 'ui-kit/constants'


export const STable = styled.div`
  overflow: hidden;
  border-radius: 8px;
`
export const SRow = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, auto));
    font-weight: 400;
    grid-column-gap: 16px;
    position: relative;
    box-sizing: content-box;
    padding: 0 1px 1px;
    background: ${COLORS.dark600};
    border-bottom: 1px solid ${COLORS.dark550};

    &:first-child {
        padding: 1px;
        border-top: none;
    }
`

export const SData = styled.div`
    flex: 1;
    max-width: 100%;
    font-size: 14px;
    padding: 16px;

  &.overflow {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

export const SCell = styled.div`
    display: flex;
    align-items: start;
    position: relative;
    min-width: 0;
    min-height: 38px;
    box-sizing: border-box;

  &.center {
    justify-content: center;

    ${SData} {
      text-align: center;
      justify-content: center;
      display: flex;
    }
  }

  &.right ${SData} {
    text-align: center;
  }

  &.vtop {
    align-items: start;
  }

  &.vcenter {
    align-items: center;
  }
`

export const SHead = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, auto));
  grid-column-gap: 16px;
  font-weight: 600;
  margin-bottom: -2px;
  background: ${COLORS.dark550};


  &.hidden {
    visibility: hidden;
  }

  ${SCell} {
    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
      justify-content: space-between;
      cursor: pointer;
    }

    ${SData} {
      color: ${COLORS.dark200};
      ${FONTS.p1};
      white-space: nowrap;
    }
  }

  &.simple {
    background: ${COLORS.dark600} !important;
  }
`


export const SBody = styled.div`
  &.simple {
    ${SRow} {
      background: none;
      border: none !important;

      &:nth-child(even) {
        background: ${COLORS.dark600} !important;
        border-radius: 8px;
      }
    }

    ${SCell} {
      padding: 12px 8px !important;
    }
  }
`
