import React from 'react'

import styled from 'styled-components'
import ReactModal from 'react-modal'

import { BREAKPOINTS, COLORS, FONTS } from './constants'
import { SvgIcon } from './svg'

interface InfoModalProps {
  className?: string,
  isOpen: boolean,
  onClose: () => void,
  children: React.ReactNode,
  title: string
}

export const InfoModal: React.FC<InfoModalProps> = ({ className = '', isOpen, onClose, title, children }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      onRequestClose={onClose}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 300
        },
        content: {
          background: 'transparent',
          border: 'none',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0
        }
      }}
    >
      <StyledContent className={className}>
        <StyledHeader>
          {title}

          <StyledCloseIcon type='close'
            onClick={onClose}
          />
        </StyledHeader>

        <StyledChildrenBox>
          {children}
        </StyledChildrenBox>
      </StyledContent>

    </ReactModal>
  )
}

const StyledContent = styled.div`
  width: 376px;
  background: linear-gradient(180deg, #33343F 0%, rgba(29, 30, 37, 0.00) 100%);
  padding: 0;
  border-radius: 16px;
  background: ${COLORS.dark600};

  @media (max-width: ${BREAKPOINTS.xsMedium}px) {
    width: 100%;
    max-width: 361px;
  }
`

const StyledChildrenBox = styled.div`
  margin-top: 3px;
  padding: 0 32px 32px;
`

const StyledHeader = styled.div`
  height: 60px;
  background: linear-gradient(180deg, #33343F 0%, rgba(29, 30, 37, 0.00) 100%);
  border-radius: 16px 16px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 19px 26px 0 32px;
  ${FONTS.h1};
`

const StyledCloseIcon = styled(SvgIcon)`
  width: 24px;
  height: 24px;
  color: ${COLORS.dark200};
  cursor: pointer;

  &:hover {
    color: white;
  }
`
