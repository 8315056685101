import { ReactElement } from 'react'

import classNames from 'classnames'
import { useAppContext } from 'context/AppContext'

import styles from './index.module.scss'


interface Props {
  fixed?: boolean
  children?: ReactElement | ReactElement[]
}

export default function ProfileModalLayout(props: Props) {
  const context = useAppContext()

  return (
    <div className={classNames(styles.root, { [styles.fixed]: props.fixed || context.isMobile })}>
      {props.children}
    </div>
  )
}
