import React from 'react'

interface Props {
  color?: string
  className?: string
}

export const SearchSvg: React.FC<Props> = ({ className, color }) => {
  return (
    <svg className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="
          M9.35271 14.2106C12.0357 14.2106 14.2107 12.0357 14.2107 9.35268C14.2107 6.66971 12.0357 4.49473 9.35271
          4.49473C6.66973 4.49473 4.49476 6.66971 4.49476 9.35268C4.49476 12.0357 6.66973 14.2106 9.35271
          14.2106ZM9.35271 15.7054C12.8612 15.7054 15.7054 12.8612 15.7054 9.35268C15.7054 5.84418 12.8612 2.99997
          9.35271 2.99997C5.8442 2.99997 3 5.84418 3 9.35268C3 12.8612 5.8442 15.7054 9.35271 15.7054Z
        "
        fill={color}
      />

      <path fillRule="evenodd"
        clipRule="evenodd"
        d="
          M16.0107 16.9563C15.7405 17.2264 15.3025 17.2264 15.0323 16.9563L12.5516 14.4756C12.2814 14.2054 12.2814
          13.7674 12.5516 13.4972C12.8218 13.2271 13.2598 13.2271 13.53 13.4972L16.0107 15.9779C16.2808 16.2481
          16.2808 16.6861 16.0107 16.9563Z
        "
        fill={color}
      />
    </svg>
  )
}
