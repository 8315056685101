import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const GiftIcon: React.FC<Props> = ({ className = '', ...props }) => {
  return (
    <StyledSvg width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path d="M3.45923 12.9067V17.1912C3.46157 17.4065 3.54876 17.6123 3.70187 17.7637C3.85498 17.9151 4.06165 18.0001 4.27701 18.0001H9.27256V12.9067H3.45923Z" />

      <path d="M10.7292 18.0001H15.7248C15.9402 18.0001 16.1468 17.9151 16.2999 17.7637C16.4531 17.6123 16.5402 17.4065 16.5426 17.1912V12.9067H10.7292V18.0001Z" />

      <path d="M17.2 5.63556H15.5644C15.732 5.29531 15.8201 4.92147 15.8222 4.54222C15.8199 3.86717 15.5501 3.22056 15.0719 2.74406C14.5937 2.26755 13.9462 2 13.2711 2C12.5659 2.03344 11.8845 2.26569 11.3057 2.66993C10.7269 3.07416 10.2742 3.63388 10 4.28444C9.72577 3.63388 9.2731 3.07416 8.69428 2.66993C8.11546 2.26569 7.4341 2.03344 6.72889 2C6.05383 2 5.40629 2.26755 4.92812 2.74406C4.44994 3.22056 4.18013 3.86717 4.17778 4.54222C4.17989 4.92147 4.26803 5.29531 4.43556 5.63556H2.8C2.35556 5.63556 2 6.16 2 6.8V10.2933C2 10.9333 2.35556 11.4578 2.8 11.4578H9.27111V5.63556H10.7289V11.4578H17.2C17.6444 11.4578 18 10.9333 18 10.2933V6.8C18 6.16 17.6444 5.63556 17.2 5.63556ZM6.72889 5.63556C6.57763 5.64924 6.42517 5.63125 6.28124 5.58275C6.13732 5.53424 6.00507 5.45628 5.89294 5.35384C5.78081 5.25139 5.69126 5.12671 5.62999 4.98774C5.56872 4.84876 5.53707 4.69855 5.53707 4.54667C5.53707 4.39479 5.56872 4.24457 5.62999 4.1056C5.69126 3.96662 5.78081 3.84194 5.89294 3.7395C6.00507 3.63705 6.13732 3.55909 6.28124 3.51059C6.42517 3.46208 6.57763 3.4441 6.72889 3.45778C7.97333 3.45778 8.67556 4.73778 9.02222 5.63556H6.72889ZM13.2711 5.63556H10.9778C11.3244 4.74667 12.0267 3.45778 13.2711 3.45778C13.4224 3.4441 13.5748 3.46208 13.7188 3.51059C13.8627 3.55909 13.9949 3.63705 14.1071 3.7395C14.2192 3.84194 14.3087 3.96662 14.37 4.1056C14.4313 4.24457 14.4629 4.39479 14.4629 4.54667C14.4629 4.69855 14.4313 4.84876 14.37 4.98774C14.3087 5.12671 14.2192 5.25139 14.1071 5.35384C13.9949 5.45628 13.8627 5.53424 13.7188 5.58275C13.5748 5.63125 13.4224 5.64924 13.2711 5.63556Z" />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  path {
    fill: currentColor;
  }
`
