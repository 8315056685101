import { FC } from 'react'

import { useTranslation } from 'next-i18next'
import ProfileModalLayout from 'components/Profile/layout/ProfileModalLayout'
import ModalHeader from 'components/Profile/layout/ProfileModalHeader/ModalHeader'
import ProfileModalBody from 'components/Profile/layout/ProfileModalBody'
import { useAppContext } from 'context/AppContext'
import BottomSheetLayout from 'components/layout/BottomSheetLayout'
import BottomSheetBody from 'components/layout/BottomSheetBody'
import BottomSheetHeader from 'components/layout/BottomSheetHeader'
import { ModalType, ProfileModalType } from 'types/enums'

import styles from './index.module.scss'

interface Props {
  isBottomSheet?: boolean
}

export const AstroPayModal: FC<Props> = ({ isBottomSheet }) => {
  const { t } = useTranslation()
  const appContext = useAppContext()

  const handleBtnClick = () => {
    if (!appContext.auth) {
      appContext.showModal(ModalType.login)

      return
    }

    appContext.showModal(ProfileModalType.wallet)
  }

  const component = (
    <div className={styles.root}>
      <img className={styles.bg}
        src="/img/astropay-modal/modal-bg.png"
      />

      <img className={styles.logo}
        src="/img/astropay-modal/logo.png"
      />

      <div className={styles['middle-row']}>
        <div className={styles['middle-text']}>
          <p>
Faça qualquer depósito
          </p>

          e ganhe
          {' '}

          <span>
50 BRL GRÁTIS
          </span>

          {' '}

          <br />

          {' '}
em sua conta Astropay!
        </div>

        <img className={styles['coin-img']}
          src="/img/astropay-modal/coin.png"
        />
      </div>

      <button className={styles['btn']}
        onClick={handleBtnClick}
      >
Obter bônus
      </button>

      <div className={styles['text-content']}>
        <p className={styles['text-title']}>
          Richy Casino com Astropay dá aos novos jogadores
          {' '}

          <span>
50 BRL
          </span>
!
        </p>

        <p className={styles['text']}>
          Cada jogador que fizer seu primeiro depósito receberá
          {' '}

          <span>
50 BRL
          </span>

          {' '}
em sua conta Astropay
        </p>

        <p className={styles['text']}>
          Você também receberá nossos bônus padrão:
        </p>

        <p className={styles['text']}>
          - Bônus de depósito de
          {' '}

          <span>
150%
          </span>
        </p>

        <p className={styles['text']}>
          -
          {' '}

          <span>
200
          </span>

          {' '}
Rodadas Grátis
        </p>

        <p className={styles['text']}>
          -
          {' '}

          <span>
200
          </span>

          {' '}
Bilhetes de Loteria
        </p>

        <p className={styles['text']}>
          Depressa, apenas os primeiros
          {' '}

          <span>
500
          </span>

          {' '}
jogadores receberão um presente da Astropay!
        </p>

        <p className={styles['text']}>
          Depósito mínimo -
          {' '}

          <span>
50 BRL
          </span>
        </p>
      </div>
    </div>
  )

  if (isBottomSheet) {
    return (
      <BottomSheetLayout>
        <BottomSheetHeader
          className={styles.mobileHeader}
          title=""
        />

        <BottomSheetBody>
          {component}
        </BottomSheetBody>
      </BottomSheetLayout>
    )
  }

  return (
    <ProfileModalLayout fixed>
      <ModalHeader title="" />

      <ProfileModalBody fixed>
        {component}
      </ProfileModalBody>
    </ProfileModalLayout>
  )
}
