import { useEffect, useState } from 'react'

import classNames from 'classnames'
import Button from 'components/ui/Button'
import styled from "styled-components"

import { SvgIcon } from "../../../../ui-kit/svg"
import { COLORS } from "../../../../ui-kit/constants"

import styles from './index.module.scss'

interface Props {
  placeholder?: string
  onChange?: (value) => void
  onClick?: () => void
  searchValue?: string
  className?: string
}

export default function InputSearch(props: Props) {
  const [value, setValue] = useState('')
  useEffect(() => {
    if (props.searchValue && !value) {
      setValue(props.searchValue)
    }
  }, [props.searchValue])

  const handleSearch = (e) => {
    setValue(e.currentTarget.value)
    if(props.onChange) {
      props.onChange(e.currentTarget.value)
    }
  }

  const handleClear = () => {
    setValue('')

    if(props.onChange) {
      props.onChange('')
    }
  }

  return (
    <div className={classNames(styles.root, props.className)}>
      <input
        name="query"
        type="text"
        value={value}
        autoComplete={'off'}
        onChange={handleSearch}
        placeholder={props.placeholder}
      />

      <div onClick={props.onClick}
        className={styles.btn}
      >
        <Button>
          <SSearchIcon type="search"
            color={!!value ? 'white' : COLORS.dark150}
          />
        </Button>
      </div>

      {!!value && (
        <SClearIcon type="close"
          onClick={handleClear}
        />
      )}
    </div>
  )
}

export const SSearchIcon = styled(SvgIcon)`

`

export const SClearIcon = styled(SvgIcon)`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: ${COLORS.dark150};

  &:hover {
    color: white;
  }
`
