import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const StarEmptyIcon: React.FC<Props> = ({ className = '', ...props }) => {
  return (
    <StyledSvg width="16"
      height="16"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M0.368924 6.00091C0.452749 5.73459 0.610235 5.49968 0.82181 5.32537C1.03338 5.15106 1.28972 5.04503 1.55895
        5.02047L5.48893 4.66817L7.02645 0.870757C7.13222 0.612516 7.30897 0.392248 7.53469 0.237407C7.7604 0.082566
        8.02509 0 8.29577 0C8.56644 0 8.83113 0.082566 9.05685 0.237407C9.28256 0.392248 9.45932 0.612516 9.56508
        0.870757L11.1047 4.66817L15.0347 5.02047C15.3053 5.0437 15.5634 5.14927 15.7764 5.32394C15.9894 5.49861
        16.1479 5.73459 16.232 6.00227C16.3161 6.26996 16.3221 6.55743 16.2492 6.82861C16.1762 7.09979 16.0276 7.34261
        15.822 7.52659L12.8352 10.2278L13.7316 14.2467C13.793 14.5204 13.7753 14.8068 13.6806 15.0701C13.5859 15.3333
        13.4184 15.5618 13.1991 15.727C12.9798 15.8922 12.7183 15.9867 12.4475 15.9987C12.1766 16.0107 11.9084 15.9397
        11.6762 15.7946L8.29784 13.6679L4.91943 15.7946C4.68731 15.9395 4.41913 16.0103 4.14841 15.9982C3.87768 15.986
        3.6164 15.8915 3.3972 15.7264C3.17801 15.5612 3.01061 15.3328 2.91593 15.0697C2.82124 14.8065 2.80346 14.5203
        2.86481 14.2467L3.76112 10.2278L0.774333 7.52659C0.569288 7.34194 0.421426 7.09863 0.349302 6.82721C0.277178
        6.55578 0.284004 6.26833 0.368924 6.00091ZM4.97114 9.04655C5.16022 9.21706 5.3009 9.43775 5.37804
        9.68491C5.45517 9.93207 5.46586 10.1963 5.40895 10.4493L4.62365 13.9738L7.58423 12.1101C7.79923 11.9744 8.04611
        11.9026 8.29784 11.9026C8.54956 11.9026 8.79644 11.9744 9.01144 12.1101L11.972 13.9738L11.1867 10.4493C11.1298
        10.1963 11.1405 9.93207 11.2176 9.68491C11.2948 9.43775 11.4354 9.21706 11.6245 9.04655L14.2507 6.67192L10.7937
        6.36178C10.5434 6.33956 10.3037 6.2466 10.1008 6.09301C9.89793 5.93942 9.73957 5.73108 9.64299 5.49068L8.29784
        2.16848L6.95268 5.49068C6.8561 5.73108 6.69774 5.93942 6.49484 6.09301C6.29195 6.2466 6.05229 6.33956 5.80195
        6.36178L2.34495 6.67192L4.97114 9.04655Z" fill={props.color}
      />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  path {
    fill: currentColor;
  }
`
