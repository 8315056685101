import { FC } from 'react'

import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'next-i18next'
import { Form, Formik } from 'formik'
import Button from 'components/ui/Button'
import InputField from 'components/ui/Inputs/InputField'
import FormError from 'components/ui/Form/FormError'
import BottomSheetLayout from 'components/layout/BottomSheetLayout'
import BottomSheetHeader from 'components/layout/BottomSheetHeader'
import BottomSheetBody from 'components/layout/BottomSheetBody'
import ProfileModalLayout from 'components/Profile/layout/ProfileModalLayout'
import ModalHeader from 'components/Profile/layout/ProfileModalHeader/ModalHeader'
import ProfileModalBody from 'components/Profile/layout/ProfileModalBody'
import { useAppContext } from 'context/AppContext'
import UserRepository from 'data/repositories/UserRepository'
import { ConfirmNewPhoneModalArguments } from 'types/interfaces'
import Validator from 'utils/validator'
import Formatter from 'utils/formatter'

import classes from './ModalConfirmNewPhone.module.scss'

interface ConfirmPhoneForm {
  code: string
}

interface Props {
  isBottomSheet?: boolean
}

export const ModalConfirmNewPhone: FC<Props> = (props) => {
  const { t } = useTranslation('common')

  const appContext = useAppContext()
  const args = appContext.modalArguments as ConfirmNewPhoneModalArguments

  const confirmNewPhone = useMutation(
    (data: ConfirmPhoneForm) =>
      UserRepository.confirmNewPhone({
        code: data.code,
        phone: Formatter.cleanPhone(args.phone),
      }),
    {
      onSuccess: () => {
        appContext.updateUserFromCookies()
        appContext.goBackModalProfile()
        appContext.goBackModalProfile()
      },
    },
  )

  const result = (
    <Formik<ConfirmPhoneForm>
      initialValues={{ code: '' }}
      onSubmit={(data) => confirmNewPhone.mutate(data)}
    >
      {() => (
        <Form className={classes.form}>
          <div className={classes.description}>
            {t('phone_new_confirm_text_1')}

            {' '}

            <span className={classes.code}>
              {t('phone_new_confirm_text_2')}
            </span>

            {' '}

            {t('phone_new_confirm_text_3')} {t('phone_new_confirm_text_4')}

            {' '}

            <p className={classes.phone}>
              {Formatter.formatPhone(args.phone)}
            </p>
          </div>

          <div className={classes.inputs}>
            <InputField
              name={'code'}
              disabled={confirmNewPhone.isLoading}
              placeholder={t('phone_new_confirm_field_code')}
              validate={Validator.required}
            />
          </div>

          <FormError error={confirmNewPhone.error} />

          <Button
            type="submit"
            fluid
            spinner={confirmNewPhone.isLoading}
            className={classes.button}
            size="submit"
            background="blueGradient500"
          >
            {t('phone_new_confirm_button')}
          </Button>
        </Form>
      )}
    </Formik>
  )

  if (props.isBottomSheet) {
    return (
      <BottomSheetLayout>
        <BottomSheetHeader title={t('phone_new_confirm_title')} />

        <BottomSheetBody>
          {result}
        </BottomSheetBody>
      </BottomSheetLayout>
    )
  } else {
    return (
      <ProfileModalLayout>
        <ModalHeader title={t('phone_new_confirm_title')} />

        <ProfileModalBody>
          {result}
        </ProfileModalBody>
      </ProfileModalLayout>
    )
  }
}
