import { FC, MouseEvent } from 'react'

import Image from 'next/image'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { useMeasure } from 'react-use'
import classNames from 'classnames'
import Button from 'components/ui/Button'
import { FavoriteBtn } from 'components/ui/FavoriteBtn/FavoriteBtn'
import { useAppContext } from 'context/AppContext'
import { IGame } from 'data/interfaces/IGame'
import { Routes } from 'types/routes'
import { ModalType } from 'types/enums'

import classes from './GameCard.module.scss'

interface Props {
  game: IGame
  onDeleteFromFavorite?: (game?: IGame) => void,
  onPlayClick?: () => void,
  isFreeSpins?: boolean
}

export const GameCard: FC<Props> = ({ game, onDeleteFromFavorite, onPlayClick, isFreeSpins }) => {
  const { t } = useTranslation()
  const appContext = useAppContext()

  const [ref, { width }] = useMeasure<HTMLDivElement>()
  const isCardSmall = width < 140

  const link = Routes.game(game.id, false, isFreeSpins)
  const linkDemo = Routes.game(game.id, true)

  const handlePlayClick = (event: MouseEvent) => {
    if (appContext.auth) {
      if (onPlayClick) {
        onPlayClick()
      }

      return
    }

    event.preventDefault()
    appContext.showModal(ModalType.registration)
  }


  return (
    <div
      ref={ref}
      className={classNames(classes.box, {
        [classes.small]: isCardSmall,
      })}
    >
      {game.imageIconPreviewUrlSquare && (
        <Image
          src={game.imageIconPreviewUrlSquare}
          layout="fill"
          alt={game.name}
          className={classes.background_img}
        />
      )}

      <div className={classes.content}>
        <div className={classes.text_box}>
          <div className={classes.provider_name}>
            {game.providerName}
          </div>

          <div className={classes.name}>
            {game.name}
          </div>
        </div>

        {game.isBlackListed ? (
          <div className={classes.black_list_text}>
            Игра не доступна в вашем регионе
          </div>
        ) : (
          <div className={classes.controls_box}>
            <Button
              size={isCardSmall ? 'small' : 'play'}
              background="payGradient500"
              href={link}
              onClick={handlePlayClick}
              className={classes.btn_play}
            >
              {t('game_card_play')}
            </Button>

            <Link href={linkDemo}>
              <a
                className={classNames(classes.demo_text, {
                  [classes.hidden]: !game.isDemoEnable,
                })}
                onClick={onPlayClick}
              >
                {t('game_card_demo')}
              </a>
            </Link>
          </div>
        )}

        <div className={classes.favorite_box}>
          <FavoriteBtn
            id={game.id}
            onChange={(checked) =>
              onDeleteFromFavorite?.(!checked ? game : undefined)
            }
          />
        </div>
      </div>
    </div>
  )
}
