import styled from "styled-components"
import { BREAKPOINTS, COLORS, FONTS } from "ui-kit/constants"

export const StyledMobileFooter = styled.div`
  position: fixed;
  left: 0;
  bottom: -1px;
  z-index: 99999999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 17.5px;
  min-height: 52px;
  background: ${COLORS.dark550};
  box-shadow: 0px -5px 20px 0px rgba(0, 0, 0, 0.25);
  padding-bottom: calc(env(safe-area-inset-bottom) - 16px);
  padding-top: min(8px, env(safe-area-inset-bottom));

  @media (min-width: ${BREAKPOINTS.sMin}px) {
    display: none;
  }
`

export const StyledItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 12px;
  height: 100%;
  min-width: 50px;

  svg {
    width: 24px;
    height: 24px;
  }
`

export const StyledLabel = styled.div`
  margin-top: 4px;
  color: ${COLORS.dark200};
  ${FONTS.p2};

  &.active {
    color: white;
  }
`
