import React, { useEffect, useMemo } from 'react'

import { IPaymentMethod } from 'data/interfaces/IPaymentMethod'
import { useAppContext } from 'context/AppContext'
import { ICurrency } from 'data/interfaces/ICurrency'
import CurrencySvg from 'components/svg/CurrencySvg/CurrencySvg'
import classNames from 'classnames'
import styled from 'styled-components'
import { useTranslation } from 'next-i18next'

import { CardBox, CardMinAmount, CardName, CardsBox, WalletPaymentSystemBox } from './styles'

interface Props {
  paymentMethod: IPaymentMethod,
  onCurrencySelect: (currency: ICurrency) => void,
  selected?: ICurrency,
  isWithdraw?: boolean
}

export const WalletPaymentCurrency: React.FC<Props> = ({ onCurrencySelect, paymentMethod, selected, isWithdraw }) => {
  const appContext = useAppContext()
  const { t } = useTranslation()

  const isMobile = appContext.isMobile

  const currencies = useMemo<ICurrency[]>(() => {
    return paymentMethod.paymentSystems.flatMap(system => system.settings.map(s => {
      const currencyData = appContext.currencies.find(currency => currency.iso === s.currencyIso)

      if (!currencyData) {
        return
      }

      return {
        ...currencyData,
        name: system.name !== 'Default' ? `${currencyData.iso} ${system.name}` : currencyData.name || currencyData?.iso,
        paymentSystem: system,
        deposit: s.deposit,
        withdraw: s.withdraw,
        order: s.order
      }
    })).filter(Boolean).sort((a, b) => Number(a?.order) - Number(b?.order))
  }, [paymentMethod])

  useEffect(() => {
    if (isMobile) {
      return
    }

    onCurrencySelect(currencies[0])
  }, [])


  return (
    <WalletPaymentSystemBox>
      <CardsBox>
        {currencies.map((currency) => {
          const min = Math.ceil(currency?.[isWithdraw ? 'withdraw' : 'deposit']?.minAmount * currency?.toUsd)

          return (
            <CardBox
              className={classNames({ active: selected?.name === currency.name })}
              key={currency.iso}
              onClick={() => onCurrencySelect(currency)}
            >
              <StyledCurencySvg
                currencyIso={currency.iso}
                color
                size={48}
              />

              <CardName>
                {currency.name}
              </CardName>

              <CardMinAmount>
                {t('deposit_modal_min')}
:

                {' '}

                $
                {min}
              </CardMinAmount>
            </CardBox>
          )})}
      </CardsBox>
    </WalletPaymentSystemBox>
  )
}

const StyledCurencySvg = styled(CurrencySvg)`
  margin-bottom: 4px;
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
`
