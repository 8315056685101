import { useEffect } from 'react'
import { GetServerSidePropsContext } from 'next'
import { SSRConfig, useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

import { checkLanguage, getLangFromHeader } from './language'
import { CookiesType } from '../types/enums'

export const getServerSideTranslation = (
  context: GetServerSidePropsContext,
): Promise<SSRConfig> => {
  const acceptLanguage = getLangFromHeader(
    context.req.headers['accept-language'],
  )

  return serverSideTranslations(
    checkLanguage(context.locale),
    ['common'],
  )
}

export const useIniti18n = () => {
  const { i18n } = useTranslation()

  useEffect(() => {
    window.i18n = i18n

    if (i18n.language) {
      i18n.reloadResources(i18n.language, ['common'])
    }
  }, [])
}
