import React, { useState } from 'react'

import classNames from 'classnames'

import { SAnswer, SArrow, SHeader, SItem, SQuestion, SDropdownArrow } from './styles'

const Item = ({ item }) => {
  const [opened, toggle] = useState(false)

  return (
    <SItem onClick={() => toggle((value) => !value)}>
      <SHeader>
        <SQuestion className={opened ? 'active' : ''}>
          {item.question}
        </SQuestion>

        <SArrow>
          <SDropdownArrow
            type="dropdown-arrow"
            className={classNames({ rotated: opened })}
          />
        </SArrow>
      </SHeader>

      {opened && (
        <SAnswer>
          {item.answer}
        </SAnswer>
      )}
    </SItem>
  )
}

export default Item
