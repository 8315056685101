import React, { useEffect, useState } from 'react'

import { createPortal } from 'react-dom'
import { useAppContext } from 'context/AppContext'
import HiddenXs from 'components/ui/HiddenXS'
import VisibleXs from 'components/ui/VisibleXS'
import { BonusDepositShowMode } from 'types/enums'
import BonusFooter from 'components/for_pages/Common/BonusFooter'
import { useRouter } from 'next/router'
import Gift from 'components/for_pages/Common/Gift'
import BonusFooterMobile from 'components/for_pages/Common/BonusFooterMobile'
import { Routes } from 'types/routes'

import { MobileFooter } from '../MobileFooter'

import styles from './index.module.scss'

export const AuthUserFeatures = () => {
  const context = useAppContext()
  const router = useRouter()

  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  if (router.pathname === '/game/[...slug]') {
    return null
  }


  return (
    <>
      {context.showBonus &&
        context.bonusShowMode === BonusDepositShowMode.Spoiler &&
        router.pathname !== Routes.sport && (
        <div className={styles.longDown}>
          <HiddenXs>
            <BonusFooter
              style="footer"
              onRequestClose={() =>
                context.setBonusShowMode(BonusDepositShowMode.Gift)
              }
            />
          </HiddenXs>

          <VisibleXs>
            <BonusFooterMobile
              onRequestClose={() =>
                context.setBonusShowMode(BonusDepositShowMode.Gift)
              }
            />
          </VisibleXs>
        </div>
      )}

      {context.showBonus &&
        (context.bonusShowMode === BonusDepositShowMode.Gift ||
          (router.pathname === Routes.sport &&
            context.bonusShowMode === BonusDepositShowMode.Spoiler)) &&
        router.pathname !== Routes.sport && (
        <div className={styles.bonus}>
          <Gift timer />
        </div>
      )}

      {isClient && createPortal(<MobileFooter />, document.body)}
    </>
  )
}
