import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const SettingsIcon: React.FC<Props> = ({ className = '', ...props }) => {
  return (
    <StyledSvg width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path d="M4.66727 3.63827C5.47952 2.91144 6.44116 2.3481 7.50044 2C8.08685 2.73258 8.98864 3.20177 10 3.20177C11.0114 3.20177 11.9131 2.73258 12.4996 2C13.5589 2.3481 14.5205 2.91144 15.3327 3.63827C14.9924 4.51194 15.0373 5.52659 15.5426 6.40177C16.0482 7.27745 16.9051 7.82378 17.8325 7.96548C17.9423 8.4937 18 9.04098 18 9.60178C18 10.1625 17.9423 10.7099 17.8325 11.238C16.9051 11.3798 16.0482 11.9261 15.5426 12.8018C15.0373 13.6769 14.9924 14.6916 15.3327 15.5652C14.5205 16.2921 13.5589 16.8555 12.4996 17.2035C11.9131 16.471 11.0114 16.0018 10 16.0018C8.98864 16.0018 8.08685 16.471 7.50044 17.2035C6.44116 16.8555 5.47952 16.2921 4.66727 15.5652C5.00762 14.6916 4.96272 13.6769 4.45744 12.8018C3.95186 11.9261 3.0949 11.3798 2.16748 11.238C2.0577 10.7099 2 10.1625 2 9.60178C2 9.04098 2.0577 8.4937 2.16748 7.96548C3.0949 7.82378 3.95186 7.27745 4.45744 6.40177C4.96272 5.52659 5.00762 4.51194 4.66727 3.63827ZM11.2 11.6803C12.3479 11.0175 12.7412 9.5497 12.0785 8.40178C11.4157 7.25386 9.94792 6.86056 8.8 7.5233C7.6521 8.18602 7.2588 9.65386 7.92154 10.8018C8.58432 11.9497 10.0521 12.343 11.2 11.6803Z"
        fill="#686A7B"
      />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  path {
    fill: currentColor;
  }
`
