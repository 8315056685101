import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const TransactionHistoryIcon: React.FC<Props> = ({ className = '', ...props }) => {
  return (
    <StyledSvg width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path d="M10 18C5.58172 18 2 14.4183 2 9.99997C2 5.58172 5.58172 2 10 2C14.4182 2 18 5.58172 18 9.99997C18 14.4183 14.4182 18 10 18ZM10 7.59999H7.59998C7.15816 7.59999 6.8 7.95816 6.8 8.39998C6.8 8.8418 7.15816 9.19997 7.59998 9.19997H14L10 5.2V7.59999ZM6 10.8L10 14.8V12.4H12.4C12.8418 12.4 13.2 12.0418 13.2 11.6C13.2 11.1581 12.8418 10.8 12.4 10.8H6Z" />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  path {
    fill: currentColor;
  }
`
