import { ICurrency } from "../data/interfaces/ICurrency"

export interface IMinMaxBonusAmount {
  inCurrentCurrency: boolean,
  value: number
}

export const getBonusMaxAmount = (bonus: any, currency?: ICurrency) => {
  const maxAmounts = bonus?.maxAmounts as { amount: string, currency: string }[]

  const maxAmount = {
    inCurrentCurrency: true,
    value: Number(bonus?.maxAmount)
  }

  if (!currency) {
    return maxAmount
  }

  if (maxAmounts && maxAmounts.length) {
    const maxAmountConfig = maxAmounts.find(amount => amount.currency === currency?.iso)

    if (maxAmountConfig) {
      maxAmount.inCurrentCurrency = true
      maxAmount.value = Number(maxAmountConfig.amount)
    }
  }

  return maxAmount
}

export const getBonusMinAmount = (minAmountPropertyValue: any, currency?: ICurrency, minAmountKey = 'amount') => {
  const minAmounts = minAmountPropertyValue?.minAmounts as { amount: string, currency: string }[]

  const minAmount = {
    inCurrentCurrency: false,
    value: Number(minAmountPropertyValue?.[minAmountKey])
  }

  if (!currency) {
    return minAmount
  }

  if (minAmounts && minAmounts.length) {
    const minAmountConfig = minAmounts.find(amount => amount.currency === currency?.iso)

    if (minAmountConfig) {
      minAmount.inCurrentCurrency = true
      minAmount.value = Number(minAmountConfig.amount)
    }
  }

  return minAmount
}


export const getCurrentLevels = (currentLevel = {}, levels) => {
  let reachedLevel = null
  let nextLevel = null

  for (let i = 0; i < levels.length; i++) {
    if (levels[i]?.level?.points <= currentLevel?.points) {
    // if (levels[i]?.level?.points <= currentLevel?.points) {
      reachedLevel = levels[i]
    } else {
      nextLevel = levels[i]
      break
    }
  }

  const pointsDifference = (nextLevel?.level?.points - reachedLevel?.level?.points) || 0
  const levelPointsAchieved = (currentLevel?.points - reachedLevel?.level?.points) || 0

  return {
    reachedLevel,
    nextLevel,
    percentage: Math.floor(Math.min(100, Math.max(0, (levelPointsAchieved / pointsDifference) * 100))) || 0
  }
}
