import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const StarFullIcon: React.FC<Props> = ({ className = '', ...props }) => {
  return (
    <StyledSvg width="16"
      height="16"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path fill={props.color}
        d="M0.769315 6.00091C0.85314 5.73459 1.01063 5.49968 1.2222 5.32537C1.43377 5.15106 1.69011 5.04503 1.95934
        5.02047L5.88932 4.66817L7.42684 0.870757C7.53261 0.612516 7.70936 0.392248 7.93508 0.237407C8.16079 0.082566
        8.42548 0 8.69616 0C8.96683 0 9.23152 0.082566 9.45724 0.237407C9.68295 0.392248 9.85971 0.612516 9.96547
        0.870757L11.5051 4.66817L15.435 5.02047C15.7057 5.0437 15.9638 5.14927 16.1768 5.32394C16.3898 5.49861 16.5483
        5.73459 16.6324 6.00227C16.7165 6.26996 16.7225 6.55743 16.6495 6.82861C16.5766 7.09979 16.428 7.34261 16.2224
        7.52659L13.2356 10.2278L14.1319 14.2467C14.1934 14.5204 14.1757 14.8068 14.081 15.0701C13.9863 15.3333 13.8188
        15.5618 13.5995 15.727C13.3802 15.8922 13.1187 15.9867 12.8479 15.9987C12.577 16.0107 12.3088 15.9397 12.0766
        15.7946L8.69823 13.6679L5.31982 15.7946C5.0877 15.9395 4.81952 16.0103 4.5488 15.9982C4.27807 15.986 4.01679
        15.8915 3.7976 15.7264C3.5784 15.5612 3.411 15.3328 3.31632 15.0697C3.22163 14.8065 3.20385 14.5203 3.2652
        14.2467L4.16151 10.2278L1.17472 7.52659C0.969679 7.34194 0.821817 7.09863 0.749693 6.82721C0.677568 6.55578
        0.684395 6.26833 0.769315 6.00091Z"
      />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  path {
    fill: currentColor;
  }
`
