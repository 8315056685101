import { FC, useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import BottomSheetLayout from 'components/layout/BottomSheetLayout'
import BottomSheetBody from 'components/layout/BottomSheetBody'
import ProfileModalLayout from 'components/Profile/layout/ProfileModalLayout'
import ProfileModalBody from 'components/Profile/layout/ProfileModalBody'
import BonusSmallBanner from 'components/for_pages/Common/BonusSmallBanner'
import { StepMethod } from 'components/Profile/Wallet/StepMethod/StepMethod'
import { StepCurrency } from 'components/Profile/Wallet/StepCurrency/StepCurrency'
import { WalletHeader } from 'components/Profile/Wallet/WalletHeader/WalletHeader'
import { StepFormFiat } from 'components/Profile/Wallet/StepFormFiat/StepFormFiat'
import { StepForm } from 'components/Profile/Wallet/StepForm/StepForm'
import { useAppContext } from 'context/AppContext'
import { IPaymentMethod } from 'data/interfaces/IPaymentMethod'
import { IPaymentSystem } from 'data/interfaces/IPaymentSystem'
import PaymentMethodRepository from 'data/repositories/PaymentMethodRepository'
import { ICurrency } from 'data/interfaces/ICurrency'
import { IDepositCryptoResponse, IDepositResponse } from 'data/interfaces/IPaymentDeposit'
import BalanceTransactionRepository from 'data/repositories/BalanceTransationRepository'
import { BalanceTransactionType } from 'data/interfaces/IBalanceTransaction'

import StepCrypto from './StepCrypto'
import { WalletStep, FIRST_STEP } from './store/Wallet.types'
import styles from './Wallet.module.scss'

interface Props {
  isBottomSheet?: boolean
}

export const Wallet: FC<Props> = (props) => {
  const appContext = useAppContext()

  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([])
  const [paymentMethod, setPaymentMethod] = useState<
    IPaymentMethod | undefined
  >()
  const [paymentSystem, setPaymentSystem] = useState<
    IPaymentSystem | undefined
  >()
  const [currency, setCurrency] = useState<ICurrency | undefined>()
  const [depositResponse, setDepositResponse] = useState<IDepositResponse | null>(null)
  const [isDepositExist, toggleDepositExistingStatus] = useState(false)

  useEffect(() => {
    const checkIsDepositExist = async () => {
      const response = await BalanceTransactionRepository.fetchTransactions([BalanceTransactionType.Deposit], 1, 1)
      toggleDepositExistingStatus(response.total > 0)
    }

    checkIsDepositExist()
  }, [])

  const handleCryptoDepositSubmit = (deposit: IDepositResponse) => {
    setDepositResponse(deposit)
    setStep(WalletStep.SuccessCrypto)
  }

  const { isLoading: isPaymentMethodsLoading } = useQuery(['fetchDeposit'], PaymentMethodRepository.fetchDeposit, {
    onSuccess: (response) => {
      setPaymentMethods(response)
    },
  })

  const [step, setStep] = useState<WalletStep>(FIRST_STEP)

  const nextStep = (step?: WalletStep) => {
    if (step === WalletStep.Method) {
      setStep(paymentMethod?.isCrypto ? WalletStep.Currency : WalletStep.Form)
    } else {
      setStep((prevState) => step || ++prevState)
    }
  }

  const handleBack = () => {
    if (step === FIRST_STEP) {
      appContext.hideModal()
      return
    }

    if (step === WalletStep.Form) {
      setStep(paymentMethod?.isCrypto ? WalletStep.Currency : WalletStep.Method)
    } else {
      setStep((prevState) => --prevState)
    }
  }

  const goToPaymentMethodStep = () => setStep(WalletStep.Method)
  const goToCurrencyStep = () => setStep(WalletStep.Currency)

  const handlePaymentMethodSelect = (
    paymentMethod: IPaymentMethod,
    paymentSystem?: IPaymentSystem,
  ) => {
    setPaymentMethod(paymentMethod)
    setPaymentSystem(paymentSystem)
    setCurrency(undefined)

    nextStep(paymentMethod.isCrypto ? WalletStep.Currency : WalletStep.Form)
  }

  const handleCurrencySelect = (
    currency: ICurrency,
    paymentSystem: IPaymentSystem,
  ) => {
    setCurrency(currency)
    setPaymentSystem(paymentSystem)

    nextStep()
  }


  const component = (
    <div className={styles.box}>
      {appContext.showBonus && <BonusSmallBanner style="wallet" />}

      {step === WalletStep.Method && (
        <StepMethod
          paymentMethods={paymentMethods}
          onSelect={handlePaymentMethodSelect}
          loading={isPaymentMethodsLoading}
        />
      )}

      {step === WalletStep.Currency && (
        <StepCurrency
          paymentMethod={paymentMethod!}
          paymentSystem={paymentSystem}
          onSelect={handleCurrencySelect}
          onPaymentMethodClick={goToPaymentMethodStep}
        />
      )}

      {step === WalletStep.Form &&
        (paymentMethod?.isCrypto ? (
          <StepForm
            paymentMethod={paymentMethod!}
            paymentSystem={paymentSystem!}
            currency={currency!}
            onPaymentMethodClick={goToPaymentMethodStep}
            onCurrencyClick={goToCurrencyStep}
            onSubmit={handleCryptoDepositSubmit}
            isFirstDeposit={!isDepositExist}
          />
        ) : (
          <StepFormFiat
            paymentSystem={paymentSystem!}
            currency={
              appContext.currencies.find(
                (currency) => currency.iso === appContext.user.currencyIso,
              )!
            }
            onPaymentSystemClick={goToPaymentMethodStep}
            isFirstDeposit={!isDepositExist}
          />
        ))}

      {step === WalletStep.SuccessCrypto && (
        <StepCrypto method={paymentMethod}
          currency={currency}
          response={depositResponse as IDepositCryptoResponse}
          onSetStep={setStep}
        />
      )}
    </div>
  )

  if (props.isBottomSheet) {
    return (
      <BottomSheetLayout>
        <WalletHeader isBottomSheet />

        <BottomSheetBody>
          {component}
        </BottomSheetBody>
      </BottomSheetLayout>
    )
  }
  return (
    <ProfileModalLayout fixed>
      <WalletHeader
        showBack={step !== FIRST_STEP}
        onBackClick={step === FIRST_STEP ? undefined : handleBack}
      />

      <ProfileModalBody fixed>
        {component}
      </ProfileModalBody>
    </ProfileModalLayout>
  )
}
