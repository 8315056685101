import React from 'react'

interface Props {
  color?: string
  className?: string
}

const Close: React.FC<Props> = ({ color, className = '' }) => (
  <svg viewBox="0 0 16 16"
    width="16"
    height="16"
    fill="none"
    className={className}
  >
    <path d="M2.22419 0.810985C2.03559 0.628827 1.78299 0.528033 1.52079 0.530311C1.2586 0.53259 1.00778 0.637759 0.822376 0.823167C0.636968 1.00858 0.531799 1.25939 0.52952 1.52158C0.527242 1.78378 0.628036 2.03638 0.810194 2.22499L6.58519 7.99999L0.809194 13.775C0.713684 13.8672 0.637502 13.9776 0.585093 14.0996C0.532684 14.2216 0.505098 14.3528 0.503944 14.4856C0.50279 14.6184 0.528092 14.75 0.578373 14.8729C0.628653 14.9958 0.702907 15.1075 0.796799 15.2014C0.890692 15.2953 1.00234 15.3695 1.12524 15.4198C1.24814 15.4701 1.37982 15.4954 1.5126 15.4942C1.64537 15.4931 1.77659 15.4655 1.8986 15.4131C2.0206 15.3607 2.13095 15.2845 2.22319 15.189L7.99919 9.41399L13.7742 15.189C13.9628 15.3711 14.2154 15.4719 14.4776 15.4697C14.7398 15.4674 14.9906 15.3622 15.176 15.1768C15.3614 14.9914 15.4666 14.7406 15.4689 14.4784C15.4711 14.2162 15.3704 13.9636 15.1882 13.775L9.41319 7.99999L15.1882 2.22499C15.3704 2.03638 15.4711 1.78378 15.4689 1.52158C15.4666 1.25939 15.3614 1.00858 15.176 0.823167C14.9906 0.637759 14.7398 0.53259 14.4776 0.530311C14.2154 0.528033 13.9628 0.628827 13.7742 0.810985L7.99919 6.58599L2.22419 0.809985V0.810985Z"
      fill={color}
    />
  </svg>
)

export default Close
