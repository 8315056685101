import { getSelectorsByUserAgent } from 'react-device-detect'
import { breakpoints } from 'scss/constants'

import { isServer } from './media'

export const getIsMobile = (userAgent: string): boolean => {
  const { isMobile, isTablet } = getSelectorsByUserAgent(userAgent)

  if (!isServer && isTablet && window?.screen.width >= breakpoints.lg) {
    return false
  } else {
    return isMobile
  }
}

export const setMobileClass = (isMobile: boolean): void => {
  if (!isMobile) return

  document.body.classList.add('mobile-ua')
  document.documentElement.className = 'mobile-ua'
}


// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const getIsMobileForServer = (req) => {
  const userAgent = req?.headers['user-agent'] || ''

  return /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/.test(userAgent)
}
