import { FC, useMemo } from 'react'

import CurrencySvg from 'components/svg/CurrencySvg/CurrencySvg'
import { PaymentMethodList } from 'components/Profile/Wallet/PaymentMethodList'
import { PaymentMethodCard } from 'components/Profile/Wallet/PaymentMethodCard'
import { PaymentMethodSelected } from 'components/Profile/Wallet/PaymentMethodSelected'
import { PaymentOptions } from 'components/Profile/Wallet/PaymentOptions'
import { PaymentSeparator } from 'components/Profile/Wallet/PaymentSeparator'
import { useAppContext } from 'context/AppContext'
import { ICurrency } from 'data/interfaces/ICurrency'
import { IPaymentMethod } from 'data/interfaces/IPaymentMethod'
import { IPaymentSystem } from 'data/interfaces/IPaymentSystem'
import { CURRENCY_ISO, PAYMENT_SYSTEM_NAME } from 'utils'

import { checkPaymentSystem } from '../../Wallet/store/Wallet.helpers'

import classes from './StepCurrency.module.scss'

interface ICurrencyWithPaymentSystem extends ICurrency {
  paymentSystem: IPaymentSystem
}

interface Props {
  paymentMethod: IPaymentMethod
  paymentSystem?: IPaymentSystem
  onSelect: (currency: ICurrency, paymentSystem: IPaymentSystem) => void
  onPaymentMethodClick: () => void
}

export const StepCurrency: FC<Props> = ({
  paymentMethod,
  paymentSystem,
  onSelect,
  onPaymentMethodClick,
}) => {
  const appContext = useAppContext()

  const currencies = useMemo<ICurrencyWithPaymentSystem[]>(() => {
    const currenciesIso: string[] = []

    if (paymentMethod.isCrypto) {
      currenciesIso.push(
        ...paymentMethod.paymentSystems
          .map((paymentSystem) =>
            paymentSystem.settings.map((setting) => setting.currencyIso),
          )
          .flat(),
      )
    } else {
      if (paymentSystem) {
        currenciesIso.push(
          ...paymentSystem.settings.map((setting) => setting.currencyIso),
        )
      }
    }

    const currencies = appContext.currencies
      .filter((currency) => currenciesIso.includes(currency.iso))
      .map((currency) => ({
        ...currency,
        paymentSystem:
          checkPaymentSystem(currency.iso, paymentSystem) ||
          paymentMethod.paymentSystems.find((paymentSystem) =>
            paymentSystem.settings.find(
              (settings) => settings.currencyIso === currency.iso,
            ),
          )!,
      }))

    const usdtIndex = currencies.findIndex(
      (currency) => currency.iso.toUpperCase() === CURRENCY_ISO.USDT,
    )

    if (usdtIndex !== -1 && paymentMethod.isCrypto) {
      const currenciesUpdated = [...currencies]

      const currencyUsdt = {
        ...currencies[usdtIndex],
        name: CURRENCY_ISO.USDT_TRC20,
        paymentSystem: paymentMethod.paymentSystems.find(
          (paymentSystem) => paymentSystem.name === PAYMENT_SYSTEM_NAME.TRC20,
        )!,
      }

      currenciesUpdated.splice(usdtIndex, 0, currencyUsdt)

      return currenciesUpdated
    }

    return currencies
  }, [paymentMethod, paymentSystem])

  return (
    <div className={classes.box}>
      <PaymentOptions>
        <PaymentMethodSelected
          method={paymentMethod}
          paymentSystem={paymentSystem}
          onClick={onPaymentMethodClick}
        />
      </PaymentOptions>

      <PaymentSeparator />

      <PaymentMethodList>
        {currencies.map((currency) => (
          <PaymentMethodCard
            key={currency.iso}
            label={currency.name}
            icon={(
              <CurrencySvg currencyIso={currency.iso}
                color
              />
            )}
            onClick={() => onSelect(currency, currency.paymentSystem)}
          />
        ))}
      </PaymentMethodList>
    </div>
  )
}
