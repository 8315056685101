
import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const PlusSvg: React.FC<Props> = ({ className = '', color = 'white', ...props }) => {
  return (
    <StyledSvg
      width="12"
      height="13"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path d="M4.8 1.98633C4.8 1.43404 5.24772 0.986328 5.8 0.986328H6.2C6.75229 0.986328 7.2 1.43404 7.2 1.98633V11.9863C7.2 12.5386 6.75229 12.9863 6.2 12.9863H5.8C5.24771 12.9863 4.8 12.5386 4.8 11.9863V1.98633Z"
        fill={color}
      />

      <path d="M1 8.18633C0.447715 8.18633 8.5335e-08 7.73861 6.11944e-08 7.18633L4.37103e-08 6.78633C1.95697e-08 6.23404 0.447715 5.78633 1 5.78633L11 5.78633C11.5523 5.78633 12 6.23404 12 6.78633V7.18633C12 7.73861 11.5523 8.18633 11 8.18633L1 8.18633Z"
        fill={color}
      />
    </StyledSvg>

  )
}

const StyledSvg = styled.svg`

`
