import { FC, useMemo, useRef, useState } from 'react'

import { table } from './utils'
import { STable } from './styles'


const Table: FC = ({ children, mode = 'default' }) => {
  const [cols, setCols] = useState([]),
    ready = useRef(false)

  const value = useMemo(() => ({
    ready: ready.current,
    cols,
    mode,
    setCols: value => {
      ready.current = true
      setCols(value)
    }
  }), [cols])

  return (
    <STable>
      <table.Provider value={value}>
        {children}
      </table.Provider>
    </STable>
  )
}


export default Table
