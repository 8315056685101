import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const InfoIcon: React.FC<Props> = ({ className = '', ...props }) => {
  return (
    <StyledSvg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      className={className}
      {...props}
    >
      <path
        d="M11 16.5C11.3117 16.5 11.5731 16.3944 11.7843 16.1832C11.9948 15.9727 12.1 15.7117 12.1 15.4V10.9725C12.1 10.6608 11.9948 10.4042 11.7843 10.2025C11.5731 10.0008 11.3117 9.9 11 9.9C10.6883 9.9 10.4273 10.0052 10.2168 10.2157C10.0056 10.4269 9.9 10.6883 9.9 11V15.4275C9.9 15.7392 10.0056 15.9958 10.2168 16.1975C10.4273 16.3992 10.6883 16.5 11 16.5ZM11 7.7C11.3117 7.7 11.5731 7.5944 11.7843 7.3832C11.9948 7.17273 12.1 6.91167 12.1 6.6C12.1 6.28833 11.9948 6.0269 11.7843 5.8157C11.5731 5.60523 11.3117 5.5 11 5.5C10.6883 5.5 10.4273 5.60523 10.2168 5.8157C10.0056 6.0269 9.9 6.28833 9.9 6.6C9.9 6.91167 10.0056 7.17273 10.2168 7.3832C10.4273 7.5944 10.6883 7.7 11 7.7ZM11 22C9.47833 22 8.04833 21.7111 6.71 21.1332C5.37167 20.5561 4.2075 19.7725 3.2175 18.7825C2.2275 17.7925 1.44393 16.6283 0.8668 15.29C0.288933 13.9517 0 12.5217 0 11C0 9.47833 0.288933 8.04833 0.8668 6.71C1.44393 5.37167 2.2275 4.2075 3.2175 3.2175C4.2075 2.2275 5.37167 1.44357 6.71 0.8657C8.04833 0.288567 9.47833 0 11 0C12.5217 0 13.9517 0.288567 15.29 0.8657C16.6283 1.44357 17.7925 2.2275 18.7825 3.2175C19.7725 4.2075 20.5561 5.37167 21.1332 6.71C21.7111 8.04833 22 9.47833 22 11C22 12.5217 21.7111 13.9517 21.1332 15.29C20.5561 16.6283 19.7725 17.7925 18.7825 18.7825C17.7925 19.7725 16.6283 20.5561 15.29 21.1332C13.9517 21.7111 12.5217 22 11 22ZM11 19.8C13.4383 19.8 15.5148 18.9431 17.2293 17.2293C18.9431 15.5148 19.8 13.4383 19.8 11C19.8 8.56167 18.9431 6.48523 17.2293 4.7707C15.5148 3.0569 13.4383 2.2 11 2.2C8.56167 2.2 6.4856 3.0569 4.7718 4.7707C3.05727 6.48523 2.2 8.56167 2.2 11C2.2 13.4383 3.05727 15.5148 4.7718 17.2293C6.4856 18.9431 8.56167 19.8 11 19.8Z"
      />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  path {
    fill: currentColor;
  }
`
