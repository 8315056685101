const customStyle = `
  Tawk_API.customStyle = {
    visibility : {
      desktop : {
        yOffset : '70px'
      }
    }
  };
`

export const TAWK_SCRIPTS = {
  en: `
    var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    ${customStyle}
    (function(){
    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    s1.async=true;
    s1.src='https://embed.tawk.to/64f84e43a91e863a5c11f395/1h9kv3elb';
    s1.charset='UTF-8';
    s1.setAttribute('crossorigin','*');
    s0.parentNode.insertBefore(s1,s0);
    })();
  `,
  ru: `
    var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    ${customStyle}
    (function(){
    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    s1.async=true;
    s1.src='https://embed.tawk.to/64f84e43a91e863a5c11f395/1ha272npd';
    s1.charset='UTF-8';
    s1.setAttribute('crossorigin','*');
    s0.parentNode.insertBefore(s1,s0);
    })();
  `,
  es: `
    var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    ${customStyle}
    (function(){
    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    s1.async=true;
    s1.src='https://embed.tawk.to/64f84e43a91e863a5c11f395/1ha278hkv';
    s1.charset='UTF-8';
    s1.setAttribute('crossorigin','*');
    s0.parentNode.insertBefore(s1,s0);
    })();
  `,
  pt: `
    var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    ${customStyle}
    (function(){
    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    s1.async=true;
    s1.src='https://embed.tawk.to/64f84e43a91e863a5c11f395/1ha27al5i';
    s1.charset='UTF-8';
    s1.setAttribute('crossorigin','*');
    s0.parentNode.insertBefore(s1,s0);
    })();
  `,
  bn: `
    var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    ${customStyle}
    (function(){
    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    s1.async=true;
    s1.src='https://embed.tawk.to/64f84e43a91e863a5c11f395/1ha27cqr5';
    s1.charset='UTF-8';
    s1.setAttribute('crossorigin','*');
    s0.parentNode.insertBefore(s1,s0);
    })();
  `,
  az: `
    var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    ${customStyle}
    (function(){
    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    s1.async=true;
    s1.src='https://embed.tawk.to/64f84e43a91e863a5c11f395/1ha27eieu';
    s1.charset='UTF-8';
    s1.setAttribute('crossorigin','*');
    s0.parentNode.insertBefore(s1,s0);
    })();
  `,
  tr: `
    var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    ${customStyle}
    (function(){
    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    s1.async=true;
    s1.src='https://embed.tawk.to/64f84e43a91e863a5c11f395/1ha27gv30';
    s1.charset='UTF-8';
    s1.setAttribute('crossorigin','*');
    s0.parentNode.insertBefore(s1,s0);
    })();
  `
}
