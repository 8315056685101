import { runtimeConfig } from 'config/runtimeConfig'

type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[];
}

declare const window: WindowWithDataLayer

export const analytics = {
  dataLayerPush: (data: Record<string, any>) => {
    if (runtimeConfig.DEV) {
      console.log('Analytics event: ', data)
    }

    if (!window.dataLayer) {
      console.log('window.dataLayer doesn\'t exist')

      return
    }

    window.dataLayer.push(data)
  }
}
