import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const WalletPlusSvg: React.FC<Props> = ({ className = '', color = 'white', ...props }) => {
  return (
    <StyledSvg
      width="20"
      height="20"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M4.40625 5.56184H16.125C16.6223 5.56184 17.0992 5.76269 17.4508 6.1202C17.8025 6.47772 18 6.9626 18 7.4682V15.0936C18 15.5992 17.8025 16.0841 17.4508 16.4416C17.0992 16.7992 16.6223 17 16.125 17H4.875C4.37772 17 3.90081 16.7992 3.54917 16.4416C3.19754 16.0841 3 15.5992 3 15.0936V14.4649C3.58835 14.8052 4.27143 15 5 15C7.20914 15 9 13.2091 9 11C9 8.79086 7.20914 7 5 7C4.27143 7 3.58835 7.19479 3 7.53513V5.56184C3 4.51335 3.83531 3.53443 4.85625 3.38574L14.2697 2.01888C15.2897 1.87113 16.125 2.60699 16.125 3.65548V5.08525H14.7188V4.24646L4.40625 5.08525V5.56184ZM14.1805 12.1308C14.3511 12.2027 14.534 12.2398 14.7188 12.2398C15.0918 12.2399 15.4497 12.0894 15.7136 11.8212C15.9775 11.5531 16.1258 11.1894 16.1259 10.8101C16.1261 10.4307 15.978 10.0669 15.7143 9.79857C15.4505 9.53026 15.0928 9.37945 14.7197 9.37933C14.535 9.37927 14.352 9.4162 14.1813 9.48802C14.0106 9.55984 13.8555 9.66513 13.7248 9.7979C13.5942 9.93067 13.4905 10.0883 13.4198 10.2618C13.349 10.4353 13.3126 10.6213 13.3125 10.8091C13.3124 10.9969 13.3488 11.1829 13.4194 11.3565C13.49 11.53 13.5936 11.6877 13.7242 11.8206C13.8548 11.9534 14.0098 12.0588 14.1805 12.1308Z"
        fill={color}
      />

      <path d="M7.03091 10.401V11.49H5.54591V12.975H4.45691V11.49H2.98091V10.401H4.45691V8.92498H5.54591V10.401H7.03091Z"
        fill={color}
      />

    </StyledSvg>

  )
}

const StyledSvg = styled.svg`

`
