import { IPaymentSystem } from '../../../../data/interfaces/IPaymentSystem'

export function checkPaymentSystem(
  currencyIso: string,
  paymentSystem?: IPaymentSystem,
): IPaymentSystem | undefined {
  const isPaymentSystemHasCurrency = paymentSystem?.settings.some(
    (settings) => settings.currencyIso === currencyIso,
  )

  return isPaymentSystemHasCurrency ? paymentSystem : undefined
}
