import React from 'react'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  color?: string
  className?: string
}

export const CopySvg: React.FC<Props> = ({ className, color = 'white', ...props }) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path d="M15.805 6.82871L12.475 3.32878C12.4049 3.25626 12.3193 3.19825 12.2237 3.15847C12.1281 3.11868 12.0247 3.09801 11.92 3.09778H5.92C5.67081 3.09501 5.42348 3.13807 5.19213 3.22452C4.96077 3.31097 4.74993 3.4391 4.57164 3.6016C4.39334 3.7641 4.2511 3.95779 4.15301 4.17161C4.05493 4.38543 4.00294 4.61518 4 4.84775V15.3476C4.00294 15.5801 4.05493 15.8099 4.15301 16.0237C4.2511 16.2375 4.39334 16.4312 4.57164 16.5937C4.74993 16.7562 4.96077 16.8843 5.19213 16.9708C5.42348 17.0572 5.67081 17.1003 5.92 17.0975H14.08C14.3292 17.1003 14.5765 17.0572 14.8079 16.9708C15.0392 16.8843 15.2501 16.7562 15.4284 16.5937C15.6067 16.4312 15.7489 16.2375 15.847 16.0237C15.9451 15.8099 15.9971 15.5801 16 15.3476V7.29771C15.9995 7.12422 15.93 6.95709 15.805 6.82871ZM11.5 4.49776L14.305 7.29771H12.055C11.9765 7.29328 11.8997 7.2743 11.8291 7.24187C11.7585 7.20944 11.6955 7.16421 11.6438 7.10882C11.5922 7.05344 11.5528 6.989 11.5281 6.91927C11.5034 6.84955 11.4939 6.77593 11.5 6.70272V4.49776Z"
        fill={color}
      />
    </svg>
  )
}
