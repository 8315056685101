
import { HeaderMobile } from 'components/Profile/layout/ProfileModalHeader/HeaderMobile/HeaderMobile'
import { HeaderDesktop } from 'components/Profile/layout/ProfileModalHeader/HeaderDesktop/HeaderDesktop'
import { useAppContext } from 'context/AppContext'

import styles from './ModalHeader.module.scss'

interface Props {
  title?: string
  showId?: boolean
  showBack?: boolean
  onBackClick?: () => void
}

export default function ModalHeader(props: Props) {
  const appContext = useAppContext()

  const handleBackClick = () => {
    if (props.onBackClick) {
      props.onBackClick()
    } else {
      appContext.goBackModalProfile()
    }
  }

  return (
    <div className={styles.root}>
      {appContext.isMobile ? (
        <HeaderMobile
          title={props.title}
          onBackClick={handleBackClick}
        />
      ) : (
        <HeaderDesktop
          title={props.title}
          showId={props.showId}
          showBack={props.showBack}
          onBackClick={handleBackClick}
        />
      )}
    </div>
  )
}
