import { FC, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'next-i18next'
import BottomSheetLayout from 'components/layout/BottomSheetLayout'
import BottomSheetHeader from 'components/layout/BottomSheetHeader'
import BottomSheetBody from 'components/layout/BottomSheetBody'
import ProfileModalLayout from 'components/Profile/layout/ProfileModalLayout'
import ModalHeader from 'components/Profile/layout/ProfileModalHeader/ModalHeader'
import ProfileModalBody from 'components/Profile/layout/ProfileModalBody'
import { useAppContext } from 'context/AppContext'
import PaymentMethodRepository from 'data/repositories/PaymentMethodRepository'
import { IPaymentMethod } from 'data/interfaces/IPaymentMethod'
import { IPaymentSystem } from 'data/interfaces/IPaymentSystem'
import { ICurrency } from 'data/interfaces/ICurrency'

import { FIRST_STEP, WithdrawStep } from './store/Withdraw.types'
import { StepForm } from './StepForm/StepForm'
import { StepCurrency } from './StepCurrency/StepCurrency'
import { StepMethod } from './StepMethod/StepMethod'
import classes from './Withdraw.module.scss'
import { StepInfo } from './StepInfo/StepInfo'

interface Props {
  isBottomSheet?: boolean
}

export const Withdraw: FC<Props> = (props) => {
  const { t } = useTranslation()
  const appContext = useAppContext()

  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([])
  const [paymentMethod, setPaymentMethod] = useState<
    IPaymentMethod | undefined
  >()
  const [paymentSystem, setPaymentSystem] = useState<
    IPaymentSystem | undefined
  >()
  const [currency, setCurrency] = useState<ICurrency | undefined>()

  useQuery(['fetchWithdraw'], () => PaymentMethodRepository.fetchWithdraw(), {
    onSuccess: (paymentMethods) => {
      setPaymentMethods(paymentMethods)
    },
  })

  const [step, setStep] = useState<WithdrawStep>(FIRST_STEP)

  const nextStep = () => setStep((prevState) => ++prevState)

  const handleBack = () => {
    if (step === FIRST_STEP) {
      appContext.hideModal()
    } else {
      setStep((prevState) => --prevState)
    }
  }
  const goToPaymentMethodStep = () => setStep(WithdrawStep.Method)
  const goToCurrencyStep = () => setStep(WithdrawStep.Currency)
  const goToInfoStep = () => setStep(WithdrawStep.Info)

  const handlePaymentMethodSelect = (
    paymentMethod: IPaymentMethod,
    paymentSystem?: IPaymentSystem,
  ) => {
    setPaymentMethod(paymentMethod)
    setPaymentSystem(paymentSystem)
    setCurrency(undefined)
    nextStep()
  }

  const handleCurrencySelect = (
    currency: ICurrency,
    paymentSystem: IPaymentSystem,
  ) => {
    setCurrency(currency)
    setPaymentSystem(paymentSystem)

    nextStep()
  }

  const component = (
    <div>
      {step === WithdrawStep.Method && (
        <StepMethod
          paymentMethods={paymentMethods}
          onSelect={handlePaymentMethodSelect}
        />
      )}

      {step === WithdrawStep.Currency && (
        <StepCurrency
          paymentMethod={paymentMethod!}
          paymentSystem={paymentSystem}
          onSelect={handleCurrencySelect}
          onPaymentMethodClick={goToPaymentMethodStep}
        />
      )}

      {step === WithdrawStep.Form && (
        <StepForm
          paymentMethod={paymentMethod!}
          paymentSystem={paymentSystem!}
          currency={currency!}
          onPaymentMethodClick={goToPaymentMethodStep}
          onCurrencyClick={goToCurrencyStep}
          onGoToInfoStep={goToInfoStep}
        />
      )}

      {
        step === WithdrawStep.Info && (
          <StepInfo />
        )
      }
    </div>
  )

  if (props.isBottomSheet) {
    return (
      <BottomSheetLayout>
        <BottomSheetHeader
          title={t('withdraw_title')}
          suffix={(
            <div className={classes.user_id}>
ID
              {appContext.user?.id}
            </div>
          )}
        />

        <BottomSheetBody
          detectKeyboard={step === WithdrawStep.Form}
          footerHeight={74}
        >
          {component}
        </BottomSheetBody>
      </BottomSheetLayout>
    )
  }

  return (
    <ProfileModalLayout fixed>
      <ModalHeader
        title={t('withdraw_title')}
        showId
        showBack
        onBackClick={step === FIRST_STEP || step === WithdrawStep.Info ? undefined : handleBack}
      />

      <ProfileModalBody fixed>
        {component}
      </ProfileModalBody>
    </ProfileModalLayout>
  )
}
