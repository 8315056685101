import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const CheckIcon: React.FC<Props> = ({ className = '', ...props }) => {
  return (
    <StyledSvg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M3.15869 9.08213L3.1507 9.09012L4.86584 10.8053L11.956 3.71515L10.2408 2L4.87384 7.36698L1.71515 4.20829L0
        5.92343L3.15869 9.08213Z"
        fill={props.color}
      />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  path {
    fill: currentColor;
  }
`
