import React, { useMemo, useState } from 'react'

import styled from 'styled-components'
import InputMask from 'react-input-mask'
import classNames from 'classnames'
import { FieldConfig, useField } from 'formik'
import { useTranslation } from 'next-i18next'

import { COLORS, FONTS } from './constants'

type Props = React.InputHTMLAttributes<HTMLInputElement> & FieldConfig & {
  className?: string,
  prefixContent?: React.ReactNode,
  suffixContent?: React.ReactNode,
  mask?: string | RegExp[],
  label?: string,
  error?: string
  success?: string | null
}

export const Input: React.FC<Props> = React.forwardRef<HTMLInputElement, Props>(({
  className = '',
  mask,
  prefixContent,
  suffixContent,
  label,
  success,
  ...props
}, ref) => {
  const { t, i18n } = useTranslation()
  const [field, meta] = useField(props)
  const error = useMemo(() => meta?.error || props?.error || '', [meta, props])

  const [focused, toggleFocus] = useState(false)

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    toggleFocus(true)
    if (props.onFocus) props.onFocus(e)
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    toggleFocus(false)
    if (props.onBlur) props.onBlur(e)
  }

  return (
    <div className={className}>
      {!!label && (
        <Label>
          {label}
        </Label>
      )}

      <InputBox className={classNames('input-box', { error: !!error, success: !!success, focused })}>
        {!!prefixContent && prefixContent}

        {!!mask && (
          <StyledInputMask
            {...field}
            {...props}
            className="input"
            ref={ref}
            mask={mask}
            maskPlaceholder=""
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        )}

        {!mask && (
          <StyledInput
            {...field}
            {...props}
            className="input"
            ref={ref}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        )}

        {!!suffixContent && suffixContent}
      </InputBox>

      {
        !!error && (
          <ErrorBox>
            {i18n.exists(error) ? t(error) : error}
          </ErrorBox>
        )
      }
    </div>
  )
})

export const InputBox = styled.div`
  width: 100%;
  height: 44px;
  background: ${COLORS.dark550};
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 16px;
  border: 2px solid transparent;
  position: relative;

  &.focused {
    border-color: ${COLORS.blue};
  }

  &.error {
    border-color: ${COLORS.red};
  }

  &.success {
    border-color: ${COLORS.green};
  }
`

const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  background: transparent;
  ${FONTS.p1};
  color: ${COLORS.white};
  flex: 1;
  padding: 0;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    color: white;
    -webkit-text-fill-color: white;
    -webkit-box-shadow: 0 0 0 30px #272832 inset;
  }

  &::placeholder {
    ${FONTS.p1};
    color: ${COLORS.dark200};
  }
`

const StyledInputMask = styled(InputMask)`
  width: 100%;
  height: 100%;
  border: none;
  background: transparent;
  ${FONTS.p1};
  color: ${COLORS.white};
  flex: 1;
  padding: 0;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    color: white;
    -webkit-text-fill-color: white;
  }

  &::placeholder {
    ${FONTS.p1};
    color: ${COLORS.dark200};
  }
`

const Label = styled.p`
  margin-bottom: 4px;
  ${FONTS.p1};
  color: ${COLORS.dark200};
`

const ErrorBox = styled.p`
  margin-top: 8px;
  font-size: 12px;
  color: ${COLORS.red};
`
