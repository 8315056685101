import { FC } from 'react'

import classNames from 'classnames'

import { SButton } from './styles'

const Button: FC<{ toPage: () => void, active?: boolean}> = ({ toPage, active, children }) => (
  <SButton
    onClick={toPage}
    className={classNames( active  ? 'active' : '')}
  >
    {children}
  </SButton>
)

export default Button
