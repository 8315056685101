import React, { useMemo } from 'react'

import classNames from 'classnames'
import { useAppContext } from 'context/AppContext'
import Image from 'next/image'
import { runtimeConfig } from 'config/runtimeConfig'

import styles from './index.module.scss'
interface Props {
  currencyIso: string
  color?: boolean
  className?: string,
  size?: number
}

function CurrencySvg(props: Props) {
  const className = classNames(styles.root, props.className)
  const appContext = useAppContext()
  const currency = useMemo(() => {

    return appContext.currencies.find(i => i.iso.toLowerCase() === props.currencyIso.toLowerCase())
  }, [props.currencyIso, appContext.currencies])

  if(currency && currency.imageIconSmallUrl){
    return (
      <div className={className}>
        <Image  src={`${runtimeConfig.HOST}${currency.imageIconSmallUrl}`}
          width={props.size || 24}
          height={props.size || 24}
        />
      </div>
    )
  }
  switch (props.currencyIso?.toUpperCase()){
  case 'BTC':
    if(props.color){
      return (
        <svg className={className}
          width="120"
          height="120"
          viewBox="0 0 120 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          shapeRendering="geometricPrecision"
        >
          <path d="M118.218 74.5143C110.204 106.657 77.6445 126.219 45.4938 118.204C13.3567 110.19 -6.20719 77.6324 1.81066 45.4918C9.82148 13.3451 42.381 -6.21803 74.5216 1.79541C106.67 9.80884 126.233 42.3702 118.217 74.5149L118.218 74.5143H118.218Z"
            fill="#F7931A"
          />

          <path fillRule="evenodd"
            clipRule="evenodd"
            d="M86.4739 51.4507C87.6683 43.466 81.5886 39.1739 73.2751 36.3107L75.972 25.4947L69.3873 23.8542L66.7619 34.3853C65.0308 33.9536 63.253 33.5469 61.4861 33.1436L64.1306 22.5429L57.55 20.9023L54.8515 31.7148C53.419 31.3887 52.012 31.0664 50.647 30.7267L50.6546 30.6927L41.5741 28.4254L39.8224 35.4576C39.8224 35.4576 44.7078 36.5773 44.6048 36.6462C47.2713 37.3116 47.7535 39.0766 47.6734 40.4755L44.6013 52.7974C44.7849 52.844 45.0231 52.9115 45.2859 53.0171L45.0949 52.9694C44.9323 52.9287 44.7634 52.8865 44.5899 52.8449L40.2838 70.1062C39.958 70.9162 39.1308 72.1318 37.2666 71.6702C37.3326 71.7658 32.4807 70.476 32.4807 70.476L29.2115 78.0126L37.7804 80.1485C38.7188 80.3838 39.646 80.6255 40.5638 80.8647C41.2053 81.0319 41.8422 81.1979 42.4751 81.3597L39.7503 92.2997L46.3274 93.9403L49.0259 83.1164C50.8227 83.6041 52.5664 84.054 54.2734 84.4781L51.5841 95.251L58.1691 96.8915L60.8937 85.972C72.1221 88.0968 80.5649 87.2402 84.1186 77.0852C86.9822 68.9093 83.9761 64.1935 78.0689 61.1182C82.3714 60.1261 85.6122 57.2965 86.4763 51.4516L86.4742 51.4501L86.4739 51.4507ZM71.4293 72.5449C69.5623 80.046 57.8196 76.9449 52.4469 75.526L52.4464 75.5258C51.9633 75.3983 51.5317 75.2843 51.1635 75.1928L54.7794 60.6992C55.2283 60.8113 55.7769 60.9344 56.3984 61.0738L56.3985 61.0739C61.9559 62.3209 73.3422 64.8759 71.4296 72.5449H71.4293ZM57.5133 54.3474C61.9925 55.5427 71.764 58.1502 73.4657 51.3326H73.4663C75.204 44.3599 65.7078 42.2577 61.0699 41.231C60.5481 41.1155 60.0878 41.0136 59.7119 40.9199L56.4336 54.0648C56.7432 54.1419 57.1064 54.2388 57.5133 54.3474Z"
            fill="white"
          />
        </svg>
      )
    }else{
      return (
        <svg className={className}
          width="120"
          height="120"
          viewBox="0 0 120 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path fillRule="evenodd"
            clipRule="evenodd"
            d="M86.4641 51.4507C87.6584 43.466 81.5794 39.1739 73.2668 36.3107L75.9635 25.4947L69.3794 23.8542L66.7543 34.3853C65.0234 33.9536 63.2459 33.5469 61.4792 33.1436L64.1234 22.5429L57.5435 20.9023L54.8453 31.7148C53.413 31.3887 52.0061 31.0664 50.6413 30.7267L50.6489 30.6927L41.5694 28.4254L39.818 35.4576C39.818 35.4576 44.7027 36.5773 44.5998 36.6462C47.266 37.3116 47.7481 39.0766 47.668 40.4755L44.5963 52.7974C44.7799 52.844 45.018 52.9115 45.2808 53.0171L45.0898 52.9694L45.0896 52.9694C44.9271 52.9287 44.7582 52.8864 44.5848 52.8449L40.2793 70.1062C39.9534 70.9162 39.1264 72.1318 37.2624 71.6702C37.3283 71.7658 32.477 70.476 32.477 70.476L29.2082 78.0126L37.7762 80.1485C38.7144 80.3838 39.6415 80.6255 40.5593 80.8647L40.5611 80.8652C41.2019 81.0322 41.8381 81.1981 42.4703 81.3597L39.7458 92.2997L46.3222 93.9403L49.0203 83.1164C50.8169 83.6041 52.5604 84.054 54.2673 84.4781L51.5783 95.251L58.1626 96.8915L60.8868 85.972C72.1139 88.0968 80.5558 87.2402 84.1092 77.0852C86.9724 68.9093 83.9666 64.1935 78.0601 61.1182C82.3621 60.1261 85.6025 57.2965 86.4665 51.4516L86.4644 51.4501L86.4641 51.4507ZM71.4212 72.5448C69.5544 80.0459 57.8131 76.9447 52.441 75.5259C51.9578 75.3982 51.526 75.2842 51.1577 75.1927L54.7732 60.6991C55.2221 60.8112 55.7706 60.9343 56.392 61.0737L56.3922 61.0738C61.9489 62.3208 73.3339 64.8758 71.4215 72.5448H71.4212ZM57.5068 54.347C61.9855 55.5423 71.7559 58.1498 73.4574 51.3322H73.458C75.1955 44.3595 65.7004 42.2573 61.063 41.2306C60.5413 41.1151 60.081 41.0132 59.7052 40.9195L56.4273 54.0644C56.7368 54.1415 57.0999 54.2384 57.5068 54.347Z"
            fill="white"
          />
        </svg>
      )
    }
    break
  case 'BCH':
    if(props.color){
      return (
        <svg className={className}
          width="120"
          height="120"
          viewBox="0 0 120 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="60"
            cy="60"
            r="60"
            fill="#8DC351"
          />

          <path fillRule="evenodd"
            clipRule="evenodd"
            d="M79.5518 39.791C76.5487 32.3284 69.4162 31.9552 60.7822 33.4478L57.779 23L51.3974 24.8657L54.4005 34.9403C53.7922 35.0915 53.1223 35.3039 52.4157 35.5279C51.3781 35.8569 50.2616 36.2109 49.145 36.4328L46.1419 25.9851L39.7602 27.8507L42.7633 38.2985C42.0126 38.4851 41.3557 38.6716 40.6988 38.8582L40.6987 38.8582L40.6986 38.8582C40.0417 39.0448 39.3848 39.2313 38.634 39.4179L30 42.403L31.877 49.1194C31.877 49.1194 36.7571 47.6269 36.3817 47.6269C39.0094 46.8806 40.1356 48 40.8864 49.4925L44.2649 61.4328H45.0157L44.2649 61.806L49.145 78.597C49.145 79.3433 49.145 80.8358 47.2681 81.5821L42.7633 83.0746L43.5141 90.9104L51.7728 88.6716L56.2775 87.5522L59.2806 98L65.6623 96.1343L62.6591 85.6866C64.5361 85.3134 66.0377 84.9403 67.9146 84.194L70.9178 94.6418L77.2994 92.7761L74.2963 82.3284C84.8073 78.597 91.5644 73.7463 89.6874 63.2985C88.1858 55.0896 83.3057 52.4776 76.5487 52.8507C80.3026 49.1194 81.8042 45.3881 79.5518 39.791ZM77.2994 65.1642C79.2849 72.0716 68.727 75.2096 63.1115 76.8787L63.1113 76.8787C62.3566 77.103 61.6912 77.3008 61.1576 77.4776L57.0283 63.2985C57.6203 63.1514 58.3615 62.9334 59.2084 62.6843C64.805 61.038 75.0171 58.0341 77.2994 65.1642ZM56.198 56.6548C60.6862 55.387 70.3613 52.654 68.29 46.1343C66.5921 39.3833 57.2139 42.4038 52.6616 43.87C52.1816 44.0246 51.7554 44.1619 51.3974 44.2687L55.1513 56.9552C55.453 56.8652 55.8057 56.7656 56.198 56.6548Z"
            fill="white"
          />
        </svg>
      )
    }else{
      return (
        <svg className={className}
          width="120"
          height="120"
          viewBox="0 0 120 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path fillRule="evenodd"
            clipRule="evenodd"
            d="M79.5518 39.791C76.5487 32.3284 69.4162 31.9552 60.7822 33.4478L57.779 23L51.3974 24.8657L54.4005 34.9403C53.7922 35.0915 53.1223 35.3039 52.4157 35.5279C51.3781 35.8569 50.2616 36.2109 49.145 36.4328L46.1419 25.9851L39.7602 27.8507L42.7633 38.2985C42.0126 38.4851 41.3557 38.6716 40.6988 38.8582L40.6987 38.8582L40.6986 38.8582C40.0417 39.0448 39.3848 39.2313 38.634 39.4179L30 42.403L31.877 49.1194C31.877 49.1194 36.7571 47.6269 36.3817 47.6269C39.0094 46.8806 40.1356 48 40.8864 49.4925L44.2649 61.4328H45.0157L44.2649 61.806L49.145 78.597C49.145 79.3433 49.145 80.8358 47.2681 81.5821L42.7633 83.0746L43.5141 90.9104L51.7728 88.6716L56.2775 87.5522L59.2806 98L65.6623 96.1343L62.6591 85.6866C64.5361 85.3134 66.0377 84.9403 67.9146 84.194L70.9178 94.6418L77.2994 92.7761L74.2963 82.3284C84.8073 78.597 91.5644 73.7463 89.6874 63.2985C88.1858 55.0896 83.3057 52.4776 76.5487 52.8507C80.3026 49.1194 81.8042 45.3881 79.5518 39.791ZM77.2994 65.1642C79.2849 72.0716 68.727 75.2096 63.1115 76.8787L63.1113 76.8787C62.3566 77.103 61.6912 77.3008 61.1576 77.4776L57.0283 63.2985C57.6203 63.1514 58.3615 62.9334 59.2084 62.6843C64.805 61.038 75.0171 58.0341 77.2994 65.1642ZM56.198 56.6548C60.6862 55.387 70.3613 52.654 68.29 46.1343C66.5921 39.3833 57.2139 42.4038 52.6616 43.87C52.1816 44.0246 51.7554 44.1619 51.3974 44.2687L55.1513 56.9552C55.453 56.8652 55.8057 56.7656 56.198 56.6548Z"
            fill="white"
          />
        </svg>
      )
    }
    break
  case 'DOGE':
    if(props.color){
      return (
        <svg className={className}
          width="120"
          height="120"
          viewBox="0 0 120 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="60"
            cy="60"
            r="60"
            fill="#988430"
          />

          <circle cx="60"
            cy="60"
            r="58.0781"
            fill="#7A6A2A"
          />

          <circle cx="59.9999"
            cy="60.0009"
            r="56.8544"
            fill="#BA9F33"
          />

          <path fillRule="evenodd"
            clipRule="evenodd"
            d="M93.0002 45.2427C86.9324 29.573 71.9321 26.6699 71.9321 26.6699H29.1359L29.301 42.6309H37.8239V78.2718H29.1353V94.204H70.7474C80.7762 94.204 88.8153 84.1259 88.8153 84.1259C101.689 65.8546 92.9996 45.2427 92.9996 45.2427H93.0002ZM70.9708 73.388C70.9708 73.388 67.7068 78.0971 64.1554 78.0971H57.0514L56.8831 42.6699H65.9999C65.9999 42.6699 70.2239 43.5531 73.1749 51.9122C73.1749 51.9122 77.0873 63.8802 70.9708 73.388Z"
            fill="white"
            fillOpacity="0.8"
          />
        </svg>
      )
    }else{
      return (
        <svg className={className}
          width="120"
          height="120"
          viewBox="0 0 120 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path fillRule="evenodd"
            clipRule="evenodd"
            d="M93.0002 45.2427C86.9324 29.573 71.9321 26.6699 71.9321 26.6699H29.1359L29.301 42.6309H37.8239V78.2718H29.1353V94.204H70.7474C80.7762 94.204 88.8153 84.1259 88.8153 84.1259C101.689 65.8546 92.9996 45.2427 92.9996 45.2427H93.0002ZM70.9708 73.388C70.9708 73.388 67.7068 78.0971 64.1554 78.0971H57.0514L56.8831 42.6699H65.9999C65.9999 42.6699 70.2239 43.5531 73.1749 51.9122C73.1749 51.9122 77.0873 63.8802 70.9708 73.388Z"
            fill="white"
          />
        </svg>
      )
    }
    break
  case 'ETH':
    if(props.color){
      return (
        <svg className={className}
          width="120"
          height="120"
          viewBox="0 0 120 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="60"
            cy="60"
            r="60"
            fill="#EDF0F4"
          />

          <path d="M59.6906 24L59.2133 25.6213V72.663L59.6906 73.1392L81.5264 60.2318L59.6906 24Z"
            fill="#343434"
          />

          <path d="M59.6906 24L37.8542 60.2318L59.6906 73.1392V50.3064V24Z"
            fill="#8C8C8C"
          />

          <path d="M59.6906 77.2737L59.4216 77.6017V94.3587L59.6906 95.144L81.5398 64.373L59.6906 77.2737Z"
            fill="#3C3C3B"
          />

          <path d="M59.6906 95.144V77.2737L37.8542 64.373L59.6906 95.144Z"
            fill="#8C8C8C"
          />

          <path d="M59.6906 73.1394L81.5264 60.2321L59.6906 50.3066V73.1394Z"
            fill="#141414"
          />

          <path d="M37.8542 60.2321L59.6906 73.1394V50.3066L37.8542 60.2321Z"
            fill="#393939"
          />
        </svg>
      )
    }else{
      return (
        <svg className={className}
          width="120"
          height="120"
          viewBox="0 0 120 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M59.9935 73.1391L38.1573 60.2316L59.9933 24L81.8298 60.2315L59.9935 73.1391Z"
            fill="white"
          />

          <path d="M59.9934 95.1438L81.8428 64.3725L59.9935 77.2734L38.1571 64.3723L59.9934 95.1438Z"
            fill="white"
          />
        </svg>
      )
    }
  case 'LTC':
    if(props.color){
      return (
        <svg className={className}
          width="120"
          height="120"
          viewBox="0 0 120 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="60.5"
            cy="59.5"
            r="53.5"
            fill="white"
          />

          <path fillRule="evenodd"
            clipRule="evenodd"
            d="M0 60C0 26.8629 26.8628 0 59.9997 0H60.3484C76.2151 0.046169 91.4136 6.39353 102.6 17.6457C113.787 28.8979 120.046 44.1332 119.999 60C119.999 93.1371 93.1367 120 59.9997 120C26.8628 120 0 93.1371 0 60ZM54.7697 83.0993L61.0167 62.0339L70.7503 59.2736L72.7842 52.3002L63.1959 55.2058L73.3653 20.6295V20.0775C73.3502 19.6304 73.1579 19.2078 72.8309 18.9026C72.5039 18.5974 72.069 18.4347 71.622 18.4504H58.6922C57.685 18.4193 56.7862 19.0785 56.5131 20.0484L44.455 61.017L34.8667 63.9225L32.6875 70.6053L42.2758 67.6998L33.7045 96.9007H84.8423C85.8528 96.9425 86.7576 96.279 87.0214 95.3027L89.927 85.2785V84.7264C89.9118 84.2794 89.7196 83.8567 89.3926 83.5515C89.0656 83.2463 88.6307 83.0836 88.1836 83.0993H54.7697Z"
            fill="#345D9D"
          />
        </svg>
      )
    }else{
      return (
        <svg className={className}
          width="120"
          height="120"
          viewBox="0 0 120 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M61.0167 62.0338L54.7697 83.0992H88.1836C88.6307 83.0835 89.0656 83.2462 89.3926 83.5514C89.7196 83.8566 89.9118 84.2793 89.927 84.7263V85.2784L87.0214 95.3026C86.7576 96.2789 85.8528 96.9425 84.8422 96.9006H33.7044L42.2758 67.6997L32.6875 70.6052L34.8667 63.9224L44.455 61.0169L56.5131 20.0483C56.7862 19.0784 57.685 18.4192 58.6922 18.4503H71.622C72.069 18.4346 72.5039 18.5973 72.8309 18.9025C73.1579 19.2077 73.3501 19.6303 73.3653 20.0774V20.6294L63.1958 55.2057L72.7842 52.3001L70.7503 59.2735L61.0167 62.0338Z"
            fill="white"
          />
        </svg>
      )
    }
  case 'XMR':
    if(props.color){
      return (
        <svg className={className}
          width="120"
          height="120"
          viewBox="0 0 120 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="60"
            cy="60"
            r="60"
            fill="white"
          />

          <path d="M120 59.9941C120 93.1268 93.1428 119.988 60.0078 119.988C26.8728 119.988 0.0156517 93.1268 0.0156517 59.9941C0.0156517 26.8614 26.8735 0 60.0078 0C93.1422 0 120 26.8588 120 59.9941Z"
            fill="white"
          />

          <path d="M60.0078 0C26.8847 0 -0.0201258 26.8994 0.0220412 59.9922C0.0303469 66.613 1.08612 72.9819 3.06765 78.9392H21.0167V28.4707L60.0078 67.4615L98.997 28.4707V78.9402H116.95C118.934 72.9831 119.985 66.6143 119.996 59.9931C120.053 26.8658 93.1345 0.00798618 60.0078 0.00798618V0Z"
            fill="#F26822"
          />

          <path d="M51.0409 76.4252L34.025 59.4082V91.1657H21.0155L8.73943 91.168C19.2694 108.444 38.2995 120 60.0078 120C81.7162 120 100.747 108.441 111.279 91.1654H85.9869V59.4082L68.9699 76.4252L60.0059 85.3892L51.0416 76.4252H51.0409Z"
            fill="#4D4D4D"
          />
        </svg>
      )
    }else{
      return (
        <svg className={className}
          width="120"
          height="120"
          viewBox="0 0 120 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M60.0078 0C26.8847 0 -0.0201258 26.8994 0.0220412 59.9922C0.0303469 66.613 1.08612 72.9819 3.06765 78.9392H21.0167V28.4707L60.0078 67.4615L98.997 28.4707V78.9402H116.95C118.934 72.9831 119.985 66.6143 119.996 59.9931C120.053 26.8658 93.1345 0.00798618 60.0078 0.00798618V0Z"
            fill="white"
          />

          <path d="M51.0409 76.4252L34.025 59.4082V91.1657H21.0155L8.73943 91.168C19.2694 108.444 38.2995 120 60.0078 120C81.7162 120 100.747 108.441 111.279 91.1654H85.9869V59.4082L68.9699 76.4252L60.0059 85.3892L51.0416 76.4252H51.0409Z"
            fill="white"
          />
        </svg>
      )
    }
  case 'USDT':
    if(props.color){
      return (
        <svg className={className}
          width="120"
          height="120"
          viewBox="0 0 120 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          shapeRendering="geometricPrecision"
        >
          <path d="M118.205 74.5143C110.19 106.657 77.6309 126.219 45.4803 118.204C13.3432 110.19 -6.22074 77.6324 1.79711 45.4918C9.80793 13.3451 42.3674 -6.21803 74.5081 1.79541C106.656 9.80884 126.219 42.3702 118.204 74.5149L118.204 74.5143H118.205Z"
            fill="#50AF95"
          />

          <path fillRule="evenodd"
            clipRule="evenodd"
            d="M67.5924 66.8023C67.1681 66.8341 64.9761 66.9649 60.0866 66.9649C56.1976 66.9649 53.4364 66.8482 52.4677 66.8023C37.4385 66.1411 26.2205 63.5249 26.2205 60.3925C26.2205 57.2601 37.4385 54.6474 52.4677 53.9756V64.1966C53.4506 64.2673 56.2648 64.4335 60.1538 64.4335C64.8206 64.4335 67.1575 64.2391 67.5782 64.2002V53.9827C82.5756 54.6509 93.7689 57.2671 93.7689 60.3925C93.7689 63.5178 82.5792 66.1341 67.5782 66.7987L67.5924 66.8023ZM67.5924 52.9256V43.7794H88.5223V29.832H31.5378V43.7794H52.4642V52.9221C35.4551 53.7034 22.6638 57.0727 22.6638 61.1102C22.6638 65.1477 35.4551 68.5134 52.4642 69.2983V98.6072H67.5888V69.2877C84.559 68.5063 97.3291 65.1406 97.3291 61.1066C97.3291 57.0727 84.5696 53.7069 67.5888 52.9221L67.5924 52.9256Z"
            fill="white"
          />
        </svg>
      )
    }else{
      return (
        <svg className={className}
          width="120"
          height="120"
          viewBox="0 0 120 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path fillRule="evenodd"
            clipRule="evenodd"
            d="M67.5924 66.8023C67.1681 66.8341 64.9761 66.9649 60.0866 66.9649C56.1976 66.9649 53.4364 66.8482 52.4677 66.8023C37.4385 66.1411 26.2205 63.5249 26.2205 60.3925C26.2205 57.2601 37.4385 54.6474 52.4677 53.9756V64.1966C53.4506 64.2673 56.2648 64.4335 60.1538 64.4335C64.8206 64.4335 67.1575 64.2391 67.5782 64.2002V53.9827C82.5756 54.6509 93.7689 57.2671 93.7689 60.3925C93.7689 63.5178 82.5792 66.1341 67.5782 66.7987L67.5924 66.8023ZM67.5924 52.9256V43.7794H88.5223V29.832H31.5378V43.7794H52.4642V52.9221C35.4551 53.7034 22.6638 57.0727 22.6638 61.1102C22.6638 65.1477 35.4551 68.5134 52.4642 69.2983V98.6072H67.5888V69.2877C84.559 68.5063 97.3291 65.1406 97.3291 61.1066C97.3291 57.0727 84.5696 53.7069 67.5888 52.9221L67.5924 52.9256Z"
            fill="white"
          />
        </svg>
      )
    }
  case 'TRX':
    if(props.color){
      return (
        <svg className={className}
          width="120"
          height="120"
          viewBox="0 0 120 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path fillRule="evenodd"
            clipRule="evenodd"
            d="M60 120C27 120 0 93.0006 0 59.7012C0.3 26.7019 27 -0.297518 60.3 0.00247602C93.3 0.30247 120 27.0019 120 60.6012C119.7 93.3006 93 120 60 120Z"
            fill="#FC070C"
          />

          <path fillRule="evenodd"
            clipRule="evenodd"
            d="M102.75 47.306C101.112 45.7941 99.2138 43.9508 97.2355 42.03L97.2351 42.0296C94.4565 39.3317 91.5204 36.481 88.9292 34.1811L88.6667 33.9973C88.2299 33.6466 87.7375 33.3715 87.2098 33.1836L85.2099 32.8105C71.4102 30.2356 26.6789 21.8892 25.7587 22.0011C25.4866 22.0392 25.2265 22.1379 24.9975 22.2899L24.7481 22.4867C24.4411 22.7986 24.2078 23.1753 24.0656 23.5892L24 23.7599V24.6917V24.8361C27.0369 33.2924 34.9258 52.1773 42.2099 69.6145L42.21 69.6147L42.2102 69.6151L42.2103 69.6154C48.3685 84.3571 54.0943 98.064 56.0905 103.56L56.0913 103.562C56.354 104.376 56.8526 105.922 57.7836 106.001H57.9936C58.4924 106.001 60.6186 103.192 60.6186 103.192C60.6186 103.192 98.6285 57.0973 102.474 52.1885C102.972 51.5839 103.411 50.9335 103.787 50.246C103.981 49.1519 103.588 48.0358 102.75 47.306ZM70.3705 52.6741L86.5929 39.2211L96.1085 47.9885L70.3705 52.6741ZM36.1406 28.9048L64.0705 51.7948L81.3298 37.2392L36.1406 28.9048ZM66.5905 57.7929L95.1767 53.186L62.4955 92.5609L66.5905 57.7929ZM61.7343 56.126L32.3475 31.1886L57.4818 92.5871L61.7343 56.126Z"
            fill="white"
          />
        </svg>
      )
    }else{
      return (
        <svg className={className}
          width="120"
          height="120"
          viewBox="0 0 120 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path fillRule="evenodd"
            clipRule="evenodd"
            d="M102.75 47.306C101.112 45.7941 99.2138 43.9508 97.2355 42.03L97.2351 42.0296C94.4565 39.3317 91.5204 36.481 88.9292 34.1811L88.6667 33.9973C88.2299 33.6466 87.7375 33.3715 87.2098 33.1836L85.2099 32.8105C71.4102 30.2356 26.6789 21.8892 25.7587 22.0011C25.4866 22.0392 25.2265 22.1379 24.9975 22.2899L24.7481 22.4867C24.4411 22.7986 24.2078 23.1753 24.0656 23.5892L24 23.7599V24.6917V24.8361C27.0369 33.2924 34.9258 52.1773 42.2099 69.6145L42.21 69.6147L42.2102 69.6151L42.2103 69.6154C48.3685 84.3571 54.0943 98.064 56.0905 103.56L56.0913 103.562C56.354 104.376 56.8526 105.922 57.7836 106.001H57.9936C58.4924 106.001 60.6186 103.192 60.6186 103.192C60.6186 103.192 98.6285 57.0973 102.474 52.1885C102.972 51.5839 103.411 50.9335 103.787 50.246C103.981 49.1519 103.588 48.0358 102.75 47.306ZM70.3705 52.6741L86.5929 39.2211L96.1085 47.9885L70.3705 52.6741ZM36.1406 28.9048L64.0705 51.7948L81.3298 37.2392L36.1406 28.9048ZM66.5905 57.7929L95.1767 53.186L62.4955 92.5609L66.5905 57.7929ZM61.7343 56.126L32.3475 31.1886L57.4818 92.5871L61.7343 56.126Z"
            fill="white"
          />
        </svg>
      )
    }
  case 'USDC':
    if(props.color){
      return (
        <svg className={className}
          width="120"
          height="120"
          viewBox="0 0 120 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M60 120C93.2502 120 120 93.2502 120 60C120 26.7498 93.2502 0 60 0C26.7498 0 0 26.7498 0 60C0 93.2502 26.7498 120 60 120Z"
            fill="#2775CA"
          />

          <path d="M76.5 69.5C76.5 60.7502 71.25 57.7502 60.75 56.5004C53.25 55.5002 51.75 53.5004 51.75 50C51.75 46.4996 54.2502 44.2502 59.25 44.2502C63.75 44.2502 66.2502 45.7502 67.5 49.5002C67.7502 50.2502 68.5002 50.75 69.2502 50.75H73.2498C74.25 50.75 75 50 75 49.0004V48.7502C73.9998 43.25 69.4998 39.0002 63.75 38.5004V32.5004C63.75 31.5002 63 30.7502 61.7502 30.5H58.0002C57 30.5 56.25 31.25 55.9998 32.5004V38.2502C48.4998 39.2504 43.7502 44.2502 43.7502 50.5004C43.7502 58.7504 48.75 62 59.25 63.2504C66.2502 64.5002 68.5002 66.0002 68.5002 70.0004C68.5002 74.0006 64.9998 76.7504 60.2502 76.7504C53.7498 76.7504 51.4998 74 50.7498 70.25C50.5002 69.2504 49.7502 68.75 49.0002 68.75H44.7498C43.7502 68.75 43.0002 69.5 43.0002 70.5002V70.7504C43.9998 77 48 81.5 56.25 82.7504V88.7504C56.25 89.75 57 90.5 58.2498 90.7502H61.9998C63 90.7502 63.75 90.0002 64.0002 88.7504V82.7504C71.5002 81.5 76.5 76.25 76.5 69.5Z"
            fill="white"
          />

          <path fillRule="evenodd"
            clipRule="evenodd"
            d="M25.0002 47.7496C17.7498 67 27.75 88.75 47.25 95.7496C48 96.25 48.75 97.2496 48.75 97.9996V101.5C48.75 102 48.75 102.25 48.4998 102.5C48.2502 103.5 47.25 104 46.2498 103.5C32.25 98.9998 21.4998 88.2496 16.9998 74.2498C9.49979 50.5 22.5 25.2496 46.2498 17.7496C46.5 17.5 46.9998 17.5 47.25 17.5C48.2502 17.7496 48.75 18.4996 48.75 19.4998V22.9996C48.75 24.25 48.2502 25 47.25 25.4998C37.0002 29.2498 28.7502 37.2496 25.0002 47.7496ZM71.5002 18.7498C71.7498 17.7496 72.75 17.2498 73.7502 17.7496C87.4998 22.2496 98.5002 32.9998 103 47.2498C110.5 70.9996 97.5 96.25 73.7502 103.75C73.5 104 73.0002 104 72.75 104C71.7498 103.75 71.25 103 71.25 102V98.5C71.25 97.2496 71.7498 96.4996 72.75 95.9998C82.9998 92.2498 91.2498 84.25 94.9998 73.75C102.25 54.4996 92.25 32.7496 72.75 25.75C72 25.2496 71.25 24.25 71.25 23.2498V19.75C71.25 19.2496 71.25 19 71.5002 18.7498Z"
            fill="white"
          />
        </svg>
      )
    }else{
      return (
        <svg className={className}
          width="120"
          height="120"
          viewBox="0 0 120 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M76.5 69.5C76.5 60.7502 71.25 57.7502 60.75 56.5004C53.25 55.5002 51.75 53.5004 51.75 50C51.75 46.4996 54.2502 44.2502 59.25 44.2502C63.75 44.2502 66.2502 45.7502 67.5 49.5002C67.7502 50.2502 68.5002 50.75 69.2502 50.75H73.2498C74.25 50.75 75 50 75 49.0004V48.7502C73.9998 43.25 69.4998 39.0002 63.75 38.5004V32.5004C63.75 31.5002 63 30.7502 61.7502 30.5H58.0002C57 30.5 56.25 31.25 55.9998 32.5004V38.2502C48.4998 39.2504 43.7502 44.2502 43.7502 50.5004C43.7502 58.7504 48.75 62 59.25 63.2504C66.2502 64.5002 68.5002 66.0002 68.5002 70.0004C68.5002 74.0006 64.9998 76.7504 60.2502 76.7504C53.7498 76.7504 51.4998 74 50.7498 70.25C50.5002 69.2504 49.7502 68.75 49.0002 68.75H44.7498C43.7502 68.75 43.0002 69.5 43.0002 70.5002V70.7504C43.9998 77 48 81.5 56.25 82.7504V88.7504C56.25 89.75 57 90.5 58.2498 90.7502H61.9998C63 90.7502 63.75 90.0002 64.0002 88.7504V82.7504C71.5002 81.5 76.5 76.25 76.5 69.5Z"
            fill="white"
          />

          <path fillRule="evenodd"
            clipRule="evenodd"
            d="M25.0002 47.7496C17.7498 67 27.75 88.75 47.25 95.7496C48 96.25 48.75 97.2496 48.75 97.9996V101.5C48.75 102 48.75 102.25 48.4998 102.5C48.2502 103.5 47.25 104 46.2498 103.5C32.25 98.9998 21.4998 88.2496 16.9998 74.2498C9.49979 50.5 22.5 25.2496 46.2498 17.7496C46.5 17.5 46.9998 17.5 47.25 17.5C48.2502 17.7496 48.75 18.4996 48.75 19.4998V22.9996C48.75 24.25 48.2502 25 47.25 25.4998C37.0002 29.2498 28.7502 37.2496 25.0002 47.7496ZM71.5002 18.7498C71.7498 17.7496 72.75 17.2498 73.7502 17.7496C87.4998 22.2496 98.5002 32.9998 103 47.2498C110.5 70.9996 97.5 96.25 73.7502 103.75C73.5 104 73.0002 104 72.75 104C71.7498 103.75 71.25 103 71.25 102V98.5C71.25 97.2496 71.7498 96.4996 72.75 95.9998C82.9998 92.2498 91.2498 84.25 94.9998 73.75C102.25 54.4996 92.25 32.7496 72.75 25.75C72 25.2496 71.25 24.25 71.25 23.2498V19.75C71.25 19.2496 71.25 19 71.5002 18.7498Z"
            fill="white"
          />
        </svg>
      )
    }
    break
  default:
    if(props.currencyIso) {
      return (
        <img className={className}
          src={`/img/currencies/${props.currencyIso.toLowerCase()}.png`}
        />
      )
    }else{
      return null
    }}
  return null
}

export default CurrencySvg
