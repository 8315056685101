import React, { useEffect, useMemo, useRef, useState } from 'react'

import { IPaymentMethod } from 'data/interfaces/IPaymentMethod'
import { IPaymentSystem } from 'data/interfaces/IPaymentSystem'
import { useAppContext } from 'context/AppContext'
import { FormikProvider, FormikValues, useFormik } from 'formik'
import { PaymentDepositAmountField } from 'components/Profile/Wallet/PaymentDepositAmountField'
import Validator from 'utils/validator'
import PaymentMethodRepository from 'data/repositories/PaymentMethodRepository'
import { partition } from 'lodash'
import { IPaymentMethodField } from 'data/interfaces/IPaymentFields'
import { PaymentFormExtraFields } from 'components/Profile/Wallet/PaymentFormExtraFields'
import Formatter from 'utils/formatter'
import PromoCode from 'components/for_pages/Common/Promocode'
import BalanceTransactionRepository from 'data/repositories/BalanceTransationRepository'
import { BalanceTransactionType } from 'data/interfaces/IBalanceTransaction'
import PaymentsRepository from 'data/repositories/PaymentsRepository'
import { BrowserUtils } from 'utils/browser'
import FormError, { FormErrorValue } from 'components/ui/Form/FormError'
import { IDepositResponse } from 'data/interfaces/IPaymentDeposit'
import ContentLoader from 'components/ui/ContentLoader'
import styled from 'styled-components'
import { Button, CopyIcon, Input } from 'ui-kit'
import { useTranslation } from 'next-i18next'
import { BREAKPOINTS, COLORS, FONTS } from 'ui-kit/constants'
import QRCode from 'react-qr-code'
import ReCAPTCHA from 'react-google-recaptcha'
import { runtimeConfig } from 'config/runtimeConfig'
import { useLoyaltyContext } from 'context/loyaltyContext'
import { IBonus, PropertyIdent } from 'data/interfaces/ILoyalty'
import { SIZES, useLayoutContext } from 'context/layoutContext'

import { WalletModalNewTabs } from '../WalletModalNewTabs/WalletModalNewTabs'
import { WalletPaymentSystem } from '../WalletPayment/WalletPaymentSystem'
import { IBreadcrumb, WalletBreadcrumbs } from '../WalletBreadcrumbs/WalletBreadcrumbs'
import { WalletPaymentCurrency } from '../WalletPayment/WalletPaymentCurrency'
import { WalletCryptoDepositInfo } from '../WalletCryptoDepositInfo/WalletCryptoDepositInfo'
import { WalletDepositBanner } from '../WalletDepositBanner/WalletDepositBanner'
import { MIN_BONUS_DEPOSIT_AMOUNT_USD } from "../../../../types/constants"
import { IMinMaxBonusAmount } from "../../../../utils/loyaltyUtils"

import {
  CloseImg,
  Fields,
  FixedBottomBox,
  Left,
  MobileTitle,
  MobileTitleRight,
  Right,
  RightTitleRow,
  Root,
  StyledArrowBack,
  StyledArrowBackMobile,
  StyledForm,
  Title
} from './styles'

interface Props {
  onClose: () => void,
  onTabChange: (tab: 'deposit' | 'withdraw') => void
}

export const WalletNewDeposit: React.FC<Props> = ({ onClose, onTabChange }) => {
  const { t, i18n } = useTranslation()

  const appContext = useAppContext()
  const { contentSize } = useLayoutContext()
  const loyaltyContext = useLoyaltyContext()
  const paymentMethods = appContext.depositPaymentMethods
  const paymentMenthodsLoading = !paymentMethods.length

  const [paymentMethod, setPaymentMethod] = useState<
    IPaymentMethod | undefined
  >()
  const [paymentSystem, setPaymentSystem] = useState<
    IPaymentSystem | undefined
  >()
  const [paymentFields, setPaymentFields] = useState<IPaymentMethodField[]>([])
  const [sending, setSending] = useState(false)
  const [currency, setCurrency] = useState<any>()
  const [bonuses, setBonuses] = useState<IBonus[]>([])
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([{
    label: t('header_profile_menu_wallet'),
    type: 'default'
  }])
  const [error, setError] = useState<FormErrorValue | null>(null)
  const [depositResponse, setDepositResponse] = useState<IDepositResponse | null>(null)
  const [fieldsLoading, toggleFieldsLoading] = useState(true)
  const [contextToShow, setContextToShow] = useState<any>(null)
  const [isFirstDeposit, setIsFirstDeposit] = useState(false)

  const recaptchaRef = useRef<any>(null)

  const [cryptoPaymentMethods, otherPaymentMethods] = partition(
    paymentMethods,
    (paymentMethod) => paymentMethod.isCrypto,
  )

  const sortedPaymentSystems = otherPaymentMethods.flatMap(method => method.paymentSystems
    .map(system => ({ ...system, method, order: system.settings?.[0]?.order })))
    .sort((a, b) => a.order - b.order)


  const isMobile = SIZES.MOBILE === contentSize

  const depositBonus = loyaltyContext.depositPageBonus

  const handleSetDefaultPaymentSystem = () => {
    if (isMobile || !sortedPaymentSystems[0]) {
      return
    }

    handleSetPaymentSystem(sortedPaymentSystems[0])
  }

  useEffect(() => {
    if (isMobile) {
      return
    }

    if (!sortedPaymentSystems.length && cryptoPaymentMethods[0]) {
      handleSetPaymentMethod(cryptoPaymentMethods[0])

      return
    }

    if (!sortedPaymentSystems.length) {
      return
    }

    handleSetPaymentSystem(sortedPaymentSystems[0])
  }, [sortedPaymentSystems.length, cryptoPaymentMethods.length])

  useEffect(() => {
    if (!paymentSystem || !paymentSystem.systemCode || !appContext.auth) {
      return
    }

    toggleFieldsLoading(true)

    PaymentMethodRepository.fetchDepositFields(
      paymentSystem.systemCode,
      paymentSystem.id,
    ).then((fields) => {
      setPaymentFields(fields)
      toggleFieldsLoading(false)
    })
  }, [paymentSystem, appContext.auth])

  useEffect(() => {
    setBonuses([depositBonus])
  }, [depositBonus])

  useEffect(() => {
    setError(null)
    setDepositResponse(null)
    setContextToShow(null)
    setSending(false)
  }, [paymentSystem, currency, paymentMethod])

  useEffect(() => {
    if (!paymentFields.length || !formik) {
      return
    }

    if (paymentFields.find(paymentFields => paymentFields.key === 'email')) {
      formik.setFieldValue('extra_data.email', appContext.user.email || '', false)
    }

    if (paymentFields.find(paymentFields => paymentFields.key === 'phone') && appContext.user.phone) {
      formik.setFieldValue('extra_data.phone', '+' + String(appContext.user.phone) || '', false)
    }
  }, [paymentFields])


  const handleSetPaymentMethod = (method: IPaymentMethod) => {
    setPaymentMethod(method)
    setBreadcrumbs(state => [...state.slice(0, 1), { label: method.title, type: 'method' }])

    if (!formik) {
      return
    }

    formik.resetForm()
  }

  const handleSetPaymentSystem = (system: IPaymentSystem) => {
    setPaymentSystem(system)
    setBreadcrumbs(state => [...state.slice(0, 1), { label: system.name, type: 'system' }])

    if (!formik) {
      return
    }

    formik.resetForm()
  }

  const handleCurrencySelect = (currency: any) => {
    if (isMobile) {
      setBreadcrumbs(state => [...state.slice(0, 2), { label: currency.displayName, type: 'currency' }])
    }

    setCurrency(currency)
    setPaymentSystem(currency.paymentSystem)
  }

  const handleBreadcrumbClick = (breadcrumb: IBreadcrumb) => {
    setDepositResponse(null)

    if (breadcrumb.type === 'default') {
      setPaymentMethod(undefined)
      setCurrency(undefined)

      if (isMobile) {
        setPaymentSystem(undefined)
      } else {
        handleSetDefaultPaymentSystem()
      }

      setBreadcrumbs(state => state.slice(0, 1))
    }

    if (breadcrumb.type === 'method') {
      // setPaymentMethod(undefined)
      setCurrency(undefined)

      if (isMobile) {
        setPaymentSystem(undefined)
      } else {
        handleSetDefaultPaymentSystem()
      }

      setBreadcrumbs(state => state.slice(0, 2))
    }
  }

  const handleBackClick = () => {
    const breadcrumb = breadcrumbs[breadcrumbs.length - 2]

    handleBreadcrumbClick(breadcrumb)
  }

  const handleSubmitFiat = async (data: FormikValues, token: string) => {
    setError(null)
    setSending(true)

    if (!paymentSystem) {
      return
    }

    try {
      const amount =
        typeof data.amount === 'string' ? parseFloat(data.amount) : data.amount

      const res = await PaymentsRepository.depositFiat(
        currencyIso,
        paymentSystem.id,
        paymentSystem.systemCode,
        window.location.origin,
        amount,
        {
          ...data,
          context: contextToShow,
          ...(paymentSystem?.isBrowserInfoRequired
            ? { browser_info: BrowserUtils.getDetailsForPayment(i18n.language) }
            : {}),
        },
        token
      )

      if (res.qrCode) {
        setContextToShow({
          ...res.qrCode,
          type: 'qr',
          amount: res.amount
        })

        return
      }

      if (res.actionType === 'text') {
        const context = res.context

        setContextToShow({ ...context, type: 'text' })

        return
      }

      if (res.actionType === 'more_data') {
        const context = res.context

        const fields = context.fields

        const keys = Object.keys(fields ?? {})
        const items = []

        for (const key of keys) {
          items.push({ ...fields[key], key })
        }

        setContextToShow({ ...context, fields: items, type: 'inputs' })
        setSending(false)

        return
      }

      if (res.method === 'GET' || !res.method) {
        window.location.assign(res.url)
      }

      if (res.method === 'POST') {
        const form = document.getElementById('post-form') as HTMLFormElement
        form.method = 'POST'
        form.action = res.url

        Object.entries(res.formData || {}).forEach((field) => {
          const input = document.createElement('input')
          input.type = 'hidden'
          input.name = field[0]
          input.value = field[1]

          form.appendChild(input)
        })

        form.submit()
      }
    } catch (e) {
      setError(e as FormErrorValue)
    }

    setSending(false)
  }

  const handleSubmitCrypto = async (data: FormikValues, token: string) => {
    if (!data.amount || Number(data.amount) <= 0 || !paymentSystem) {
      return
    }

    setError(null)
    setSending(true)

    try {
      const response = await PaymentsRepository.depositCrypto(
        currency.iso,
        paymentSystem.id,
        paymentSystem.systemCode,
        data.amount * (currency.rate ?? 1),
        token
      )

      setDepositResponse({ ...response, amount: data.amount / (currency.toUsd ?? 1) })
    } catch (e) {
      setError(e as FormErrorValue)
    }

    setSending(false)
  }

  const handleDeposit = async (values: FormikValues) => {
    try {
      const recaptchaToken = await recaptchaRef.current?.executeAsync()

      if (!isCryptoSelected) {
        handleSubmitFiat(values, recaptchaToken)
      } else {
        handleSubmitCrypto(values, recaptchaToken)
      }
    } finally {
      recaptchaRef.current?.reset()
    }
  }

  const handleCheckDeposit: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault()
    e.stopPropagation()

    try {
      const response = await PaymentsRepository.checkDeposit(
        paymentSystem?.systemCode,
        paymentSystem?.id,
        contextToShow.uuid
      )

      if (response.result) {
        appContext.hideModal()
      } else {
        setContextToShow(state => ({ ...state, error: t('wallet_deposit_p2p_waiting') }))
      }
    } catch (error) {
      setContextToShow(state => ({ ...state, error: t('wallet_deposit_p2p_waiting') }))
    }
  }

  const currentSettings = paymentSystem?.settings?.[0]

  const isCryptoSelected = !!paymentMethod?.isCrypto && !!currency

  const userCurrency = appContext.currencies.find(
    (currency) => currency.iso === appContext?.user?.currencyIso,
  )

  const currencyIso = userCurrency?.iso as string
  // const currentSettings = paymentSystem?.settings?.find(
  //   (i) => i.currencyIso === currencyIso,
  // )

  const minBonusAmount = useMemo(() => {
    const minBonusAmountInUsd = depositBonus?.properties?.[PropertyIdent.minDeposit]?.amount || MIN_BONUS_DEPOSIT_AMOUNT_USD
    const currentCurrency = currency || userCurrency

    if (!currentCurrency || isCryptoSelected) {
      return String(minBonusAmountInUsd)
    }

    return String(Math.ceil((minBonusAmountInUsd || MIN_BONUS_DEPOSIT_AMOUNT_USD) / currentCurrency?.toUsd) || '')
  }, [depositBonus?.properties?.[PropertyIdent.minDeposit]?.amount, currency, userCurrency])

  const fiatMinAmount = Number(currentSettings?.deposit?.minAmount ?? 0)
  const fiatMaxAmount = Number(currentSettings?.deposit?.maxAmount ?? 0)

  const fiatMin = `${fiatMinAmount} ${currentSettings?.currencyIso}`
  const fiatMax = `${fiatMaxAmount} ${currentSettings?.currencyIso}`

  const cryptoMinAmount = !!currency ? Math.ceil(
    (currency?.deposit?.minAmount ?? 0) * (currency?.toUsd || 1),
    currencyIso,
  ) : 0

  const cryptoMaxAmount = !!currency ? Math.ceil(
    (currency?.deposit?.maxAmount ?? 0) * (currency?.toUsd || 1),
    currencyIso,
  ) : 0

  const cryptoMin = !!currency ? `$${Math.ceil(Formatter.formatAmount(
    (currency?.deposit?.minAmount ?? 0) * (currency?.toUsd || 1),
    currencyIso,
  ))}` : ''
  const cryptoMax = !!currency ? `$${Math.ceil(Formatter.formatAmount(
    (currency?.deposit?.maxAmount ?? 0) * (currency?.toUsd || 1),
    currencyIso,
  ))}` : ''

  const initialValues = contextToShow && contextToShow.type !== 'inputs' && contextToShow.fields ?
    contextToShow.fields.reduce((r, field) => ({ ...r, [field.title]: field.value }), {})
    :
    {
      amount:  (isCryptoSelected ? String(cryptoMinAmount) : String(fiatMinAmount))
        || '5'
    }

  const formik = useFormik({
    initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: handleDeposit,
  })

  const checkOnlyMobileCondition = (condition: boolean) => {
    if (!isMobile) {
      return true
    }

    return condition
  }

  const isChoosingStep = ((!paymentSystem) || (!!paymentMethod && paymentMethod.isCrypto)) && !currency && !depositResponse

  const handleBonusOptionClick = (option: IMinMaxBonusAmount) => {
    if (!option.inCurrentCurrency) {
      formik.setFieldValue('amount', String(Math.ceil(Number(Formatter.formatAmount(Number(option.value) / userCurrency.toUsd, userCurrency?.iso)))))
    } else {
      formik.setFieldValue('amount', String(option.value))
    }
  }

  return (
    <Root>
      <MobileTitle>
        {breadcrumbs.length > 1 && (
          <StyledArrowBackMobile color={COLORS.dark200}
            onClick={handleBackClick}
          />
        )
        }

        <span onClick={handleBackClick}>
          {t('wallet_title')}
        </span>

        <MobileTitleRight
          onClick={onClose}
        >
          ID
          {' '}

          {appContext?.user?.id}

          <CloseImg src="/img/Wallet/close.svg" />
        </MobileTitleRight>
      </MobileTitle>

      <Left>
        <Title>
          {t('wallet_title')}
        </Title>

        {!paymentMethod?.isCrypto && (isMobile ? breadcrumbs.length < 2 : true) && (
          <WalletModalNewTabs
            activeTab='deposit'
            onTabChange={onTabChange}
          />
        )}

        {(!!paymentMethod?.isCrypto || isMobile) && (
          <WalletBreadcrumbs
            breadcrumbs={breadcrumbs}
            onClick={handleBreadcrumbClick}
          />
        )}

        {!paymentMenthodsLoading && checkOnlyMobileCondition(isChoosingStep) && (
          <>
            {
              (!paymentMethod || !paymentMethod.isCrypto) && (
                <WalletPaymentSystem
                  onMethodSelect={handleSetPaymentMethod}
                  onSystemSelect={handleSetPaymentSystem}
                  cryptoPaymentMethods={cryptoPaymentMethods}
                  sortedPaymentSystems={sortedPaymentSystems}
                  selectedMethodName={paymentSystem?.name || ''}
                  currencyIso={currencyIso}
                />
              )}

            {
              !!paymentMethod && paymentMethod.isCrypto && (
                <WalletPaymentCurrency
                  paymentMethod={paymentMethod}
                  onCurrencySelect={handleCurrencySelect}
                  selected={currency}
                />
              )}
          </>
        )}

        {!!paymentMenthodsLoading && (
          <ContentLoader style={'block'}
            isOpen={true}
          />
        )}
      </Left>


      {
        checkOnlyMobileCondition(!isChoosingStep) && (
          <Right>
            <RightTitleRow>
              {
                (!!paymentMethod?.isCrypto || isMobile) && (
                  <StyledArrowBack color={COLORS.dark200}
                    onClick={handleBackClick}
                  />
                )
              }

              ID
              {' '}

              {appContext?.user?.id}

              <CloseImg src="/img/Wallet/close.svg"
                onClick={onClose}
              />
            </RightTitleRow>

            {!!fieldsLoading && (
              <ContentLoader style={'block'}
                isOpen={true}
              />
            )}

            {
              !!depositResponse && (
                <WalletCryptoDepositInfo
                  response={depositResponse}
                  currency={currency}
                />
              )
            }

            {
              !!contextToShow && contextToShow.type === 'text' && (
                <FormikProvider value={formik}>
                  <StyledForm>
                    <DepositText>

                      {t('wallet_deposit_p2p_description', { amount: contextToShow.sum + ' ' + currencyIso })}
                    </DepositText>

                    {
                      !!contextToShow.fields && contextToShow.fields.map((field: any) => (
                        <React.Fragment key={field.label}>
                          <StyledInput
                            name={field.title}
                            disabled={true}
                            value={field.value}
                            label={field.title}
                          />
                        </React.Fragment>
                      ))
                    }

                    {
                      !!contextToShow.error && (
                        <ErrorBox>
                          {contextToShow.error}
                        </ErrorBox>
                      )}

                    <StyledDoneButton
                      onClick={handleCheckDeposit}
                      type="button"
                    >
                      {t('wallet_deposit_p2p_done')}
                    </StyledDoneButton>
                  </StyledForm>
                </FormikProvider>
              )}

            {
              !!contextToShow && contextToShow.type === 'qr' && (
                <DepositText>

                  {t('wallet_deposit_p2p_description', { amount: contextToShow.amount + ' ' + currencyIso })}

                  <QrCodeBox>
                    <QrCodeWrapper>
                      <QRCode size={136}
                        value={contextToShow.text}
                      />
                    </QrCodeWrapper>
                  </QrCodeBox>

                  <QrLinkRow>
                    <QrLink target="_blank"
                      href={contextToShow.text}
                    >
                      {contextToShow.text}
                    </QrLink>

                    <StyledCopyIcon value={contextToShow.text} />
                  </QrLinkRow>
                </DepositText>
              )}

            {
              !!contextToShow && contextToShow.type === 'inputs' && (
                <FormikProvider value={formik}>
                  <StyledForm>
                    <DepositText>

                      {/* {t('wallet_deposit_p2p_description', { amount: contextToShow.sum + ' ' + currencyIso })} */}
                    </DepositText>

                    <StyledExtraFields
                      fields={contextToShow.fields}
                      sending={sending}
                      defaultCountry={appContext.countryByIp?.iso}
                      labelOnPlaceholder
                    />

                    <FormError error={error} />

                    <StyledDepositButton
                      spinner={sending}
                      onClick={() => formik.handleSubmit()}
                      color="green"
                      type='button'
                    >
                      <ButtonImg src="/img/icons/wallet.svg"
                        alt=""
                      />

                      {t('wallet_form_button_deposit')}
                    </StyledDepositButton>
                  </StyledForm>
                </FormikProvider>
              )}

            {
              !depositResponse && !contextToShow && !fieldsLoading && (
                <FormikProvider value={formik}>
                  <StyledForm>
                    <PaymentDepositAmountField
                      name={'amount'}
                      hasOptions
                      optionCurrency={isCryptoSelected ? '$' : undefined}
                      currencyObject={isCryptoSelected ? undefined : userCurrency}
                      currency={isCryptoSelected ? '$' : currentSettings?.currencyIso}
                      disabled={sending}
                      validate={Validator.combine([Validator.required,
                        Validator.minMax(Number(isCryptoSelected ? cryptoMinAmount : fiatMinAmount),
                          Number(isCryptoSelected ? cryptoMaxAmount : fiatMaxAmount), t)])}
                      bonus={bonuses[0]}
                      min={isCryptoSelected ? cryptoMin : fiatMin}
                      max={isCryptoSelected ? cryptoMax : fiatMax}
                      bonusConvertationDisabled={isCryptoSelected}
                    />

                    <Fields>
                      <StyledExtraFields
                        fields={paymentFields}
                        sending={sending}
                        defaultCountry={appContext.countryByIp?.iso}
                        labelOnPlaceholder
                        isDeposit
                      />

                      <PromoCode
                        bonuses={bonuses}
                        onSetBonuses={setBonuses}
                        currency={isCryptoSelected ? currency : userCurrency}
                        isFirstDeposit={isFirstDeposit}
                        onBonusOptionClick={handleBonusOptionClick}
                        depositAmount={formik.values.amount}
                        bonusConvertationDisabled={isCryptoSelected}
                      />
                    </Fields>

                    <FormError error={error} />

                    <FixedBottomBox>
                      <WalletDepositBanner />

                      <Button
                        spinner={sending}
                        onClick={() => formik.handleSubmit()}
                        color="green"
                        type='button'
                      >
                        <ButtonImg src="/img/icons/wallet.svg"
                          alt=""
                        />

                        {t('wallet_form_button_deposit')}
                      </Button>
                    </FixedBottomBox>
                  </StyledForm>
                </FormikProvider>
              )
            }
          </Right>
        )}

      <form id="post-form" />

      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={runtimeConfig.RECAPTCHA_KEY}
        style={{ visibility: "hidden" }}
      />
    </Root>
  )
}

const ButtonImg = styled.img`
  margin-right: 4px;
`

const StyledExtraFields = styled(PaymentFormExtraFields)`
  margin-top: -16px;
`

const DepositText = styled.p`
  ${FONTS.p1};
  color: ${COLORS.white};

  @media (max-width: ${BREAKPOINTS.xsMax}px) {
    margin-top: 20px;
  }
`

const StyledDoneButton = styled(Button)`
  margin-top: 40px;
`

const ErrorBox = styled.div`
  margin-top: 16px;
  color: ${COLORS.red};
  ${FONTS.p1};
`

const QrCodeBox = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
  width: 100%;
`

const QrCodeWrapper = styled.div`
  background: ${COLORS.dark550};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 164px;
  height: 164px;
`

const StyledInput = styled(Input)`
  margin-top: 16px;
`

const StyledDepositButton = styled(Button)`
  margin-top: auto;
`

const QrLinkRow = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  align-items: center;
`

const QrLink = styled.a`
  margin-right: 10px;
  ${FONTS.p1};
  color: white;
  text-decoration: underline;
  word-wrap: break-word;
  max-width: 280px;
`

const StyledCopyIcon = styled(CopyIcon)`
  margin-left: auto;
`
