import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const MenuIcon: React.FC<Props> = ({ className = '', ...props }) => {
  return (
    <StyledSvg width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <g clipPath="url(#clip0_957_37476)">
        <path fillRule="evenodd"
          clipRule="evenodd"
          d="M2 5C2 4.73478 2.12041 4.48043 2.33474 4.29289C2.54906 4.10536 2.83975 4 3.14286 4H16.8571C17.1602 4 17.4509 4.10536 17.6653 4.29289C17.8796 4.48043 18 4.73478 18 5C18 5.26522 17.8796 5.51957 17.6653 5.70711C17.4509 5.89464 17.1602 6 16.8571 6H3.14286C2.83975 6 2.54906 5.89464 2.33474 5.70711C2.12041 5.51957 2 5.26522 2 5ZM2 10C2 9.73478 2.12041 9.48043 2.33474 9.29289C2.54906 9.10536 2.83975 9 3.14286 9H16.8571C17.1602 9 17.4509 9.10536 17.6653 9.29289C17.8796 9.48043 18 9.73478 18 10C18 10.2652 17.8796 10.5196 17.6653 10.7071C17.4509 10.8946 17.1602 11 16.8571 11H3.14286C2.83975 11 2.54906 10.8946 2.33474 10.7071C2.12041 10.5196 2 10.2652 2 10ZM2 15C2 14.7348 2.12041 14.4804 2.33474 14.2929C2.54906 14.1054 2.83975 14 3.14286 14H16.8571C17.1602 14 17.4509 14.1054 17.6653 14.2929C17.8796 14.4804 18 14.7348 18 15C18 15.2652 17.8796 15.5196 17.6653 15.7071C17.4509 15.8946 17.1602 16 16.8571 16H3.14286C2.83975 16 2.54906 15.8946 2.33474 15.7071C2.12041 15.5196 2 15.2652 2 15Z"
        />
      </g>

      <defs>
        <clipPath id="clip0_957_37476">
          <rect width="20"
            height="20"
            fill="white"
          />
        </clipPath>
      </defs>
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  path {
    fill: currentColor;
  }
`
