import { useTranslation } from 'next-i18next'
import BottomSheetHeader from 'components/layout/BottomSheetHeader'
import ModalHeader from 'components/Profile/layout/ProfileModalHeader/ModalHeader'
import { useAppContext } from 'context/AppContext'

import styles from './WalletHeader.module.scss'

interface Props {
  isBottomSheet?: boolean
  showBack?: boolean
  onBackClick?: () => void
}

export const WalletHeader = (props: Props) => {
  const context = useAppContext()
  const { t } = useTranslation()

  if (props.isBottomSheet) {
    return (
      <BottomSheetHeader
        className={styles.mobileHeader}
        title={t('wallet_title')}
        suffix={(
          <div className={styles.userId}>
ID
            {context.user?.id}
          </div>
        )}
      />
    )
  }

  return (
    <ModalHeader
      title={t('wallet_title')}
      showId
      showBack={props.showBack}
      onBackClick={props.onBackClick}
    />
  )
}
