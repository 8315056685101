import request from 'utils/request'
import Converter from 'utils/converter'
import { ICurrentLevel, ILoyaltyBonus, IPromocodeResponse } from 'data/interfaces/ILoyalty';

export default class LoyaltyRepository {
  static async fetchBonusesList(): Promise<ILoyaltyBonus[]> {
    const res = await request({
      method: 'get',
      url: '/api/loyalty/bonus/list'
    })

    if (res.err) {
      throw res.err
    }

    return Converter.objectKeysToCamelCase(res.data?.data)
  }

  static async fetchLevelsList(): Promise<ILoyaltyBonus[]> {
    const res = await request({
      method: 'get',
      url: '/api/loyalty/level/list'
    })

    if (res.err) {
      throw res.err
    }

    return Converter.objectKeysToCamelCase(res.data?.data)
  }

  static async fetchLevel(id): Promise<ILoyaltyBonus[]> {
    const res = await request({
      method: 'get',
      url: '/api/loyalty/level',
      data: { id }
    })

    if (res.err) {
      throw res.err
    }

    return Converter.objectKeysToCamelCase(res.data?.data)
  }

  static async fetchLevelUserInfo(): Promise<ICurrentLevel> {
    const res = await request({
      method: 'get',
      url: '/api/loyalty/level/user-info'
    })

    if (res.err) {
      throw res.err
    }

    return Converter.objectKeysToCamelCase(res.data?.data)
  }

  static async activatePromocode(promocode: string): Promise<IPromocodeResponse> {
    const res = await request({
      method: 'post',
      url: '/api/loyalty/promocode/set',
      data: {
        keyword: promocode
      }
    })

    if (res.err) {
      throw res.err
    }

    return Converter.objectKeysToCamelCase(res.data?.data)
  }

  static async getPromocodeBonuses(promocode: string): Promise<ILoyaltyBonus[]> {
    const res = await request({
      method: 'get',
      url: '/api/loyalty/bonus/list-by-promocode',
      data: {
        keyword: promocode
      }
    })

    if (res.err) {
      throw res.err
    }

    return Converter.objectKeysToCamelCase(res.data?.data)
  }

  static async getPrivilegeList({ page = 1, perPage = 200, filters }: { page?: number, perPage?: number, filters?: { finished: 0 | 1 } }): Promise<ILoyaltyBonus[]> {
    const res = await request({
      method: 'get',
      url: '/api/loyalty/bonus/user-privilege-list',
      data: {
        page,
        'per-page': perPage,
        ...(filters && { ...filters })
      }
    })

    if (res.err) {
      throw res.err
    }

    return Converter.objectKeysToCamelCase(res.data)
  }

  static async bonusAction(type: 'activate' | 'finish' | 'withdraw', id: number): Promise<IPromocodeResponse> {
    const res = await request({
      method: 'post',
      url: `/api/loyalty/bonus/${type}`,
      data: {
        id
      }
    })

    if (res.err) {
      throw res.err
    }

    return Converter.objectKeysToCamelCase(res.data?.data)
  }

  static async FetchFAQ(): Promise<unknown[]> {
    const res = await request({
      method: 'get',
      url: '/api/content/faq/index',
    })

    if (res.err) {
      throw res.err
    }

    return res.data?.data
  }
}
