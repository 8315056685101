export enum IPaymentMethodFieldType {
  String = 'string',
  Dropdown = 'dropdown',
  Number = 'number',
}

export interface IPaymentMethodField {
  key: string
  is_required: boolean
  is_payment_address: boolean
  title: string
  type: IPaymentMethodFieldType
  options: { [key: string]: string }
}

export type IPaymentMethodFields = { [key: string]: IPaymentMethodField }
