import { useRef, useState } from 'react'

import { useTranslation } from 'next-i18next'
import { Form, Formik } from 'formik'
import Button from 'components/ui/Button'
import FormError from 'components/ui/Form/FormError'
import InputField from 'components/ui/Inputs/InputField'
import BottomSheetLayout from 'components/layout/BottomSheetLayout'
import BottomSheetHeader from 'components/layout/BottomSheetHeader'
import BottomSheetBody from 'components/layout/BottomSheetBody'
import ProfileModalLayout from 'components/Profile/layout/ProfileModalLayout'
import ModalHeader from 'components/Profile/layout/ProfileModalHeader/ModalHeader'
import ProfileModalBody from 'components/Profile/layout/ProfileModalBody'
import { useAppContext } from 'context/AppContext'
import { ModalType } from 'types/enums'
import { PasswordResetModalArguments } from 'types/interfaces'
import AuthRepository from 'data/repositories/AuthRepository'
import Validator from 'utils/validator'
import ReCAPTCHA from 'react-google-recaptcha'
import { runtimeConfig } from 'config/runtimeConfig'

import styles from './index.module.scss'

interface Props {
  isBottomSheet?: boolean
}

export default function ModalPasswordRecovery(props: Props) {
  const { t } = useTranslation()
  const appContext = useAppContext()

  const [error, setError] = useState<string | null>(null)
  const [sending, setSending] = useState<boolean>(false)

  const recaptchaRef = useRef<any>(null)

  const handleSubmit = async (data) => {
    setSending(true)

    try {
      setError(null)

      await recaptchaRef.current?.executeAsync()

      await AuthRepository.forgotPassword(data.login)

      appContext.showModal(ModalType.passwordReset, {
        login: data.login,
      } as PasswordResetModalArguments)
    } catch (e: unknown) {
      setError(e)
    } finally {
      recaptchaRef.current?.reset()
    }

    setSending(false)
  }

  const initialValues = {
    login: '',
  }

  const result = (
    <Formik initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      <Form className={styles.form}>
        <div className={styles.description}>
          {t('password_forgot_text')}
        </div>

        <div className={styles.inputs}>
          <InputField
            name="login"
            disabled={sending}
            placeholder={t('password_forgot_field_identity')}
            validate={Validator.required}
          />
        </div>

        <FormError error={error} />

        <div className={styles.buttons}>
          <Button
            type="button"
            size="submit"
            background="dark600"
            className={styles.cancel}
            onClick={() => appContext.showModal(ModalType.login)}
          >
            {t('password_forgot_cancel')}
          </Button>

          <Button
            type="submit"
            size="submit"
            spinner={sending}
            className={styles.button}
            background="blueGradient500"
          >
            {t('password_forgot_next')}
          </Button>
        </div>

        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={runtimeConfig.RECAPTCHA_KEY}
          style={{ visibility: "hidden" }}
        />
      </Form>
    </Formik>
  )

  if (props.isBottomSheet) {
    return (
      <BottomSheetLayout>
        <BottomSheetHeader title={t('password_forgot_title')} />

        <BottomSheetBody>
          {result}
        </BottomSheetBody>
      </BottomSheetLayout>
    )
  } else {
    return (
      <ProfileModalLayout>
        <ModalHeader title={t('password_forgot_title')} />

        <ProfileModalBody>
          {result}
        </ProfileModalBody>
      </ProfileModalLayout>
    )
  }
}
