import React from 'react'

import styled from 'styled-components'

import { COLORS } from "./constants"

interface ISwitchProps extends React.HTMLAttributes<HTMLInputElement> {
  className?: string,
  checked: boolean,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  name: string
}

export const Switch: React.FC<ISwitchProps> = ({
  className = '', checked, onChange, name, ...props
}) => {
  return (
    <SwitchBox className={className}>
      <Input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        name={name}
        {...props}
      />

      <Slider />
    </SwitchBox>
  )
}

const SwitchBox = styled.label`
  width: 32px;
  min-width: 32px;
  height: 17px;
  position: relative;
`

const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${COLORS.dark500};
  border-radius: 8px;
  transition: .4s;

  &:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    border-radius: 50%;
    left: 2px;
    bottom: 2px;
    background: ${COLORS.dark200};
    transition: .4s;
  }

  ${Input}:checked + &:before {
    transform: translateX(14px);
    background: ${COLORS.btnBlue};
  }
`
