import Sheet from 'react-modal-sheet'
import { useAppContext } from 'context/AppContext'
import Close from 'components/svg/Close'

import styles from './index.module.scss'


interface Props {
  children: React.ReactNode
  lineOver?: boolean
  closeIconColor?: string
  dragListener?: boolean
  style?: 'fortune'
  preventClosing?: boolean
}

export default function BottomSheetLayout(props: Props) {
  const appContext = useAppContext()
  return (
    <>
      <Sheet.Container style={{ background: '#1A1C23', borderRadius: '36px 36px 0 0',
        // height: props.style === 'fortune' ? 'min(120%, calc(100% - env(safe-area-inset-top) - 34px))' : 'min(80%, calc(100% - env(safe-area-inset-top) - 34px))'
      }}
      >
        <Sheet.Content dragListener={!!props.dragListener} >
          <div className={styles.root}>
            {!props.preventClosing && <Sheet.Header style={{ position: props.lineOver ? 'absolute' : 'static', zIndex: 1 }} />}

            <div className={styles.content}>
              {props.children}
            </div>

            {!props.preventClosing && (
              <div className={styles.closeBtn}
                onClick={() => { appContext.hideBottomSheet() }}
              >
                <Close />
              </div>
            )}
          </div>
        </Sheet.Content>
      </Sheet.Container>


      <Sheet.Backdrop />

      {/* <Sheet.Backdrop  onTap={() => { props.preventClosing ? undefined : appContext.hideBottomSheet() }} /> */}
    </>
  )
}

