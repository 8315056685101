import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const UserAddIcon: React.FC<Props> = ({ className = '', ...props }) => {
  return (
    <StyledSvg width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path d="M10.75 10.9075V15.75H4.5C4.5 12.9886 6.73857 10.75 9.5 10.75C9.93162 10.75 10.3505 10.8047 10.75 10.9075ZM9.5 10.125C7.42812 10.125 5.75 8.44687 5.75 6.375C5.75 4.30312 7.42812 2.625 9.5 2.625C11.5719 2.625 13.25 4.30312 13.25 6.375C13.25 8.44687 11.5719 10.125 9.5 10.125ZM13.25 12.625V11.375C13.25 11.0298 13.5298 10.75 13.875 10.75C14.2202 10.75 14.5 11.0298 14.5 11.375V12.625H15.75C16.0952 12.625 16.375 12.9048 16.375 13.25C16.375 13.5952 16.0952 13.875 15.75 13.875H14.5V15.125C14.5 15.4702 14.2202 15.75 13.875 15.75C13.5298 15.75 13.25 15.4702 13.25 15.125V13.875H12C11.6548 13.875 11.375 13.5952 11.375 13.25C11.375 12.9048 11.6548 12.625 12 12.625H13.25Z" />
    </StyledSvg>

  )
}

const StyledSvg = styled.svg`
  path {
    fill: currentColor;
  }
`
