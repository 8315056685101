import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const ItemsSearch: React.FC<Props> = ({ className = '', ...props }) => {
  const id = Math.random().toString(36).substr(2, 8)

  return (
    <StyledSvg width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      {...props}
    >
      <g clipPath={`url(#${id})`}>
        <path
          d='M15.4585 15.2216C15.8216 14.641 16.0279 13.9425 16.0279 13.202C16.0279 11.0982 14.3775 9.41515 12.3146
          9.41515C10.2517 9.41515 8.60134 11.0982 8.60134 13.202C8.60134 15.3058 10.2517 16.9888 12.3146 16.9888C13.0325
          16.9888 13.7091 16.7784 14.2785 16.4166L16.2649 18.4098C16.5886 18.7347 17.1153 18.7325 17.4364 18.4051C17.7497
          18.0855 17.7505 17.5742 17.4382 17.2537L15.4585 15.2216ZM12.3146 15.3058C11.7675 15.3058 11.2428 15.0841 10.8559
          14.6896C10.469 14.295 10.2517 13.7599 10.2517 13.202C10.2517 12.644 10.469 12.1089 10.8559 11.7144C11.2428
          11.3198 11.7675 11.0982 12.3146 11.0982C12.8617 11.0982 13.3864 11.3198 13.7733 11.7144C14.1602 12.1089 14.3775
          12.644 14.3775 13.202C14.3775 13.7599 14.1602 14.295 13.7733 14.6896C13.3864 15.0841 12.8617 15.3058 12.3146
          15.3058ZM7.43785 15.4741L2.81997 11.808C2.40088 11.4753 2.40102 10.839 2.82024 10.5065C3.12276 10.2665 3.55071
          10.2666 3.8531 10.5067L6.76647 12.8198C6.88305 12.9124 6.95101 13.0531 6.95101 13.202C6.95101 14.0098 7.13254
          14.784 7.43785 15.4741ZM7.36359 11.1318L2.99044 7.67379C2.48461 7.2738 2.48403 6.50668 2.98927 6.10593L8.80507
          1.49291C9.16906 1.2042 9.68395 1.2042 10.0479 1.49291L15.8653 6.10714C16.37 6.5075 16.37 7.27371 15.8653
          7.67407L14.9221 8.42216C14.3353 8.09601 13.6948 7.88211 13.0325 7.79102L13.6712 7.28228C13.9228 7.08192 13.9225
          6.69963 13.6707 6.49963L9.73746 3.37598C9.55538 3.23138 9.29764 3.23138 9.11555 3.37598L5.18346 6.49875C4.93127
          6.69903 4.93142 7.08199 5.18376 7.28208L8.21351 9.68443C7.85869 10.122 7.56163 10.6101 7.36359 11.1318Z'
          fill={props.color}
        />
      </g>

      <defs>
        <clipPath id={`${id}`}>
          <rect width='20' height='20' fill={props.color} />
        </clipPath>
      </defs>
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  path {
    fill: currentColor;
  }
`
