import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const FreeBetIcon: React.FC<Props> = ({ className = '', ...props }) => {
  return (
    <StyledSvg width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      {...props}
    >
      <path
        d='M8.00012 0C8.40175 0 8.72738 0.325519 8.72751 0.727154L8.728 2.22692C11.3594 2.55538 13.445 4.64119
        13.7732 7.27273H15.2726C15.6743 7.27273 16 7.59839 16 8.00012C16 8.40175 15.6745 8.72738 15.2728 8.72751L13.7731
        8.728C13.4447 11.3592 11.3592 13.4447 8.728 13.7731L8.72751 15.2728C8.72738 15.6745 8.40175 16 8.00012 16C7.59839
        16 7.27273 15.6743 7.27273 15.2726V13.7732C4.64119 13.445 2.55538 11.3594 2.22692 8.728L0.727154 8.72751C0.32552
        8.72738 0 8.40175 0 8.00012C0 7.59839 0.325664 7.27273 0.727391 7.27273H2.22683C2.555 4.64094 4.64094 2.555
        7.27273 2.22683V0.727392C7.27273 0.325664 7.59839 0 8.00012 0ZM8 6.54545C7.19665 6.54545 6.54545 7.19665 6.54545
        8C6.54545 8.80335 7.19665 9.45455 8 9.45455C8.80335 9.45455 9.45455 8.80335 9.45455 8C9.45455 7.19665 8.80335
        6.54545 8 6.54545Z'
        fill={props.color}
      />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  path {
    fill: currentColor;
  }
`
