import React, { useState } from 'react'

import { Formik } from 'formik'
import { useTranslation } from 'next-i18next'
import { ProfileModalType } from 'types/enums'
import { useAppContext } from 'context/AppContext'
import AuthRepository from 'data/repositories/AuthRepository'
import Formatter from 'utils/formatter'
import { analytics } from 'utils/analytics'
import Validator from 'utils/validator'
import Converter from 'utils/converter'
import FormError from 'components/ui/Form/FormError'
import ReCAPTCHA from 'react-google-recaptcha'
import { runtimeConfig } from 'config/runtimeConfig'
import PromoCode from 'components/for_pages/Common/Promocode'

import {
  StyledForm,
  StyledInputFields,
  StyledScrollable,
  StyledPhoneInput,
} from './styles'
import { CurrencyField } from './CurrencyField'
import CheckboxAgreement from './CheckboxAgreement'
import { SubmitFooter } from './SubmitFooter'
import { getErrorCodeByFieldName } from './helper'

interface PhoneRegistrationProps {
  minHeight: number,
  onInputFocus: () => void,
  onInputBlur: () => void
}

const PhoneRegistration: React.FC<PhoneRegistrationProps> = ({ minHeight, onInputFocus, onInputBlur }) => {
  const { t } = useTranslation('common')
  const context = useAppContext()
  const [errors, setErrors] = useState<string | null>(null)
  const [sending, setSending] = useState<boolean>(false)

  const recaptchaRef = React.useRef<any>(null)

  const handleSubmit = async (data) => {
    setSending(true)

    try {
      setErrors(null)

      const recaptchaToken = await recaptchaRef.current?.executeAsync()

      const res = await AuthRepository.registerPhoneSendOtp({
        phone: Formatter.cleanPhone(data.phone),
        currency: data.currency,
        token: recaptchaToken
      })

      const accessToken = res.token

      if (!accessToken) {
        setErrors(t('registration_error'))
        setSending(false)
        return
      }

      context.setToken(accessToken)

      analytics.dataLayerPush({
        event: 'formSuccess',
        formName: 'registrationSuccess',
        registrationType:'phone'
      })

      await context.updateUserFromCookies()

      setSending(false)

      context.hideModal()
      context.showModal(ProfileModalType.wallet)
      context.setIsUserJustRegistered(true)
    } catch (e) {
      setSending(false)
      setErrors(e)
    } finally {
      recaptchaRef.current?.reset()
    }

    setSending(false)
  }

  const initialValues = {
    phone: null,
    currency: Converter.convertCurrencyToOptions(context.currencies)[0]?.value,
    checkBox: true,
    checkBoxPromotion: true
  }

  return (
    <Formik initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur={false}
    >
      <StyledForm>
        <StyledScrollable style={{ minHeight }}>
          <StyledInputFields>
            <CurrencyField name='currency' />

            <StyledPhoneInput name='phone'
              defaultCountry={context.countryByIp?.iso}
              validate={Validator.required}
              error={getErrorCodeByFieldName(errors, 'phone')}
              mask='+999999999999999'
              disabled={sending}
              onFocus={onInputFocus}
              onBlur={onInputBlur}
            />

            <FormError error={typeof errors === 'string' ? errors : null} />
          </StyledInputFields>

          <PromoCode />

          <CheckboxAgreement />
        </StyledScrollable>

        <SubmitFooter sending={sending} />

        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={runtimeConfig.RECAPTCHA_KEY}
          style={{ visibility: "hidden" }}
        />
      </StyledForm>
    </Formik>
  )
}

export default PhoneRegistration
