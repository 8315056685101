import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const DropdownArrowIcon: React.FC<Props> = ({ className = '', ...props }) => {
  return (
    <StyledSvg width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <g clipPath="url(#clip0_525_46512)">
        <path d="M9.49481 12.4857L9.49524 12.486C9.65769 12.6206 9.86169 12.6948 10.0725 12.696C10.2738 12.7083 10.4736 12.6536 10.6407 12.5403L10.6474 12.5357L10.6538 12.5306L14.9301 9.08817C15.0291 9.01215 15.1115 8.91666 15.1721 8.80756L14.9973 8.71041L15.1721 8.80756C15.2332 8.69767 15.2709 8.57635 15.2829 8.45121C15.2949 8.32606 15.2809 8.19979 15.2418 8.0803C15.2033 7.96242 15.1411 7.85365 15.0592 7.76061C14.9844 7.66857 14.8924 7.59197 14.7883 7.5351L14.6926 7.71025L14.7883 7.5351C14.6827 7.47742 14.5668 7.44118 14.4472 7.42845C14.3275 7.41572 14.2066 7.42677 14.0912 7.46094L14.148 7.6527L14.0912 7.46094C13.976 7.49508 13.8686 7.55162 13.7753 7.62733C13.7752 7.62742 13.7751 7.62751 13.775 7.6276L10.0815 10.6044L6.38557 7.51531L6.38557 7.51531L6.38515 7.51496C6.19874 7.36002 5.95842 7.28548 5.71706 7.30774C5.47569 7.32999 5.25306 7.44721 5.09812 7.63362C4.94318 7.82003 4.86864 8.06035 4.8909 8.30171C4.91315 8.54304 5.03034 8.76566 5.2167 8.92059C5.21673 8.92061 5.21676 8.92063 5.21678 8.92065L9.49481 12.4857Z"
          strokeWidth="0.4"
        />
      </g>

      <defs>
        <clipPath id="clip0_525_46512">
          <rect width="8"
            height="12"
            fill="white"
            transform="matrix(0 1 -1 0 15.9991 5.99997)"
          />
        </clipPath>
      </defs>
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  path {
    fill: currentColor;
  }
`
