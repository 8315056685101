import { forwardRef, PropsWithChildren, ReactElement } from 'react'

import clsx from 'clsx'

import classes from './index.module.scss'

interface Props {
  label?: string
  icon?: string | ReactElement
  selected?: boolean
  onClick?: () => void
  className?: string
}

export const PaymentMethodCard = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  ({ icon, label, className, children, onClick, selected }, ref) => (
    <div
      ref={ref}
      className={clsx(
        classes.root,
        { [classes.selected]: selected },
        className,
      )}
      onClick={onClick}
    >
      {children ? (
        children
      ) : (
        <>
          {icon && typeof icon === 'string' ? (
            <img className={classes.icon}
              src={icon}
              alt=""
            />
          ) : (
            icon
          )}

          <div className={classes.label}>
            {label}
          </div>
        </>
      )}
    </div>
  ),
)
