import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const ReferralIcon: React.FC<Props> = ({ className = '', ...props }) => {
  return (
    <StyledSvg width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path d="M10 10.4401C8.27344 10.4401 6.875 8.9991 6.875 7.22004C6.875 5.44097 8.27344 4 10 4C11.7266 4 13.125 5.44097 13.125 7.22004C13.125 8.9991 11.7266 10.4401 10 10.4401Z" />

      <path d="M5 16C5 13.3443 7.23857 11.1914 10 11.1914C12.7614 11.1914 15 13.3443 15 16H5Z" />

      <path d="M6.16517 11.0599C4.29498 11.9308 3 13.8154 3 16H1C1 13.4896 3.01472 11 5.5 11C5.726 11 5.94811 11.0206 6.16517 11.0599Z" />

      <path d="M6 8C6 9.10457 5.10457 10 4 10C2.89543 10 2 9.10457 2 8C2 6.89543 2.89543 6 4 6C5.10457 6 6 6.89543 6 8Z" />

      <path d="M13.8348 11.0599C15.705 11.9308 17 13.8154 17 16H19C19 13.4896 16.9853 11 14.5 11C14.274 11 14.0519 11.0206 13.8348 11.0599Z" />

      <path d="M14 8C14 9.10457 14.8954 10 16 10C17.1046 10 18 9.10457 18 8C18 6.89543 17.1046 6 16 6C14.8954 6 14 6.89543 14 8Z" />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  path {
    fill: currentColor;
  }
`
