import React from 'react'

import { useTranslation } from 'next-i18next'
import { Tabs } from 'ui-kit'
import styled from 'styled-components'
import { WalletSvg } from 'components/svg/WalletSvg'
import { COLORS } from 'ui-kit/constants'
import { DepositSvg } from 'components/svg/DepositSvg'

interface Props {
  activeTab: string,
  onTabChange: (tab: string) => void
}

export const WalletModalNewTabs: React.FC<Props> = ({ activeTab, onTabChange }) => {
  const { t } = useTranslation()

  const tabs = [
    {
      content: (
        <>
          <StyledWalletIcon color={activeTab === 'deposit' ? 'white' : COLORS.dark200} />

          {' '}

          {t('wallet_title')}
        </>
      ),
      value: 'deposit',
    },
    {
      content: (
        <>
          <StyledDepositIcon color={activeTab === 'withdraw' ? 'white' : COLORS.dark200} />

          {' '}

          {t('profile_account_action_withdrawal')}
        </>
      ),
      value: 'withdraw',
    },
  ]

  return (
    <StyledTabs
      tabs={tabs}
      activeTab={activeTab}
      onChange={onTabChange}
    />
  )
}

const StyledTabs = styled(Tabs)`
  margin-top: 10px;
  min-height: 32px;
`

const StyledWalletIcon = styled(WalletSvg)`
  margin-right: 6px;
`

const StyledDepositIcon = styled(DepositSvg)`
  margin-right: 6px;
`

