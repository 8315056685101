import { useAppContext } from 'context/AppContext'
import {
  ModalType,
  ProfileModalType,
} from 'types/enums'
import { isServer } from 'utils/media'
import Modal from 'components/ui/Modal'
import ModalPasswordRecovery from 'components/Auth/ModalPasswordRecovery'
import ModalPasswordReset from 'components/Auth/ModalPasswordReset'
import ModalRegistrationSuccess from 'components/Auth/ModalRegistrationSuccess'
import PaymentHistory from 'components/Profile/PaymentHistory'
import { ProfileModal } from 'components/Profile/ProfileModal/ProfileModal'
import Settings from 'components/Profile/Settings'
import Fortune from 'components/Fortune'
import ModalRegistrationPhone from 'components/Auth/ModalRegistrationPhone'
import ProfileBurger from 'components/ui/ProfileBurger'
import Favorite from 'components/Profile/Favorite'
import BetsHistory from 'components/Profile/BetsHistory'
import { Wallet } from 'components/Profile/Wallet/Wallet'
import Exchange from 'components/Profile/Exchange'
import BuyCrypto from 'components/Profile/BuyCrypto'
import { FA } from 'components/Profile/FA/FA'
import { Withdraw } from 'components/Profile/Withdraw/Withdraw'
import { RemoveScroll } from 'react-remove-scroll'
import ProfileModalNew from 'components/Profile/layout/ProfileModalNew'
import ModalConfirmOldPhone from 'components/Profile/ModalConfirmOldPhone'
import { ModalConfirmNewPhone } from 'components/Profile/ModalConfirmNewPhone/ModalConfirmNewPhone'
import FALogin from 'components/Profile/FALogin'
import MobileAppModal from 'components/ui/MobileAppModal'
import { AstroPayModal } from 'components/ui/AstroPayModal'
import FreeSpinsGames from 'components/Profile/FreeSpinsGames'
import { RestrictedCountryModal } from 'components/ui/RestrictedCountryModal'
import { ModalRegistration } from 'components/Auth/ModalRegistration'
import { WalletModalNew } from 'components/Profile/WalletNew/WalletModalNew/WalletModalNew'
import PaymentModal from 'components/for_pages/Common/PaymentModal'
import { ModalLogin } from 'components/Auth/ModalLogin'

import GameInterruptModal from '../../ui/GameInterruptModal'


export const ModalList = () => {
  const context = useAppContext()

  const commonSettings = {
    onRequestClose: context.hideModal,
  }

  if (!context.modal) return null

  return (
    <RemoveScroll>
      {!isServer && (
        <div aria-hidden='true'>
          <ModalLogin
            isOpen={context.modal === ModalType.login}
            {...commonSettings}
          />

          <ProfileModalNew
            size='small'
            isOpen={context.modal === ModalType.passwordRecovery}
            {...commonSettings}
          >
            {context.modal === ModalType.passwordRecovery && (
              <ModalPasswordRecovery />
            )}
          </ProfileModalNew>

          <ProfileModalNew
            size='small'
            isOpen={context.modal === ModalType.passwordReset}
            {...commonSettings}
          >
            {context.modal === ModalType.passwordReset && (
              <ModalPasswordReset />
            )}
          </ProfileModalNew>

          <ModalRegistration
            isOpen={context.modal === ModalType.registration}
            onRequestClose={context.hideModal}
          />

          <ProfileModalNew
            size='small'
            isOpen={context.modal === ModalType.registrationPhone}
            {...commonSettings}
          >
            <ModalRegistrationPhone />
          </ProfileModalNew>

          <ProfileModalNew
            size='small'
            isOpen={context.modal === ModalType.registrationSuccess}
            {...commonSettings}
          >
            {context.modal === ModalType.registrationSuccess && (
              <ModalRegistrationSuccess />
            )}
          </ProfileModalNew>

          <ProfileModalNew
            size='large'
            isOpen={context.modal === ProfileModalType.paymentHistory}
            {...commonSettings}
          >
            {context.modal === ProfileModalType.paymentHistory && (
              <PaymentHistory />
            )}
          </ProfileModalNew>

          <ProfileModalNew
            isOpen={context.modal === ProfileModalType.profile}
            {...commonSettings}
          >
            {context.modal === ProfileModalType.profile && <ProfileModal />}
          </ProfileModalNew>

          <ProfileModalNew
            size='large'
            isOpen={context.modal === ProfileModalType.settings}
            {...commonSettings}
          >
            {context.modal === ProfileModalType.settings && <Settings />}
          </ProfileModalNew>

          <ProfileModalNew
            size='small'
            isOpen={context.modal === ProfileModalType.walletNew}
            {...commonSettings}
          >
            {context.modal === ProfileModalType.walletNew && <Wallet />}
          </ProfileModalNew>

          <ProfileModalNew
            size='small'
            isOpen={context.modal === ProfileModalType.withdraw}
            {...commonSettings}
          >
            {context.modal === ProfileModalType.withdraw && <Withdraw />}
          </ProfileModalNew>

          <Modal
            fortune
            isOpen={context.modal === ModalType.fortune}
            {...commonSettings}
            noBorder
            size='fortune'
          >
            {context.modal === ModalType.fortune && <Fortune />}
          </Modal>

          <ProfileModalNew
            size='small'
            isOpen={context.modal === ProfileModalType.exchange}
          >
            {context.modal === ProfileModalType.exchange && <Exchange />}
          </ProfileModalNew>

          <ProfileModalNew
            size='large'
            isOpen={context.modal === ProfileModalType.favorite}
            {...commonSettings}
          >
            {context.modal === ProfileModalType.favorite && <Favorite />}
          </ProfileModalNew>

          <ProfileModalNew
            size='large'
            isOpen={context.modal === ProfileModalType.betsHistory}
            {...commonSettings}
          >
            <BetsHistory />
          </ProfileModalNew>

          <ProfileModalNew
            size='small'
            isOpen={context.modal === ProfileModalType.buyCrypto}
            {...commonSettings}
          >
            {context.modal === ProfileModalType.buyCrypto && <BuyCrypto />}
          </ProfileModalNew>

          <ProfileModalNew
            size='small'
            isOpen={context.modal === ProfileModalType.FA}
            {...commonSettings}
          >
            {context.modal === ProfileModalType.FA && <FA />}
          </ProfileModalNew>

          <ProfileModalNew
            size='small'
            isOpen={context.modal === ModalType.profileBurger}
            {...commonSettings}
          >
            <ProfileBurger onRequestClose={() => context.hideModal()} />
          </ProfileModalNew>

          <ProfileModalNew
            size='small'
            isOpen={context.modal === ProfileModalType.oldPhoneConfirm}
            {...commonSettings}
          >
            {context.modal === ProfileModalType.oldPhoneConfirm && (
              <ModalConfirmOldPhone />
            )}
          </ProfileModalNew>

          <ProfileModalNew
            size='small'
            isOpen={context.modal === ProfileModalType.newPhoneConfirm}
            {...commonSettings}
          >
            {context.modal === ProfileModalType.newPhoneConfirm && (
              <ModalConfirmNewPhone />
            )}
          </ProfileModalNew>

          <ProfileModalNew
            size='small'
            isOpen={context.modal === ModalType.faLogin}
            {...commonSettings}
          >
            {context.modal === ModalType.faLogin && <FALogin />}
          </ProfileModalNew>

          <ProfileModalNew
            size='small'
            isOpen={context.modal === ModalType.mobileApp}
            {...commonSettings}
          >
            {context.modal === ModalType.mobileApp && <MobileAppModal />}
          </ProfileModalNew>

          <ProfileModalNew
            size='large'
            isOpen={context.modal === ModalType.restrictedCountry}
            onRequestClose={() => undefined}
          >
            {context.modal === ModalType.restrictedCountry && (
              <RestrictedCountryModal />
            )}
          </ProfileModalNew>

          <ProfileModalNew
            size='large'
            isOpen={context.modal === ModalType.astroPay}
            {...commonSettings}
          >
            {context.modal === ModalType.astroPay && <AstroPayModal />}
          </ProfileModalNew>

          {context.modal === ProfileModalType.freeSpinsGames && (
            <FreeSpinsGames />
          )}

          <WalletModalNew
            opened={context.modal === ProfileModalType.wallet}
            onClose={context.hideModal}
          />

          {context.modal === ModalType.depositSuccess && (
            <PaymentModal
              title='deposit_success_text_title'
              variant='success'
              isOpen={context.modal === ModalType.depositSuccess}
              {...commonSettings}
            />
          )}

          {context.modal === ModalType.depositFail && (
            <PaymentModal
              title='deposit_fail_text_title'
              variant='fail'
              isOpen={context.modal === ModalType.depositFail}
              {...commonSettings}
            />
          )}

          {context.modal === ModalType.withdrawSuccess && (
            <PaymentModal
              title='withdraw_success_title'
              variant='success'
              isOpen={context.modal === ModalType.withdrawSuccess}
              {...commonSettings}
            />
          )}

          {context.modal === ModalType.withdrawFail && (
            <PaymentModal
              title='withdraw_fail_title'
              variant='fail'
              isOpen={context.modal === ModalType.withdrawFail}
              {...commonSettings}
            />
          )}

          {context.modal === ModalType.gameInterrupt && (
            <ProfileModalNew
              isOpen={context.modal === ModalType.gameInterrupt}
              size="large"
            >
              <GameInterruptModal />
            </ProfileModalNew>
          )}
        </div>
      )}
    </RemoveScroll>
  )
}
