import classNames from 'classnames'
import { useAppContext } from 'context/AppContext'
import { ScrollableWithShadows } from 'ui-kit'
import styled from 'styled-components'

import styles from './index.module.scss'


interface Props {
  fixed?: boolean
  children?: React.ReactNode
  id?: string
  className?: string
}

export default function ProfileModalBody(props: Props) {
  const context = useAppContext()
  return (
    <div className={classNames(styles.wrapper, props.className)}>
      <StyledScrollable>
        <div className={classNames(styles.root, { [styles.fixed]: props.fixed || context.isMobile })}
          id={props.id}
        >
          {props.children}
        </div>
      </StyledScrollable>
    </div>
  )
}

const StyledScrollable = styled(ScrollableWithShadows)`
  flex: 1;
  overflow: auto;
  border-radius: 0 0 30px 30px;
`
