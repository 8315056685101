import classNames from 'classnames'
import Spinner from 'components/ui/Spinner'
import { colors } from 'scss/variables'
import Image from 'next/image'

import styles from './index.module.scss'

interface Props {
  className?: string
}

export default function RichyLoader(props: Props) {
  return (
    <div className={classNames(styles.root, props.className)}>
      <Spinner
        size={140}
        color={colors.pay500}
        secondaryColor="rgb(123, 210, 69, 0.2)"
        thickness={120}
      />

      <div className={styles.logo}>
        <Image src={'/img/logo/richy_symbol.svg'}
          width={52}
          height={44}
        />
      </div>
    </div>
  )
}
