import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const MenuAccountIcon: React.FC<Props> = ({ className = '', ...props }) => {
  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
      {...props}
    >
      <path d="M7.45023 9.99983C9.32827 9.99983 10.8504 8.43307 10.8504 6.49992C10.8504 4.56676 9.32827 3 7.45023 3C5.57219 3 4.0501 4.56676 4.0501 6.49992C4.0501 8.43307 5.57219 9.99983 7.45023 9.99983ZM9.83033 10.8748H9.38671C8.797 11.1537 8.14088 11.3123 7.45023 11.3123C6.75958 11.3123 6.10612 11.1537 5.51375 10.8748H5.07014C3.09912 10.8748 1.5 12.5209 1.5 14.5497V15.6872C1.5 16.4118 2.07112 16.9997 2.77505 16.9997H10.0774C10.0136 16.8137 9.98705 16.6169 10.0083 16.4173L10.1889 14.7521L10.2208 14.4486L10.4307 14.2325L12.484 12.1189C11.8332 11.3615 10.8902 10.8748 9.83033 10.8748ZM11.0337 14.8478L10.853 16.5157C10.8238 16.7946 11.0522 17.0297 11.3205 16.9969L12.9383 16.811L16.6014 13.0404L14.6968 11.0799L11.0337 14.8478ZM18.3147 10.3526L17.308 9.31626C17.0609 9.06197 16.6572 9.06197 16.4101 9.31626L15.406 10.3498L15.2971 10.4619L17.2044 12.4224L18.3147 11.2795C18.5618 11.0225 18.5618 10.6096 18.3147 10.3526Z" />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  path {
    fill: currentColor;
  }
`
