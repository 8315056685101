import { FC, useContext, useMemo } from 'react'

import classNames from 'classnames'

import { table } from './utils'
import { SRow } from './styles'
import { IRow } from './types'


const Row: FC<IRow> = ({ onClick, children }) => {
  const { cols, ready } = useContext(table),
    gridTemplateColumns = useMemo(() => cols.map(item => item.size).join(' '), [cols])

  if (!ready) return null

  return (
    <SRow
      onClick={onClick}
      style={{ gridTemplateColumns }}
    >
      {children}
    </SRow>
  )
}


export default Row
