import { useCallback, useEffect, useState } from 'react'

import { IGame } from 'data/interfaces/IGame'
import { useTranslation } from 'next-i18next'
import ContentLoader from 'components/ui/ContentLoader'
import GameCard from 'components/pages-blocks/Components/GameCard'
import GameListRepository from 'data/repositories/GameListRepository'
import InputSearch from 'components/ui/Inputs/InputSearch'
import ShowMoreButton from 'components/ui/ShowMoreButton'
import { useAppContext } from 'context/AppContext'
import { debounce } from 'lodash'
import ReactModal from 'react-modal'
import styled, { css } from "styled-components"

import { BREAKPOINTS, COLORS, FONTS } from "../../../ui-kit/constants"
import { SvgIcon } from "../../../ui-kit/svg"
import { ProfileModalType } from "../../../types/enums"
import { applyStyles, SIZES } from "../../../context/layoutContext"
import { ScrollableWithShadows } from "../../../ui-kit"

import styles from './index.module.scss'

export default function FreeSpinsGames() {
  const { t } = useTranslation()
  const appContext = useAppContext()
  const [games, setGames] = useState<IGame[]>([])
  const [total, setTotal] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingMore, setLoadingMore] = useState<boolean>(false)
  const [search, setSearch] = useState('')
  const countPerPage = 9

  const isOpen = appContext.modal === ProfileModalType.freeSpinsGames

  const handleClose = () => {
    appContext.hideModal()
  }

  useEffect(() => {
    if (!appContext?.user?.extraBalances?.freespinAmount) {
      setTotal(0)
      setLoading(false)

      return
    }

    const fetchGames = async () => {
      const response = await GameListRepository.fetchFreeSpinsGames({ isMobile: appContext.isMobile }, 1, countPerPage)

      setGames(response.data)
      setTotal(response.total)
      setLoading(false)
    }

    fetchGames()
  }, [appContext?.user?.extraBalances?.freespinAmount])

  const handleSearch = useCallback(debounce(async (search) => {
    setSearch(search)
    setLoading(true)

    const response = await GameListRepository.fetchFreeSpinsGames({ isMobile: appContext.isMobile,  name: search }, 1, countPerPage)

    setGames(response.data)
    setTotal(response.total)
    setLoading(false)
  }, 600), [])

  const handleLoadMore = async () => {
    setLoadingMore(true)

    const response = await GameListRepository.fetchFreeSpinsGames({ isMobile: appContext.isMobile, name: search }, Math.floor(games.length / countPerPage) + 1, countPerPage)

    setGames(state => ([...state, ...response.data]))
    setLoadingMore(false)
  }

  const handlePlayClick = () => {
    appContext.hideModal()
  }

  return (
    <ReactModal
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      onRequestClose={handleClose}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 300
        },
        content: {
          background: 'transparent',
          border: 'none',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          padding: 0,
          top: 0,
          left: 0
        }
      }}
    >
      <SContent>
        <SCloseIcon type='close'
          onClick={handleClose}
        />

        <STitleRow>
          <STitleIcon src="/img/account/spins.png" />

          {t('profile_free_spins_title')}
        </STitleRow>

        <SAvailableSpinsText>
          {t('free_spins_modal_available')}

:

          {' '}

          {appContext.user.extraBalances.freespinAmount ?? 0}
        </SAvailableSpinsText>

        <SSearchInput
          placeholder={t('catalog_filter_search_field')}
          onChange={handleSearch}
          className={styles['search']}
        />

        {loading && (
          <ContentLoader style={'block'}
            isOpen={true}
          />
        )}

        <SGamesBoxWrapper>

          {!loading && !!games.length && (
            <SGamesBox>
              {games.map((game) => (
                <SGameCard
                  key={game.id}
                  game={game}
                  onPlayClick={handlePlayClick}
                  isFreeSpins
                  withoutMargin
                />
              ))}
            </SGamesBox>
          )}

          {!loading && !games.length && (
            <SEmptyBox>
              <SEmptyImg src="/img/account/empty-wallet.png" />

              {t('profile_free_spins_games_not_found')}
            </SEmptyBox>
          )}

          {!loading && !!games.length && total > games.length && (
            <>
              <SShowMoreButton
                title={t('catalog_list_more')}
                loading={loadingMore}
                onShow={handleLoadMore}
                className={styles['show-more']}
              />
            </>
          )}
        </SGamesBoxWrapper>
      </SContent>
    </ReactModal>
  )
}

const SContent = styled.div`
  width: 560px;
  background: linear-gradient(180deg, #33343F 0%, rgba(29, 30, 37, 0.00) 100%);
  padding: 25px 32px;
  border-radius: 16px;
  background: ${COLORS.dark600};
  position: relative;
  display: flex;
  flex-direction: column;

  ${({ theme }) => `
    ${applyStyles([SIZES.MOBILE], theme.contentSize, css`
      width: 100%;
      height: 100vh;
      padding: 25px 25px 60px;
    `)}
  `};
`

export const STitleRow = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  color: white;
`

export const STitleIcon = styled.img`
  margin-right: 4px;
  width: 28px;
  height: 28px;
`

export const SAvailableSpinsText = styled.p`
  margin: 10px 0;
  ${FONTS.h3};
  color: white;
`

export const SSearchInput = styled(InputSearch)`
  margin-top: 0;

  input {
    background: ${COLORS.dark400}!important;
  }
`

const SCloseIcon = styled(SvgIcon)`
  width: 24px;
  height: 24px;
  color: ${COLORS.dark200};
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;

  &:hover {
    color: white;
  }
`

export const SGamesBoxWrapper = styled(ScrollableWithShadows)`
  flex: 1 1 0%;
  overflow: auto;
  max-height: 75vh;

  ${({ theme }) => `
    ${applyStyles([SIZES.MOBILE], theme.contentSize, css`
      max-height: unset;
    `)}
  `};
`

export const SGamesBox = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  overflow-y: auto;
  overflow-x: hidden;
  gap: 8px;

  ${({ theme }) => `
    ${applyStyles([SIZES.MOBILE], theme.contentSize, css`
      gap: 9px;
    `)}
  `};
`

export const SGameCard = styled(GameCard)`
  ${({ theme }) => `
    ${applyStyles([SIZES.MOBILE], theme.contentSize, css`
      height: calc(((100vw - 68px) / 3) * 1.125);
      width: calc((100vw - 68px) / 3);
    `)}
  `};
`

export const SEmptyBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 400px;
  ${FONTS.h4};
  color: ${COLORS.dark400};
`

export const SEmptyImg = styled.img`
  margin-bottom: 14px;
  width: 92px;
  height: 92px;
`

const SShowMoreButton = styled(ShowMoreButton)`
  box-shadow: -26px -10px 43px 16px rgba(0,0,0,0.75);
  z-index: 22;
`

// const Providers = () => {
//   const { t } = useTranslation()

//   const appContext = useAppContext()

//   const sliderRef = useRef<Slider | null>(null)

//   const fetchProviders = useQuery<IGameProviderTop3[]>(
//     ['fetchProvidersTop3'],
//     () => GameListRepository.fetchProvidersTop3(),
//     { placeholderData: [] },
//   )

//   const data = fetchProviders?.data || []

//   return (
//     <>
//       <div className={styles['providers-title-row']}>
//         {t('catalog_filter_providers_dropdown')}
//       </div>


//       {!fetchProviders.isLoading &&
//         <Slider
//           ref={(slider) => (sliderRef.current = slider)}
//           {...sliderSettings}
//         >
//           {data.map((provider, index) => (
//             <div key={index}>
//               <ProviderCard provider={provider} key={provider.id} />
//             </div>
//           ))}
//         </Slider>
//       }
//     </>
//   )
// }

// const sliderSettings: Settings = {
//   className: styles.slider,
//   dots: false,
//   arrows: false,
//   variableWidth: false,
//   adaptiveHeight: false,
//   speed: 500,
//   slidesToShow: 3
// }

// interface ProviderCardProps {
//   provider: IGameProviderTop3
// }

// const ProviderCard: React.FC<ProviderCardProps> = ({ provider }) => {
//   return (
//     <a className={styles['provider-card']}>
//       {provider.imagePreviewUrl ? (
//           <img
//             src={provider.imagePreviewUrl || '/img/logo/richy.svg'}
//             alt={provider.name}
//             className={styles['provider-image']}
//           />
//         ) : (
//           <div className={styles['provider-name']}>{provider.name}</div>
//         )}
//     </a>
//   )
// }
