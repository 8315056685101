import { FC } from 'react'
import { useTranslation } from 'next-i18next'
import ProfileModalLayout from 'components/Profile/layout/ProfileModalLayout'
import ModalHeader from 'components/Profile/layout/ProfileModalHeader/ModalHeader'
import ProfileModalBody from 'components/Profile/layout/ProfileModalBody'
import { useAppContext } from 'context/AppContext'
import styles from './index.module.scss'
import BottomSheetLayout from 'components/layout/BottomSheetLayout'
import BottomSheetBody from 'components/layout/BottomSheetBody'
import BottomSheetHeader from 'components/layout/BottomSheetHeader'
import { ModalType, ProfileModalType } from 'types/enums'

interface Props {
  isBottomSheet?: boolean
}

export const RestrictedCountryModal: FC<Props> = ({isBottomSheet}) => {
  const { t } = useTranslation()

  console.log(isBottomSheet)

  const component = (
    <div className={styles.root}>
      <p className={styles.title}>
        {t('restricted_country_modal_title_1')} <br />
        {t('restricted_country_modal_title_2')}
      </p>

      <p className={styles.text}>
        {t('restricted_country_modal_description_1')}<br />
        {t('restricted_country_modal_description_2')}: <a href="mailto:support@richy.casino">support@richy.casino</a>
      </p>

      <img src="/img/restricted-country-modal/logo.png" className={styles.img} />
    </div>
  )

  if (isBottomSheet) {
    return (
      <BottomSheetLayout preventClosing>
        <BottomSheetBody>{component}</BottomSheetBody>
      </BottomSheetLayout>
    )
  }

  return (
    <ProfileModalLayout fixed>
      <ProfileModalBody fixed>{component}</ProfileModalBody>
    </ProfileModalLayout>
  )
}
