import React from 'react'

import { useTranslation } from 'next-i18next'

export const StepInfo = ({ className = '' }) => {
  const { t } = useTranslation()

  return (
    <div className='withdraw-in-process-text'>
      {t('withdraw_in_process')}
    </div>
  )
}
