import { FC, MouseEvent, useEffect } from 'react'

import { useAppContext } from 'context/AppContext'
import { useFavoriteContext } from 'context/favorite_state'
import { ModalType } from 'types/enums'
import { prevent } from 'utils'

import classes from './FavoriteBtn.module.scss'

interface Props {
  id: number
  onChange?: (checked: boolean) => void
}

export const FavoriteBtn: FC<Props> = (props) => {
  const appContext = useAppContext()

  const favoriteContext = useFavoriteContext()
  const active = favoriteContext.store.games.includes(props.id)

  useEffect(() => {
    favoriteContext.addRecord(props.id)
  }, [])

  const handleBoxClick = (e: MouseEvent) => {
    prevent(e)

    if (!appContext.auth) {
      appContext.showModal(ModalType.login)
      return
    }

    props.onChange?.(!active)

    if (active) {
      favoriteContext.unlike(props.id)
    } else {
      favoriteContext.like(props.id)
    }
  }

  return (
    <button className={classes.box}
      onClick={handleBoxClick}
    >
      {active ? (
        <img
          src="/img/GamesList/star-fill.svg"
          alt=""
          className={classes.activeImage}
        />
      ) : (
        <img
          src="/img/GamesList/star-stroke.svg"
          alt=""
          className={classes.inactiveImage}
        />
      )}
    </button>
  )
}
