import { FC } from 'react'

import { useTranslation } from 'next-i18next'
import { Header } from 'components/layout/Header/Header'
import { useAppContext } from 'context/AppContext'
import { ProfileModalType } from 'types/enums'

import { ModalClose } from '../ModalClose/ModalClose'

import classes from './HeaderMobile.module.scss'

interface Props {
  title?: string
  onBackClick?: () => void
}

export const HeaderMobile: FC<Props> = (props) => {
  const { t } = useTranslation()
  const appContext = useAppContext()

  const getBackTitle = (type: ProfileModalType) => {
    switch (type) {
    case ProfileModalType.betsHistory:
      return t('bets_history_title')
    case ProfileModalType.buyCrypto:
      return t('buy_crypto_title')
    case ProfileModalType.exchange:
      return t('exchange_title')
    case ProfileModalType.FA:
      return t('2fa_title')
    case ProfileModalType.favorite:
      return t('favorite_title')
    case ProfileModalType.paymentHistory:
      return t('payment_history_title')
    case ProfileModalType.profile:
      return t('profile_title')
    case ProfileModalType.settings:
      return t('settings_title')
    case ProfileModalType.wallet:
      return t('wallet_title')
    case ProfileModalType.withdraw:
      return t('withdraw_title')
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Header rightButton={<ModalClose />} />
      </div>

      {(appContext.lastProfileModal?.type || props.title) && (
        <div className={classes.footer}>
          {!!appContext.lastProfileModal?.type && (
            <div className={classes.backArea}>
              <div className={classes.arrow}
                onClick={props.onBackClick}
              >
                <img src="/img/icons/back-arrow.svg"
                  alt=""
                />
              </div>

              <div className={classes.backTitle}
                onClick={props.onBackClick}
              >
                {getBackTitle(appContext.lastProfileModal?.type) ?? ''}
              </div>
            </div>
          )}

          {props.title && (
            <div className={classes.title}>
              {props.title}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
