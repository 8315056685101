import React from 'react'

import { colors } from 'scss/variables'

interface Props {
  color?: string
  className?: string
}

function GiftSvg({ color = "#686A7B", className = '' }) {
  return (
    <svg width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M3.95923 12.9068V17.1912C3.96157 17.4066 4.04876 17.6123 4.20187 17.7638C4.35498 17.9152 4.56165 18.0001 4.77701 18.0001H9.77256V12.9068H3.95923Z"
        fill={color}
      />

      <path d="M11.2292 18.0001H16.2248C16.4402 18.0001 16.6468 17.9152 16.7999 17.7638C16.9531 17.6123 17.0402 17.4066 17.0426 17.1912V12.9068H11.2292V18.0001Z"
        fill={color}
      />

      <path d="M17.7 5.63556H16.0644C16.232 5.29531 16.3201 4.92147 16.3222 4.54222C16.3199 3.86717 16.0501 3.22056 15.5719 2.74406C15.0937 2.26755 14.4462 2 13.7711 2C13.0659 2.03344 12.3845 2.26569 11.8057 2.66993C11.2269 3.07416 10.7742 3.63388 10.5 4.28444C10.2258 3.63388 9.7731 3.07416 9.19428 2.66993C8.61546 2.26569 7.9341 2.03344 7.22889 2C6.55383 2 5.90629 2.26755 5.42812 2.74406C4.94994 3.22056 4.68013 3.86717 4.67778 4.54222C4.67989 4.92147 4.76803 5.29531 4.93556 5.63556H3.3C2.85556 5.63556 2.5 6.16 2.5 6.8V10.2933C2.5 10.9333 2.85556 11.4578 3.3 11.4578H9.77111V5.63556H11.2289V11.4578H17.7C18.1444 11.4578 18.5 10.9333 18.5 10.2933V6.8C18.5 6.16 18.1444 5.63556 17.7 5.63556ZM7.22889 5.63556C7.07763 5.64924 6.92517 5.63125 6.78124 5.58275C6.63732 5.53424 6.50507 5.45628 6.39294 5.35384C6.28081 5.25139 6.19126 5.12671 6.12999 4.98774C6.06872 4.84876 6.03707 4.69855 6.03707 4.54667C6.03707 4.39479 6.06872 4.24457 6.12999 4.1056C6.19126 3.96662 6.28081 3.84194 6.39294 3.7395C6.50507 3.63705 6.63732 3.55909 6.78124 3.51059C6.92517 3.46208 7.07763 3.4441 7.22889 3.45778C8.47333 3.45778 9.17556 4.73778 9.52222 5.63556H7.22889ZM13.7711 5.63556H11.4778C11.8244 4.74667 12.5267 3.45778 13.7711 3.45778C13.9224 3.4441 14.0748 3.46208 14.2188 3.51059C14.3627 3.55909 14.4949 3.63705 14.6071 3.7395C14.7192 3.84194 14.8087 3.96662 14.87 4.1056C14.9313 4.24457 14.9629 4.39479 14.9629 4.54667C14.9629 4.69855 14.9313 4.84876 14.87 4.98774C14.8087 5.12671 14.7192 5.25139 14.6071 5.35384C14.4949 5.45628 14.3627 5.53424 14.2188 5.58275C14.0748 5.63125 13.9224 5.64924 13.7711 5.63556Z"
        fill={color}
      />
    </svg>
  )
}

export default GiftSvg



