import { FC } from 'react'

import { SProgressBar, SProgressWrapper, SValue } from './styles'


export const Progress: FC<{ value?: number }> = ({ value = 0 }) => {
  return (
    <SProgressWrapper>
      <SProgressBar value={value} />

      <SValue>
        {`${value}%`}
      </SValue>
    </SProgressWrapper>
  )
}
