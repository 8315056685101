import React, { useEffect, useState } from 'react'

import classNames from 'classnames'
import styled from 'styled-components'

export interface PageOverlayProps {
    className?: string,
    onClick?: () => void;
    active: boolean;
    animationDuration?: number;
    backgroundColor?: string
}

export const PageOverlay: React.FC<PageOverlayProps> = ({ onClick, active, backgroundColor, animationDuration = 0.2, className = '' }) => {
  const [visible, toggleVisibility] = useState(false)

  useEffect(() => {
    if (active) {
      toggleVisibility(true);

      (document.querySelector('html') as HTMLElement).style.overflow = 'hidden'
    } else {
      setTimeout(() => {
        toggleVisibility(false);

        (document.querySelector('html') as HTMLElement).style.overflow = 'auto'
      }, animationDuration * 1000)
    }

    return () => {
      (document.querySelector('html') as HTMLElement).style.overflow = 'auto'
    }
  }
  , [active, animationDuration])

  if (!active && !visible) {
    return null
  }

  return (
    <PageOverlayBox
      onClick={onClick}
      className={classNames(className, { visible: visible && active })}
      animationDuration={animationDuration}
      backgroundColor={backgroundColor}
    />
  )
}

const PageOverlayBox = styled.div`
  position: fixed;
  z-index: 14;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: ${(props: { backgroundColor?: string, animationDuration?: number }) => props.backgroundColor ? props.backgroundColor : 'rgba(0, 0, 0, 0.7)'};
  opacity: 1;
  transition: opacity ${(props: any) => `${props.animationDuration}s`} linear;
  display: block;
`
