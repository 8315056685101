export const getPagination = (currentPage, pageCount) => {
  const result = []

  for (let i = 1; i <= pageCount; i++) {
    result.push(i)
  }

  return result
}

export const getCurrentPages = (pagination, currentPage) => {
  const length = pagination.length
  const currentPages = length > 5 ? [] : pagination

  if (length > 5) {
    pagination[currentPage - 1] && currentPage - 1 !== 0 && currentPages.push(currentPage - 1)
    currentPages.push(currentPage)
    pagination[currentPage] && currentPages.push(currentPage + 1)
  }

  const isFirstPage = currentPages[0] !== 1
  const isLastPage = ![length, length - 1].includes(currentPage)
  const visibleLeftDots = currentPage - 2 >= 2
  const visibleRightDots = length - currentPage > 2

  return {
    currentPages,
    isFirstPage,
    isLastPage,
    visibleLeftDots,
    visibleRightDots,
  }
}
