import React from 'react'

import { SLabel, SOption, SOptionText, SRadioButtonsBox } from './styles'

interface IRadioButtonOption {
  value: string,
  label: string
}

interface IRadioButtonsProps {
  className?: string,
  options: IRadioButtonOption[],
  value: string,
  label?: string,
  onChange: (value: string) => void
}

export const RadioButtons: React.FC<IRadioButtonsProps> = ({ className = '', options, value, onChange, label }) => {
  return (
    <SRadioButtonsBox className={className}>
      {!!label && (
        <SLabel>
          {label}
        </SLabel>
      )}

      {options.map(option => (
        <SOption
          key={option.value}
          onClick={() => onChange(option.value)}
          className='radio-buttons-option'
        >
          {option.value === value && <ActiveCircle />}

          {option.value !== value && <DefaultCircle />}

          <SOptionText>
            {option.label}
          </SOptionText>
        </SOption>
      ))}
    </SRadioButtonsBox>
  )
}

const DefaultCircle = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <circle cx="10"
        cy="10"
        r="9"
        fill="#272832"
        stroke="#33343F"
        strokeWidth="2"
      />
    </svg>
  )
}

const ActiveCircle = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_373_103196)">
        <circle cx="10"
          cy="10"
          r="9"
          fill="#272832"
          stroke="url(#paint0_linear_373_103196)"
          strokeWidth="2"
        />

        <circle cx="10"
          cy="10"
          r="5"
          fill="url(#paint1_linear_373_103196)"
        />
      </g>

      <defs>
        <linearGradient id="paint0_linear_373_103196"
          x1="21.2025"
          y1="9.99999"
          x2="-2.97468"
          y2="9.99999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2C50FF" />

          <stop offset="1"
            stopColor="#4785F6"
          />
        </linearGradient>

        <linearGradient id="paint1_linear_373_103196"
          x1="15.6013"
          y1="10"
          x2="3.51266"
          y2="10"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2C50FF" />

          <stop offset="1"
            stopColor="#4785F6"
          />
        </linearGradient>

        <clipPath id="clip0_373_103196">
          <rect width="20"
            height="20"
            rx="2"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
