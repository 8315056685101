import { ReactNode, createContext, useContext } from 'react'

import { useQuery } from '@tanstack/react-query'
import LoyaltyRepository from 'data/repositories/LoyaltyRepository'
import { IBonus, ICurrentLevel, ILoyaltyBonus, IPrivelege, IProperty, PropertyIdent } from 'data/interfaces/ILoyalty'

import { getCurrentLevels } from '../utils/loyaltyUtils'

import { useAppContext } from './AppContext'


interface IState {
  bonuses: any[],
  levels: any[],
  currentLevel: ICurrentLevel,
  getPageBonus: (page: string) => IBonus,
  getCurrentLevels: () => unknown,
  indexPageBonus: IBonus,
  registrationPageBonus: IBonus,
  depositPageBonus: IBonus,
  refetchBonuses: () => void
}

interface Props {
  children: ReactNode
}

const defaultValue: IState = {
  bonuses: [],
  levels: [],
  getPageBonus: () => ({}),
  getCurrentLevels: () => ({}),
  currentLevel: {},
  indexPageBonus: {},
  registrationPageBonus: {},
  depositPageBonus: {},
  refetchBonuses: () => undefined
}

const LoyaltyContext = createContext<IState>(defaultValue)

export const LoyaltyContextProvider: React.FC<Props> = ({ children }) => {
  const { user, auth } = useAppContext()

  const fetchBonuses = useQuery<ILoyaltyBonus[]>(
    ['fetchBonuses', auth],
    () => LoyaltyRepository.fetchBonusesList(),
    { placeholderData: [], enabled: auth !== null },
  )

  const fetchLevels = useQuery<ILoyaltyBonus[]>(
    ['fetchLevels', auth],
    () => LoyaltyRepository.fetchLevelsList(),
    { placeholderData: [] },
  )

  const fetchLevelUserInfo = useQuery<ICurrentLevel>(
    ['fetchLevelUserInfo', auth],
    () => LoyaltyRepository.fetchLevelUserInfo(),
    { placeholderData: {}, enabled: auth !== null },
  )

  const bonuses = fetchBonuses.data || []
  const currentLevel = fetchLevelUserInfo.data || []
  const levels = fetchLevels.data || []

  const getPageBonus = (page: string) => {
    const existedBonus = bonuses.find(bonus => !!bonus?.properties?.find(property => property.ident === PropertyIdent.showIn && property?.data?.pages.includes(page)))
    let bonus: IBonus = null

    if (existedBonus) {
      bonus = formatBonusData(existedBonus)
    }

    if (user && bonus?.properties?.[PropertyIdent.dependsOn]?.event === 'registration' && bonus?.properties?.[PropertyIdent.showHours]) {
      bonus.validTill = user.createdAt * 1000 + 1000 * 60 * 60 * bonus?.properties?.[PropertyIdent.showHours]?.hours
    }

    return bonus
  }

  const value: IState = {
    bonuses,
    getPageBonus,
    currentLevel,
    levels,
    getCurrentLevels: () => getCurrentLevels(currentLevel, levels),
    indexPageBonus: getPageBonus('index'),
    registrationPageBonus: getPageBonus('registration'),
    depositPageBonus: getPageBonus('deposit'),
    refetchBonuses: fetchBonuses.refetch
  }

  return (
    <LoyaltyContext.Provider value={value}>
      {children}
    </LoyaltyContext.Provider>
  )
}

export function useLoyaltyContext() {
  return useContext(LoyaltyContext)
}

const mapPrivilegesToBonusData = (privileges: IPrivelege[]) => {
  return privileges.reduce((r: any, privilegy) => {
    if (privilegy.privilegeSubs) {
      const data = { ...privilegy.data, ...privilegy.privilegeSubs.reduce((r, e) => ({ ...r, [e.ident]: e.config }), {}) }

      if (r[privilegy.ident]) {
        r[privilegy.ident].push(data)
      } else {
        r[privilegy.ident] = [data]
      }

      return r
    }

    return { ...r, [privilegy.ident]: privilegy.data, }
  }, {})
}

const mapPropertiesToBonusData = (properties: IProperty[]) => {
  return properties.reduce((r, property) => ({ ...r, [property.ident]: property.data }), {})
}

export const formatBonusData = (bonus: ILoyaltyBonus): IBonus => {
  const formattedBonus: IBonus = {}

  formattedBonus.name = bonus.name
  formattedBonus.privileges = mapPrivilegesToBonusData(bonus.privileges)
  formattedBonus.properties = mapPropertiesToBonusData(bonus.properties)

  if (bonus.checkData && formattedBonus.properties?.[PropertyIdent.showHours]) {
    formattedBonus.validTill = bonus.checkData.validAt
    formattedBonus.activeHours = bonus.checkData.activeHours
  }

  return formattedBonus
}
