export const Urls = {
  Home: '/',
  TermsOfService: '/terms_of_service',
  BonusTerms: '/bonus_terms',
  Info: '/info',
  Selfexclusionandcoc: '/selfexclusionandcoc',
  FairnessRng: '/fairness_rng',
  PrivacyPolicy: '/privacy_policy',
  UserAgreements: '/user_agreements',
  GamblingAware: '/gambling_aware',
  KycAndAml: '/kyc_and_aml',
  DisputeResolution: '/dispute_resolution',
}
