import { SIZES } from '../context/layoutContext'


export function getContentSize(
  { width, leftMenuOpened, isNewPage }:{ width?: number, leftMenuOpened: boolean, isNewPage: boolean }
): { withSidebars: SIZES, default: SIZES } {
  const sizes = { withSidebars: SIZES.MOBILE, default: SIZES.MOBILE }
  if (!width) return sizes

  const availableWidth = (leftMenuOpened && isNewPage) ? width - 277 - 48 : width - 48

  if (width <= SIZES.W512) {
    sizes.withSidebars = SIZES.MOBILE
  } else if (availableWidth <= SIZES.W688) {
    sizes.withSidebars = SIZES.W512
  } else if (availableWidth <= SIZES.W1040) {
    sizes.withSidebars = SIZES.W688
  } else {
    sizes.withSidebars = SIZES.W1040
  }

  if (width <= SIZES.W512) {
    sizes.default = SIZES.MOBILE
  } else if (width <= SIZES.W688) {
    sizes.default = SIZES.W512
  } else if (width <= SIZES.W1040) {
    sizes.default = SIZES.W688
  } else {
    sizes.default = SIZES.W1040
  }

  return sizes
}
