import { cloneElement, FC, ReactElement } from 'react'

import { useRouter } from 'next/router'
import classNames from 'classnames'
import { COLORS } from 'ui-kit/constants'
import { useLayoutContext } from 'context/layoutContext'

import { StyledItem, StyledLabel } from './styles'

export enum ActionType {
  Main = 'main',
  Sport = 'sport',
  Casino = 'casino',
  Menu = 'menu',
  Bonuses = 'bonuses'
}

export interface ItemProps {
  type: ActionType
  label: string
  icon: ReactElement
  link?: string
  onClick?: () => void
}

export const Item: FC<ItemProps> = ({ type, link, label, icon, onClick }) => {
  const { asPath } = useRouter()

  const { leftMenuOpened } = useLayoutContext()

  const isActive = link === asPath || (type === ActionType.Menu && leftMenuOpened)
  const isActiveDisabled = type !== ActionType.Menu && leftMenuOpened

  const color = isActive && !isActiveDisabled ? COLORS.white : COLORS.dark200

  return (
    <StyledItem
      className={classNames({
        'active': isActive && !isActiveDisabled,
      })}
      onClick={onClick}
    >
      {cloneElement(icon, { color, className: 'icon' })}

      <StyledLabel className={classNames({
        'active': isActive && !isActiveDisabled,
      })}
      >
        {label}
      </StyledLabel>
    </StyledItem>
  )
}
