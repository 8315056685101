import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const BallIcon: React.FC<Props> = ({ className = '', ...props }) => {
  return (
    <StyledSvg width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path d="M2.5 10C2.5 7.87827 3.34285 5.84344 4.84315 4.34315C6.34344 2.84285 8.37827 2 10.5 2C12.6217 2 14.6566 2.84285 16.1569 4.34315C17.6571 5.84344 18.5 7.87827 18.5 10C18.5 12.1217 17.6571 14.1566 16.1569 15.6569C14.6566 17.1571 12.6217 18 10.5 18C8.37827 18 6.34344 17.1571 4.84315 15.6569C3.34285 14.1566 2.5 12.1217 2.5 10ZM7.59908 3.88308C6.70119 4.31007 5.90796 4.92894 5.27538 5.696L6.07169 8.28554L7.30985 8.69785L9.88462 6.74092V5.40677L7.59908 3.88308ZM3.77508 10.7803C3.89815 11.8449 4.26738 12.8357 4.82492 13.6911H7.16831L7.94985 12.9095L6.93692 9.86954L5.67662 9.44985L3.77508 10.7803ZM9.12892 16.6289C10.0335 16.8151 10.9665 16.8151 11.8711 16.6289L12.8434 14.4431L12.0914 13.6923H8.90862L8.15785 14.4431L9.12892 16.6302V16.6289ZM16.1738 13.6923C16.7154 12.8628 17.0797 11.9077 17.2126 10.88L15.3123 9.45477L14.0631 9.87077L13.0502 12.9108L13.8317 13.6923H16.1738ZM15.7086 5.67631C15.0787 4.91779 14.2913 4.30546 13.4009 3.88185L11.1154 5.40677V6.74092L13.6902 8.69785L14.9258 8.28677L15.7086 5.67631Z" />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  path {
    fill: currentColor;
  }
`
