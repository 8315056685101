import { useEffect, useMemo } from 'react'

import { useAppContext } from 'context/AppContext'
import UserUtils from 'utils/user'
import { useField } from 'formik'
import Converter from 'utils/converter'
import CurrencySvg from 'components/svg/CurrencySvg/CurrencySvg'

import styles from '../index.module.scss'

import { StyledSelect, ToggleContent } from './styles'


export const CurrencyField: React.FC<{ name: string }> = (props) => {
  const context = useAppContext()
  const [, , helpers] = useField(props)

  useEffect(() => {
    context.fetchDefaultCurrency().then(i =>{
      if(i) {
        helpers.setValue(i.iso)
      }
    })
  }, [])

  const data = useMemo( () => Converter.convertCurrencyToOptions(context.currencies).map(i => ({ ...i, symbol: <img src={UserUtils.getCurrencyIcon(i.value)} /> })), [context.currencies])

  const options = useMemo(() => data.map((currency) => ({
    label: currency.label,
    value: currency.value,
    iconContent: (
      <div>
        <CurrencySvg
          size={20}
          className={styles.icon}
          color
          currencyIso={!!currency.value && currency.value}
        />
      </div>
    )
  })), [data])

  return (
    <>
      <StyledSelect
        options={options}
        withSearch
        renderToggleContent={option => option ? (
          <ToggleContent>
            {option.iconContent}

            {option.label}
          </ToggleContent>
        ) : null}
        {...props}
      />
    </>
  )
}

