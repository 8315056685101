import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const CloseIcon: React.FC<Props> = ({ className = '', ...props }) => {
  return (
    <StyledSvg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path d="M10.3127 12.0979L5.37179 17.0273L5.37171 17.0273C5.13348 17.2656 4.99965 17.5887 4.99965 17.9256C4.99965 18.2625 5.13348 18.5856 5.37171 18.8238C5.60994 19.0621 5.93305 19.1959 6.26995 19.1959C6.60686 19.1959 6.92997 19.0621 7.1682 18.8238L7.16828 18.8237L12.0977 13.8829L17.027 18.8237L17.0271 18.8238C17.2653 19.0621 17.5885 19.1959 17.9254 19.1959C18.2623 19.1959 18.5854 19.0621 18.8236 18.8238C19.0618 18.5856 19.1957 18.2625 19.1957 17.9256C19.1957 17.5887 19.0618 17.2656 18.8236 17.0273L18.8235 17.0273L13.8827 12.0979L18.8233 7.16871C18.8234 7.16866 18.8234 7.16861 18.8235 7.16856C18.942 7.05094 19.0361 6.91102 19.1003 6.75687C19.1646 6.60265 19.1977 6.43724 19.1977 6.27017C19.1977 6.10311 19.1646 5.93769 19.1003 5.78348C19.0361 5.62938 18.9421 5.48952 18.8236 5.37193C18.706 5.25345 18.5661 5.1594 18.4121 5.0952C18.2578 5.03095 18.0924 4.99788 17.9254 4.99788C17.7583 4.99788 17.5929 5.03095 17.4387 5.0952C17.2845 5.15944 17.1445 5.25356 17.0269 5.37214C17.0269 5.37217 17.0269 5.37219 17.0268 5.37222L12.0977 10.3129L7.16849 5.37222C7.16846 5.37219 7.16843 5.37217 7.16841 5.37214C7.05078 5.25356 6.91084 5.15944 6.75665 5.0952C6.60243 5.03095 6.43702 4.99788 6.26995 4.99788C6.10289 4.99788 5.93748 5.03095 5.78326 5.0952C5.62916 5.1594 5.4893 5.25345 5.37171 5.37193C5.25323 5.48952 5.15918 5.62938 5.09498 5.78348C5.03073 5.93769 4.99766 6.10311 4.99766 6.27017C4.99766 6.43724 5.03073 6.60265 5.09498 6.75687C5.15922 6.91105 5.25334 7.051 5.37192 7.16863C5.37195 7.16865 5.37197 7.16868 5.372 7.16871L10.3127 12.0979Z"
        fill="#686A7B"
        stroke="#686A7B"
        strokeWidth="0.2"
      />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  path {
    fill: currentColor;
  }
`
