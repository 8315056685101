import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const ConfidentialityIcon: React.FC<Props> = ({ className = '', ...props }) => {
  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
      {...props}
    >
      <path d="M14.9394 16.5C14.0485 16.5 13.2953 16.1923 12.6799 15.5768C12.0644 14.9614 11.757 14.2085 11.7576 13.3182C11.7576 12.4273 12.0653 11.6741 12.6807 11.0587C13.2962 10.4432 14.049 10.1358 14.9394 10.1364C15.8303 10.1364 16.5835 10.4441 17.1989 11.0595C17.8143 11.675 18.1218 12.4278 18.1212 13.3182C18.1212 14.2091 17.8135 14.9623 17.1981 15.5777C16.5826 16.1931 15.8297 16.5006 14.9394 16.5ZM4.27273 14.5C3.72044 14.5 3.27273 14.0523 3.27273 13.5V13C3.27273 12.4477 3.72044 12 4.27273 12H9.06061C9.61289 12 10.0606 12.4477 10.0606 13V13.5C10.0606 14.0523 9.61289 14.5 9.06061 14.5H4.27273ZM5.18182 8.86364C4.29091 8.86364 3.53774 8.55592 2.9223 7.94049C2.30687 7.32505 1.99944 6.57216 2 5.68182C2 4.79091 2.30772 4.03774 2.92315 3.4223C3.53859 2.80687 4.29148 2.49944 5.18182 2.5C6.07273 2.5 6.8259 2.80772 7.44133 3.42315C8.05677 4.03859 8.3642 4.79148 8.36364 5.68182C8.36364 6.57273 8.05592 7.3259 7.44049 7.94133C6.82505 8.55677 6.07216 8.8642 5.18182 8.86364ZM11.0606 7C10.5083 7 10.0606 6.55228 10.0606 6V5.5C10.0606 4.94772 10.5083 4.5 11.0606 4.5H15.8485C16.4008 4.5 16.8485 4.94772 16.8485 5.5V6C16.8485 6.55228 16.4008 7 15.8485 7H11.0606Z" />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  path {
    fill: currentColor;
  }
`
