import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const CategoriesIcon: React.FC<Props> = ({ className = '', ...props }) => {
  return (
    <StyledSvg width="16"
      height="16"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <rect x="2" y="4" width="3" height="2" rx="1" fill={props.color} />

      <rect x="2" y="9" width="3" height="2" rx="1" fill={props.color} />

      <rect x="2" y="14" width="3" height="2" rx="1" fill={props.color} />

      <rect x="7" y="4" width="10" height="2" rx="1" fill={props.color} />

      <rect x="7" y="9" width="10" height="2" rx="1" fill={props.color} />

      <rect x="7" y="14" width="10" height="2" rx="1" fill={props.color} />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  path {
    fill: currentColor;
  }
`
