import { useTranslation } from 'next-i18next'
import { COLORS } from 'ui-kit/constants'
import styled from 'styled-components'

export type FormErrorValue = string[] | string | null
interface Props {
  error?: FormErrorValue
}
export default function FormError({ error }: Props) {
  const { t, i18n } = useTranslation()

  if (!error) {
    return <></>
  }

  return (
    <div>
      {Array.isArray(error)
        ? error.map((error, index) => (
          <ErrorBox
            key={index}
          >
            {i18n.exists(error) ? t(error) : error}
          </ErrorBox>
        ))
        : (
          <ErrorBox>
            {error}
          </ErrorBox>
        )}
    </div>
  )
}

export const ErrorBox = styled.p`
  margin: 15px 0;
  font-size: 12px;
  color: ${COLORS.red};
  text-align: center;
`
