import styled from 'styled-components'
import { COLORS, FONTS } from 'ui-kit/constants'


export const SProgressWrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  height: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.dark550};
`

export const SProgressBar = styled.div<{ value: number }>`
  position: absolute;
  overflow: hidden;
  border-radius: 12px;
  height: 24px;
  width: ${({ value }) => value}%;
  background: #2ACF45;
  left: 0;
  top: 0;
  z-index: 1;
`


export const SValue = styled.div`
  z-index: 2;
  ${FONTS.a2}
`
