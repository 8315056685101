import request from "utils/request"
import Converter from "utils/converter"
import { IPagination } from "types/interfaces"
import {
  IGameProvider,
  IGameProviderTop3,
} from "data/interfaces/IGameProvider"
import { IGameCategory } from "data/interfaces/IGameCategory"
import { IGame } from "data/interfaces/IGame"
import { IGameWin } from "data/interfaces/IGameWin"
import { IGameSession } from "data/interfaces/IGameSession"
import { RICHY_CATEGORY_NAME } from "types/constants"
import { IGameHistory } from "data/interfaces/IGameHistory"
import { format, subDays } from "date-fns"
import { RequestError } from "types/request"

export default class GameListRepository {
  static async fetchProviders(
    language?: string,
    name: string | null = null,
    page = 1,
    limit = 30,
  ): Promise<IPagination<IGameProvider>> {
    const res = await request({
      method: "get",
      url: "/api/games/provider",
      language,
      data: {
        ...(name ? { name } : {}),
        page,
        "per-page": limit,
      },
    })
    if (res.err) {
      return { data: [], total: 0 }
    }
    const result = Converter.convertApiPaginationResponse(res.data)

    if (!result?.data) {
      return { data: [], total: 0 }
    }

    try {
      return { ...result, data: result.data }
    } catch (e) {}
    return result
  }

  static async fetchProvidersList(): Promise<IGameProvider[]> {
    const res = await request({
      method: "get",
      url: "/api/games/provider/index",
    })
    if (res.err) {
      return []
    }
    return res.data.data?.map((i) => Converter.objectKeysToCamelCase(i)) ?? []
  }

  static async fetchCategories(
    {
      isMobile = false,
      name = null,
      bannerSlogan = null,
      page = 1,
      limit = 30,
      isFeatured,
      language
    } : {
      isMobile?: boolean | 'mobile'| 'desktop',
      name?: string | null,
      bannerSlogan?: string | null,
      page?: number,
      limit?: number,
      isFeatured?: boolean,
      language?: string
    }
  ): Promise<IPagination<IGameCategory>> {
    const res = await request({
      method: "get",
      url: "/api/games/category",
      language,
      data: {
        client_type: isMobile ? 'mobile' : 'desktop',
        ...(name ? { name } : {}),
        ...(bannerSlogan ? { banner_slogan: bannerSlogan } : {}),
        page,
        "per-page": limit,
        ...(typeof isFeatured !== "undefined"
          ? { is_featured: isFeatured }
          : {}),
      },
    })
    if (res.err) {
      return { data: [], total: 0 }
    }
    return Converter.convertApiPaginationResponse(res.data)
  }
  static async fetchGames(
    {
      name,
      providerId,
      categoryId,
      providerInternalName,
      isShowAll,
      isMobile
    }: {
      name?: string;
      providerId?: number;
      categoryId?: number;
      providerInternalName?: string;
      isShowAll?: boolean;
      isMobile?: boolean
    } = {},
    page = 1,
    limit = 30
  ): Promise<IPagination<IGame>> {
    const res = await request({
      method: "get",
      url: "/api/games/game",
      data: {
        client_type: isMobile ? 'mobile' : 'desktop',
        ...(name ? { name } : {}),
        ...(providerId ? { provider_id: providerId } : {}),
        ...(isShowAll ? { is_show_all: true } : {}),
        ...(categoryId ? { category_id: categoryId } : {}),
        ...(providerInternalName ? { providerInternalName } : {}),

        page,
        "per-page": limit,
      },
    })

    if (res.err) {
      return { data: [], total: 0 }
    }

    return Converter.convertApiPaginationResponse(res.data)
  }

  static async fetchFreeSpinsGames(
    {
      name,
      providerId,
      providerInternalName,
      isMobile
    }: {
      name?: string;
      providerId?: number;
      providerInternalName?: string;
      isMobile?: boolean | 'mobile' | 'desktop';
    } = {},
    page = 1,
    limit = 30
  ): Promise<IPagination<IGame>> {
    const res = await request({
      method: "get",
      url: "/api/games/game",
      data: {
        ...(name ? { name } : {}),
        ...(providerId ? { provider_id: providerId } : {}),
        ...(providerInternalName ? { providerInternalName } : {}),
        client_type: isMobile ? 'mobile' : 'desktop',
        is_freespins: 1,
        page,
        "per-page": limit,
      },
    })

    if (res.err) {
      return { data: [], total: 0 }
    }

    return Converter.convertApiPaginationResponse(res.data)
  }

  static async fetchLiveGames(
    {
      name,
      providerId,
      categoryId,
      isMobile
    }: { name?: string; providerId?: number; categoryId?: number, isMobile?: boolean } = {},
    page = 1,
    limit = 30
  ): Promise<IPagination<IGame>> {
    const res = await request({
      method: "get",
      url: "/api/games/game/live",
      data: {
        client_type: isMobile ? 'mobile' : 'desktop',
        ...(name ? { name } : {}),
        ...(providerId ? { provider_id: providerId } : {}),
        ...(categoryId ? { category_id: categoryId } : {}),
        page,
        "per-page": limit,
      },
    })
    if (res.err) {
      return { data: [], total: 0 }
    }
    return Converter.convertApiPaginationResponse(res.data)
  }

  static async fetchLatestGames(
    {
      name,
      providerId,
      categoryId,
      isLive,
      isMobile
    }: {
      name?: string;
      providerId?: number;
      categoryId?: number;
      isLive?: boolean;
      isMobile?: boolean
    } = {},
    page = 1,
    limit = 30
  ): Promise<IPagination<IGame>> {
    const res = await request({
      method: "get",
      url: "/api/games/game/latest",
      data: {
        client_type: isMobile ? 'mobile' : 'desktop',
        ...(name ? { name } : {}),
        ...(providerId ? { provider_id: providerId } : {}),
        ...(categoryId ? { category_id: categoryId } : {}),
        ...(typeof isLive !== "undefined" ? { is_live: isLive } : {}),
        page,
        "per-page": limit,
      },
    })
    if (res.err) {
      return { data: [], total: 0 }
    }
    return Converter.convertApiPaginationResponse(res.data)
  }

  static async fetchPopularGames(
    page = 1,
    limit = 30,
    isLive?: boolean,
    isMobile?: boolean
  ): Promise<IPagination<IGame>> {
    const res = await request({
      method: "get",
      url: "/api/games/game/popular",
      data: {
        client_type: isMobile ? 'mobile' : 'desktop',
        page,
        "per-page": limit,
        ...(typeof isLive !== "undefined" ? { is_live: isLive } : {}),
      },
    })
    if (res.err) {
      return { data: [], total: 0 }
    }
    return Converter.convertApiPaginationResponse(res.data)
  }

  static async fetchLatestWinGames({ isMobile }: { isMobile?: boolean }): Promise<IGameWin[]> {
    const res = await request({
      method: 'get',
      url: '/api/games/game/latest-win',
      data: {
        client_type: isMobile ? 'mobile' : 'desktop',
      }
    })

    if (res.err) {
      return []
    }

    return res.data.data?.map((i) => Converter.objectKeysToCamelCase(i)) ?? []
  }

  static async fetchTop(
    isMobile?: boolean  | 'mobile'| 'desktop',
    page = 1,
    limit = 30,
    { dateFrom, dateTo }: { dateFrom?: string; dateTo?: string } = {},
  ): Promise<IPagination<IGame>> {
    const res = await request({
      method: "get",
      url: "/api/games/game/top",
      data: {
        client_type: isMobile ? 'mobile' : 'desktop',
        ...(dateFrom ? { dateFrom } : {}),
        ...(dateTo ? { dateTo } : {}),
      },
    })
    if (res.err) {
      return { data: [], total: 0 }
    }
    return Converter.convertApiPaginationResponse(res.data)
  }

  static async fetchTopWeek(page = 1, limit = 30, isMobile = false): Promise<IPagination<IGame>> {
    return this.fetchTop(isMobile, 1, limit, {
      dateFrom: format(subDays(new Date(), 7), "yyyy-MM-dd"),
      dateTo: format(new Date(), "yyyy-MM-dd"),
    })
  }
  static async fetchTopMonth(
    page = 1,
    limit = 30,
    isMobile = false
  ): Promise<IPagination<IGame>> {
    return this.fetchTop(isMobile, 1, limit, {
      dateFrom: format(subDays(new Date(), 30), "yyyy-MM-dd"),
      dateTo: format(new Date(), "yyyy-MM-dd"),
    })
  }

  static async fetchGameSessionHistory(
    page = 1,
    limit = 30
  ): Promise<IPagination<IGameHistory>> {
    const res = await request({
      method: "get",
      url: "/api/games/session/history",
      data: {
        page,
        "per-page": limit,
      },
    })
    if (res.err) {
      return { data: [], total: 0 }
    }
    return Converter.convertApiPaginationResponse(res.data)
  }

  static async fetchGameSessionHistoryLastRows(
    rowAmount: number
  ): Promise<IPagination<IGameHistory>> {
    const res = await request({
      method: "get",
      url: "/api/games/session/history-all",
      data: {
        row_amount: rowAmount,
      },
    })
    if (res.err) {
      return { data: [], total: 0 }
    }
    return Converter.convertApiPaginationResponse(res.data)
  }

  static async fetchGameInfo(id: number): Promise<IGame | null> {
    const res = await request({
      method: "get",
      url: "/api/games/game/info-game",
      data: {
        id,
      },
    })
    if (res.err) {
      throw res.err
    }
    return Converter.objectKeysToCamelCase(res.data?.data)
  }

  static async createGame(
    gameId: number,
    clientType: string,
    token?: string,
    sessionId?: string,
    language?: string
  ): Promise<IGameSession> {
    const res = await request({
      method: "post",
      url: "/api/games/session/start",
      token,
      language,
      sessionId,
      data: {
        game_id: gameId,
        client_type: clientType,
        game_end_url: `${window?.location?.origin}/game-interrupt`
      },
    })

    if (res.err) {
      if (res.data?.error?.name === 'Forbidden') {
        throw new RequestError(
          res.err as string,
          403
        )
      }

      throw res.err
    }

    return res.data.data
      ? Converter.objectKeysToCamelCase(res.data.data)
      : null
  }

  static async fetchRichy(page = 1, limit = 30): Promise<IPagination<IGame>> {
    return this.fetchGames(
      { providerInternalName: RICHY_CATEGORY_NAME },
      page,
      limit
    )
  }

  static async createGameDemo(
    gameId: number,
    clientType: string,
    token?: string,
    sessionId?: string,
    language?: string
  ): Promise<IGameSession> {
    const res = await request({
      method: 'post',
      url: '/api/games/session/demo',
      token,
      language,
      sessionId,
      data: {
        game_id: gameId,
        client_type: clientType,
        game_end_url: `${window?.location?.origin}/game-interrupt`
      },
    })
    if (res.err) {
      if (res.data?.error?.status === 403) {
        throw new RequestError(
          res.err as string,
          res.data?.error?.status ?? 500
        )
      }
      throw res.err
    }

    return res.data.data
      ? Converter.objectKeysToCamelCase(res.data.data)
      : null
  }

  static async activateFreeSpins(gameId: number): Promise<{ message: string }> {
    const res = await request({
      method: "post",
      url: "/api/freespin/issue",
      data: {
        game_ids: [gameId],
      },
    })

    return res.data.data
      ? Converter.objectKeysToCamelCase(res.data.data)
      : null
  }
}
