import { IBonusBannerDetails } from "./interfaces"

export const RICHY_CATEGORY_NAME = 'richy'
export const BONUS_SATOSHI = 200

export const MIN_BONUS_DEPOSIT_AMOUNT_USD = 1

export const CookiesLifeTime = {
  accessToken: 365 * 3,
  sessionId: 365 * 3,
  language: 365 * 3,
  notificationShown: 365 * 3,
  firstVisitAt: 365 * 3,
  bonusDepositShowMode: 365 * 3,
  ppDetails: 365 * 3,
  NEXT_LOCALE: 365 * 3
}

export const Timers = {
  showNotificationBanner: 3 * 1000,
  showBonusesBanner: 5 * 1000,
}

export const FIRST_DEPOSIT_BONUS_MOCK: IBonusBannerDetails = {
  amount: 150,
  maxAmount: 20000,
  freeSpins: 200
}

export const DEFAULT_CURRENCY = 'USD'
