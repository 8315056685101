import { FC } from 'react'

import Close from 'components/svg/Close'
import { useAppContext } from 'context/AppContext'

import classes from './ModalClose.module.scss'

export const ModalClose: FC = (props) => {
  const appContext = useAppContext()

  const handleClose = () => {
    appContext.hideModal()
  }

  return (
    <div className={classes.box}
      onClick={handleClose}
    >
      <Close />
    </div>
  )
}
