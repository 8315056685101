import { FC, useContext } from 'react'

import classNames from 'classnames'

import { table } from './utils'
import { SBody } from './styles'


const Body: FC = ({ children }) => {
  const { ready, mode } = useContext(table)

  if (!ready) return null

  return (
    <SBody
      className={classNames( {
        ['simple']: mode === 'simple'
      })}
    >
      {children}
    </SBody>
  )
}


export default Body
