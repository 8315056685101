import { forwardRef, ReactElement } from 'react'

import Link from 'next/link'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import { Sticky } from 'react-sticky'
import classNames from 'classnames'
import { isDesktop, isMobile } from 'react-device-detect'
import Button from 'components/ui/Button'
import LangSelect from 'components/for_pages/Common/LangSelect'
import ErrorBoundary from 'components/ui/ErrorBoundary'
import HiddenXs from 'components/ui/HiddenXS'
import VisibleXs from 'components/ui/VisibleXS'
import { useAppContext } from 'context/AppContext'
import { usePwaContext } from 'context/pwa_state'
import { Routes } from 'types/routes'
import { ModalType, ProfileModalType } from 'types/enums'

import ProfileMenu from './components/Profile/ProfileMenu'
import ProfileAccountsMenu from './components/Profile/ProfileAccountsMenu'
import classes from './Header.module.scss'
import { BalancesDropdown } from './components/Profile/BalancesDropdown/BalancesDropdown'

interface Props {
  rightButton?: ReactElement
  isSticky?: boolean
}

const HeaderInner = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { t } = useTranslation()

  const appContext = useAppContext()
  const pwaContext = usePwaContext()
  const user = appContext.user

  const handleAppClick = (apple = false) => {
    if (isDesktop) {
      appContext.showModal(ModalType.mobileApp)
    } else if (isMobile && apple) {
      appContext.showBottomSheet(ModalType.mobileApp)
    } else if (isMobile && !apple) {
      pwaContext.install()
    }
  }

  const handleLogoClick = () => {
    appContext.hideModal()
  }

  return (
    <div
      id="top"
      ref={ref}
      className={classNames(classes.root, {
        [classes.sticky]: props.isSticky,
      })}
      {...props}
    >
      <div className={classes.top}>
        <div className={classes.left}>
          <div className={classes.apps}>
            <div className={classes.btn}>
              <Button
                size="extraSmall"
                background="dark700"
                onClick={() => handleAppClick(false)}
              >
                <img src="/img/layout/top/android.svg"
                  alt=""
                />
              </Button>
            </div>

            <Button
              size="extraSmall"
              background="dark700"
              onClick={() => handleAppClick(true)}
            >
              <img src="/img/layout/top/apple.svg"
                alt=""
              />
            </Button>
          </div>

          <div className={classes.bonuses}>
            <Link href={Routes.bonuses}>
              <a className={classes.bonus}>
                <div className={classes.gift}>
                  <img src="/img/layout/top/gift.svg"
                    alt=""
                  />
                </div>

                <div className={classes.textGift}>
                  {t('header_bonuses')}
                </div>
              </a>
            </Link>

            <Link href={Routes.freeBitcoin}>
              <a className={classes.bonus}>
                <div className={classes.free}>
                  {t('header_freebitcoin_badge')}
                </div>

                <div className={classes.bitcoin}>
                  <img src="/img/layout/top/bitcoin.svg"
                    alt=""
                  />
                </div>

                <div className={classes.textBitcoin}>
                  {t('header_freebitcoin')}
                </div>
              </a>
            </Link>

            <div
              className={classes.bonus}
              onClick={() => appContext.showModal(ModalType.fortune)}
            >
              <div className={classes.wheel}>
                <img src="/img/layout/top/wheel.svg"
                  alt=""
                />
              </div>

              <div className={classes.textWheel}>
                {t('header_wheel_of_fortune')}
              </div>
            </div>
          </div>
        </div>

        <Link href="/">
          <a className={classes.logoMobile}
            onClick={handleLogoClick}
          >
            <Image src={'/img/logo/richy_symbol.svg'}
              layout={'fill'}
            />
          </a>
        </Link>

        <Link href="/">
          <a className={classes.logo}
            onClick={handleLogoClick}
          >
            <Image src={'/img/logo/richy.svg'}
              height={30}
              width={81.29}
            />
          </a>
        </Link>

        <div className={classes.right}>
          {!user ? (
            <>
              <div className={classes.login}>
                <Button
                  onClick={() => appContext.showModal(ModalType.login)}
                  size="small"
                  background="dark700"
                >
                  {t('header_login')}
                </Button>
              </div>

              <div className={classes.reg}>
                <Button
                  onClick={() => appContext.showModal(ModalType.registration)}
                  size="normal"
                  background="payGradient500"
                >
                  <img src="/img/layout/top/person.svg"
                    alt=""
                  />

                  {t('header_registration')}
                </Button>
              </div>
            </>
          ) : (
            <div className={classes.userBtns}>
              <ErrorBoundary>
                <BalancesDropdown />
              </ErrorBoundary>

              <HiddenXs>
                <ErrorBoundary>
                  <ProfileMenu />
                </ErrorBoundary>
              </HiddenXs>

              <VisibleXs>
                {props.rightButton || (
                  <ErrorBoundary>
                    <ProfileMenu className={classes.proMenu} />
                  </ErrorBoundary>
                )}
              </VisibleXs>
            </div>
          )}

          <div className={classes.lang}>
            <LangSelect styleType={'top'} />
          </div>
        </div>
      </div>
    </div>
  )
})

export const Header = (props: Props) => {
  if (props.isSticky) {
    return (
      <Sticky>
        {(props) => <HeaderInner {...props} />}
      </Sticky>
    )
  }

  return <HeaderInner {...props} />
}
