import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const DotsIcon: React.FC<Props> = ({ className = '', ...props }) => {
  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width="3"
      height="19"
      viewBox="0 0 3 19"
      fill="none"
      className={className}
      {...props}
    >
      <rect width="3"
        height="3"
        rx="1"
      />

      <rect y="8"
        width="3"
        height="3"
        rx="1"
      />

      <rect y="16"
        width="3"
        height="3"
        rx="1"
      />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  rect {
    fill: currentColor;
  }
`
