import { FC } from 'react'

import { colors } from 'scss/variables'

interface Props {
  color?: string
  className?: string
}

export const CasinoSvg: FC<Props> = ({ className =' ', color = "#686A7B" }) => (
  <svg width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M5.40909 10.1111L6.86364 7.26667V6.55556H4.68182V7.26667H6.13636L4.68182 10.1111M8.31818 10.1111L9.77273 7.26667V6.55556H7.59091V7.26667H9.04545L7.59091 10.1111M11.2273 10.1111L12.6818 7.26667V6.55556H10.5V7.26667H11.9545L10.5 10.1111M17.0455 3C16.2455 3 15.5909 3.64 15.5909 4.42222C15.5909 4.92 15.8818 5.41778 16.3182 5.63111V13.6667H14.1364V12.2444C14.5727 12.2444 14.8636 11.96 14.8636 11.5333V5.13333C14.8636 4.70667 14.5727 4.42222 14.1364 4.42222H11.3727C10.7909 3.56889 9.77273 3 8.68182 3C7.59091 3 6.57273 3.56889 5.99091 4.42222H3.22727C2.79091 4.42222 2.5 4.70667 2.5 5.13333V11.5333C2.5 11.96 2.79091 12.2444 3.22727 12.2444V17.2222H14.1364V15.0889H16.3182C17.1182 15.0889 17.7727 14.4489 17.7727 13.6667V5.63111C18.2091 5.41778 18.5 4.92 18.5 4.42222C18.5 3.64 17.8455 3 17.0455 3ZM11.2273 15.0889H6.13636V13.6667H11.2273V15.0889ZM13.4091 10.8222H3.95455V5.84444H13.4091V10.8222Z"
      fill={color}
    />
  </svg>

)
