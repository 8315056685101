export enum ModalType {
  login = 'login',
  registration = 'registration',
  passwordReset = 'passwordReset',
  passwordRecovery = 'passwordRecovery',
  registrationSuccess = 'registrationSuccess',
  registrationPhone = 'registrationPhone',
  fortune = 'fortune',
  bonus = 'bonus',
  profileBurger = 'profileBurger',
  createGame = 'createGame',
  opponents = 'opponents',
  faLogin = 'faLogin',
  mobileApp = 'mobileApp',
  restrictedCountry = 'restrictedCountry',
  astroPay = 'astroPay',
  depositSuccess = 'depositSuccess',
  depositFail = 'depositFail',
  withdrawSuccess = 'withdrawSuccess',
  withdrawFail = 'withdrawFail',
  gameInterrupt = 'gameInterrupt'
}

export enum ProfileModalType {
  paymentHistory = 'paymentHistory',
  profile = 'profile',
  settings = 'settings',
  wallet = 'wallet',
  exchange = 'exchange',
  favorite = 'favorite',
  betsHistory = 'betsHistory',
  buyCrypto = 'buyCrypto',
  FA = 'FA',
  withdraw = 'withdraw',
  newPhoneConfirm = 'newPhoneConfirm',
  oldPhoneConfirm = 'oldPhoneConfirm',
  freeSpinsGames = 'freeSpinsGames',
  walletNew = 'walletNew'
}

export enum CookiesType {
  accessToken = 'accessToken',
  sessionId = 'sessionId',
  language = 'language',
  notificationShown = 'notificationShown',
  firstVisitAt = 'firstVisitAt',
  bonusDepositShowMode = 'bonusDepositShowMode',
  ppDetails = 'ppDetails',
  NEXT_LOCALE = 'NEXT_LOCALE',
}

export enum LocalStorageTypes {
  registeredWithSocials = 'registeredWithSocials',
}

export enum GameType {
  Baccarat = 'baccarat',
  Blackjack = 'blackjack',
  Coinflip = 'coinflip',
  Crash = 'crash',
  Diamonds = 'diamonds',
  Dice = 'dice',
  HiLo = 'hilo',
  Keno = 'keno',
  Limbo = 'limbo',
  Mines = 'mines',
  Plinko = 'plinko',
  Roulette = 'roulette',
  Stairs = 'stairs',
  Tower = 'tower',
  VideoPoker = 'videoPoker',
  Wheel = 'wheel',
}

export enum BetSwitchFilterKey {
  All = 'all',
  My = 'my',
}

export enum PaymentSwitchFilterKey {
  All = 'all',
  Deposit = 'deposit',
  Withdrawal = 'withdrawal',
  Applications = 'applications',
}

export enum SnackbarType {
  error,
  success,
}

export enum FavoriteEntityType {
  games = 'games',
}

export enum BonusDepositShowMode {
  Modal = 'modal',
  Spoiler = 'spoiler',
  Gift = 'gift',
}

export enum UserBalanceType {
  Totals = 'totals',
  Real = 'real',
  Bonus = 'bonus',
}

export enum PwaState {
  NotInstalled = 'notInstalled',
  Downloading = 'downloading',
  Installing = 'installing',
  Installed = 'installed',
}

export enum RegistrationType {
  email = 'email',
  phone = 'phone',
}
