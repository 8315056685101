import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const HomeIcon: React.FC<Props> = ({ className = '', ...props }) => {
  return (
    <StyledSvg width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path d="M11.2544 2.30336C11.0437 2.10748 10.7763 1.99997 10.5 1.99997C10.2237 1.99997 9.95633 2.10748 9.74556 2.30336L3.91222 7.72854C3.78305 7.84859 3.67927 7.99802 3.60809 8.16645C3.5369 8.33489 3.50002 8.5183 3.5 8.70393V16.7211C3.5 17.0602 3.62292 17.3855 3.84171 17.6254C4.0605 17.8652 4.35725 18 4.66667 18H8.36111C8.51582 18 8.66419 17.9326 8.77359 17.8127C8.88299 17.6928 8.94444 17.5301 8.94444 17.3605V13.0317C8.94444 12.4794 9.39216 12.0317 9.94444 12.0317H11.0556C11.6078 12.0317 12.0556 12.4794 12.0556 13.0317V17.3605C12.0556 17.7135 12.3169 18 12.6389 18H16.3333C16.6428 18 16.9395 17.8652 17.1583 17.6254C17.3771 17.3855 17.5 17.0602 17.5 16.7211V8.70393C17.5 8.5183 17.4631 8.33489 17.3919 8.16645C17.3207 7.99802 17.2169 7.84859 17.0878 7.72854L11.2544 2.30336Z" />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  path {
    fill: currentColor;
  }
`
