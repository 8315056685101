import { useEffect, useRef, useState } from 'react'

import { useMutation } from '@tanstack/react-query'
import { Form, FormikProvider, useFormik } from 'formik'
import { useTranslation } from 'next-i18next'
import InputField from 'components/ui/Inputs/InputField'
import Button from 'components/ui/Button'
import FormError from 'components/ui/Form/FormError'
import Spinner from 'components/ui/Spinner'
import ProfileModalLayout from 'components/Profile/layout/ProfileModalLayout'
import ModalHeader from 'components/Profile/layout/ProfileModalHeader/ModalHeader'
import ProfileModalBody from 'components/Profile/layout/ProfileModalBody'
import ProfileModalFooter from 'components/Profile/layout/ProfileModalFooter'
import { useAppContext } from 'context/AppContext'
import Validator from 'utils/validator'
import UserRepository from 'data/repositories/UserRepository'
import { SnackbarType } from 'types/enums'
import { TwoFaModalArguments } from 'types/interfaces'

import classes from './FA.module.scss'

interface FAForm {
  code: string
  password: string
}

export const FA = () => {
  const { t } = useTranslation()

  const appContext = useAppContext()
  const modalProps = appContext.modalArguments as TwoFaModalArguments

  const qrRef = useRef<any>(null)

  const [secretCode, setSecretCode] = useState<string>('')

  const twoFaConfirm = useMutation(
    (data: FAForm) => UserRepository.twoFaConfirm(data),
    {
      onSuccess: () => {
        appContext.updateUserFromCookies()
        appContext.goBackModalProfile()
      },
    },
  )

  const twoFaEnable = useMutation(
    () => UserRepository.twoFaEnable({ asString: true }),
    {
      onSuccess: (response) => {
        if (response.secretCode) {
          setSecretCode(response.secretCode)
        }
      },
    },
  )

  const formik = useFormik<FAForm>({
    initialValues: {
      code: '',
      password: '',
    },
    onSubmit: (formData) => twoFaConfirm.mutate(formData),
  })

  useEffect(() => {
    if (!modalProps.qrUrl) return

    fetch(modalProps.qrUrl, {
      headers: {
        Authorization: appContext.token ? `Bearer ${appContext.token}` : '',
      },
    }).then(async (response) => {
      const buffer = await response.text()
      const matches = /<img[^>]+src="([^">]+)"/.exec(buffer)

      if (qrRef.current && matches?.[1]) {
        qrRef.current.src = matches[1]
      }
    }).catch(error => {
      console.error(error)
    })
  }, [modalProps.qrUrl])

  const copySecretCode = () => {
    navigator.clipboard.writeText(secretCode).then(() => {
      appContext.showSnackbar(t('text_copied'), SnackbarType.success)
    })
  }

  return (
    <FormikProvider value={formik}>
      <Form className={classes.box}>
        <ProfileModalLayout fixed>
          <ModalHeader title={t('2fa_title')} />

          <ProfileModalBody fixed>
            <div className={classes.content}>
              <div className={classes.scan_label}>
                {t('2fa_text')}
              </div>

              <div className={classes.qr_box}>
                <img ref={qrRef}
                  alt=""
                />
              </div>

              {secretCode ? (
                <div className={classes.secret_code_box}>
                  <div className={classes.secret_code_label}>
                    <span>
                      {t('2fa_field_secret_code')}

                      {', '}
                    </span>

                    <span className={classes.text_copy}
                      onClick={copySecretCode}
                    >
                      {t('2fa_copy_secret_code')}
                    </span>
                  </div>

                  <div className={classes.secret_code}
                    onClick={copySecretCode}
                  >
                    {secretCode}
                  </div>
                </div>
              ) : (
                <div
                  className={classes.text_cant_scan}
                  onClick={() => twoFaEnable.mutate()}
                >
                  {twoFaEnable.isLoading ? (
                    <Spinner
                      size={18}
                      color="#fff"
                      secondaryColor="rgba(255,255,255,0.4)"
                    />
                  ) : (
                    t('2fa_field_cant_see')
                  )}
                </div>
              )}

              <div>
                <div className={classes.code_label}>
                  {t('2fa_field_code')}
                </div>

                <InputField
                  type="number"
                  name="code"
                  validate={Validator.required}
                />
              </div>

              <InputField
                type="password"
                name="password"
                validate={Validator.required}
                placeholder={t('2fa_field_password')}
                obscure
              />
            </div>
          </ProfileModalBody>

          <ProfileModalFooter fixed>
            <FormError error={twoFaConfirm.error} />

            <Button
              type="submit"
              size="submit"
              fluid
              spinner={twoFaConfirm.isLoading}
              background="blueGradient500"
              className={classes.btn}
            >
              {t('2fa_activate')}
            </Button>
          </ProfileModalFooter>
        </ProfileModalLayout>
      </Form>
    </FormikProvider>
  )
}
