export const COLORS = {
  dark800: '#14151B',
  dark750: '#1C1D26',
  dark700: '#0C0E14',
  dark600: '#17181F',
  dark550: '#24252F',
  dark500: '#2D2E38',
  dark400: '#292A36',
  dark200: '#9092A3',
  dark150: '#686A7B',
  dark90: '#707189',
  dark80: '#9596B2',
  white: '#FFFFFF',
  blue: 'rgba(44, 80, 255, 1)',
  yellow: 'rgba(255, 215, 71, 1)',
  btnBlue: 'linear-gradient(270deg, #2C50FF -6.01%, #4785F6 114.87%)',
  btnBlueFocused: 'linear-gradient(273.01deg, #7000FF -8.03%, #1B6BFF 109.58%)',
  green: 'rgba(23, 170, 37, 1)',
  btnGreen: 'linear-gradient(270deg, #17AA25 -6.01%, #76DC26 114.87%)',
  btnGreenFocused: 'linear-gradient(270deg, #04D400 0%, #69D527 100%)',
  red: 'rgba(255, 31, 71, 1)',
  redLight: 'rgba(223, 0, 0, 1)',
  wrong: 'linear-gradient(270deg, #FF1F47 0%, #DF0000 100%)'
}

export const FONTS = {
  h0: `
    font-weight: 900;
    font-size: 28px;
  `,
  h1: `
    font-weight: 900;
    font-size: 24px;
  `,
  h2: `
    font-weight: 900;
    font-size: 20px;
  `,
  h3: `
    font-weight: 900;
    font-size: 16px;
  `,
  h4: `
    font-weight: 700;
    font-size: 16px;
  `,
  h5: `
    font-weight: 900;
    font-size: 14px;
  `,

  p1: `
    font-weight: 600;
    font-size: 14px;

    @media (min-width: 1441px) {
      font-size: 16px;
    }
  `,
  p2: `
    font-weight: 600;
    font-size: 12px;
  `,

  a0: `
    font-weight: 700;
    font-size: 16px;
  `,
  a1: `
    font-weight: 700;
    font-size: 14px;

    @media (min-width: 1441px) {
      font-size: 16px;
    }
  `,
  a2: `
    font-weight: 700;
    font-size: 12px;
  `,
  a3: `
    font-weight: 700;
    font-size: 10px;
  `,
}

export const BREAKPOINTS = {
  sm: 576,
  xsMedium: 600,
  xsMax: 768,
  sMin: 1024,
  lg: 1140,
  xlg: 1440
}

export const Z_INDEXES = {
  header: 260
}
