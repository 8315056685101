
import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const MinusSvg: React.FC<Props> = ({ className = '', color = 'white', ...props }) => {
  return (
    <StyledSvg
      width="12"
      height="13"
      viewBox="0 0 12 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path d="M1 3.20005C0.447715 3.20005 8.5335e-08 2.75233 6.11944e-08 2.20005L4.37103e-08 1.80005C1.95697e-08 1.24776 0.447715 0.800049 1 0.800049L11 0.800049C11.5523 0.800049 12 1.24776 12 1.80005V2.20005C12 2.75233 11.5523 3.20005 11 3.20005L1 3.20005Z"
        fill={color}
      />
    </StyledSvg>

  )
}

const StyledSvg = styled.svg`

`
