import { isPast as dataIsPast, addSeconds as dateAddSeconds } from 'date-fns'

export function isPast(date: Date) {
  return dataIsPast(date)
}

export function addSeconds(date: Date, sec: number) {
  return dateAddSeconds(date, sec)
}


export const msInSec = 1000,
  secInMin = 60,
  minInHour = 60,
  hoursInHalfDay = 12,
  hoursInDay = 24,
  daysInWeek = 7,
  secInHour = minInHour * secInMin,
  secInDay = hoursInDay * secInHour,
  msInMin = msInSec * secInMin,
  msInHour = secInHour * msInSec,
  msInHalfDay = hoursInHalfDay * msInHour,
  msInDay = hoursInDay * msInHour,
  msInWeek = daysInWeek * msInDay


export const getNow = () => new Date().getTime()


type DateAdjustments = {
  days?: number;
  hours?: number;
  minutes?: number;
};

export const adjustmentDate = (
  value: string,
  adjustments?: DateAdjustments
): unknown => {
  const date = new Date(value)

  if (adjustments) {
    if (adjustments.days) {
      date.setUTCDate(date.getUTCDate() + adjustments.days)
    }
    if (adjustments.hours) {
      date.setUTCHours(date.getUTCHours() + adjustments.hours)
    }
    if (adjustments.minutes) {
      date.setUTCMinutes(date.getUTCMinutes() + adjustments.minutes)
    }
  }

  return date
}

export const formateDate = (value, format = 'D.M.y') => {
  const date = new Date(value)

  const replacements = {
    'h': String(date.getUTCHours()),
    'H': `0${date.getUTCHours()}`.substr(-2),
    'min': String(date.getUTCMinutes()),
    'MIN': `0${date.getUTCMinutes()}`.substr(-2),
    's': String(date.getUTCSeconds()),
    'S': `0${date.getUTCSeconds()}`.substr(-2),
    'd': String(date.getUTCDate()),
    'D': `0${date.getUTCDate()}`.substr(-2),
    'm': String(date.getUTCMonth() + 1),
    'M': `0${date.getUTCMonth() + 1}`.substr(-2),
    'Y': String(date.getUTCFullYear()),
    'y': String(date.getUTCFullYear()).substr(2, 2)
  }

  let result = format
  for (const key in replacements) {
    result = result.replaceAll(key, replacements[key])
  }

  return result
}
