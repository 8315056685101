import styled from 'styled-components'
import registrationBannerImg from 'public/img/registration/banner.png'
import { SvgIcon } from 'ui-kit/svg'
import { COLORS } from 'ui-kit/constants'


export const BannerWrapper = styled.div<any>`
  border-radius: 16px;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: right;

  @media screen and (max-width: 768px){
    margin: -24px auto 0;
    background-image: url(${registrationBannerImg.src});
    background-size: 70%;
    width: 100%;
    max-width: 100%;
    background-position: center;
    background-position: top;
    box-shadow: 0 0 8px 8px ${COLORS.dark600};
    z-index: 3;
    padding-bottom: 8px;
    overflow: hidden;
    display: block;
  }
`

export const BannerSlide = styled.div`
  background-image: url(${registrationBannerImg.src});
  background-size: 302px;
  width: 100%;
  background-repeat: no-repeat;
  min-width: 281px;
  margin-left: 12px;
  background-repeat: no-repeat;
  background-position: top center;
  padding: 224px 40px 24px;
  display: flex;
  gap: 4px;
  justify-content: center;
  flex-direction: column;
  position: relative;

  @media screen and (max-width: 768px){
    background-image: none !important;
    padding: 180px 0px 0;
    margin: 0;
  }
`

export const StyledTitle = styled.div`
  color: #FFF;
  text-align: center;
  font-size: 22px;
  font-family: 'Gilroy';
  line-height: 100%;
  font-style: normal;
  font-weight: 900;
  letter-spacing: 0.52px;
  text-transform: uppercase;
  z-index: 10;
  white-space: nowrap;

  @media screen and (max-width: 768px){
    font-size: 24px;
  }
`

export const StyledPercent = styled.div`
  white-space: nowrap;
  font-family: 'Gilroy';
  font-weight: 900;
  font-size: 48px;
  color: #FFD747;
  text-align: center;
  z-index: 10;
  font-style: normal;
  letter-spacing: 0.5px;
  line-height: 100%;

  @media screen and (max-width: 768px){
    font-size: 40px;
    letter-spacing: 0.8px;
  }
`

export const StyledText = styled.div`
  font-family: 'Gilroy';
  font-weight: 900;
  font-size: 20px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  text-align: center;
  z-index: 10;
  line-height: 100%;
  margin-bottom: 4px;
  white-space: nowrap;

  @media screen and (max-width: 768px){
    font-size: 20px;
    letter-spacing: 0.52px;
    margin-bottom: 0;
  }
`

export const StyledAmountWrap = styled.div`
  z-index: 10;

  @media screen and (max-width: 768px){
    display: flex;
    justify-content: center;
    align-items: baseline;
    gap: 8px;
  }
`

export const StyledAmount = styled.div`
    font-family: 'Gilroy';
    font-weight: 900;
    font-size: 36px;
    color: #FFD747;
    letter-spacing: 0.72px;
    text-align: center;
    z-index: 10;
    line-height: 100%;

    @media screen and (max-width: 768px){
      font-size: 32px;
      letter-spacing: 0.8px;
    }
`

export const StyledSpins = styled.div`
    font-family: 'Gilroy';
    font-weight: 900;
    font-size: 22px;
    letter-spacing: 0.02em;
    text-align: center;
    text-transform: uppercase;
    z-index: 10;
    line-height: 100%;
    white-space: nowrap;

    @media screen and (max-width: 768px){
      font-size: 20px;

      & span {
        color: #FFD747;
      }
    }
`

export const StyledLottery = styled.div`
    font-family: 'Gilroy';
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.02em;
    text-align: center;
    text-transform: uppercase;
    z-index: 10;

    & span {
        color: #FFD747;
    }

    @media screen and (max-width: 768px){
      font-size: 14px;
    }
`

export const StyledBlur = styled.div`
  width: 223px;
  height: 242px;
  left: 20px;
  bottom: 0;
  background: #1D1E25;
  filter: blur(24px);
  position: absolute;
  border-radius: 80px;

  @media screen and (max-width: 768px){
    width: 323px;
    height: 242px;
    left: 0;
    bottom: -70px;
  }
`

export const StyledCloseWrap = styled.div`
  display: none;

  @media screen and (max-width: 768px){
    display: block;
    position: absolute;
    right: 0;
    top: 40px;
    z-index: 10;
  }
`

export const StyledCloseIcon = styled(SvgIcon)`
  position: absolute;
  right: 12px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: ${COLORS.dark200};
`
