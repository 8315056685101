const HttpBackend = require("i18next-http-backend/cjs");
const ChainedBackend = require("i18next-chained-backend").default;
const LocalStorageBackend = require("i18next-localstorage-backend").default;

const DEFAULT_LANGUAGE = "en";
//prettier-ignore
const LANGUAGE = ['en', 'ru', 'uz', 'az', 'tr', 'hi', 'fa', 'uk', 'kk', 'es', 'fr', 'hy', 'th', 'vi', 'pt', 'pt-br', 'be', 'cs-CZ', 'pl', 'ro', 'bn', 'hu-HU', 'fi-FI', 'ne', 'sw', 'de', 'it', 'es-CL', 'es-PE']

const isBrowser = typeof window !== "undefined";

module.exports = {
  debug: false,
  backend: {
    backendOptions: [{ expirationTime: 60 * 60 * 1000 }, {}],
    backends: isBrowser ? [LocalStorageBackend, HttpBackend] : [],
  },
  serializeConfig: false,
  use: isBrowser ? [ChainedBackend] : [],
  i18n: {
    locales: [...LANGUAGE],
    defaultLocale: DEFAULT_LANGUAGE,
    fallbackLng: [DEFAULT_LANGUAGE],
    localeDetection: true,
  },
};
