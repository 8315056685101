import { FC } from 'react'

import PhoneField from 'components/ui/Inputs/PhoneField'
import {
  IPaymentMethodField,
  IPaymentMethodFieldType,
} from 'data/interfaces/IPaymentFields'
import Validator from 'utils/validator'
import { Input, PhoneInputWithCountry, Select } from 'ui-kit'
import styled from 'styled-components'

import styles from './index.module.scss'

interface Props {
  fields: IPaymentMethodField[]
  sending?: boolean
  defaultCountry?: string
  labelOnPlaceholder?: boolean
  isDeposit?: boolean
  className?: string
}

export const PaymentFormExtraFields: FC<Props> = (props) => {
  return (
    <ExtraFieldsBox className={props.className}>
      {props.fields.map((field) => {
        const labelText = getLabel(field.title, field.is_required)

        const placeholderProps: { placeholder?: string, label?: string } = {}

        if (props.labelOnPlaceholder) {
          placeholderProps.placeholder = labelText
          placeholderProps.label = labelText
        }

        if (field.is_payment_address && !props.isDeposit) {
          return (
            <StyledInput
              name={'address'}
              disabled={props.sending}
              validate={Validator.combine([
                ...(field.is_required ? [Validator.required] : []),
              ])}
              {...placeholderProps}
            />
          )
        }

        if (field.key === 'card_pan') {
          return (
            <StyledInput
              name={field.key}
              disabled={props.sending}
              validate={Validator.combine([
                ...(field.is_required ? [Validator.required] : []),
              ])}
              {...placeholderProps}
            />
          )
        } else if (field.key === 'card_expires') {
          if (placeholderProps.placeholder) {
            placeholderProps.placeholder = labelText + ' MM/YY'
          }

          return (
            <StyledInput
              name={field.key}
              // placeholder={'MM/YY'}
              disabled={props.sending}
              validate={Validator.combine([
              ])}
              {...placeholderProps}
            />
          )
        } else if (field.key === 'card_holder') {
          return (
            <StyledInput
              name={field.key}
              disabled={props.sending}
              validate={Validator.combine([
                ...(field.is_required ? [Validator.required] : []),
              ])}
              {...placeholderProps}
            />
          )
        } else if (field.key === 'card_cvv') {
          return (
            <StyledInput
              name={field.key}
              disabled={props.sending}
              mask="999"
              validate={Validator.combine([
                ...(field.is_required ? [Validator.required] : []),
                Validator.cardCvc,
              ])}
              {...placeholderProps}
            />
          )
        }

        switch (field.type) {
        case IPaymentMethodFieldType.String:
        case IPaymentMethodFieldType.Number:
          if (field.key === 'phone') {
            return (
              <StyledPhoneInput
                name={`extra_data.${field.key}`}
                defaultCountry={props.defaultCountry}
                disabled={props.sending}
                validate={Validator.combine([
                  ...(field.is_required ? [Validator.required] : []),
                ])}
                {...placeholderProps}
              />
            )
            return (
              <PhoneField
                defaultCountry={props.defaultCountry}
                styleType={'vertical'}
                name={`extra_data.${field.key}`}
                disabled={props.sending}
                fieldWrapperClassName={styles['phone-input']}
                validate={Validator.combine([
                  ...(field.is_required ? [Validator.required] : []),
                ])}
                {...placeholderProps}
              />
            )
          }

          return (
            <StyledInput
              name={`extra_data.${field.key}`}
              disabled={props.sending}
              validate={Validator.combine([
                ...(field.is_required ? [Validator.required] : []),
                ...(field.key === 'email' ? [Validator.email] : []),
              ])}
              {...placeholderProps}
            />
          )

        case IPaymentMethodFieldType.Dropdown:
          const options = Object.keys(field.options)
            .map((key) => ({ label: field.options[key], value: key }))
            .filter((i) => !!i.label)

          if (!options.length || options.length === 1) return null

          return (
            <StyledSelect
              name={`extra_data.${field.key}`}
              {...placeholderProps}
              options={Object.keys(field.options).map((key) => ({
                label: field.options[key],
                value: key,
              }))}
              validate={Validator.combine([
                ...(field.is_required ? [Validator.required] : []),
              ])}
              disabled={props.sending}
            />
          )
        }
      })}
    </ExtraFieldsBox>
  )
}

function getLabel(label: string, isRequired: boolean) {
  if (isRequired) {
    return `${label} *`
  }

  return label
}

const StyledInput = styled(Input)`
  margin-top: 16px;
`

const StyledSelect = styled(Select)`
  margin-top: 16px;
`

const ExtraFieldsBox = styled.div`
`

const StyledPhoneInput = styled(PhoneInputWithCountry)`
  margin-top: 16px;
`
