import styled from 'styled-components'
import { BREAKPOINTS, COLORS, FONTS } from 'ui-kit/constants'
import { Form } from "formik"
import { Input, PasswordInput } from 'ui-kit'
import { SvgIcon } from 'ui-kit/svg'

export const Frame = styled.div`
  overflow-y: scroll;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: auto;
    padding-top: 0px;
    padding-bottom: 0px;
    height: 100%;
    min-height: 100vh;
    background: ${COLORS.dark600};
  }
`

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 376px;
  padding: 32px;
  box-sizing: border-box;
  border-radius: 16px;
  background: ${COLORS.dark600};
  overflow: hidden;
  position: relative;
  z-index: 3;

  @media screen and (max-width: 768px){
    position: static;
    width: 100%;
    border-radius: 0;
    overflow-y: auto;
  }
`

export const StyledInputFields = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const StyledForm = styled(Form)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export const StyledScrollable = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px){
    height: 100%;
    min-height: auto;
  }
`

export const StyledTitle = styled.div`
    ${FONTS.h1};
    font-weight: 900;
    line-height: 160%;
`

export const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const StyledInput = styled(Input)`
    ${FONTS.p1};
    font-weight: 500;

    @media screen and (max-width: 768px){
        font-size: 14px;
        line-height: 100%;
    }
`

export const StyledPassword = styled(PasswordInput)`
    ${FONTS.p1};
    font-weight: 500;

    @media screen and (max-width: 768px){
        font-size: 14px;
        line-height: 100%;
    }
`

export const StyledForget = styled.div`
    ${FONTS.p2};
    font-weight: 500;
    line-height: 110%;
    color: ${COLORS.dark90};
    cursor: pointer;
    padding-top: 8px;
`

export const StyledWrapperBottom = styled.div`
    margin-top: 16px;
    font-size: 14px;
    font-weight: 500;

    @media screen and (max-width: 768px){
        margin-top: 16px;
    }
`

export const StyledRegistration = styled.div`
    padding-top: 8px;
    text-align: center;
    ${FONTS.p1};
    line-height: 140%;
    color: ${COLORS.dark200};

    & span {
        color: ${COLORS.blue};
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
    }
`

export const StyledCloseIcon = styled(SvgIcon)`
  position: absolute;
  right: 12px;
  top: 12px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: ${COLORS.dark200};

  @media (min-width: ${BREAKPOINTS.xsMax}px) {
    top: 10px;
    right: 10px;
  }
`

export const Overlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  cursor: pointer;
`

