import styled from "styled-components"

import { BREAKPOINTS } from "./constants"

export const Scrollable = styled.div`
  @media screen and (min-width: ${BREAKPOINTS.xsMax}px) {
    padding-right: 4px;

    &::-webkit-scrollbar {
      width: 6px;
      background: transparent;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 6px;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        background: #41424F;
      }
    }
  }
`
