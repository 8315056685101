import { FC } from 'react'

import Link from 'next/link'
import { Routes } from 'types/routes'
import Image from 'next/image'
import { useLayoutContext, SIZES } from 'context/layoutContext'
import { useAppContext } from 'context/AppContext'
import { useRouter } from 'next/router'
import { ModalType } from 'types/enums'

import { IGameCard } from './types'
import { SBtnWrapper, SDemoLink, SGameCard, SGameContent, SGameName, SGameProvider, SPictureWrapper, SPlayBtn } from './styles'
import FavoriteButton from './FavoriteButton'


const GameCard: FC<IGameCard> = ({ game, severalRowsInMobile, withoutMargin = false, className = '', isFreeSpins, onPlayClick }) => {
  const { auth, showModal } = useAppContext()
  const router = useRouter()
  const linkToGame = Routes.game(game.id, false, isFreeSpins)
  const linkToDemo = Routes.game(game.id, true)
  const { contentSize } = useLayoutContext()

  const handleClickPlay = () => {
    if (auth) {
      if (onPlayClick) {
        onPlayClick()
      }

      router.push(linkToGame)
    } else showModal(ModalType.registration)
  }

  return (
    <SGameCard
      severalRowsInMobile={severalRowsInMobile}
      withoutMargin={withoutMargin}
      className={className}
    >
      <SGameContent>
        {auth && <FavoriteButton id={game.id} />}

        <SGameProvider>
          {game.providerName}
        </SGameProvider>

        <SGameName>
          {game.name}
        </SGameName>

        <SBtnWrapper>
          <SPlayBtn onClick={handleClickPlay}>
            <Image
              src='/img/GameCard/play-btn.svg'
              width={[SIZES.MOBILE, SIZES.W512].includes(contentSize) ? 23 : 46 }
              height={[SIZES.MOBILE, SIZES.W512].includes(contentSize) ? 23 : 46 }
            />
          </SPlayBtn>
        </SBtnWrapper>

        <Link href={linkToDemo}>
          <SDemoLink>
            Demo
          </SDemoLink>
        </Link>
      </SGameContent>

      {game.imageIconPreviewUrlVertical && (
        <SPictureWrapper>
          <Image
            src={game.imageIconPreviewUrlVertical}
            layout='fill'
            quality={55}
          />
        </SPictureWrapper>
      )}
    </SGameCard>
  )
}

export default GameCard
