import { COUNTRIES } from 'constants/countries'

import React, { useEffect, useRef, useState } from 'react'

import styled from 'styled-components'
import flags from 'country-flag-icons/react/3x2'
import { AsYouType, getCountryCallingCode } from 'libphonenumber-js'
import { FormikProvider, useField, useFormik } from 'formik'

import { Input } from './Input'
import { Select } from './Select'
import { BREAKPOINTS, COLORS } from './constants'

type Props = React.ComponentProps<typeof Input> & {
  defaultCountry?: string,
}

export const PhoneInputWithCountry: React.FC<Props> = ({ className = '', defaultCountry, ...props }) => {
  const [fieldProps,,helpers] = useField(props)
  const [currentPrefix, setCurrentPrefix] = useState('')

  const firstLoadedRef = useRef(false)

  const formik = useFormik({
    initialValues: {
      country: defaultCountry || 'US',
    },
    onSubmit: () => undefined,
    enableReinitialize: true
  })

  const handleChange = (event) => {
    const value = event.target.value

    if (value.length <= currentPrefix.length) {
      helpers.setValue(currentPrefix)

      return
    }

    helpers.setValue(value)
  }

  const handleBlur = () => {
    if (fieldProps.value === currentPrefix) {
      helpers.setValue('')
    }
  }

  useEffect(() => {
    if (!defaultCountry) {
      return
    }

    if (firstLoadedRef.current) {
      return
    }

    if (!fieldProps.value) {
      handleChangeCountry(defaultCountry)
    }

    firstLoadedRef.current = true

  }, [defaultCountry, fieldProps.value])

  const handleChangeCountry = (country: string) => {
    const parsed = new AsYouType()

    parsed.input(country)

    const prefix = getCountryCallingCode(country)

    helpers.setValue(`+${prefix}`)
    setCurrentPrefix(`+${prefix}`)
  }

  return (
    <PhoneInputWithCountryBox className={className}>
      <StyledInput
        {...props}
        onChange={handleChange}
        onBlur={handleBlur}
        prefixContent={(
          <>
            <FormikProvider value={formik}>
              <StyledSelect
                name='country'
                //placement='auto'
                options={selectOptions}
                renderToggleContent={option => option ? option.iconContent : null}
                withSearch
                onChange={handleChangeCountry}
              />

              <Divider />
            </FormikProvider>
          </>
        )}
      />
    </PhoneInputWithCountryBox>
  )
}

const PhoneInputWithCountryBox = styled.div`

`

const StyledInput = styled(Input)`
  .input-box {
    padding-left: 0;
  }
`

const StyledSelect = styled(Select)`
  height: 100%;

  .select-box {
    height: 100%;
    position: static;

    .input-box {
      .input {
        padding-left: 16px;
      }
    }
  }

  .select-toggle-box {
    height: 100%;
    position: static;

    &.active-bottom {
      background: initial;
    }
  }

  .select-dropdown-box {
    margin-left: 0;
    width: calc(100% + 4px);
    max-height: 240px;
    height: 240px;
  }

  @media (min-width: ${BREAKPOINTS.xsMax}px) {
    .select-box {
      .input-box {
        margin-left: 0;

        .input {
          padding-left: 16px;
        }
      }
    }
  }
`

const Divider = styled.div`
  margin-left: 0;
  margin-right: 9px;
  height: 8px;
  width: 1.6px;
  background: ${COLORS.dark200};
`

const FlagBox = styled.div`
  border-radius: 100%;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  overflow: hidden;

  .country-flag-img {
    width: 31px;
    margin-top: 0;
    margin-left: -5px;
  }
`

const selectOptions = COUNTRIES.map(country => {
  const IconComponent = flags[country.code] || null

  return {
    label: country.name,
    value: country.code,
    iconContent: (
      <>
        <FlagBox>
          {IconComponent ? <IconComponent className="country-flag-img" /> : null}
        </FlagBox>
      </>)
  }})
