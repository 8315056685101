import { useTranslation } from 'next-i18next'
import classNames from 'classnames'

import styles from './index.module.scss'


interface Props {
  icon: string
  color: string
  label: string
  amount: number
  onClick?: () => void
}

export  const Bonus = (props: Props) => {
  return (
    <div className={classNames(styles.bonusItem, { [styles.interactive]: !!props.onClick })}
      onClick={props.onClick}
    >
      <div className={styles.bonusLeft}>
        <div className={styles.bonusIcon}>
          <img src={props.icon}
            alt=''
          />
        </div>

        <div className={styles.bonusLabel}
          style={{ color: props.color }}
        >
          {props.label}
        </div>
      </div>

      <div className={styles.number}
        style={{ color: props.color }}
      >
        {props.amount}
      </div>
    </div>
  )
}
