import { FC, useMemo } from 'react'

import CurrencySvg from 'components/svg/CurrencySvg/CurrencySvg'
import { PaymentMethodList } from 'components/Profile/Wallet/PaymentMethodList'
import { PaymentMethodCard } from 'components/Profile/Wallet/PaymentMethodCard'
import { PaymentMethodSelected } from 'components/Profile/Wallet/PaymentMethodSelected'
import { PaymentOptions } from 'components/Profile/Wallet/PaymentOptions'
import { CryptoWalletActions } from 'components/Profile/Wallet/CryptoWalletActions'
import { PaymentSeparator } from 'components/Profile/Wallet/PaymentSeparator'
import { useAppContext } from 'context/AppContext'
import { ICurrency } from 'data/interfaces/ICurrency'
import { IPaymentMethod } from 'data/interfaces/IPaymentMethod'
import { IPaymentSystem } from 'data/interfaces/IPaymentSystem'

import { checkPaymentSystem } from '../store/Wallet.helpers'

import classes from './StepCurrency.module.scss'

interface ICurrencyWithPaymentSystem extends ICurrency {
  paymentSystem: IPaymentSystem
}

interface Props {
  paymentMethod: IPaymentMethod
  paymentSystem?: IPaymentSystem
  onSelect: (currency: ICurrency, paymentSystem: IPaymentSystem) => void
  onPaymentMethodClick: () => void
}

export const StepCurrency: FC<Props> = ({
  paymentMethod,
  paymentSystem,
  onSelect,
  onPaymentMethodClick,
}) => {
  const appContext = useAppContext()

  const currencies = useMemo<ICurrencyWithPaymentSystem[]>(() => {
    const currenciesIso: string[] = []

    // if (paymentMethod.isCrypto) {
    //   currenciesIso.push(
    //     ...paymentMethod.paymentSystems
    //       .map((paymentSystem) =>
    //         paymentSystem.settings.map((setting) => setting.currencyIso),
    //       )
    //       .flat(),
    //   )
    // } else {
    //   if (paymentSystem) {
    //     currenciesIso.push(
    //       ...paymentSystem.settings.map((setting) => setting.currencyIso),
    //     )
    //   }
    // }

    // if (!paymentMethod.isCrypto) {
    //   return appContext.currencies
    //     .filter((currency) => currenciesIso.includes(currency.iso))
    //     .map((currency) => {
    //       const system = checkPaymentSystem(currency.iso, paymentSystem) ||
    //         paymentMethod.paymentSystems.find((paymentSystem) =>
    //           paymentSystem.settings.find(
    //             (settings) => settings.currencyIso === currency.iso,
    //           ),
    //         )!

    //       const order = system.settings.find(s => s.currencyIso === currency.iso)?.order

    //       return {
    //         ...currency,
    //         paymentSystem: system,
    //         order
    //       }})
    //     .sort((a, b) => Number(a?.order) - Number(b?.order))
    // }

    if (paymentMethod.isCrypto) {
      return paymentMethod.paymentSystems.flatMap(system => system.settings.map(s => {
        const currencyData = appContext.currencies.find(currency => currency.iso === s.currencyIso)

        if (!currencyData) {
          return
        }

        return {
          ...currencyData,
          name: currencyData.name || currencyData?.iso,
          paymentSystem: system,
          order: s.order
        }
      })).filter(p => !!p).sort((a, b) => Number(a?.order) - Number(b?.order))
    }
  }, [paymentMethod, paymentSystem])

  return (
    <div className={classes.box}>
      <PaymentOptions>
        <PaymentMethodSelected
          method={paymentMethod}
          paymentSystem={paymentSystem}
          onClick={onPaymentMethodClick}
        />
      </PaymentOptions>

      <PaymentSeparator />

      {/* {paymentMethod.isCrypto && <CryptoWalletActions />}

      <PaymentSeparator /> */}

      <PaymentMethodList>
        {currencies.map((currency) => (
          <PaymentMethodCard
            key={currency.iso}
            label={currency.name}
            icon={(
              <CurrencySvg currencyIso={currency.iso}
                color
              />
            )}
            onClick={() => onSelect(currency, currency.paymentSystem)}
          />
        ))}
      </PaymentMethodList>
    </div>
  )
}
