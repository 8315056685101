import React from 'react'

import { COLORS } from 'ui-kit/constants'

interface Props {
  color?: string,
  className?: string
}

function MenuSvg({ className = '', color = COLORS.dark200 }: Props) {
  return (
    <svg width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1C0 0.734784 0.120408 0.48043 0.334735 0.292893C0.549062 0.105357 0.839753 0 1.14286 0H14.8571C15.1602 0 15.4509 0.105357 15.6653 0.292893C15.8796 0.48043 16 0.734784 16 1C16 1.26522 15.8796 1.51957 15.6653 1.70711C15.4509 1.89464 15.1602 2 14.8571 2H1.14286C0.839753 2 0.549062 1.89464 0.334735 1.70711C0.120408 1.51957 0 1.26522 0 1ZM0 6C0 5.73478 0.120408 5.48043 0.334735 5.29289C0.549062 5.10536 0.839753 5 1.14286 5L14.8571 5.11678C15.1602 5.11678 15.4509 5.22214 15.6653 5.40967C15.8796 5.59721 16 5.85156 16 6.11678C16 6.38199 15.8796 6.63635 15.6653 6.82388C15.4509 7.01142 15.1602 7.11678 14.8571 7.11678L1.14286 7C0.839753 7 0.549062 6.89464 0.334735 6.70711C0.120408 6.51957 0 6.26522 0 6ZM0 11C0 10.7348 0.120408 10.4804 0.334735 10.2929C0.549062 10.1054 0.839753 10 1.14286 10H14.8571C15.1602 10 15.4509 10.1054 15.6653 10.2929C15.8796 10.4804 16 10.7348 16 11C16 11.2652 15.8796 11.5196 15.6653 11.7071C15.4509 11.8946 15.1602 12 14.8571 12H1.14286C0.839753 12 0.549062 11.8946 0.334735 11.7071C0.120408 11.5196 0 11.2652 0 11Z"
        fill={color}
      />
    </svg>
  )
}

export default MenuSvg
