import { useMemo } from 'react'

import { useTranslation } from 'next-i18next'
import { useAppContext } from 'context/AppContext'
import { PaymentMethodSelectedMobile } from 'components/Profile/Wallet/PaymentMethodSelectedMobile'
import { IPaymentMethod } from 'data/interfaces/IPaymentMethod'
import { CryptoCurrencyIcons } from 'components/Profile/Wallet/CryptoCurrencyIcons'
import { ICurrency } from 'data/interfaces/ICurrency'
import { IPaymentSystem } from 'data/interfaces/IPaymentSystem'
import { runtimeConfig } from 'config/runtimeConfig'

import { PaymentMethodCard } from '../PaymentMethodCard'

interface Props {
  method: IPaymentMethod
  paymentSystem?: IPaymentSystem
  onClick?: () => void
}

export const PaymentMethodSelected = (props: Props) => {
  const context = useAppContext()
  const currencies  = useMemo<ICurrency[]>(() => {
    let currencies: string[] = []
    if(props.method.isCrypto) {
      currencies = props.method.paymentSystems.map(i => i.settings.map(i => i.currencyIso)).flat()
    }
    return context.currencies.filter(i => currencies.includes(i.iso))
  }, [props.method])
  const isMobile = context.isMobile
  const { t } = useTranslation()
  const label = props.method.isCrypto ? t('wallet_payment_type_crypto') : props.paymentSystem?.name ?? props.method.title
  const image =  props.paymentSystem?.imageUrl ??  props.method.imageUrl
  return isMobile ? (
    <PaymentMethodSelectedMobile icon={props.method.isCrypto ? (
      <CryptoCurrencyIcons currencies={currencies}
        selected={true}
      />
    ) : image ? `${runtimeConfig.HOST}${image}` : null}
    label={label}
    onClick={props.onClick}
    />
  ) : (
    <PaymentMethodCard  icon={props.method.isCrypto ? (
      <CryptoCurrencyIcons currencies={currencies}
        selected={true}
      />
    ) :  image ? `${runtimeConfig.HOST}${image}` : null}
    label={label}
    selected
    onClick={props.onClick}
    />
  )
}

