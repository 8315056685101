import React, { useState } from 'react'

import { FormikProvider, useFormik } from 'formik'
import { useTranslation } from 'next-i18next'
import Formatter from 'utils/formatter'
import { ICurrency } from 'data/interfaces/ICurrency'
import { MIN_BONUS_DEPOSIT_AMOUNT_USD } from 'types/constants'
import styled from 'styled-components'
import { COLORS, FONTS } from 'ui-kit/constants'
import { PlusSvg } from 'components/svg/PlusSvg'
import { MinusSvg } from 'components/svg/MinusSvg'
import { Button, Input } from 'ui-kit'
import { IBonus, PrivelegyIdent, PrivelegySubIdent, PropertyIdent } from 'data/interfaces/ILoyalty'
import LoyaltyRepository from 'data/repositories/LoyaltyRepository'
import { formatBonusData } from 'context/loyaltyContext'
import classNames from "classnames"

import { getBonusMaxAmount, getBonusMinAmount, IMinMaxBonusAmount } from "../../../../utils/loyaltyUtils"

interface Props {
  bonuses?: IBonus[],
  onSetBonuses?: (bonuses: IBonus[]) => void,
  currency?: ICurrency,
  bonusConvertationDisabled?: boolean,
  isFirstDeposit?: boolean,
  onBonusOptionClick?: (amount: number) => void,
  depositAmount?: string
}

export default function PromoCode({
  bonuses = [],
  onSetBonuses,
  currency,
  bonusConvertationDisabled,
  onBonusOptionClick,
  depositAmount
}: Props) {
  const { t } = useTranslation()

  const [isInputVisible, toggleInputVisibility] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState<string>()
  const [sending, setSending] = useState(false)

  const depositAmountInUsd = bonusConvertationDisabled ? Number(depositAmount) : Formatter.formatAmount(Number(depositAmount) * currency?.toUsd, currency?.iso)

  const handleSubmit = async (values, { resetForm, setValues }) => {
    if (!values.keyword) {
      return
    }

    setError(null)
    setSending(true)
    setSuccess(null)

    try {
      const res = await LoyaltyRepository.activatePromocode(values.keyword)

      if (!res.promocode) {
        return
      }

      const bonuses = await LoyaltyRepository.getPromocodeBonuses(values.keyword)

      if (onSetBonuses) {
        onSetBonuses(bonuses.map(bonus => formatBonusData(bonus)))
      }

      setSuccess(t('promocode_success_message', { promocode: values.keyword }))
    } catch (e) {
      setError(e)
    } finally {
      resetForm()
      setValues({ keyword: '' })
      setSending(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      keyword: null
    },
    onSubmit: handleSubmit
  })

  const calculateBonusMaxAmount = (maxAmount: IMinMaxBonusAmount) => {
    if (bonusConvertationDisabled || !currency || maxAmount.inCurrentCurrency) {
      return Math.floor(Number(maxAmount.value))
    }

    return Math.ceil(Number(Formatter.formatAmount(maxAmount.value / currency.toUsd, currency?.iso)))
  }

  const calculateMinAmount = ({ inCurrentCurrency, value }: { inCurrentCurrency: boolean, value: number }) => {
    if (!currency || bonusConvertationDisabled) {
      return `${value || MIN_BONUS_DEPOSIT_AMOUNT_USD}$`
    }

    if (inCurrentCurrency) {
      return `${value} ${currency.displayIso}`
    }

    return `${Number(Math.ceil((value || MIN_BONUS_DEPOSIT_AMOUNT_USD) / currency?.toUsd) || 0)} ${currency?.displayIso}`
  }

  return (
    <PromocodeBox>
      <ToggleBox onClick={() => toggleInputVisibility(state => !state)}>
        <ToggleIconBox>
          {isInputVisible ? <MinusSvg /> : <PlusSvg />}
        </ToggleIconBox>


        <ToggleText>
          {t('registration_has_promocode')}
        </ToggleText>
      </ToggleBox>

      {
        isInputVisible && (
          <FormikProvider value={formik}>

            <Form>
              <StyledInput
                name='keyword'
                placeholder={t('promocode_form_field')}
                disabled={sending}
              />

              <StyledButton type={'button'}
                onClick={(e) => formik.handleSubmit(e as any)}
                spinner={sending}
                disabled={!formik.values.keyword}
              >
                {t('promocode_form_use')}
              </StyledButton>
            </Form>

            {success && (
              <SuccessBox>
                {success}
              </SuccessBox>
            )}

            {
              !!error && (
                <ErrorBox>
                  {error}
                </ErrorBox>
              )}
          </FormikProvider>
        )}

      {
        !!bonuses.length &&
        bonuses.map((bonus, index) => {
          const relativeAmountBonus = bonus?.privileges?.[PrivelegyIdent.relativeAmount]
          const freeSpinsBonus = bonus?.privileges?.[PrivelegyIdent.freeSpins]
          const fixedAmountBonus = bonus?.privileges?.[PrivelegyIdent.fixedAmount]
          const minDepositAmount = getBonusMinAmount(bonus?.properties?.[PropertyIdent.minDeposit], currency)

          let options = []

          if (fixedAmountBonus && !Array.isArray(fixedAmountBonus)) {
            options = [
              {
                from: 0,
                convertedFrom: {
                  value: 0,
                  inCurrentCurrency: true
                },
                to: Infinity,
                freeSpinsCount: freeSpinsBonus?.count,
                maxAmount: getBonusMaxAmount(fixedAmountBonus, currency),
                amount: fixedAmountBonus.amount,
                currency: fixedAmountBonus.currency
              }
            ]
          }

          if (fixedAmountBonus && Array.isArray(fixedAmountBonus)) {
            options = fixedAmountBonus.map((bonus) => {
              const data: {
                from: number,
                convertedFrom: IMinMaxBonusAmount,
                to: number,
                freeSpinsCount?: number,
                maxAmount: IMinMaxBonusAmount,
                amount: number,
                currency: string
              } = {
                from: bonus?.[PrivelegySubIdent?.range]?.minAmount,
                convertedFrom: getBonusMinAmount(bonus?.[PrivelegySubIdent?.range], currency, 'minAmount'),
                to: bonus?.[PrivelegySubIdent?.range]?.maxAmount,
                maxAmount: getBonusMaxAmount(bonus, currency),
                amount: bonus?.amount,
                currency: bonus?.currency
              }

              if (freeSpinsBonus && Array.isArray(freeSpinsBonus)) {
                data.freeSpinsCount = freeSpinsBonus.find(fsBonus => fsBonus?.[PrivelegySubIdent?.range].minAmount === bonus?.[PrivelegySubIdent?.range]?.minAmount)?.count || 0
              }

              if (freeSpinsBonus && !Array.isArray(freeSpinsBonus)) {
                data.freeSpinsCount = freeSpinsBonus?.count || 0
              }

              return data
            })
          }

          if (relativeAmountBonus && Array.isArray(relativeAmountBonus)) {
            options = relativeAmountBonus.map((bonus) => {
              const data: {
                percent: number,
                from: number,
                convertedFrom: IMinMaxBonusAmount,
                to: number,
                freeSpinsCount?: number,
                maxAmount: IMinMaxBonusAmount
              } = {
                percent: bonus?.percent,
                from: bonus?.[PrivelegySubIdent?.range]?.minAmount,
                convertedFrom: getBonusMinAmount(bonus?.[PrivelegySubIdent?.range], currency, 'minAmount'),
                to: bonus?.[PrivelegySubIdent?.range]?.maxAmount,
                maxAmount: getBonusMaxAmount(bonus, currency)
              }

              if (freeSpinsBonus && Array.isArray(freeSpinsBonus)) {
                data.freeSpinsCount = freeSpinsBonus.find(fsBonus => fsBonus?.[PrivelegySubIdent?.range].minAmount === bonus?.[PrivelegySubIdent?.range]?.minAmount)?.count || 0
              }

              if (freeSpinsBonus && !Array.isArray(freeSpinsBonus)) {
                data.freeSpinsCount = freeSpinsBonus?.count
              }

              return data
            })
          }

          if (relativeAmountBonus && !Array.isArray(relativeAmountBonus)) {
            options = [
              {
                percent: relativeAmountBonus?.percent,
                from: 0,
                convertedFrom: {
                  value: 0,
                  inCurrentCurrency: true
                },
                to: Infinity,
                freeSpinsCount: freeSpinsBonus?.count,
                maxAmount: getBonusMaxAmount(relativeAmountBonus, currency)
              }
            ]
          }

          if (!bonus) {
            return null
          }

          console.log(options)

          return (
            <BonusBox
              key={index}
            >
              <BonusName>
                {bonus.name}
              </BonusName>

              <TopUpFrom>
                {t('wallet_top_up_1')}

                {' '}

                <span>
                  {t('wallet_top_up_2')}

                  {' '}

                  {calculateMinAmount(minDepositAmount)}
                </span>

                {' '}

                {t('wallet_top_up_3')}
              </TopUpFrom>

              <BonusesOptions>
                {
                  options.map((option, index) => {
                    const isOptionActive = (Number(depositAmountInUsd) || 1) >= option.from && Number(depositAmountInUsd) < option.to
                      || (index === options.length - 1 && Number(depositAmountInUsd) > options[options.length - 1].to)

                    return (
                      <BonusContentBox className={classNames({ active: isOptionActive })}
                        onClick={() => onBonusOptionClick && onBonusOptionClick(option.convertedFrom)}
                        key={option.from}
                      >
                        {
                          !!isOptionActive && (
                            <>
                              <FlashLeftImg src="/img/bonuses/flash-left.png" />

                              <FlashRightImg src="/img/bonuses/flash-right.png" />

                              <CakeImg src="/img/bonuses/cake.png" />

                              <BonusText>
                                {!!option.percent && option.percent + '%'}

                                {!!option.amount && `${!!option.amount} ${!!option.currency}`}

                                {' '}

                                {!!option.freeSpinsCount && `+${Formatter.formatNumber(option.freeSpinsCount)} FS`}
                              </BonusText>

                              {!!option.maxAmount && (
                                <BonusMaxAmount>
                                  {`${t('deposit_modal_max')}: ${calculateBonusMaxAmount(option.maxAmount)} ${bonusConvertationDisabled ? '$' : currency?.iso}`}
                                </BonusMaxAmount>
                              )}

                            </>
                          )}

                        {!isOptionActive && (
                          <>
                            <FlashRightImg src="/img/bonuses/flash-right.png" />

                            <BonusText>
                              {!!option.percent && option.percent + '%'}
                            </BonusText>

                            <MoneyImg src="/img/bonuses/money.png" />
                          </>
                        )}
                      </BonusContentBox>
                    )
                  })
                }
              </BonusesOptions>
            </BonusBox>
          )
        })
      }
    </PromocodeBox>
  )
}

const PromocodeBox = styled.div`
  margin-top: 16px;
`

const ToggleBox = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  color: white;
  cursor: pointer;
  user-select: none;
`

const ToggleIconBox = styled.div`
  margin-right: 8px;
  width: 24px;
  height: 24px;
  background: ${COLORS.btnBlue};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`

const ToggleText = styled.p`
  font-weight: 600;
  font-size: 12px;
`

const Form = styled.form`
  margin-top: 8px;
  width: 100%;
  display: flex;
  align-items: center;
`

const StyledInput = styled(Input)`
  flex: 1;
`

const StyledButton = styled(Button)`
  margin-left: 8px;
  width: unset;
  min-width: 100px;
`

const ErrorBox = styled.p`
  margin-top: 4px;
  ${FONTS.p2};
  color: ${COLORS.red};
`

const SuccessBox = styled.p`
  margin-top: 4px;
  ${FONTS.p2};
  color: ${COLORS.green};
`

const BonusBox = styled.div`
  margin-top: 26px;
  width: 100%;
  padding: 20px 16px 16px;
  background: ${COLORS.dark550};
  border-radius: 4px;
  position: relative;
`

const BonusName = styled.div`
  position: absolute;
  min-height: 23px;
  top: -12px;
  left: 0;
  padding: 0 16px;
  background: ${COLORS.btnGreen};
  border-radius: 20px 100px 100px 0px;
  display: flex;
  align-items: center;
  line-height: 1;
  ${FONTS.a1};
  color: ${COLORS.white};
`

const TopUpFrom = styled.div`
  min-height: 16px;
  margin-top: 6.5px;
  font-weight: 600;
  font-size: 12px;

  span {
    color: #FFD747;
  }
`

const BonusesOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

const BonusContentBox = styled.div`
  margin-top: 10px;
  width: 66px;
  height: 56px;
  background-color: ${COLORS.dark600};
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  padding: 10px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    min-width: 132px;
    border: 2px solid ${COLORS.blue};
    display: unset;
    flex: 1;
  }
`

const MoneyImg = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
`

const FlashLeftImg = styled.img`
  width: 12px;
  height: 12px;
  position: absolute;
  top: 1px;
  left: 1px;
`

const FlashRightImg = styled.img`
  width: 15px;
  height: 15px;
  position: absolute;
  right: 3px;
  top: 3px;
`

const CakeImg = styled.img`
  width: 35px;
  height: 35px;
  position: absolute;
  right: 0;
  bottom: -5px;
`

const BonusText = styled.p`
  color: #FFD747;
  font-weight: 700;
  font-size: 16px;
  line-height: 110%;
`

const BonusMaxAmount = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  color: ${COLORS.white};
  max-width: 96px;
`
