import { FC } from 'react'

import { useAppContext } from 'context/AppContext'

const VisibleXs: FC = (props) => {
  const appContext = useAppContext()

  if (appContext.isMobile) {
    return (
      <>
        {props.children}
      </>
    )
  }

  return null
}

export default VisibleXs
