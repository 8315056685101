import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const FreeSpinsIcon: React.FC<Props> = ({ className = '', ...props }) => {
  return (
    <StyledSvg width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      {...props}
    >
      <path
        d='M9.00008 0.666748C4.40008 0.666748 0.666748 4.40008 0.666748 9.00008C0.666748 13.6001 4.40008 17.3334
        9.00008 17.3334C13.6001 17.3334 17.3334 13.6001 17.3334 9.00008C17.3334 4.40008 13.6001 0.666748 9.00008
        0.666748ZM9.75841 14.1251C9.70018 14.1822 9.62646 14.2209 9.54641 14.2365C9.46635 14.2521 9.38348 14.2439
        9.30806 14.2128C9.23264 14.1818 9.168 14.1293 9.12214 14.0618C9.07628 13.9944 9.05123 13.915 9.05008
        13.8334V13.1667H9.00008C7.93341 13.1667 6.86675 12.7584 6.05008 11.9501C5.48427 11.3829 5.09379 10.6647
        4.92532 9.88151C4.75685 9.09827 4.81752 8.28308 5.10008 7.53341C5.25841 7.10841 5.81675 7.00008 6.13341
        7.32508C6.31675 7.50841 6.35841 7.77508 6.27508 8.00841C5.89175 9.04175 6.10841 10.2417 6.94175
        11.0751C7.52508 11.6584 8.29175 11.9334 9.05841 11.9167V11.1334C9.05841 10.7584 9.50841 10.5751 9.76675
        10.8417L11.1167 12.1917C11.2834 12.3584 11.2834 12.6167 11.1167 12.7834L9.75841 14.1251ZM11.8667
        10.6834C11.7806 10.5948 11.7215 10.4834 11.6964 10.3624C11.6713 10.2413 11.6813 10.1157 11.7251
        10.0001C12.1084 8.96675 11.8917 7.76675 11.0584 6.93341C10.4751 6.35008 9.70841 6.06675 8.95008
        6.08341V6.86675C8.95008 7.24175 8.50008 7.42508 8.24175 7.15841L6.88341 5.81675C6.71675 5.65008 6.71675
        5.39175 6.88341 5.22508L8.23341 3.87508C8.29165 3.81798 8.36537 3.77923 8.44542 3.76363C8.52548 3.74803
        8.60835 3.75626 8.68377 3.78732C8.75919 3.81837 8.82383 3.87088 8.86969 3.93832C8.91555 4.00577 8.9406
        4.0852 8.94175 4.16675V4.84175C10.0251 4.82508 11.1167 5.21675 11.9417 6.05008C12.5076 6.61727 12.898
        7.33542 13.0665 8.11865C13.235 8.90189 13.1743 9.71708 12.8917 10.4667C12.7334 10.9001 12.1834 11.0084
        11.8667 10.6834Z'
        fill={props.color}
      />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  path {
    fill: currentColor;
  }
`
