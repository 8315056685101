import React, { useEffect, useState } from 'react'

import ReactModal from 'react-modal'
import classNames from 'classnames'
import { useAppContext } from 'context/AppContext'

import styles from './index.module.scss'
import { WalletNewDeposit } from './WalletNewDeposit'
import { WalletNewWithdraw } from './WalletNewWithdraw'
import { Frame, Overlay } from './styles'

interface Props {
  opened: boolean
  onClose: () => void
  children?: React.ReactNode
}

export const WalletModalNew: React.FC<Props> = ({ onClose, opened }) => {
  const appContext = useAppContext()

  const settings = {
    className: styles.modalContent,
    overlayClassName: classNames(styles.modalOverlay),
  }

  const handleClose = () => {
    appContext.setWalletModalActiveTab('deposit')

    onClose()
  }

  if (!appContext?.user) {
    return null
  }

  return (
    <ReactModal {...settings}
      isOpen={opened}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick
    >
      <Frame>
        { appContext.walletModalActiveTab === 'deposit' && (
          <WalletNewDeposit
            onClose={handleClose}
            onTabChange={appContext.setWalletModalActiveTab}
          />
        )}

        { appContext.walletModalActiveTab === 'withdraw' && (
          <WalletNewWithdraw onClose={handleClose}
            onTabChange={appContext.setWalletModalActiveTab}
          />
        )}

        <Overlay
          onClick={() => handleClose()}
        />
      </Frame>

    </ReactModal>
  )
}
