import { FC } from 'react'

import { useTranslation } from 'next-i18next'
import { CryptoCurrencyIcons } from 'components/Profile/Wallet/CryptoCurrencyIcons'
import { PaymentMethodCard } from 'components/Profile/Wallet/PaymentMethodCard'
import { useAppContext } from 'context/AppContext'
import { IPaymentMethod } from 'data/interfaces/IPaymentMethod'
import usePressAndHover from 'hooks/usePressAndHover'

import styles from './index.module.scss'

interface Props {
  method: IPaymentMethod
  selected?: boolean
  onClick?: () => void
}

export const PaymentMethodCryptoCard: FC<Props> = (props) => {
  const { t } = useTranslation()
  const appContext = useAppContext()

  const [ref, _, hover] = usePressAndHover()

  const currenciesIso = props.method.paymentSystems
    .map((paymentSystem) =>
      paymentSystem.settings.map((settings) => settings.currencyIso),
    )
    .flat()

  const currencies = appContext.currencies.filter((currency) =>
    currenciesIso.includes(currency.iso),
  )

  return (
    <PaymentMethodCard
      ref={ref}
      className={styles.root}
      onClick={props.onClick}
      selected={props.selected}
    >
      <div className={styles.icons}>
        <CryptoCurrencyIcons
          currencies={currencies}
          selected={hover || props.selected}
        />
      </div>

      <div className={styles.label}>
        {t('wallet_payment_type_crypto')}
      </div>
    </PaymentMethodCard>
  )
}
