import { FC } from 'react'

import { SvgIcon } from 'ui-kit/svg'
import { COLORS } from 'ui-kit/constants'
import { useFavoriteContext } from 'context/favorite_state'

import { SFavoriteButton } from './styles'


const FavoriteButton: FC<{ id: number }> = ({ id }) => {
  const favoriteContext = useFavoriteContext()
  const active = favoriteContext.store.games.includes(id)

  const handleBoxClick = () => active ? favoriteContext.unlike(id) : favoriteContext.like(id)

  return (
    <SFavoriteButton onClick={handleBoxClick}>
      {active ? (
        <SvgIcon color={COLORS.white}
          type='star-full'
        />
      ) : (
        <SvgIcon color={COLORS.white}
          type='star-empty'
        />
      )}
    </SFavoriteButton>
  )
}

export default FavoriteButton
