import React from 'react'

import ReactModal from 'react-modal'
import { useRouter } from "next/router"

import { useNotificationsContext } from "../../../context/notificationsContext"

import { SButton, SButtonBox, SCloseIcon, SContent, SHeader, SText } from "./styles"

export const NotificationModal: React.FC = () => {
  const router = useRouter()
  const { notificationModalData, setNotificationModalData } = useNotificationsContext()

  if (!notificationModalData) {
    return null
  }

  const { title, content, button, link } = notificationModalData

  const handleClose = () => {
    setNotificationModalData(null)
  }

  const handleBtnClick = () => {
    if (link) {
      router.push(link)
    } else {
      handleClose()
    }
  }

  return (
    <ReactModal
      isOpen
      shouldCloseOnOverlayClick
      onRequestClose={handleClose}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 300
        },
        content: {
          background: 'transparent',
          border: 'none',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0
        }
      }}
    >
      <SContent>
        <SHeader>
          {title}
        </SHeader>

        <SCloseIcon
          type='close'
          onClick={handleClose}
        />

        <SText>
          {content}
        </SText>

        {!!button && (
          <SButtonBox>
            <SButton color="blue"
              onClick={handleBtnClick}
            >
              {button}
            </SButton>
          </SButtonBox>
        )}
      </SContent>

    </ReactModal>
  )
}
