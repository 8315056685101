import React from 'react'

import styled from 'styled-components'

interface Props extends React.SVGAttributes<SVGSVGElement> {
  className?: string,
  color?: string
}

export const BackArrowIcon: React.FC<Props> = ({ className = '', ...props }) => {
  return (
    <StyledSvg width='14'
      height='15'
      viewBox='0 0 14 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14 7.02734C14 7.51059 13.6364 7.90234 13.1878 7.90234L0.813459 7.90235C0.364915 7.90235 0.00128645 7.5106
          0.00128647 7.02735C0.00128649 6.5441 0.364915 6.15235 0.813459 6.15235L13.1878 6.15234C13.6364 6.15234 14 6.54409 14 7.02734Z'
        fill='#686A7B'
      />

      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.22084 0.286307C7.51792 0.622395 7.50829 1.15682 7.19932 1.47999L1.89566 7.02722L7.19932 12.5745C7.50829
        12.8976 7.51792 13.432 7.22084 13.7681C6.92375 14.1042 6.43244 14.1147 6.12347 13.7915L0.237992 7.63576C0.0858137
        7.4766 -0.000189103 7.25686 -0.000189094 7.02722C-0.000189086 6.79758 0.0858137 6.57785 0.237992 6.41868L6.12347
        0.262901C6.43244 -0.0602609 6.92375 -0.049782 7.22084 0.286307Z'
        fill='#686A7B'
      />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  path {
    fill: currentColor;
  }
`
