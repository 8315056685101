import { FC, useEffect, useState } from 'react'

import { Form, Formik } from 'formik'
import { useTranslation } from 'next-i18next'
import Button from 'components/ui/Button'
import FormError from 'components/ui/Form/FormError'
import AmountCurrencyField from 'components/ui/Inputs/AmountCurrencyField'
import InputField from 'components/ui/Inputs/InputField'
import { PaymentOptions } from 'components/Profile/Wallet/PaymentOptions'
import { PaymentSeparator } from 'components/Profile/Wallet/PaymentSeparator'
import { PaymentFormExtraFields } from 'components/Profile/Wallet/PaymentFormExtraFields'
import { PaymentMethodSelected } from 'components/Profile/Wallet/PaymentMethodSelected'
import { PaymentCurrencySelected } from 'components/Profile/Wallet/PaymentCurrencySelected'
import { useAppContext } from 'context/AppContext'
import { PaymentSwitchFilterKey, ProfileModalType } from 'types/enums'
import { PaymentHistoryModalArguments } from 'types/interfaces'
import PaymentMethodRepository from 'data/repositories/PaymentMethodRepository'
import PaymentsRepository from 'data/repositories/PaymentsRepository'
import { ICurrency } from 'data/interfaces/ICurrency'
import { IPaymentMethod } from 'data/interfaces/IPaymentMethod'
import { IPaymentSystem } from 'data/interfaces/IPaymentSystem'
import { IPaymentMethodField } from 'data/interfaces/IPaymentFields'
import { BrowserUtils } from 'utils/browser'
import Validator from 'utils/validator'

import classes from './StepForm.module.scss'

interface Props {
  paymentMethod: IPaymentMethod
  paymentSystem: IPaymentSystem
  currency: ICurrency
  onPaymentMethodClick: () => void
  onCurrencyClick: () => void,
  onGoToInfoStep: () => void
}

export const StepForm: FC<Props> = ({
  paymentMethod,
  paymentSystem,
  currency,
  onPaymentMethodClick,
  onCurrencyClick,
  onGoToInfoStep
}) => {
  const context = useAppContext()

  const { t, i18n } = useTranslation()

  const [sending, setSending] = useState(false)
  const [error, setError] = useState(null)
  const [fields, setFields] = useState<IPaymentMethodField[]>([])

  const currentSettings = paymentSystem.settings.find(
    (i) => i.currencyIso === currency.iso,
  )
  const min = currentSettings?.withdraw?.minAmount ?? 0
  const max = currentSettings?.withdraw?.maxAmount ?? 0
  const initialValues = {
    amount: min,
    address: '',
    cardOwnerName: '',
    cardExpiry: '',
  }

  useEffect(() => {
    if (!paymentSystem.systemCode) return

    PaymentMethodRepository.fetchWithdrawalFields(
      paymentSystem.systemCode,
      paymentSystem.id,
    ).then((fields) => {
      setFields(fields)
    })
  }, [])

  const validateMinMax = (value: number) => {
    const num = parseFloat(value.toString())

    if (min && num < min) {
      return t('form_field_validation_amount_less', { number: min })
    }

    if (max && num > max) {
      return t('form_field_validation_amount_greater', { number: max })
    }

    return undefined
  }

  const handleSubmit = async (data) => {
    setError(null)
    setSending(true)

    try {
      if (paymentMethod.isCrypto) {
        await PaymentsRepository.withdrawCrypto(
          currency.iso,
          paymentSystem.id,
          paymentSystem.systemCode,
          data.amount,
          data.address,
        )

        context.showModalProfile(ProfileModalType.paymentHistory, {
          filter: PaymentSwitchFilterKey.Applications,
        } as PaymentHistoryModalArguments)
      } else {
        const formattedData = {
          ...data,
          extra_data: data.extra_data || {},
          ...(paymentSystem?.isBrowserInfoRequired
            ? {
              browser_info: BrowserUtils.getDetailsForPayment(
                i18n.language,
              ),
            }
            : {}),
        }

        const res = await PaymentsRepository.withdrawFiat(
          currency.iso,
          paymentSystem.id,
          paymentSystem.systemCode,
          `${window.location.origin}?withdrawal=1`,
          data.amount,
          data.address,
          data.cardHolderName,
          data.cardExpiry,
          formattedData
        )

        if (res.is_redirect_url_required || !res.url) {
          onGoToInfoStep()

          return
        }

        if (res.url) {
          window.location.href = res.url
        }
      }
    } catch (e) {
      setError(e)
    }

    setSending(false)
  }

  return (
    <div className={classes.box}>
      <PaymentOptions>
        <PaymentMethodSelected
          method={paymentMethod}
          paymentSystem={paymentSystem}
          onClick={onPaymentMethodClick}
        />

        <PaymentCurrencySelected
          currency={currency}
          onClick={onCurrencyClick}
        />
      </PaymentOptions>

      <PaymentSeparator />

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <PaymentFormExtraFields
              fields={fields}
              sending={sending}
              defaultCountry={context.countryByIp?.iso}
            />

            <div className={classes.label}>
              {t('withdraw_form_sum')}

              <div className={classes.limit}>
                {t('withdraw_form_limit')}

                {' '}

                {min}

-
                {max}
              </div>
            </div>

            <AmountCurrencyField
              name={'amount'}
              currency={currency.iso}
              className={classes.input}
              disabled={sending}
              validate={Validator.combine([Validator.required, validateMinMax])}
            />

            {(paymentMethod.isCrypto || paymentSystem?.isWalletRequired) && (
              <>
                <div className={classes.label}>
                  {!paymentMethod.isCrypto && t('withdraw_form_address')}

                  {!!paymentMethod.isCrypto && 'Address'}
                </div>

                <InputField
                  name={'address'}
                  disabled={sending}
                  className={classes.input}
                  validate={Validator.required}
                />
              </>
            )}

            {!paymentMethod.isCrypto && paymentSystem?.isCardDataRequired && (
              <>
                <div className={classes.label}>
                  {t('withdraw_form_card_owner')}
                </div>

                <InputField
                  name={'cardOwnerName'}
                  disabled={sending}
                  className={classes.input}
                  validate={Validator.required}
                />

                <div className={classes.label}>
                  {t('withdraw_form_card_expiry')}
                </div>

                <InputField
                  name={'cardExpiry'}
                  placeholder={'01/25'}
                  disabled={sending}
                  className={classes.input}
                  format={'cardExpiry'}
                  validate={Validator.cardExpiryValidation}
                />
              </>
            )}

            <FormError error={error} />

            <Button
              type="submit"
              size="normal"
              spinner={sending}
              background="blueGradient500"
              className={classes.button}
            >
              {t('withdraw_form_button_continue')}
            </Button>

            {
              (paymentSystem.name ==='Interac' || Number(paymentSystem.id) === 719) && (
                <p className={classes.description}>
                  ®Trade-mark of Interac Corp. Used under license.
                </p>
              )}
          </Form>
        )}
      </Formik>
    </div>
  )
}
