import { useState } from 'react'

import { useTranslation } from 'next-i18next'
import { Form, Formik } from 'formik'
import Button from 'components/ui/Button'
import InputField from 'components/ui/Inputs/InputField'
import Validator from 'utils/validator'
import { useAppContext } from 'context/AppContext'
import Formatter from 'utils/formatter'
import AuthRepository from 'data/repositories/AuthRepository'
import { ProfileModalType } from 'types/enums'
import FormError from 'components/ui/Form/FormError'
import { RegistrationPhoneModalArguments } from 'types/interfaces'
import ProfileModalLayout from 'components/Profile/layout/ProfileModalLayout'
import ModalHeader from 'components/Profile/layout/ProfileModalHeader/ModalHeader'
import ProfileModalBody from 'components/Profile/layout/ProfileModalBody'
import BottomSheetLayout from 'components/layout/BottomSheetLayout'
import BottomSheetHeader from 'components/layout/BottomSheetHeader'
import BottomSheetBody from 'components/layout/BottomSheetBody'

import styles from './index.module.scss'

interface Props {
  isBottomSheet?: boolean
}

export default function ModalRegistrationPhone(props: Props) {
  const context = useAppContext()
  const args = context.modalArguments as RegistrationPhoneModalArguments

  const [error, setError] = useState<string | null>(null)
  const [sending, setSending] = useState<boolean>(false)

  const handleSubmit = async (data) => {
    try {
      setSending(true)
      setError(null)
      const res = await AuthRepository.registerPhone({
        code:  data.code,
        phone: Formatter.cleanPhone(args.phone),
        password: data.password
      })
      const accessToken = res.token

      if (!accessToken) {
        setError(t('registration_error'))
      }

      context.setToken(accessToken)
      await context.updateUserFromCookies()
      setSending(false)
      context.showModal(ProfileModalType.wallet)
      context.setIsUserJustRegistered(true)
    } catch (e) {
      setError(e)
    }
    setSending(false)
  }
  const initialValues = {
    code: '',
    password: '',
    newPassword: ''
  }


  const { t } = useTranslation('common')

  const result = (
    <Formik initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <Form className={styles.form}>
          <div className={styles.description}>
            {t('registration_phone_text_1')} <span className={styles.code}>
              {' '}

              {t('registration_phone_text_2')}
                                             </span> {t('registration_phone_text_3')}
&nbsp;
            {t('registration_phone_text_4')} <span className={styles.phone}>
              {' '}

              {Formatter.formatPhone(args?.phone)}
                                             </span>
          </div>

          <div className={styles.inputs}>
            <InputField
              name={'code'}
              disabled={sending}
              placeholder={t('registration_phone_field_code')}
              validate={Validator.required}
            />

            <InputField
              name={'password'}
              type={'password'}
              obscure={true}
              disabled={sending}
              placeholder={t('registration_phone_field_password')}
              validate={Validator.required}
            />

            <InputField
              name={'passwordConfirm'}
              type={'password'}
              obscure={true}
              disabled={sending}
              placeholder={t('registration_phone_field_password_confirm')}
              validate={Validator.combine([Validator.required, Validator.passwordsMustMatch(values)])}
            />
          </div>

          <FormError error={error} />

          <Button type='submit'
            fluid
            spinner={sending}
            className={styles.button}
            size='submit'
            background='blueGradient500'
          >
            {t('registration_phone_button')}
          </Button>

        </Form>
      )}
    </Formik>
  )
  if(props.isBottomSheet){
    return (
      <BottomSheetLayout>
        <BottomSheetHeader title={t('registration_phone_title')} />

        <BottomSheetBody>
          {result}
        </BottomSheetBody>
      </BottomSheetLayout>
    )
  }else {
    return (
      <ProfileModalLayout>
        <ModalHeader title={t('registration_phone_title')} />

        <ProfileModalBody>
          {result}
        </ProfileModalBody>
      </ProfileModalLayout>
    )
  }
}
